import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'
import $ from 'jquery'

const IMAGE_TYPE = 'JPEG'
const DATA_TYPE = 'image/jpeg'
const X_START_POS = 0
const Y_START_POS = 0
const A4_LANDSCAPE_WIDTH_MM = 297
const A4_LANDSCAPE_HEIGHT_MM = 210

const getCanvas = async (htmlElement: HTMLElement) => {
  const canvasOptions = {
    scale: 1.5,
    onclone: (document: Document, _: HTMLElement) => {
      document.getElementById('print')!.style.display = 'block'
    },
  }

  return await html2canvas(htmlElement, canvasOptions)
}

const addImage = (pdf: jsPDF, canvas: HTMLCanvasElement, printType: 'landscape' | 'portrait') => {
  pdf.addImage(
    canvas.toDataURL(DATA_TYPE),
    IMAGE_TYPE,
    X_START_POS,
    Y_START_POS,
    printType === 'landscape' ? A4_LANDSCAPE_WIDTH_MM : A4_LANDSCAPE_HEIGHT_MM,
    printType === 'landscape' ? A4_LANDSCAPE_HEIGHT_MM : A4_LANDSCAPE_WIDTH_MM
  )
}

const generatePdf = async (
  printType: 'landscape' | 'portrait' = 'landscape',
  updateProgress?: (progress: number) => void
) => {
  const elementsToPrint = $.map($('.page'), (n) => n as HTMLElement)
  const pdf = new jsPDF(printType, 'mm', 'a4')
  for (let i = 0; i < elementsToPrint.length; i++) {
    const element = elementsToPrint[i]
    const canvas = await getCanvas(element)
    addImage(pdf, canvas, printType)
    if (updateProgress) {
      const progress = ((i + 1) * 100) / elementsToPrint.length
      updateProgress(Math.round(progress))
    }

    if (i + 1 < elementsToPrint.length) pdf.addPage()
  }

  pdf.save('Report.pdf') // TODO: add name and timestamp
}

export { generatePdf }
