import React from 'react'
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Divider,
  ListItemIcon,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link as RouterLink } from 'react-router-dom'
import { GetBuyerOrganizationPersonalDTO } from '../../../api/api.generated'

interface BuyerPersonelDetailsProps {
  personnel: GetBuyerOrganizationPersonalDTO[]
  buyerID: number
}

export const BuyerPersonelDetails: React.FC<BuyerPersonelDetailsProps> = ({
  personnel,
  buyerID,
}) => {
  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h6">Personnel</Typography>
      <Button
        variant="contained"
        color="secondary"
        component={RouterLink}
        to={`/dropzone/buyersurvey/${buyerID}`}
      >
        Upload Buyer Personnel Survey Responses
      </Button>
      <List>
        {personnel.map((person) => (
          <React.Fragment key={person.personalId}>
            <ListItem>
              {person.completedSurvey && (
                <ListItemIcon>
                  <CheckCircleIcon style={{ color: 'green' }} />
                </ListItemIcon>
              )}
              <ListItemText primary={person.email} secondary={`ID: ${person.personalId}`} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )
}
