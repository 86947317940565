import { IconButton } from '@mui/material'
import { useState } from 'react'
import TextInput, { TextInputProps } from './TextInput'
import Visibility from '@mui/icons-material/Visibility'
import VisibilityOff from '@mui/icons-material/VisibilityOff'

interface PasswordInputProps extends Omit<TextInputProps, 'type' | 'endAdornment'> {}

const PasswordInput: React.FunctionComponent<PasswordInputProps> = ({ ...rest }) => {
  const [showPassword, setShowPassword] = useState(false)

  return (
    <TextInput
      type={showPassword ? 'text' : 'password'}
      endAdornment={
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => setShowPassword(!showPassword)}
          edge="end"
          size="small"
          tabIndex={-1}
        >
          {showPassword ? <VisibilityOff /> : <Visibility />}
        </IconButton>
      }
      {...rest}
    />
  )
}

export default PasswordInput
