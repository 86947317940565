import { BuyerDashboard, HiddenBuyerDashboardReport } from './BuyerDashboard/BuyerDashboard'
import { BuyerSupplierDashboard } from './BuyerSupplierDashboard/BuyerSupplierDashboard'
import { GetCombinedProfileResponse } from '../../api/api.generated'
import { useState } from 'react'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'

export interface CombinedProfileProps {
  combinedProfile: GetCombinedProfileResponse
}

export default function ContractDashboardContainer({ combinedProfile }: CombinedProfileProps) {
  const [showReport, setShowReport] = useState<boolean>(false)
  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={<Checkbox checked={showReport} onChange={() => setShowReport(!showReport)} />}
          label="Show Report Preview"
        />
      </FormGroup>
      <BuyerDashboard
        buyerId={combinedProfile.buyerOrgId.toString()}
        isReportView={false}
        buyerProfile={combinedProfile.buyerProfile}
        stage={combinedProfile.rapStage}
        reportingPeriod={combinedProfile.reportingPeriod.slice(0, 10)}
      />
      {combinedProfile.buyerSupplierProfiles.map((x) => (
        <BuyerSupplierDashboard
          isReportView={false}
          key={x.supplierName}
          buyerSupplierProfile={x}
          reportingPeriod={combinedProfile.reportingPeriod.slice(0, 10)}
        />
      ))}
      <HiddenBuyerDashboardReport combinedProfile={combinedProfile} previewReport={showReport} />
    </>
  )
}
