import { useParams } from 'react-router-dom'
// import AuthenticatedRoute from "../../../routes/AuthenticatedRoute";
import FullscreenContainer from '../../common/containers/FullscreenContainer'
import LoadingContainer from '../../common/containers/LoadingContainer'
import DropzoneComponent from './DropzoneComponent'
import AuthenticatedRoute from '../../../routes/AuthenticatedRoute'

const DropzoneRouter: React.FC<{
  uploadType: 'buyer' | 'supplier' | 'personnel' | 'rap' | 'buyerResponses' | 'supplierResponses'
}> = ({ uploadType }) => {
  const { buyerId } = useParams()

  return (
    <AuthenticatedRoute>
      <FullscreenContainer bgColor="#FFFFFF">
        <LoadingContainer isLoading={false} isErrored={false}>
          <DropzoneComponent
            uploadType={uploadType}
            buyerOrganizationId={buyerId ? parseInt(buyerId) : undefined}
          />
        </LoadingContainer>
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}

export default DropzoneRouter
