import React, { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import {
  Container,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
  Divider,
  Button,
  ListItemIcon,
} from '@mui/material'
import useApi from '../../../hooks/useApi'
import { dataProcessingClient } from '../../../data/myApis'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { Link as RouterLink } from 'react-router-dom'

const SupplierDetails: React.FC = () => {
  const navigate = useNavigate()
  const { supplierId } = useParams<{ supplierId: string }>()
  const supplierIdNum = parseInt(supplierId!, 10)

  const { callApi, data, state } = useApi(
    dataProcessingClient.getSupplierRelationship.bind(dataProcessingClient, supplierIdNum)
  )

  useEffect(() => {
    // Ensure buyerIdNum is not null before calling the API
    if (supplierIdNum !== null) {
      callApi()
    }
    // eslint-disable-next-line
  }, [])

  if (state === 'loading') {
    return <CircularProgress />
  }

  if (state === 'errored') {
    return <Typography variant="body1">Error loading data.</Typography>
  }

  return (
    <Container maxWidth="md">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Supplier Details
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h4" gutterBottom>
          {`Supplier Name: ${data?.supplier?.name} ( ID: ${data?.supplier?.supplierId})`}
        </Typography>
        <Divider style={{ marginBottom: 20 }} />

        <Typography variant="subtitle1">
          Contract Name:
          <Typography variant="body2" component="span">
            {data?.supplier?.contractName || 'N/A'}
          </Typography>
        </Typography>

        {/* Map other fields */}
        <Typography variant="subtitle1">
          Industry:
          <Typography variant="body2" component="span">
            {data?.supplier?.industry || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Service Category Name:
          <Typography variant="body2" component="span">
            {data?.supplier?.serviceCategoryName || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Contract Value:
          <Typography variant="body2" component="span">
            {data?.supplier?.contractValue || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Contract Term:
          <Typography variant="body2" component="span">
            {data?.supplier?.contractTerm || 'N/A'}
          </Typography>
        </Typography>
        <br />
        <Divider />
        <br />
        <Paper style={{ marginBottom: 2 }}>
          <Typography variant="h6">Personnel</Typography>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={`/dropzone/suppliersurvey/${supplierId}`}
          >
            Upload Supplier Personnel Survey Responses
          </Button>
          <List>
            {data?.supplier?.personnel.map((person, index) => (
              <ListItem key={index}>
                {person.completedSurvey && (
                  <ListItemIcon>
                    <CheckCircleIcon style={{ color: 'green' }} />
                  </ListItemIcon>
                )}
                <ListItemText
                  primary={person.email}
                  secondary={`ID: ${data?.supplier?.supplierId}`}
                />
              </ListItem>
            ))}
          </List>
        </Paper>
      </Paper>
    </Container>
  )
}

export default SupplierDetails
