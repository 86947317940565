import { Box, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import CircularIndicator from '../common/outputs/CircularIndicator'
import DecorationCircle from './DecorationCircle'

export default function RapImpactAndReconciliationIndex({
  reconIndex,
  rapImpactPc,
}: {
  reconIndex: number
  rapImpactPc: number
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          Organisational Progress and Trends
        </Typography>
      </Box>
      <Grid container spacing={0} mt={24}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" mb={1}>
            <CircularIndicator
              value={rapImpactPc}
              label={rapImpactPc + '%'}
              color="#CE6632"
              scale={2.2}
              labelStyle={{
                fontSize: '35px',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box width={'60%'}>
            <Typography fontSize={30} fontWeight={600} color={'#CE6632'}>
              RAP Impact
            </Typography>
            <Typography fontSize={20} mt={2}>
              This metric serves to quantify the broad impact that the RAP is exerting on your
              organisation’s overarching mission for reconciliation. It provides an indication of
              the effectiveness of the RAP’s core pillar actions in driving tangible changes and
              advancements toward achieving the organisation’s broader goals related to
              reconciliation.
            </Typography>
            <Typography fontSize={20} mt={2}>
              As a baseline is required, the RAP Impact metric only becomes activated in all
              subsequent assessments following the completion of the initial Reconciliation Impact
              Assessment Report.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={0} mt={16}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" mb={1}>
            <CircularIndicator
              value={reconIndex}
              label={reconIndex + '%'}
              color="#CE6632"
              scale={2.2}
              labelStyle={{
                fontSize: '35px',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={10}>
          <Box width={'60%'}>
            <Typography fontSize={30} fontWeight={600} color={'#CE6632'}>
              Reconciliation Index
            </Typography>
            <Typography fontSize={20} mt={2}>
              This indicates where the business stands in its connection with indigenous suppliers
              on the path to complete Reconciliation. The index considers various aspects, providing
              a nuanced view of progress in building a partnership aligned with reconciliation
              principles. Essentially, it’s a dynamic gauge reflecting how the relationship is
              evolving and moving towards the key goal of genuine and meaningful reconciliation.
            </Typography>
          </Box>
        </Grid>
      </Grid>
      <Box
        position={'absolute'}
        top={'200px'}
        left={'1000px'}
        style={{
          opacity: '20%',
        }}
      >
        <DecorationCircle scale={1.5} />
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={17} />
    </Box>
  )
}
