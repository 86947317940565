import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'
import { ScalableProps } from '../../utils/typeUtils'

export default function SwotAnalysis() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          SWOT Analysis
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'}>
        <Box width={'33%'}>
          <TextBlock title={'Strengths'} color={'#CE6632'}>
            <ol
              style={{
                marginTop: '36px',
                paddingInlineStart: '20px',
                fontSize: '17px',
              }}
            >
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
            </ol>
          </TextBlock>
          <TextBlock title={'Opportunities'} color={'#FAA338'}>
            <ol
              style={{
                marginTop: '36px',
                paddingInlineStart: '20px',
                fontSize: '17px',
              }}
            >
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
            </ol>
          </TextBlock>
        </Box>
        <Box width={'33%'}>
          <TextBlock title={'Weaknesses'} color={'#A42A2E'}>
            <ol
              style={{
                marginTop: '36px',
                paddingInlineStart: '20px',
                fontSize: '17px',
              }}
            >
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
            </ol>
          </TextBlock>
          <TextBlock title={'Threats'} color={'#A4DDEF'}>
            <ol
              style={{
                marginTop: '36px',
                paddingInlineStart: '20px',
                fontSize: '17px',
              }}
            >
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
              <StyledOrderList>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore.
              </StyledOrderList>
            </ol>
          </TextBlock>
        </Box>
      </Box>

      <Box position={'absolute'} top={'38%'} left={'30%'}>
        <Background scale={1.95} />
      </Box>
      <Box position={'absolute'} top={'55%'} left={'43%'}>
        <Typography fontSize={70} fontWeight={900} color={'#ffffff'}>
          S
        </Typography>
      </Box>
      <Box position={'absolute'} top={'55%'} left={'51%'}>
        <Typography fontSize={70} fontWeight={900} color={'#ffffff'}>
          W
        </Typography>
      </Box>
      <Box position={'absolute'} top={'68%'} left={'43%'}>
        <Typography fontSize={70} fontWeight={900} color={'#ffffff'}>
          O
        </Typography>
      </Box>
      <Box position={'absolute'} top={'68%'} left={'52%'}>
        <Typography fontSize={70} fontWeight={900} color={'#ffffff'}>
          T
        </Typography>
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={7} />
    </Box>
  )
}

function StyledOrderList({ children }: PropsWithChildren) {
  return (
    <li style={{ paddingLeft: '15px', marginTop: '5px' }}>
      <Typography>{children}</Typography>
    </li>
  )
}

type TextBlockProps = PropsWithChildren & {
  title: string
  color: string
}

function TextBlock({ title, color, children }: TextBlockProps) {
  return (
    <Box mt={12}>
      <Typography fontSize={26} color={color} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={14} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}

function Background({ scale }: ScalableProps) {
  return (
    <svg
      width={scale ? scale * 321 : '321'}
      height={scale ? scale * 322 : '322'}
      viewBox="0 0 321 322"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="161" height="161" fill="url(#paint0_radial_0_1)" />
      <path d="M160.8 63C106.78 63 63 106.79 63 160.79H160.8V63Z" fill="#CD6632" />
      <rect width="160" height="161" transform="translate(161)" fill="url(#paint1_radial_0_1)" />
      <path d="M258.59 160.79C258.59 106.79 214.9 63 161 63V160.79H258.59Z" fill="#A3292E" />
      <rect width="161" height="161" transform="translate(0 161)" fill="url(#paint2_radial_0_1)" />
      <path d="M63 161C63 214.894 106.78 258.59 160.8 258.59V161H63Z" fill="#F9A238" />
      <g clip-path="url(#clip0_0_1)">
        <rect
          width="160"
          height="160"
          transform="translate(161 161)"
          fill="url(#paint3_radial_0_1)"
        />
        <path d="M161 258.59C214.9 258.59 258.59 214.89 258.59 161H161V258.59Z" fill="#A4DCEE" />
      </g>
    </svg>
  )
}
