import FullscreenContainer from '../components/common/containers/FullscreenContainer'
import Login from '../components/account/login'

const LoginRoute: React.FunctionComponent = () => (
  <FullscreenContainer>
    <Login />
  </FullscreenContainer>
)

export default LoginRoute
