import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'

export default function ReconciliationDimensionProfiles() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          Organisational Progress and Trends
        </Typography>
      </Box>
      <Typography mt={20} fontSize={46} fontWeight={600} lineHeight={1.2}>
        Reconciliation <br />
        Dimensional Profiles
      </Typography>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      />
      <Box display={'flex'} gap={15}>
        <Box width={'45%'} mb={8}>
          <Typography mt={12} fontSize={26} color={'#DB5C11'}>
            The Reconciliation Dimensional Profile reflects and compares the relationship qualities
            of the Buyer and indigenous suppliers as seen through the dimensional lens of
            <b> Race Relations, Equality and Equity, Institutional Integrity, Unity</b> and{' '}
            <b>Historical Acceptance.</b>
          </Typography>
        </Box>
        <Box width={'45%'} mb={8} mt={8}>
          <TextBlock title="How your personnel see your organisation">
            How your nominated personnel in key roles overseeing commercial relationships with
            Indigenous suppliers perceive their performance from a reconciliation standpoint.
          </TextBlock>
          <TextBlock title="How indigenous suppliers see your organisation">
            How key personnel within your Indigenous supplier's team perceive your organisation's
            engagement, integrations and performance from a reconciliation perspective.
          </TextBlock>
          <TextBlock title="Combined Dimensional Profile">
            A graphical chart that visually represents the results of both the Buyer (RAP)
            organisation and their indigenous suppliers.
          </TextBlock>
        </Box>
      </Box>
      <Copyright pageNumber={16} />
    </Box>
  )
}

type TextBlockProps = PropsWithChildren & {
  title: string
}

function TextBlock({ title, children }: TextBlockProps) {
  return (
    <Box mt={5}>
      <Typography fontSize={16} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={16} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}
