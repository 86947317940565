import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Button, Container, Paper, Typography, Divider } from '@mui/material'
import { dataProcessingClient } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import { RapDetails } from './RapDetails'
import { BuyerPersonelDetails } from './BuyerPersonelDetails'
import { BuyerSuppliersDetails } from './BuyerSupplierDetails'
import ReportDetails from './ReportDetails'
import { Link as RouterLink } from 'react-router-dom'

const BuyerDetails: React.FC = () => {
  const { buyerId } = useParams<{ buyerId: string }>()
  const buyerIdNum = parseInt(buyerId!, 10)

  const { callApi, data } = useApi(
    dataProcessingClient.getBuyerOrganisation.bind(dataProcessingClient, buyerIdNum)
  )

  useEffect(() => {
    if (buyerIdNum !== null) {
      callApi()
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Container maxWidth="md">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Typography variant="h4" gutterBottom>
          Buyer Details
        </Typography>
        <Button variant="contained" color="primary" component={RouterLink} to={`/console`}>
          Back
        </Button>
      </div>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h4" gutterBottom>
          {data?.buyerOrganization?.name || 'N/A'}
        </Typography>
        <Divider style={{ marginBottom: 20 }} />

        <Typography variant="subtitle1">
          Email:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.email || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Source App:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.email || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Name:
          <Typography variant="body2" component="span">
            {`${data?.buyerOrganization?.firstName} ${data?.buyerOrganization?.lastName}` || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Stage:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.stage || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Title:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.title || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Industry:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.industry || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Rap Stage:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.rapStage || 'N/A'}
          </Typography>
        </Typography>

        <Typography variant="subtitle1">
          Location:
          <Typography variant="body2" component="span">
            {data?.buyerOrganization?.location || 'N/A'}
          </Typography>
        </Typography>
      </Paper>

      <ReportDetails reportReadiness={data?.reportReadiness} buyerId={buyerId!} />
      <RapDetails rap={data?.buyerOrganization?.rap} buyerId={buyerId!} />
      <BuyerSuppliersDetails
        suppliers={data?.buyerOrganization?.suppliers || []}
        buyerId={buyerId!}
      />
      <BuyerPersonelDetails
        personnel={data?.buyerOrganization?.personnel || []}
        buyerID={buyerIdNum}
      />
    </Container>
  )
}

export default BuyerDetails
