import React from 'react'
import { GetUserResponse } from '../api/api.generated'

interface UserContextState {
  user?: GetUserResponse | null
  state: 'loading' | 'fulfilled' | 'errored' | 'idle'
  refreshUser: () => void
}

const userContext = React.createContext<UserContextState>({ state: 'idle', refreshUser: () => {} })

export default userContext
