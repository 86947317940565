import { Box, ButtonBase, Collapse } from '@mui/material'
import { PropsWithChildren, useState } from 'react'
import ArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import ArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'

interface AccordionWrapperProps {
  showAsAccordion?: boolean
  onChange?: (isOpen: boolean) => void
}

const AccordionWrapper: React.FC<PropsWithChildren<AccordionWrapperProps>> = ({
  children,
  showAsAccordion = true,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false)

  const updateState = (newState: boolean) => {
    setIsOpen(newState)
    onChange && onChange(newState)
  }

  return showAsAccordion ? (
    <Box>
      <Collapse in={isOpen}>{children}</Collapse>
      <ButtonBase onClick={() => updateState(!isOpen)} sx={{ width: 1 }}>
        <Box display="flex" justifyContent="center" alignItems="center" width={1} py={0.7}>
          {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
        </Box>
      </ButtonBase>
    </Box>
  ) : (
    <>{children}</>
  )
}

export default AccordionWrapper
