import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'

export default function AboutThisReport() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Box width={'55%'}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          About this report
        </Typography>
        <Typography mt={5} color={'#CE6632'} fontSize={26}>
          This report supports organisations to strengthen business relationships with indigenous
          suppliers by:
        </Typography>
        <ol
          style={{
            marginTop: '36px',
            paddingInlineStart: '20px',
            fontSize: '17px',
          }}
        >
          <StyledOrderList>
            Measuring indigenous supplier relationships based on the five reconciliation dimensions
            as defined by Reconciliation Australia.
          </StyledOrderList>
          <StyledOrderList>Tracking organisations RAP progress and performance</StyledOrderList>
          <StyledOrderList>
            Analysing and report on RAP core pillar actions impact in supporting the five
            reconciliation dimensions
          </StyledOrderList>
          <StyledOrderList>
            Generating trend and analysis data for the organisation and the reconciliation community
          </StyledOrderList>
        </ol>
        <Typography mt={3} fontSize={'17px'}>
          The Reconciliation Profile, an infographic derived from the organisation's Indigenous
          commercial relationships, their performance and the assessments undertaken by key
          personnel with influential roles.
        </Typography>
        <Typography mt={30} fontWeight={700}>
          TERMS OF USE
        </Typography>
        <Typography mt={3}>
          The information within this report is provided on and “as is” basis, without warranty.
          Contract Harmonics shall not have any liability to any person or entity for any damages
          whatsoever that result from the use of, this report. The limitations of this liability
          applies to all claims or causes whatsoever.
        </Typography>
      </Box>

      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={3} />
    </Box>
  )
}

function StyledOrderList({ children }: PropsWithChildren) {
  return <li style={{ paddingLeft: '15px' }}>{children}</li>
}
