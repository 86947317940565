export function s2ab(s: any) {
  const buffer = new ArrayBuffer(s.length)
  const view = new Uint8Array(buffer)
  for (let i = 0; i < s.length; i++) {
    view[i] = s.charCodeAt(i) & 0xff
  }
  return buffer
}

export const uploadTypeText = {
  buyer: 'Upload Buyer Organization',
  supplier: 'Upload Supplier Relationship',
  personnel: 'Upload Personnel',
  rap: 'Upload Rap Data',
  buyerResponses: 'Upload Buyer Personnel Survey responses',
  supplierResponses: 'Upload Supplier Personnel Survey responses',
}
