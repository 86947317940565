import { useMediaQuery, Box, Typography, Theme, useTheme } from '@mui/material'
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Text,
  Tooltip as RechartsTooltip,
} from 'recharts'
import { capitaliseFirstLetter } from '../../utils/stringUtils'

interface ContractProfileDetailsProps {
  metrics: { metric: string; buyer: number; supplier: number; combined: number }[]
  isReportView?: boolean
  dashboardType: 'buyer' | 'buyerSupplier'
}

const ContractProfileDetails: React.FC<ContractProfileDetailsProps> = ({
  metrics,
  dashboardType,
  isReportView = false,
}) => {
  const theme = useTheme()
  const isSmall = useMediaQuery(theme.breakpoints.down('sm')) && !isReportView
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  return (
    <Box
      mb={0.5}
      py={isMobile ? 3 : 4}
      px={isMobile ? 2 : 4}
      width={1}
      height={0.95}
      borderRadius="5px 5px 0px 0px"
      boxSizing="border-box"
    >
      <ContractProfileGraph
        dataPoints={metrics}
        isSmall={isSmall}
        isReportView={isReportView}
        dashboardType={dashboardType}
      />
    </Box>
  )
}

interface ContractProfileGraphProps {
  dataPoints: { metric: string; buyer: number; supplier: number; combined: number }[]
  isSmall?: boolean
  isReportView?: boolean
  dashboardType: 'buyer' | 'buyerSupplier'
}

const ContractProfileGraph: React.FC<ContractProfileGraphProps> = ({
  dataPoints,
  dashboardType,
  isSmall = false,
  isReportView = false,
}) => {
  const theme = useTheme()

  const innerChartContent = (theme: Theme, isSmall = false, isReportView = false) => (
    <>
      <Bar
        dataKey="combined"
        fill="#CE663250"
        radius={5}
        maxBarSize={isSmall ? 20 : 35}
        opacity={0.75}
        isAnimationActive={false}
      />
      <CartesianGrid strokeDasharray="3 8" vertical={false} />
      <XAxis
        dataKey="metric"
        axisLine={false}
        tickLine={false}
        type="category"
        tickMargin={6}
        minTickGap={-100}
        fontSize={isSmall ? 10 : 14}
        tick={<CustomXAxisTick />}
        height={50}
        style={{
          fontFamily: 'Montserrat, Lato, Roboto, sans-serif',
        }}
      />
      <YAxis
        max={100}
        ticks={[20, 40, 60, 80, 100]}
        axisLine={false}
        tickLine={false}
        fontSize={isSmall ? 12 : 14}
        tickMargin={0}
        style={{
          fontFamily: 'Montserrat, Lato, Roboto, sans-serif',
        }}
      />
      {!isReportView && (
        <RechartsTooltip
          formatter={(value: unknown, name: string) => [value, capitaliseFirstLetter(name)]}
        />
      )}
      <Legend
        verticalAlign="top"
        align="right"
        iconType={isReportView ? undefined : 'circle'}
        formatter={(label) => (
          <Typography component={'span'} color={theme.palette.common.black}>
            {capitaliseFirstLetter(label)}
          </Typography>
        )}
      />
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="supplier"
        fill={theme.palette.primary.main}
        stroke={theme.palette.primary.main}
        strokeWidth={isSmall ? 2 : 3}
      />
      <Line
        isAnimationActive={false}
        type="monotone"
        dataKey="buyer"
        fill={theme.palette.dataBlue.main}
        stroke={theme.palette.dataBlue.main}
        strokeWidth={isSmall ? 2 : 3}
      />
    </>
  )

  return (
    <Box height={1} ml={-6}>
      {isReportView ? (
        <ComposedChart
          width={dashboardType === 'buyer' ? 630 : 750}
          height={dashboardType === 'buyer' ? 350 : 350}
          data={dataPoints}
        >
          {innerChartContent(theme, isSmall, isReportView)}
        </ComposedChart>
      ) : (
        <ResponsiveContainer width="100%" maxHeight={isSmall ? 250 : 600}>
          <ComposedChart data={dataPoints}>
            {innerChartContent(theme, isSmall, isReportView)}
          </ComposedChart>
        </ResponsiveContainer>
      )}
    </Box>
  )
}

const CustomXAxisTick = ({ x, y, payload }: any) => {
  if (payload && payload.value) {
    return (
      <Text
        fontSize={'14px'}
        width={'12px'}
        x={x}
        y={y}
        textAnchor="middle"
        verticalAnchor="start"
        fill="#736A65"
      >
        {payload.value}
      </Text>
    )
  }
  return null
}

export default ContractProfileDetails
