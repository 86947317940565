import {
  LoadingButton as MuiLoadingButton,
  LoadingButtonProps as MuiLoadingButtonProps,
} from '@mui/lab'
import { PropsWithChildren } from 'react'

interface LoadingButtonProps
  extends Pick<
    MuiLoadingButtonProps,
    'disabled' | 'type' | 'onClick' | 'loading' | 'fullWidth' | 'color' | 'size'
  > {
  paddingX?: number
}

const LoadingButton: React.FunctionComponent<PropsWithChildren<LoadingButtonProps>> = ({
  children,
  fullWidth = true,
  color,
  size,
  paddingX = 8,
  ...rest
}) => (
  <MuiLoadingButton
    {...rest}
    fullWidth={fullWidth}
    color={color ?? 'primary'}
    variant="contained"
    size={size}
    disableElevation
    disabled={!!rest.loading || !!rest.disabled}
    sx={{
      borderRadius: 25,
      paddingX: paddingX,
    }}
  >
    {children}
  </MuiLoadingButton>
)

export default LoadingButton
