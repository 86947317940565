import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { ScalableProps } from '../../utils/typeUtils'

export default function ObservationsAndAnalysis() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Box width={'49%'}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          Observation and analysis
        </Typography>
        <Typography mt={15} color={'#CE6632'} fontSize={26}>
          This section provides a summary of assessment results, together with observations and
          analysis on; RAP progress, Organisational and industry trends and Indigenous supplier
          relationships.
        </Typography>
      </Box>

      <Box position={'absolute'} top={'-10px'} left={'1100px'}>
        <DotsBackground scale={1.95} />
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={4} />
    </Box>
  )
}

function DotsBackground({ scale }: ScalableProps) {
  return (
    <svg
      width={scale ? scale * 196 : '196'}
      height={scale ? scale * 596 : '596'}
      viewBox="0 0 196 596"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_37_1051)">
        <path
          d="M0.350098 107.92C0.350098 99.99 6.7801 93.56 14.7101 93.56C22.6401 93.56 29.0701 99.99 29.0701 107.92C29.0701 115.85 22.6401 122.28 14.7101 122.28C6.7801 122.28 0.350098 115.85 0.350098 107.92Z"
          fill="#601410"
        />
        <path
          d="M70.6101 107.92C70.6101 115.85 64.1801 122.28 56.2501 122.28C48.3201 122.28 41.8901 115.85 41.8901 107.92C41.8901 99.99 48.3201 93.56 56.2501 93.56C64.1801 93.56 70.6101 99.99 70.6101 107.92Z"
          fill="#601410"
        />
        <path
          d="M112.14 107.92C112.14 115.85 105.71 122.28 97.7802 122.28C89.8502 122.28 83.4202 115.85 83.4202 107.92C83.4202 99.99 89.8502 93.56 97.7802 93.56C105.71 93.56 112.14 99.99 112.14 107.92Z"
          fill="#601410"
        />
        <path
          d="M153.68 107.92C153.68 115.85 147.25 122.28 139.32 122.28C131.39 122.28 124.96 115.85 124.96 107.92C124.96 99.99 131.39 93.56 139.32 93.56C147.25 93.56 153.68 99.99 153.68 107.92Z"
          fill="#601410"
        />
        <path
          d="M195.22 107.92C195.22 115.85 188.79 122.28 180.86 122.28C172.93 122.28 166.5 115.85 166.5 107.92C166.5 99.99 172.93 93.56 180.86 93.56C188.79 93.56 195.22 99.99 195.22 107.92Z"
          fill="#601410"
        />
        <path
          d="M0.350098 65.47C0.350098 57.54 6.7801 51.11 14.7101 51.11C22.6401 51.11 29.0701 57.54 29.0701 65.47C29.0701 73.4 22.6401 79.83 14.7101 79.83C6.7801 79.83 0.350098 73.4 0.350098 65.47Z"
          fill="#601410"
        />
        <path
          d="M70.6101 65.47C70.6101 73.4 64.1801 79.83 56.2501 79.83C48.3201 79.83 41.8901 73.4 41.8901 65.47C41.8901 57.54 48.3201 51.11 56.2501 51.11C64.1801 51.11 70.6101 57.54 70.6101 65.47Z"
          fill="#601410"
        />
        <path
          d="M112.14 65.73C112.14 73.66 105.71 80.09 97.7802 80.09C89.8502 80.09 83.4202 73.66 83.4202 65.73C83.4202 57.8 89.8502 51.37 97.7802 51.37C105.71 51.37 112.14 57.8 112.14 65.73Z"
          fill="#601410"
        />
        <path
          d="M153.68 65.73C153.68 73.66 147.25 80.09 139.32 80.09C131.39 80.09 124.96 73.66 124.96 65.73C124.96 57.8 131.39 51.37 139.32 51.37C147.25 51.37 153.68 57.8 153.68 65.73Z"
          fill="#601410"
        />
        <path
          d="M195.22 65.73C195.22 73.66 188.79 80.09 180.86 80.09C172.93 80.09 166.5 73.66 166.5 65.73C166.5 57.8 172.93 51.37 180.86 51.37C188.79 51.37 195.22 57.8 195.22 65.73Z"
          fill="#601410"
        />
        <path
          d="M0.350098 23.28C0.350098 15.35 6.7801 8.91998 14.7101 8.91998C22.6401 8.91998 29.0701 15.35 29.0701 23.28C29.0701 31.21 22.6401 37.64 14.7101 37.64C6.7801 37.64 0.350098 31.21 0.350098 23.28Z"
          fill="#601410"
        />
        <path
          d="M70.6101 23.28C70.6101 31.21 64.1801 37.64 56.2501 37.64C48.3201 37.64 41.8901 31.21 41.8901 23.28C41.8901 15.35 48.3201 8.91998 56.2501 8.91998C64.1801 8.91998 70.6101 15.35 70.6101 23.28Z"
          fill="#601410"
        />
        <path
          d="M112.14 23.28C112.14 31.21 105.71 37.64 97.7802 37.64C89.8502 37.64 83.4202 31.21 83.4202 23.28C83.4202 15.35 89.8502 8.91998 97.7802 8.91998C105.71 8.91998 112.14 15.35 112.14 23.28Z"
          fill="#601410"
        />
        <path
          d="M153.68 23.28C153.68 31.21 147.25 37.64 139.32 37.64C131.39 37.64 124.96 31.21 124.96 23.28C124.96 15.35 131.39 8.91998 139.32 8.91998C147.25 8.91998 153.68 15.35 153.68 23.28Z"
          fill="#601410"
        />
        <path
          d="M195.22 23.28C195.22 31.21 188.79 37.64 180.86 37.64C172.93 37.64 166.5 31.21 166.5 23.28C166.5 15.35 172.93 8.91998 180.86 8.91998C188.79 8.91998 195.22 15.35 195.22 23.28Z"
          fill="#601410"
        />
        <path
          d="M0.670166 277.18C0.670166 269.25 7.10017 262.82 15.0302 262.82C22.9602 262.82 29.3902 269.25 29.3902 277.18C29.3902 285.11 22.9602 291.54 15.0302 291.54C7.10017 291.54 0.670166 285.11 0.670166 277.18Z"
          fill="#A5292F"
        />
        <path
          d="M70.9302 277.18C70.9302 285.11 64.5002 291.54 56.5702 291.54C48.6402 291.54 42.2102 285.11 42.2102 277.18C42.2102 269.25 48.6402 262.82 56.5702 262.82C64.5002 262.82 70.9302 269.25 70.9302 277.18Z"
          fill="#A5292F"
        />
        <path
          d="M112.47 277.18C112.47 285.11 106.04 291.54 98.11 291.54C90.18 291.54 83.75 285.11 83.75 277.18C83.75 269.25 90.18 262.82 98.11 262.82C106.04 262.82 112.47 269.25 112.47 277.18Z"
          fill="#A5292F"
        />
        <path
          d="M154 277.18C154 285.11 147.57 291.54 139.64 291.54C131.71 291.54 125.28 285.11 125.28 277.18C125.28 269.25 131.71 262.82 139.64 262.82C147.57 262.82 154 269.25 154 277.18Z"
          fill="#A5292F"
        />
        <path
          d="M195.54 277.18C195.54 285.11 189.11 291.54 181.18 291.54C173.25 291.54 166.82 285.11 166.82 277.18C166.82 269.25 173.25 262.82 181.18 262.82C189.11 262.82 195.54 269.25 195.54 277.18Z"
          fill="#A5292F"
        />
        <path
          d="M0.670166 234.74C0.670166 226.81 7.10017 220.38 15.0302 220.38C22.9602 220.38 29.3902 226.81 29.3902 234.74C29.3902 242.67 22.9602 249.1 15.0302 249.1C7.10017 249.1 0.670166 242.67 0.670166 234.74Z"
          fill="#7F2825"
        />
        <path
          d="M70.9302 234.74C70.9302 242.67 64.5002 249.1 56.5702 249.1C48.6402 249.1 42.2102 242.67 42.2102 234.74C42.2102 226.81 48.6402 220.38 56.5702 220.38C64.5002 220.38 70.9302 226.81 70.9302 234.74Z"
          fill="#7F2825"
        />
        <path
          d="M112.47 234.99C112.47 242.92 106.04 249.35 98.11 249.35C90.18 249.35 83.75 242.92 83.75 234.99C83.75 227.06 90.18 220.63 98.11 220.63C106.04 220.63 112.47 227.06 112.47 234.99Z"
          fill="#7F2825"
        />
        <path
          d="M154 234.99C154 242.92 147.57 249.35 139.64 249.35C131.71 249.35 125.28 242.92 125.28 234.99C125.28 227.06 131.71 220.63 139.64 220.63C147.57 220.63 154 227.06 154 234.99Z"
          fill="#7F2825"
        />
        <path
          d="M195.54 234.99C195.54 242.92 189.11 249.35 181.18 249.35C173.25 249.35 166.82 242.92 166.82 234.99C166.82 227.06 173.25 220.63 181.18 220.63C189.11 220.63 195.54 227.06 195.54 234.99Z"
          fill="#7F2825"
        />
        <path
          d="M0.670166 192.55C0.670166 184.62 7.10017 178.19 15.0302 178.19C22.9602 178.19 29.3902 184.62 29.3902 192.55C29.3902 200.48 22.9602 206.91 15.0302 206.91C7.10017 206.91 0.670166 200.48 0.670166 192.55Z"
          fill="#7F2825"
        />
        <path
          d="M70.9302 192.55C70.9302 200.48 64.5002 206.91 56.5702 206.91C48.6402 206.91 42.2102 200.48 42.2102 192.55C42.2102 184.62 48.6402 178.19 56.5702 178.19C64.5002 178.19 70.9302 184.62 70.9302 192.55Z"
          fill="#7F2825"
        />
        <path
          d="M112.47 192.55C112.47 200.48 106.04 206.91 98.11 206.91C90.18 206.91 83.75 200.48 83.75 192.55C83.75 184.62 90.18 178.19 98.11 178.19C106.04 178.19 112.47 184.62 112.47 192.55Z"
          fill="#7F2825"
        />
        <path
          d="M154 192.55C154 200.48 147.57 206.91 139.64 206.91C131.71 206.91 125.28 200.48 125.28 192.55C125.28 184.62 131.71 178.19 139.64 178.19C147.57 178.19 154 184.62 154 192.55Z"
          fill="#7F2825"
        />
        <path
          d="M195.54 192.55C195.54 200.48 189.11 206.91 181.18 206.91C173.25 206.91 166.82 200.48 166.82 192.55C166.82 184.62 173.25 178.19 181.18 178.19C189.11 178.19 195.54 184.62 195.54 192.55Z"
          fill="#7F2825"
        />
        <path
          d="M0.670166 150.11C0.670166 142.18 7.10017 135.75 15.0302 135.75C22.9602 135.75 29.3902 142.18 29.3902 150.11C29.3902 158.04 22.9602 164.47 15.0302 164.47C7.10017 164.47 0.670166 158.04 0.670166 150.11Z"
          fill="#7F2825"
        />
        <path
          d="M70.9302 150.11C70.9302 158.04 64.5002 164.47 56.5702 164.47C48.6402 164.47 42.2102 158.04 42.2102 150.11C42.2102 142.18 48.6402 135.75 56.5702 135.75C64.5002 135.75 70.9302 142.18 70.9302 150.11Z"
          fill="#7F2825"
        />
        <path
          d="M112.47 150.36C112.47 158.29 106.04 164.72 98.11 164.72C90.18 164.72 83.75 158.29 83.75 150.36C83.75 142.43 90.18 136 98.11 136C106.04 136 112.47 142.43 112.47 150.36Z"
          fill="#7F2825"
        />
        <path
          d="M154 150.36C154 158.29 147.57 164.72 139.64 164.72C131.71 164.72 125.28 158.29 125.28 150.36C125.28 142.43 131.71 136 139.64 136C147.57 136 154 142.43 154 150.36Z"
          fill="#7F2825"
        />
        <path
          d="M195.54 150.36C195.54 158.29 189.11 164.72 181.18 164.72C173.25 164.72 166.82 158.29 166.82 150.36C166.82 142.43 173.25 136 181.18 136C189.11 136 195.54 142.43 195.54 150.36Z"
          fill="#7F2825"
        />
        <path
          d="M0.350098 446.45C0.350098 438.52 6.7801 432.09 14.7101 432.09C22.6401 432.09 29.0701 438.52 29.0701 446.45C29.0701 454.38 22.6401 460.81 14.7101 460.81C6.7801 460.81 0.350098 454.38 0.350098 446.45Z"
          fill="#CF6632"
        />
        <path
          d="M70.6101 446.45C70.6101 454.38 64.1801 460.81 56.2501 460.81C48.3201 460.81 41.8901 454.38 41.8901 446.45C41.8901 438.52 48.3201 432.09 56.2501 432.09C64.1801 432.09 70.6101 438.52 70.6101 446.45Z"
          fill="#CF6632"
        />
        <path
          d="M112.14 446.45C112.14 454.38 105.71 460.81 97.7802 460.81C89.8502 460.81 83.4202 454.38 83.4202 446.45C83.4202 438.52 89.8502 432.09 97.7802 432.09C105.71 432.09 112.14 438.52 112.14 446.45Z"
          fill="#CF6632"
        />
        <path
          d="M153.68 446.45C153.68 454.38 147.25 460.81 139.32 460.81C131.39 460.81 124.96 454.38 124.96 446.45C124.96 438.52 131.39 432.09 139.32 432.09C147.25 432.09 153.68 438.52 153.68 446.45Z"
          fill="#CF6632"
        />
        <path
          d="M195.22 446.45C195.22 454.38 188.79 460.81 180.86 460.81C172.93 460.81 166.5 454.38 166.5 446.45C166.5 438.52 172.93 432.09 180.86 432.09C188.79 432.09 195.22 438.52 195.22 446.45Z"
          fill="#CF6632"
        />
        <path
          d="M0.350098 404C0.350098 396.07 6.7801 389.64 14.7101 389.64C22.6401 389.64 29.0701 396.07 29.0701 404C29.0701 411.93 22.6401 418.36 14.7101 418.36C6.7801 418.36 0.350098 411.93 0.350098 404Z"
          fill="#CF6632"
        />
        <path
          d="M70.6101 404C70.6101 411.93 64.1801 418.36 56.2501 418.36C48.3201 418.36 41.8901 411.93 41.8901 404C41.8901 396.07 48.3201 389.64 56.2501 389.64C64.1801 389.64 70.6101 396.07 70.6101 404Z"
          fill="#CF6632"
        />
        <path
          d="M112.14 404.26C112.14 412.19 105.71 418.62 97.7802 418.62C89.8502 418.62 83.4202 412.19 83.4202 404.26C83.4202 396.33 89.8502 389.9 97.7802 389.9C105.71 389.9 112.14 396.33 112.14 404.26Z"
          fill="#CF6632"
        />
        <path
          d="M153.68 404.26C153.68 412.19 147.25 418.62 139.32 418.62C131.39 418.62 124.96 412.19 124.96 404.26C124.96 396.33 131.39 389.9 139.32 389.9C147.25 389.9 153.68 396.33 153.68 404.26Z"
          fill="#CF6632"
        />
        <path
          d="M195.22 404.26C195.22 412.19 188.79 418.62 180.86 418.62C172.93 418.62 166.5 412.19 166.5 404.26C166.5 396.33 172.93 389.9 180.86 389.9C188.79 389.9 195.22 396.33 195.22 404.26Z"
          fill="#CF6632"
        />
        <path
          d="M0.350098 361.82C0.350098 353.89 6.7801 347.46 14.7101 347.46C22.6401 347.46 29.0701 353.89 29.0701 361.82C29.0701 369.75 22.6401 376.18 14.7101 376.18C6.7801 376.18 0.350098 369.75 0.350098 361.82Z"
          fill="#A5292F"
        />
        <path
          d="M70.6101 361.82C70.6101 369.75 64.1801 376.18 56.2501 376.18C48.3201 376.18 41.8901 369.75 41.8901 361.82C41.8901 353.89 48.3201 347.46 56.2501 347.46C64.1801 347.46 70.6101 353.89 70.6101 361.82Z"
          fill="#A5292F"
        />
        <path
          d="M112.14 361.82C112.14 369.75 105.71 376.18 97.7802 376.18C89.8502 376.18 83.4202 369.75 83.4202 361.82C83.4202 353.89 89.8502 347.46 97.7802 347.46C105.71 347.46 112.14 353.89 112.14 361.82Z"
          fill="#A5292F"
        />
        <path
          d="M153.68 361.82C153.68 369.75 147.25 376.18 139.32 376.18C131.39 376.18 124.96 369.75 124.96 361.82C124.96 353.89 131.39 347.46 139.32 347.46C147.25 347.46 153.68 353.89 153.68 361.82Z"
          fill="#A5292F"
        />
        <path
          d="M195.22 361.82C195.22 369.75 188.79 376.18 180.86 376.18C172.93 376.18 166.5 369.75 166.5 361.82C166.5 353.89 172.93 347.46 180.86 347.46C188.79 347.46 195.22 353.89 195.22 361.82Z"
          fill="#A5292F"
        />
        <path
          d="M0.350098 319.37C0.350098 311.44 6.7801 305.01 14.7101 305.01C22.6401 305.01 29.0701 311.44 29.0701 319.37C29.0701 327.3 22.6401 333.73 14.7101 333.73C6.7801 333.73 0.350098 327.3 0.350098 319.37Z"
          fill="#A5292F"
        />
        <path
          d="M70.6101 319.37C70.6101 327.3 64.1801 333.73 56.2501 333.73C48.3201 333.73 41.8901 327.3 41.8901 319.37C41.8901 311.44 48.3201 305.01 56.2501 305.01C64.1801 305.01 70.6101 311.44 70.6101 319.37Z"
          fill="#A5292F"
        />
        <path
          d="M112.14 319.63C112.14 327.56 105.71 333.99 97.7802 333.99C89.8502 333.99 83.4202 327.56 83.4202 319.63C83.4202 311.7 89.8502 305.27 97.7802 305.27C105.71 305.27 112.14 311.7 112.14 319.63Z"
          fill="#A5292F"
        />
        <path
          d="M153.68 319.63C153.68 327.56 147.25 333.99 139.32 333.99C131.39 333.99 124.96 327.56 124.96 319.63C124.96 311.7 131.39 305.27 139.32 305.27C147.25 305.27 153.68 311.7 153.68 319.63Z"
          fill="#A5292F"
        />
        <path
          d="M195.22 319.63C195.22 327.56 188.79 333.99 180.86 333.99C172.93 333.99 166.5 327.56 166.5 319.63C166.5 311.7 172.93 305.27 180.86 305.27C188.79 305.27 195.22 311.7 195.22 319.63Z"
          fill="#A5292F"
        />
        <path
          d="M0.670166 573.27C0.670166 565.34 7.10017 558.91 15.0302 558.91C22.9602 558.91 29.3902 565.34 29.3902 573.27C29.3902 581.2 22.9602 587.63 15.0302 587.63C7.10017 587.63 0.670166 581.2 0.670166 573.27Z"
          fill="#FAA33A"
        />
        <path
          d="M70.9302 573.27C70.9302 581.2 64.5002 587.63 56.5702 587.63C48.6402 587.63 42.2102 581.2 42.2102 573.27C42.2102 565.34 48.6402 558.91 56.5702 558.91C64.5002 558.91 70.9302 565.34 70.9302 573.27Z"
          fill="#FAA33A"
        />
        <path
          d="M112.47 573.53C112.47 581.46 106.04 587.89 98.11 587.89C90.18 587.89 83.75 581.46 83.75 573.53C83.75 565.6 90.18 559.17 98.11 559.17C106.04 559.17 112.47 565.6 112.47 573.53Z"
          fill="#FAA33A"
        />
        <path
          d="M154 573.53C154 581.46 147.57 587.89 139.64 587.89C131.71 587.89 125.28 581.46 125.28 573.53C125.28 565.6 131.71 559.17 139.64 559.17C147.57 559.17 154 565.6 154 573.53Z"
          fill="#FAA33A"
        />
        <path
          d="M195.54 573.53C195.54 581.46 189.11 587.89 181.18 587.89C173.25 587.89 166.82 581.46 166.82 573.53C166.82 565.6 173.25 559.17 181.18 559.17C189.11 559.17 195.54 565.6 195.54 573.53Z"
          fill="#FAA33A"
        />
        <path
          d="M0.670166 531.08C0.670166 523.15 7.10017 516.72 15.0302 516.72C22.9602 516.72 29.3902 523.15 29.3902 531.08C29.3902 539.01 22.9602 545.44 15.0302 545.44C7.10017 545.44 0.670166 539.01 0.670166 531.08Z"
          fill="#FAA33A"
        />
        <path
          d="M70.9302 531.08C70.9302 539.01 64.5002 545.44 56.5702 545.44C48.6402 545.44 42.2102 539.01 42.2102 531.08C42.2102 523.15 48.6402 516.72 56.5702 516.72C64.5002 516.72 70.9302 523.15 70.9302 531.08Z"
          fill="#FAA33A"
        />
        <path
          d="M112.47 531.08C112.47 539.01 106.04 545.44 98.11 545.44C90.18 545.44 83.75 539.01 83.75 531.08C83.75 523.15 90.18 516.72 98.11 516.72C106.04 516.72 112.47 523.15 112.47 531.08Z"
          fill="#FAA33A"
        />
        <path
          d="M154 531.08C154 539.01 147.57 545.44 139.64 545.44C131.71 545.44 125.28 539.01 125.28 531.08C125.28 523.15 131.71 516.72 139.64 516.72C147.57 516.72 154 523.15 154 531.08Z"
          fill="#FAA33A"
        />
        <path
          d="M195.54 531.08C195.54 539.01 189.11 545.44 181.18 545.44C173.25 545.44 166.82 539.01 166.82 531.08C166.82 523.15 173.25 516.72 181.18 516.72C189.11 516.72 195.54 523.15 195.54 531.08Z"
          fill="#FAA33A"
        />
        <path
          d="M0.670166 488.64C0.670166 480.71 7.10017 474.28 15.0302 474.28C22.9602 474.28 29.3902 480.71 29.3902 488.64C29.3902 496.57 22.9602 503 15.0302 503C7.10017 503 0.670166 496.57 0.670166 488.64Z"
          fill="#CF6632"
        />
        <path
          d="M70.9302 488.64C70.9302 496.57 64.5002 503 56.5702 503C48.6402 503 42.2102 496.57 42.2102 488.64C42.2102 480.71 48.6402 474.28 56.5702 474.28C64.5002 474.28 70.9302 480.71 70.9302 488.64Z"
          fill="#CF6632"
        />
        <path
          d="M112.47 488.89C112.47 496.82 106.04 503.25 98.11 503.25C90.18 503.25 83.75 496.82 83.75 488.89C83.75 480.96 90.18 474.53 98.11 474.53C106.04 474.53 112.47 480.96 112.47 488.89Z"
          fill="#CF6632"
        />
        <path
          d="M154 488.89C154 496.82 147.57 503.25 139.64 503.25C131.71 503.25 125.28 496.82 125.28 488.89C125.28 480.96 131.71 474.53 139.64 474.53C147.57 474.53 154 480.96 154 488.89Z"
          fill="#CF6632"
        />
        <path
          d="M195.54 488.89C195.54 496.82 189.11 503.25 181.18 503.25C173.25 503.25 166.82 496.82 166.82 488.89C166.82 480.96 173.25 474.53 181.18 474.53C189.11 474.53 195.54 480.96 195.54 488.89Z"
          fill="#CF6632"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_1051">
          <rect width="195.19" height="595.28" fill="white" transform="translate(0.350098)" />
        </clipPath>
      </defs>
    </svg>
  )
}
