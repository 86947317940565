import { useSnackbar } from 'notistack'
import React from 'react'
import axios from 'axios'
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { getToken } from '../utils/authenticationUtils'

interface AxiosInterceptorsProps {
  children: React.ReactNode
}

export const myAxiosInstance = axios.create({
  withCredentials: true,
  transformResponse: [],
})

export default function AxiosInterceptors({ children }: AxiosInterceptorsProps) {
  const { enqueueSnackbar } = useSnackbar()
  const [open, setOpen] = React.useState(false)
  const navigate = useNavigate()

  const handleModalClose = () => {
    navigate('login')
    setOpen(false)
  }

  const responseErrorHandler = React.useCallback(
    async (error: any) => {
      switch (error?.response?.status) {
        case 401:
          navigate('login')
          break
        case 403:
          break
        case 404:
          break
        default:
          enqueueSnackbar('An unexpected error occurred. Please try again', { variant: 'error' })
      }

      return Promise.reject(error)
    },
    [enqueueSnackbar, navigate]
  )

  React.useEffect(() => {
    const requestInterceptor = myAxiosInstance.interceptors.request.use((config) => {
      const token = getToken()
      if (token) config.headers = { ...config.headers, Authorization: `Bearer ${token}` }

      return config
    })

    const responseInterceptor = myAxiosInstance.interceptors.response.use(
      (r) => r,
      responseErrorHandler
    )

    return () => {
      myAxiosInstance.interceptors.response.eject(responseInterceptor)
      myAxiosInstance.interceptors.request.eject(requestInterceptor)
    }
  }, [responseErrorHandler])

  return (
    <>
      {children}
      <Dialog title="You have been logged out" open={open} disableEscapeKeyDown>
        <DialogContent>
          Your login connection has timed out due to inactivity. Please log in to continue.
        </DialogContent>
        <DialogActions>
          <Button onClick={handleModalClose}>Okay</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
