import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'
import { GetCombinedProfileRapProgressReportContent } from '../../api/api.generated'

export default function RAPProgress({
  rapProgressContent,
}: {
  rapProgressContent: GetCombinedProfileRapProgressReportContent
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Typography mt={20} fontSize={46} fontWeight={600}>
        RAP Progress
      </Typography>
      <Box display={'flex'} mt={10}>
        <Box width={'45%'}>
          <TextBlock title="Overview">{rapProgressContent.overview}</TextBlock>
          <TextBlock title="Relationship">{rapProgressContent.relationship}</TextBlock>
          <TextBlock title="Respect">{rapProgressContent.respect}</TextBlock>
        </Box>
        <Box width={'45%'} marginLeft={12}>
          <TextBlock title="Opportunity">{rapProgressContent.opportunity}</TextBlock>
          <TextBlock title="Governance">{rapProgressContent.governance}</TextBlock>
          <Typography mt={31} fontWeight={600}>
            Refer to SWOT analysis for further insights and opportunities for improvement.
          </Typography>
        </Box>
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={5} />
    </Box>
  )
}

type TextBlockProps = PropsWithChildren & {
  title: string
}

function TextBlock({ title, children }: TextBlockProps) {
  return (
    <Box mt={5} minHeight={200}>
      <Typography fontSize={26} color={'#CE6632'} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={14} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}
