import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RAPIcons, { RAPType } from './RAPIcons'
import CircularIndicator from '../common/outputs/CircularIndicator'
import DecorationCircle from './DecorationCircle'
import { GetCombinedProfileCorePillars } from '../../api/api.generated'

export default function RAPProgressMetrics({
  period,
  profile,
}: {
  period: string
  profile: GetCombinedProfileCorePillars
}) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        bgcolor: '#DB5C1110',
      }}
    >
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'200px'}
        bgcolor={'#ffffff'}
      />
      <Box display={'flex'} justifyContent={'space-between'} zIndex={1} position={'relative'}>
        <LogoDark scale={2} />
      </Box>
      <Box width={'55%'}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          RAP Progress
        </Typography>
        <Typography mt={5} color={'#CE6632'} fontSize={26} fontWeight={700}>
          {period}
        </Typography>
        <Typography mt={5} fontSize={16}>
          Reconciliation Action Plans are grounded in three core pillars and governance, each
          playing a pivotal role in adopting a comprehensive and holistic strategy to facilitate
          significant and positive transformation.
        </Typography>
      </Box>
      <Box display={'flex'} gap={8} mt={15}>
        <CardWithCircularProgress type="Relationship" percentage={profile.relationshipPc} />
        <CardWithCircularProgress type="Respect" percentage={profile.respectPc} />
        <CardWithCircularProgress type="Opportunity" percentage={profile.opportunityPc} />
        <CardWithCircularProgress type="Governance" percentage={profile.governancePc} />
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={'1000px'}
        style={{
          opacity: '20%',
        }}
        zIndex={1}
      >
        <DecorationCircle scale={1.5} />
      </Box>
      <Copyright pageNumber={8} />
    </Box>
  )
}

type CardWithCircularProgressProps = {
  type: RAPType
  percentage: number
}

export function CardWithCircularProgress({ type, percentage }: CardWithCircularProgressProps) {
  return (
    <Box
      position={'relative'}
      display={'flex'}
      flexDirection={'column'}
      alignItems={'center'}
      zIndex={2}
      paddingY={10}
      paddingX={13}
      borderRadius={3}
      border={'3px solid #CE66322F'}
      style={{
        backgroundColor: 'white',
      }}
    >
      {RAPIcons(type, 0.9)}
      <Box mt={3} />
      <Typography color={'#DB5C11'} fontSize={24}>
        {type}
      </Typography>
      <Box mt={8} />
      <CircularIndicator
        value={percentage}
        label={`${percentage}%`}
        color={'#DB5C11'}
        scale={2.2}
        labelStyle={{ fontSize: '36px', fontWeight: '600' }}
      />
    </Box>
  )
}
