import { Box, Divider, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import CircularIndicator from '../common/outputs/CircularIndicator'

export default function Trust({ trustPc }: { trustPc: number }) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          Organisational Progress and Trends
        </Typography>
      </Box>
      <Grid container spacing={0} mt={24}>
        <Grid item xs={2}>
          <Box display="flex" justifyContent="center" mb={1}>
            <CircularIndicator
              value={trustPc}
              label={trustPc + '%'}
              color="#0074BC"
              scale={2.2}
              labelStyle={{
                fontSize: '35px',
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={10} mt={3}>
          <Typography fontSize={45} fontWeight={600} color={'#0074BC'}>
            TRUST
          </Typography>
          <Typography fontSize={20} mt={2}>
            This gauges the level of trust in the commercial relationship with your <br />{' '}
            organisations indigenous suppliers
          </Typography>
        </Grid>
        <Grid item xs={12} mt={10}>
          <Divider />
        </Grid>
        <Grid container spacing={10} mt={1}>
          {levelConfig.map((x) => (
            <Grid item xs={3} key={x.heading}>
              <Level {...x} />
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Typography fontSize={16} mt={10}>
        Trust serves as the foundation for reconciliation, fostering open communication,
        acknowledgment of past pain,
        <br />
        and a commitment to positive change. It is not only a prerequisite for successful
        reconciliation but also an
        <br />
        ongoing outcome, reflecting the establishment of sustainable relationships and mutual
        respect.
        <br />
      </Typography>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={18} />
    </Box>
  )
}

export type LevelProps = {
  heading: string
  description: string
}

const levelConfig: LevelProps[] = [
  {
    heading: 'Low (0-50%)',
    description: 'Relationship distrust is endemic. Likely to be toxic. Requires major surgery.',
  },
  {
    heading: 'Average (51-75%)',
    description:
      'Some trust but could deteriorate. Invest in relationship before it becomes toxic.',
  },
  {
    heading: 'High (76-89%)',
    description:
      'Most commercial relationships sit within this range. Look for opportunities for improvement.',
  },
  {
    heading: 'Elite (90%+ )',
    description:
      'This is where magic happens. Environment is right for maximum performance.Monitor and maintain.',
  },
]

const Level = ({ heading, description }: LevelProps) => {
  return (
    <>
      <Typography fontSize={22} fontWeight={600} color={'#0074BC'}>
        {heading}
      </Typography>
      <Typography mt={'10px'} fontSize={20}>
        {description}
      </Typography>
    </>
  )
}
