export type LogoWhiteProps = {
  scale?: number
}

export default function LogoWhite({ scale }: LogoWhiteProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={scale ? scale * 86 : '86'}
      height={scale ? scale * 15 : '15'}
      viewBox="0 0 86 15"
      fill="none"
    >
      <g clip-path="url(#clip0_37_984)">
        <path
          d="M2.14002 11.32C2.14002 11.77 1.78002 12.13 1.33002 12.13C0.880021 12.13 0.52002 11.77 0.52002 11.32C0.52002 10.87 0.880021 10.51 1.33002 10.51C1.78002 10.51 2.14002 10.87 2.14002 11.32Z"
          fill="#FADC92"
        />
        <path
          d="M4.49012 11.32C4.49012 11.77 4.13012 12.13 3.68012 12.13C3.23012 12.13 2.87012 11.77 2.87012 11.32C2.87012 10.87 3.23012 10.51 3.68012 10.51C4.13012 10.51 4.49012 10.87 4.49012 11.32Z"
          fill="#FAA338"
        />
        <path
          d="M6.84021 11.32C6.84021 11.77 6.48021 12.13 6.03021 12.13C5.58021 12.13 5.22021 11.77 5.22021 11.32C5.22021 10.87 5.58021 10.51 6.03021 10.51C6.48021 10.51 6.84021 10.87 6.84021 11.32Z"
          fill="#E38135"
        />
        <path
          d="M9.18006 11.32C9.18006 11.77 8.82006 12.13 8.37006 12.13C7.92006 12.13 7.56006 11.77 7.56006 11.32C7.56006 10.87 7.92006 10.51 8.37006 10.51C8.82006 10.51 9.18006 10.87 9.18006 11.32Z"
          fill="#CE6632"
        />
        <path
          d="M11.5302 11.32C11.5302 11.77 11.1702 12.13 10.7202 12.13C10.2702 12.13 9.91016 11.77 9.91016 11.32C9.91016 10.87 10.2702 10.51 10.7202 10.51C11.1702 10.51 11.5302 10.87 11.5302 11.32Z"
          fill="#A4292E"
        />
        <path
          d="M2.14002 8.93C2.14002 9.38 1.78002 9.74 1.33002 9.74C0.880021 9.74 0.52002 9.38 0.52002 8.93C0.52002 8.48 0.880021 8.12 1.33002 8.12C1.78002 8.12 2.14002 8.48 2.14002 8.93Z"
          fill="#FAA338"
        />
        <path
          d="M4.49012 8.93C4.49012 9.38 4.13012 9.74 3.68012 9.74C3.23012 9.74 2.87012 9.38 2.87012 8.93C2.87012 8.48 3.23012 8.12 3.68012 8.12C4.13012 8.12 4.49012 8.48 4.49012 8.93Z"
          fill="#FAA338"
        />
        <path
          d="M6.84021 8.95002C6.84021 9.40002 6.48021 9.76001 6.03021 9.76001C5.58021 9.76001 5.22021 9.40002 5.22021 8.95002C5.22021 8.50002 5.58021 8.14001 6.03021 8.14001C6.48021 8.14001 6.84021 8.50002 6.84021 8.95002Z"
          fill="#E38135"
        />
        <path
          d="M9.18006 8.95002C9.18006 9.40002 8.82006 9.76001 8.37006 9.76001C7.92006 9.76001 7.56006 9.40002 7.56006 8.95002C7.56006 8.50002 7.92006 8.14001 8.37006 8.14001C8.82006 8.14001 9.18006 8.50002 9.18006 8.95002Z"
          fill="#CE6632"
        />
        <path
          d="M11.5302 8.95002C11.5302 9.40002 11.1702 9.76001 10.7202 9.76001C10.2702 9.76001 9.91016 9.40002 9.91016 8.95002C9.91016 8.50002 10.2702 8.14001 10.7202 8.14001C11.1702 8.14001 11.5302 8.50002 11.5302 8.95002Z"
          fill="#A4292E"
        />
        <path
          d="M2.14002 6.53998C2.14002 6.98998 1.78002 7.34998 1.33002 7.34998C0.880021 7.34998 0.52002 6.98998 0.52002 6.53998C0.52002 6.08998 0.880021 5.72998 1.33002 5.72998C1.78002 5.72998 2.14002 6.08998 2.14002 6.53998Z"
          fill="#E38135"
        />
        <path
          d="M4.49012 6.53998C4.49012 6.98998 4.13012 7.34998 3.68012 7.34998C3.23012 7.34998 2.87012 6.98998 2.87012 6.53998C2.87012 6.08998 3.23012 5.72998 3.68012 5.72998C4.13012 5.72998 4.49012 6.08998 4.49012 6.53998Z"
          fill="#E38135"
        />
        <path
          d="M6.84021 6.53998C6.84021 6.98998 6.48021 7.34998 6.03021 7.34998C5.58021 7.34998 5.22021 6.98998 5.22021 6.53998C5.22021 6.08998 5.58021 5.72998 6.03021 5.72998C6.48021 5.72998 6.84021 6.08998 6.84021 6.53998Z"
          fill="#E38135"
        />
        <path
          d="M9.18006 6.53998C9.18006 6.98998 8.82006 7.34998 8.37006 7.34998C7.92006 7.34998 7.56006 6.98998 7.56006 6.53998C7.56006 6.08998 7.92006 5.72998 8.37006 5.72998C8.82006 5.72998 9.18006 6.08998 9.18006 6.53998Z"
          fill="#CE6632"
        />
        <path
          d="M11.5302 6.53998C11.5302 6.98998 11.1702 7.34998 10.7202 7.34998C10.2702 7.34998 9.91016 6.98998 9.91016 6.53998C9.91016 6.08998 10.2702 5.72998 10.7202 5.72998C11.1702 5.72998 11.5302 6.08998 11.5302 6.53998Z"
          fill="#A4292E"
        />
        <path
          d="M2.14002 4.14997C2.14002 4.59997 1.78002 4.95996 1.33002 4.95996C0.880021 4.95996 0.52002 4.59997 0.52002 4.14997C0.52002 3.69997 0.880021 3.33997 1.33002 3.33997C1.78002 3.33997 2.14002 3.69997 2.14002 4.14997Z"
          fill="#CE6632"
        />
        <path
          d="M4.49012 4.14997C4.49012 4.59997 4.13012 4.95996 3.68012 4.95996C3.23012 4.95996 2.87012 4.59997 2.87012 4.14997C2.87012 3.69997 3.23012 3.33997 3.68012 3.33997C4.13012 3.33997 4.49012 3.69997 4.49012 4.14997Z"
          fill="#CE6632"
        />
        <path
          d="M6.84021 4.16998C6.84021 4.61998 6.48021 4.97998 6.03021 4.97998C5.58021 4.97998 5.22021 4.61998 5.22021 4.16998C5.22021 3.71998 5.58021 3.35999 6.03021 3.35999C6.48021 3.35999 6.84021 3.71998 6.84021 4.16998Z"
          fill="#CE6632"
        />
        <path
          d="M9.18006 4.16998C9.18006 4.61998 8.82006 4.97998 8.37006 4.97998C7.92006 4.97998 7.56006 4.61998 7.56006 4.16998C7.56006 3.71998 7.92006 3.35999 8.37006 3.35999C8.82006 3.35999 9.18006 3.71998 9.18006 4.16998Z"
          fill="#CE6632"
        />
        <path
          d="M11.5302 4.16998C11.5302 4.61998 11.1702 4.97998 10.7202 4.97998C10.2702 4.97998 9.91016 4.61998 9.91016 4.16998C9.91016 3.71998 10.2702 3.35999 10.7202 3.35999C11.1702 3.35999 11.5302 3.71998 11.5302 4.16998Z"
          fill="#A4292E"
        />
        <path
          d="M2.14002 1.75995C2.14002 2.20995 1.78002 2.56995 1.33002 2.56995C0.880021 2.56995 0.52002 2.20995 0.52002 1.75995C0.52002 1.30995 0.880021 0.949951 1.33002 0.949951C1.78002 0.949951 2.14002 1.30995 2.14002 1.75995Z"
          fill="#A4292E"
        />
        <path
          d="M4.49012 1.75995C4.49012 2.20995 4.13012 2.56995 3.68012 2.56995C3.23012 2.56995 2.87012 2.20995 2.87012 1.75995C2.87012 1.30995 3.23012 0.949951 3.68012 0.949951C4.13012 0.949951 4.49012 1.30995 4.49012 1.75995Z"
          fill="#A4292E"
        />
        <path
          d="M6.84021 1.75995C6.84021 2.20995 6.48021 2.56995 6.03021 2.56995C5.58021 2.56995 5.22021 2.20995 5.22021 1.75995C5.22021 1.30995 5.58021 0.949951 6.03021 0.949951C6.48021 0.949951 6.84021 1.30995 6.84021 1.75995Z"
          fill="#A4292E"
        />
        <path
          d="M9.18006 1.75995C9.18006 2.20995 8.82006 2.56995 8.37006 2.56995C7.92006 2.56995 7.56006 2.20995 7.56006 1.75995C7.56006 1.30995 7.92006 0.949951 8.37006 0.949951C8.82006 0.949951 9.18006 1.30995 9.18006 1.75995Z"
          fill="#A4292E"
        />
        <path
          d="M11.5302 1.75995C11.5302 2.20995 11.1702 2.56995 10.7202 2.56995C10.2702 2.56995 9.91016 2.20995 9.91016 1.75995C9.91016 1.30995 10.2702 0.949951 10.7202 0.949951C11.1702 0.949951 11.5302 1.30995 11.5302 1.75995Z"
          fill="#A4292E"
        />
        <path d="M17.8799 1.16003H19.8599V11.87H17.8799V1.16003Z" fill="white" />
        <path
          d="M29.2602 7.18998V11.87H27.3502V7.42998C27.3502 5.97998 26.6302 5.26998 25.4102 5.26998C24.0502 5.26998 23.1302 6.09998 23.1302 7.72998V11.86H21.2202V3.68998H23.0402V4.74998C23.6702 3.99998 24.6602 3.59998 25.8602 3.59998C27.8202 3.59998 29.2702 4.71998 29.2702 7.17998L29.2602 7.18998Z"
          fill="white"
        />
        <path
          d="M38.8499 0.520012V11.87H37.0099V10.81C36.3699 11.59 35.4199 11.97 34.2999 11.97C31.9299 11.97 30.1699 10.32 30.1699 7.78001C30.1699 5.24001 31.9299 3.60001 34.2999 3.60001C35.3599 3.60001 36.2899 3.95001 36.9299 4.70001V0.51001H38.8399L38.8499 0.520012ZM36.9699 7.79001C36.9699 6.23001 35.9099 5.24001 34.5399 5.24001C33.1699 5.24001 32.1099 6.23001 32.1099 7.79001C32.1099 9.35001 33.1699 10.34 34.5399 10.34C35.9099 10.34 36.9699 9.35001 36.9699 7.79001Z"
          fill="white"
        />
        <path
          d="M48.6298 3.69999V10.63C48.6298 13.6 47.0798 14.94 44.2198 14.94C42.6898 14.94 41.1798 14.54 40.2398 13.78L41.0998 12.34C41.8198 12.94 42.9798 13.32 44.1098 13.32C45.9198 13.32 46.7098 12.49 46.7098 10.84V10.41C46.0398 11.14 45.0698 11.5 43.9698 11.5C41.6298 11.5 39.8398 9.90999 39.8398 7.53999C39.8398 5.16999 41.6298 3.60999 43.9698 3.60999C45.1198 3.60999 46.1299 3.97999 46.7999 4.78999V3.69999H48.6199H48.6298ZM46.7498 7.53999C46.7498 6.15999 45.7098 5.22998 44.2698 5.22998C42.8298 5.22998 41.7798 6.15999 41.7798 7.53999C41.7798 8.91999 42.8198 9.87999 44.2698 9.87999C45.7198 9.87999 46.7498 8.92999 46.7498 7.53999Z"
          fill="white"
        />
        <path
          d="M57.0401 7.08V11.87H55.2301V10.88C54.7701 11.58 53.8801 11.98 52.6601 11.98C50.7901 11.98 49.6201 10.96 49.6201 9.52999C49.6201 8.1 50.5401 7.09999 53.0201 7.09999H55.1301V6.98C55.1301 5.86 54.4601 5.21 53.1001 5.21C52.1801 5.21 51.2301 5.52 50.6201 6.02L49.8701 4.63C50.7401 3.96 52.0101 3.62 53.3301 3.62C55.6901 3.62 57.0501 4.74 57.0501 7.09L57.0401 7.08ZM55.1301 9.32V8.37H53.1601C51.8601 8.37 51.5101 8.86 51.5101 9.46C51.5101 10.15 52.0901 10.59 53.0701 10.59C54.0501 10.59 54.8101 10.16 55.1401 9.32H55.1301Z"
          fill="white"
        />
        <path
          d="M66.2801 3.69995V11.8699H64.4601V10.83C63.8501 11.58 62.8801 11.9799 61.8301 11.9799C59.7301 11.9799 58.3101 10.8299 58.3101 8.36995V3.69995H60.2201V8.10995C60.2201 9.58995 60.9201 10.2999 62.1301 10.2999C63.4601 10.2999 64.3601 9.46995 64.3601 7.83995V3.70995H66.2701L66.2801 3.69995Z"
          fill="white"
        />
        <path
          d="M76.1401 3.69999V10.63C76.1401 13.6 74.5901 14.94 71.7301 14.94C70.2001 14.94 68.6901 14.54 67.7501 13.78L68.6101 12.34C69.3301 12.94 70.4901 13.32 71.6201 13.32C73.4201 13.32 74.2201 12.49 74.2201 10.84V10.41C73.5501 11.14 72.5801 11.5 71.4801 11.5C69.1401 11.5 67.3501 9.90999 67.3501 7.53999C67.3501 5.16999 69.1401 3.60999 71.4801 3.60999C72.6301 3.60999 73.6401 3.97999 74.3101 4.78999V3.69999H76.1301H76.1401ZM74.2501 7.53999C74.2501 6.15999 73.2101 5.22998 71.7701 5.22998C70.3301 5.22998 69.2801 6.15999 69.2801 7.53999C69.2801 8.91999 70.3201 9.87999 71.7701 9.87999C73.2201 9.87999 74.2501 8.92999 74.2501 7.53999Z"
          fill="white"
        />
        <path
          d="M85.53 8.39999H79.12C79.35 9.58999 80.34 10.36 81.77 10.36C82.69 10.36 83.41 10.07 83.99 9.46999L85.01 10.65C84.28 11.52 83.13 11.98 81.72 11.98C78.98 11.98 77.21 10.22 77.21 7.78999C77.21 5.35999 79 3.60999 81.43 3.60999C83.86 3.60999 85.56 5.27999 85.56 7.82999C85.56 7.97999 85.54 8.20999 85.53 8.39999ZM79.1 7.12999H83.74C83.59 5.94999 82.68 5.13998 81.43 5.13998C80.18 5.13998 79.29 5.93999 79.1 7.12999Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_984">
          <rect
            width="85.0399"
            height="14.43"
            fill="white"
            transform="translate(0.52002 0.51001)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
