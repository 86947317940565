import { useParams } from 'react-router-dom'
// import AuthenticatedRoute from "../../routes/AuthenticatedRoute";
import FullscreenContainer from '../common/containers/FullscreenContainer'
import LoadingContainer from '../common/containers/LoadingContainer'
import BuyersList from './BuyerOrganization/BuyerList'
import AuthenticatedRoute from '../../routes/AuthenticatedRoute'

export default function ConsoleRoute() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const params = useParams()

  return (
    <AuthenticatedRoute>
      <FullscreenContainer bgColor="#FFFFFF">
        <LoadingContainer isLoading={false} isErrored={false}>
          <BuyersList />
        </LoadingContainer>
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}
