import {
  Box,
  CircularProgress,
  circularProgressClasses,
  SxProps,
  Theme,
  Typography,
} from '@mui/material'

interface CircularIndicatorProps {
  value: number
  label: string
  color?: string
  small?: boolean
  scale?: number
  labelStyle?: SxProps<Theme>
}

const CircularIndicator: React.FC<CircularIndicatorProps> = ({
  value,
  label,
  color,
  small = false,
  scale = 1,
  labelStyle,
}) => (
  <Box sx={{ position: 'relative', display: 'flex', width: 'auto' }}>
    <CircularProgress
      variant="determinate"
      sx={{
        color: (theme) => theme.palette.grey[200],
      }}
      size={(small ? 60 : 80) * scale}
      thickness={small ? 5 : 5}
      value={100}
    />
    <CircularProgress
      variant="determinate"
      value={value}
      size={(small ? 60 : 80) * scale}
      thickness={small ? 5 : 5}
      color={!!color ? 'inherit' : 'primary'}
      sx={{
        color,
        [`& .${circularProgressClasses.circle}`]: {
          strokeLinecap: 'round',
        },
        position: 'absolute',
      }}
    />
    <Box
      sx={{
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        position: 'absolute',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant={!!labelStyle ? undefined : small ? 'body1' : 'h3'}
        component="div"
        textAlign="center"
        sx={labelStyle}
      >
        {label}
      </Typography>
    </Box>
  </Box>
)

export default CircularIndicator
