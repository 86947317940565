import { Box, Typography } from '@mui/material'
import LogoDark from './LogoDark'
import Copyright from './Copyright'
import { ScalableProps } from '../../utils/typeUtils'

const Acknowledgement = () => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Box mt={60} width={'63%'}>
        <Typography mt={20} fontSize={24}>
          Contract Harmonics and Indgauge acknowledges the Traditional Owners and Custodians of
          Country throughout Australia and their continuing connection to the land, sea and
          community. We pay our respects to them and their cultures, and to the Elders both past and
          present.
        </Typography>
      </Box>
      <Box position={'absolute'} top={'380px'} left={'1200px'}>
        <AcknowledgementSquare scale={2.1} />
      </Box>
      <Copyright pageNumber={2} />
    </Box>
  )
}

function AcknowledgementSquare({ scale }: ScalableProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={scale ? scale * 218 : '218'}
      height={scale ? scale * 221 : '221'}
      viewBox="0 0 218 221"
      fill="none"
    >
      <g clip-path="url(#clip0_37_985)">
        <mask id="mask0_37_985" maskUnits="userSpaceOnUse" x="0" y="0" width="218" height="221">
          <path d="M217.47 0H0V220.9H217.47V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_37_985)">
          <path
            d="M32.05 204.88C32.05 213.73 24.88 220.9 16.03 220.9C7.18 220.9 0 213.73 0 204.88C0 196.03 7.17 188.86 16.02 188.86C24.87 188.86 32.04 196.03 32.04 204.88"
            fill="#FADC92"
          />
          <path
            d="M78.4 204.88C78.4 213.73 71.23 220.9 62.38 220.9C53.53 220.9 46.36 213.73 46.36 204.88C46.36 196.03 53.53 188.86 62.38 188.86C71.23 188.86 78.4 196.03 78.4 204.88Z"
            fill="#FAA338"
          />
          <path
            d="M124.76 204.88C124.76 213.73 117.59 220.9 108.74 220.9C99.89 220.9 92.72 213.73 92.72 204.88C92.72 196.03 99.89 188.86 108.74 188.86C117.59 188.86 124.76 196.03 124.76 204.88Z"
            fill="#E38135"
          />
          <path
            d="M171.11 204.88C171.11 213.73 163.94 220.9 155.09 220.9C146.24 220.9 139.07 213.73 139.07 204.88C139.07 196.03 146.24 188.86 155.09 188.86C163.94 188.86 171.11 196.03 171.11 204.88Z"
            fill="#CE6632"
          />
          <path
            d="M217.47 204.88C217.47 213.73 210.3 220.9 201.45 220.9C192.6 220.9 185.43 213.73 185.43 204.88C185.43 196.03 192.6 188.86 201.45 188.86C210.3 188.86 217.47 196.03 217.47 204.88Z"
            fill="#A4292E"
          />
          <path
            d="M32.05 157.67C32.05 166.52 24.88 173.69 16.03 173.69C7.18 173.69 0 166.52 0 157.67C0 148.82 7.17 141.65 16.02 141.65C24.87 141.65 32.04 148.82 32.04 157.67"
            fill="#FAA338"
          />
          <path
            d="M78.4 157.67C78.4 166.52 71.23 173.69 62.38 173.69C53.53 173.69 46.36 166.52 46.36 157.67C46.36 148.82 53.53 141.65 62.38 141.65C71.23 141.65 78.4 148.82 78.4 157.67Z"
            fill="#FAA338"
          />
          <path
            d="M124.76 157.95C124.76 166.8 117.59 173.97 108.74 173.97C99.89 173.97 92.72 166.8 92.72 157.95C92.72 149.1 99.89 141.93 108.74 141.93C117.59 141.93 124.76 149.1 124.76 157.95Z"
            fill="#E38135"
          />
          <path
            d="M171.11 157.95C171.11 166.8 163.94 173.97 155.09 173.97C146.24 173.97 139.07 166.8 139.07 157.95C139.07 149.1 146.24 141.93 155.09 141.93C163.94 141.93 171.11 149.1 171.11 157.95Z"
            fill="#CE6632"
          />
          <path
            d="M217.47 157.95C217.47 166.8 210.3 173.97 201.45 173.97C192.6 173.97 185.43 166.8 185.43 157.95C185.43 149.1 192.6 141.93 201.45 141.93C210.3 141.93 217.47 149.1 217.47 157.95Z"
            fill="#A4292E"
          />
          <path
            d="M32.05 110.45C32.05 119.3 24.88 126.47 16.03 126.47C7.18 126.47 0 119.3 0 110.45C0 101.6 7.17 94.43 16.02 94.43C24.87 94.43 32.04 101.6 32.04 110.45"
            fill="#E38135"
          />
          <path
            d="M78.4 110.45C78.4 119.3 71.23 126.47 62.38 126.47C53.53 126.47 46.36 119.3 46.36 110.45C46.36 101.6 53.53 94.43 62.38 94.43C71.23 94.43 78.4 101.6 78.4 110.45Z"
            fill="#E38135"
          />
          <path
            d="M124.76 110.45C124.76 119.3 117.59 126.47 108.74 126.47C99.89 126.47 92.72 119.3 92.72 110.45C92.72 101.6 99.89 94.43 108.74 94.43C117.59 94.43 124.76 101.6 124.76 110.45Z"
            fill="#E38135"
          />
          <path
            d="M171.11 110.45C171.11 119.3 163.94 126.47 155.09 126.47C146.24 126.47 139.07 119.3 139.07 110.45C139.07 101.6 146.24 94.43 155.09 94.43C163.94 94.43 171.11 101.6 171.11 110.45Z"
            fill="#CE6632"
          />
          <path
            d="M217.47 110.45C217.47 119.3 210.3 126.47 201.45 126.47C192.6 126.47 185.43 119.3 185.43 110.45C185.43 101.6 192.6 94.43 201.45 94.43C210.3 94.43 217.47 101.6 217.47 110.45Z"
            fill="#A4292E"
          />
          <path
            d="M32.05 63.24C32.05 72.09 24.88 79.26 16.03 79.26C7.18 79.26 0 72.09 0 63.24C0 54.39 7.17 47.22 16.02 47.22C24.87 47.22 32.04 54.39 32.04 63.24"
            fill="#CE6632"
          />
          <path
            d="M78.4 63.24C78.4 72.09 71.23 79.26 62.38 79.26C53.53 79.26 46.36 72.09 46.36 63.24C46.36 54.39 53.53 47.22 62.38 47.22C71.23 47.22 78.4 54.39 78.4 63.24Z"
            fill="#CE6632"
          />
          <path
            d="M124.76 63.52C124.76 72.37 117.59 79.54 108.74 79.54C99.89 79.54 92.72 72.37 92.72 63.52C92.72 54.67 99.89 47.5 108.74 47.5C117.59 47.5 124.76 54.67 124.76 63.52Z"
            fill="#CE6632"
          />
          <path
            d="M171.11 63.52C171.11 72.37 163.94 79.54 155.09 79.54C146.24 79.54 139.07 72.37 139.07 63.52C139.07 54.67 146.24 47.5 155.09 47.5C163.94 47.5 171.11 54.67 171.11 63.52Z"
            fill="#CE6632"
          />
          <path
            d="M217.47 63.52C217.47 72.37 210.3 79.54 201.45 79.54C192.6 79.54 185.43 72.37 185.43 63.52C185.43 54.67 192.6 47.5 201.45 47.5C210.3 47.5 217.47 54.67 217.47 63.52Z"
            fill="#A4292E"
          />
          <path
            d="M32.05 16.02C32.05 24.87 24.88 32.04 16.03 32.04C7.18 32.04 0 24.87 0 16.02C0 7.17 7.17 0 16.02 0C24.87 0 32.04 7.17 32.04 16.02"
            fill="#A4292E"
          />
          <path
            d="M78.4 16.02C78.4 24.87 71.23 32.04 62.38 32.04C53.53 32.04 46.36 24.87 46.36 16.02C46.36 7.17 53.53 0 62.38 0C71.23 0 78.4 7.17 78.4 16.02Z"
            fill="#A4292E"
          />
          <path
            d="M124.76 16.02C124.76 24.87 117.59 32.04 108.74 32.04C99.89 32.04 92.72 24.87 92.72 16.02C92.72 7.17 99.89 0 108.74 0C117.59 0 124.76 7.17 124.76 16.02Z"
            fill="#A4292E"
          />
          <path
            d="M171.11 16.02C171.11 24.87 163.94 32.04 155.09 32.04C146.24 32.04 139.07 24.87 139.07 16.02C139.07 7.17 146.24 0 155.09 0C163.94 0 171.11 7.17 171.11 16.02Z"
            fill="#A4292E"
          />
          <path
            d="M217.47 16.02C217.47 24.87 210.3 32.04 201.45 32.04C192.6 32.04 185.43 24.87 185.43 16.02C185.43 7.17 192.6 0 201.45 0C210.3 0 217.47 7.17 217.47 16.02Z"
            fill="#A4292E"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_37_985">
          <rect width="217.47" height="220.9" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Acknowledgement
