import { Box, Typography } from '@mui/material'

type CopyrightProps = {
  pageNumber: number
  pageNumberMargin?: string
}

const Copyright = ({ pageNumber, pageNumberMargin = '1098px' }: CopyrightProps) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '30px',
        display: 'flex',
      }}
    >
      <Typography fontSize={12}>
        Copyright {new Date().getFullYear()}. Contract Harmonics. All Rights Reserved.
      </Typography>
      <Typography ml={pageNumberMargin} fontSize={12}>
        Page {pageNumber}
      </Typography>
    </Box>
  )
}

export default Copyright
