import React from 'react'

export interface MyApiError {
  message: string
  statusCode?: number
  request: any
}

export default function useApi<ReturnType, RequestType>(
  apiFunction: (cancelToken?: RequestType) => Promise<ReturnType>,
  onSuccess?: (response: ReturnType) => void,
  onError?: (error: MyApiError) => void
) {
  const [state, setState] = React.useState<'loading' | 'fulfilled' | 'errored' | 'idle'>('idle')
  const [data, setData] = React.useState<ReturnType | undefined>()
  const [error, setError] = React.useState<MyApiError | undefined>()

  const callApi = React.useCallback(
    async (body?: RequestType) => {
      if (state === 'loading') return
      setState('loading')
      setData(undefined)
      setError(undefined)

      try {
        const res = await apiFunction(body)
        setData(res)
        setState('fulfilled')

        onSuccess && onSuccess(res)
      } catch (e: any) {
        const newError: MyApiError = {
          message: e.message,
          statusCode: e?.response?.status,
          request: body,
        }

        setError(newError)
        setState('errored')

        onError && onError(newError)
      }
    },
    [apiFunction, onError, onSuccess, state]
  )

  return { state, data, error, callApi }
}
