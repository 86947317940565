import { Box, Typography, BoxProps } from '@mui/material'
import TextAvatar from './TextAvatar'

interface SectionHeaderProps extends BoxProps {
  groupName: string
  subHeader?: string
  isSolo?: boolean
  type?: 'buyer' | 'supplier'
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  groupName,
  subHeader,
  type,
  isSolo,
  ...boxProps
}) => {
  return (
    <Box {...boxProps}>
      {type && <TextAvatar text={getAvatarShortenName(groupName)} type={type} />}
      <Typography
        variant="h2"
        marginTop={!(type || isSolo) ? '68px' : '0px'}
        color={'text.secondary'}
      >
        <b>{groupName}</b>
      </Typography>
      <Typography variant="subtitle1" color="GrayText">
        {subHeader}
      </Typography>
    </Box>
  )
}

export const getAvatarShortenName = (name: string): string => {
  let trimmedAndPartitioned = name
    .trim()
    .split(' ')
    .filter((x) => !['and', 'of', 'the'].includes(x.toLowerCase()))

  if (trimmedAndPartitioned.length === 1) {
    let avatarText = trimmedAndPartitioned.join('').slice(0, 2)
    return avatarText.charAt(0).toUpperCase() + avatarText.slice(1)
  } else {
    return trimmedAndPartitioned
      .map((x) => x[0])
      .join('')
      .slice(0, 2)
      .toUpperCase()
  }
}

export default SectionHeader
