import {
  Backdrop,
  Box,
  Button,
  Grid,
  Stack,
  TextField,
  Tooltip,
  Typography,
  FormControl,
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ReportContainer, ReportPage } from '../Report'
import { Link as RouterLink } from 'react-router-dom'
import PdfIcon from '@mui/icons-material/PictureAsPdf'
import ChevronLeft from '@mui/icons-material/ChevronLeft'
import { generatePdf } from '../../../utils/PdfUtils'
import CircularIndicator from '../../common/outputs/CircularIndicator'
import { PropsWithChildren, useEffect, useState } from 'react'
import theme from '../../../theme'
import MetricIcon from '../../common/icons/MetricIcon'
import { ReactComponent as BusinessDealCash } from '../../../assets/BusinessDealCash.svg'
import { ReactComponent as BusinessDealHandshake } from '../../../assets/BusinessDealHandshake.svg'
import { ReactComponent as BusinessContractGive } from '../../../assets/BusinessContractGive.svg'
import ContractProfileDetails from '../ContractProfileDetails'
import { BuyerSupplierDashboard } from '../BuyerSupplierDashboard/BuyerSupplierDashboard'
import AboutThisReport from '../../report/AboutThisReport'
import Acknowledgement from '../../report/Acknowledgement'
import IndigenousSupplierRelationships from '../../report/IndigenousSupplierRelationships'
import Introduction from '../../report/Introduction'
import Observations from '../../report/Observations'
import ObservationsAndAnalysis from '../../report/ObservationsAndAnalysis'
import OrganisationalProgressAndTrends from '../../report/OrganisationalProgressAndTrends'
import OrganisationalProgressAndTrendsDefinitions from '../../report/OrganisationalProgressAndTrendsDefinitions'
import OurServices from '../../report/OurServices'
import RAPGovernance from '../../report/RAPGovernance'
import RAPOpportunity from '../../report/RAPOpportunity'
import RAPProgress from '../../report/RAPProgress'
import RAPProgressMetrics from '../../report/RAPProgressMetrics'
import RAPRelationship from '../../report/RAPRelationship'
import RAPRespect from '../../report/RAPRespect'
import RapImpactAndReconciliationIndex from '../../report/RapImpactAndReconciliationIndex'
import ReconciliationDimensionIntensityLevels from '../../report/ReconciliationDimensionIntensityLevels'
import ReconciliationDimensionProfiles from '../../report/ReconciliationDimensionProfiles'
import SwotAnalysis from '../../report/SwotAnalysis'
import Trust from '../../report/Trust'
import LogoDark from '../../report/LogoDark'
import Copyright from '../../report/Copyright'
import TextAvatar from '../TextAvatar'
import { getAvatarShortenName } from '../SectionHeader'
import {
  GetCombinedProfileBuyerProfile,
  GetCombinedProfileCorePillars,
  GetCombinedProfileOrganisationProgressAndTrends,
  GetCombinedProfileRapProgressReportContent,
} from '../../../api/api.generated'
import { CombinedProfileProps } from '../ContractDashboardContainer'
import LinearProgressWithLabel from '../../common/utils/LinearProgressWithLabel'
import { dataProcessingClient } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'

const baseGridStyle: React.CSSProperties = {
  backgroundColor: '#DB5C1110',
}

export type ReportSupportedProps = {
  isReportView: boolean
}

export type BuyerProfileProps = ReportSupportedProps & {
  buyerId: string
  buyerProfile: GetCombinedProfileBuyerProfile
  stage: string
  reportingPeriod: string
}

export function BuyerDashboard(props: BuyerProfileProps) {
  return (
    <Box paddingX={'8px'}>
      <DashboardHeader {...props} />
      <DashboardBody {...props} />
    </Box>
  )
}

export function DashboardHeader(props: BuyerProfileProps) {
  return (
    <Box display={'flex'} flexDirection={'column'} paddingX={5} paddingBottom={5} gap={2}>
      {!props.isReportView && <BuyerDashboardNavigation buyerId={props.buyerId} />}
      <DashboardBanner {...props} />
    </Box>
  )
}

export function DashboardBody(props: BuyerProfileProps) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={7}>
        <DashboardBodyLeft {...props} />
      </Grid>
      <Grid item xs={5}>
        <DashboardBodyRight {...props} />
      </Grid>
    </Grid>
  )
}

export function DashboardBodyLeft(props: BuyerProfileProps) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <Box
          padding={4}
          height={'268px'}
          width={'100%'}
          style={{ ...baseGridStyle, borderRadius: '12px 0px 0px 0px' }}
        >
          <PersonnelMetrics {...props} />
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box height={'268px'} padding={4} width={'100%'} style={baseGridStyle}>
          <SupplierMetrics {...props} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          width={'100%'}
          style={{ ...baseGridStyle, borderRadius: '0px 0px 0px 12px' }}
          height={'449px'}
        >
          <OrgProgressAndTrends {...props.buyerProfile.organisationProgressAndTrends} />
        </Box>
      </Grid>
    </Grid>
  )
}

export function OrgProgressAndTrends(props: GetCombinedProfileOrganisationProgressAndTrends) {
  return (
    <>
      <Box flexShrink={0} paddingTop={4} paddingX={4}>
        <Typography fontSize={'16px'} fontWeight={'700'} lineHeight={'24px'} color={'#736A65'}>
          Organisation progress and trends
        </Typography>
      </Box>
      <Box height={'130px'} width={'100%'} color={'#736A65'}>
        <Box padding={4} height={'100%'}>
          <Grid container height={'inherit'}>
            <Grid item xs={3} height={'inherit'}>
              <Box display={'flex'} alignItems={'center'} height={'inherit'} gap={3}>
                <BusinessDealHandshake />
                <Typography color={'#CE6632'}>Indigenous business engagement</Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  {props.businessEngaged}
                </Typography>
                <Typography fontSize={'12px'}>Business engaged</Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  {props.businessEngagedNationalAverage}
                </Typography>
                <Typography fontSize={'12px'}>National median</Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  {props.businessEngagedIndustryAverage}
                </Typography>
                <Typography fontSize={'12px'}>Industry median</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box height={'130px'} width={'100%'} bgcolor={'#DB5C1110'} color={'#736A65'}>
        <Box padding={4} height={'100%'}>
          <Grid container height={'inherit'}>
            <Grid item xs={3} height={'inherit'}>
              <Box display={'flex'} alignItems={'center'} height={'inherit'} gap={3}>
                <BusinessDealCash />
                <Typography fontSize={16} color={'#CE6632'}>
                  Indigenous business spend
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  ${props.orgSpend}M
                </Typography>
                <Typography fontSize={'12px'}>Organisation spend</Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  ${props.orgSpendNationalAverage}M
                </Typography>
                <Typography fontSize={'12px'}>National median</Typography>
              </Box>
            </Grid>
            <Grid item xs={3} alignSelf={'center'}>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                height={'inherit'}
              >
                <Typography fontSize={'25px'} fontWeight={700}>
                  ${props.orgSpendIndustryAverage}M
                </Typography>
                <Typography fontSize={'12px'}>Industry median</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box height={'130px'} width={'100%'} color={'#736A65'}>
        <Box padding={4} height={'100%'}>
          <Grid container height={'inherit'}>
            <Grid item xs={3} height={'inherit'}>
              <Box display={'flex'} alignItems={'center'} height={'inherit'} gap={3}>
                <BusinessContractGive />
                <Typography color={'#CE6632'}>Indigenous contracts data</Typography>
              </Box>
            </Grid>
            <Grid item xs={9} height={'inherit'}>
              <Grid container height={'inherit'} paddingX={5}>
                <Grid item xs={3} alignSelf={'center'}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={'inherit'}
                  >
                    <Typography fontSize={'25px'} fontWeight={700}>
                      {props.numContracts}
                    </Typography>
                    <Typography fontSize={'12px'}># of contracts</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} alignSelf={'center'}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={'inherit'}
                  >
                    <Typography fontSize={'25px'} fontWeight={700}>
                      {props.numContractsNationalAverage}
                    </Typography>
                    <Typography fontSize={'12px'}>National median</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} alignSelf={'center'}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={'inherit'}
                  >
                    <Typography fontSize={'25px'} fontWeight={700}>
                      {props.numContractsIndustryAverage}
                    </Typography>
                    <Typography fontSize={'12px'}>Industry median</Typography>
                  </Box>
                </Grid>
                <Grid item xs={3} alignSelf={'center'}>
                  <Box
                    display={'flex'}
                    flexDirection={'column'}
                    alignItems={'center'}
                    height={'inherit'}
                  >
                    <Typography fontSize={'25px'} fontWeight={700}>
                      ${props.contractValueAvg}M
                    </Typography>
                    <Typography fontSize={'12px'}>
                      Contract value
                      <br />
                      average (AUD)
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  )
}

// metric: 'Race Relations' | 'Equality & Equity' | 'Institutional Integrity' | 'Unity' | 'Historical Acceptance'
export function PersonnelMetrics({ buyerProfile }: BuyerProfileProps) {
  return (
    <>
      <Box width={'180px'} flexShrink={0}>
        <Typography fontSize={'16px'} fontWeight={'700'} lineHeight={'24px'} color={'#736A65'}>
          How your personnel see your group
        </Typography>
      </Box>
      <Box marginTop={6}>
        <MetricsIcon
          color="secondary"
          metrics={[
            { metric: 'Race Relations', percentage: buyerProfile.buyerMetrics.raceRelationsPc },
            { metric: 'Equality & Equity', percentage: buyerProfile.buyerMetrics.equalityEquityPc },
            {
              metric: 'Institutional Integrity',
              percentage: buyerProfile.buyerMetrics.institutionalIntegrityPc,
            },
            { metric: 'Unity', percentage: buyerProfile.buyerMetrics.unityPc },
            {
              metric: 'Historical Acceptance',
              percentage: buyerProfile.buyerMetrics.historicalAcceptancePc,
            },
          ]}
        />
      </Box>
    </>
  )
}

export function SupplierMetrics({ buyerProfile }: BuyerProfileProps) {
  return (
    <>
      <Box width={'240px'} flexShrink={0}>
        <Typography fontSize={'16px'} fontWeight={'700'} lineHeight={'24px'} color={'#736A65'}>
          How your indigenous suppliers see your group
        </Typography>
      </Box>
      <Box marginTop={6}>
        <MetricsIcon
          color="primary"
          metrics={[
            { metric: 'Race Relations', percentage: buyerProfile.supplierMetrics.raceRelationsPc },
            {
              metric: 'Equality & Equity',
              percentage: buyerProfile.supplierMetrics.equalityEquityPc,
            },
            {
              metric: 'Institutional Integrity',
              percentage: buyerProfile.supplierMetrics.institutionalIntegrityPc,
            },
            { metric: 'Unity', percentage: buyerProfile.supplierMetrics.unityPc },
            {
              metric: 'Historical Acceptance',
              percentage: buyerProfile.supplierMetrics.historicalAcceptancePc,
            },
          ]}
        />
      </Box>
    </>
  )
}

function DimensionalProfile({ isReportView, buyerProfile }: BuyerProfileProps) {
  return (
    <>
      <Box flexShrink={0} height={'100%'}>
        <Typography fontSize={'16px'} fontWeight={'700'} lineHeight={'24px'} color={'#736A65'}>
          Combined Dimensional Profile
        </Typography>
        <Typography fontSize={'13px'} lineHeight={'24px'} color={'#736A65'}>
          (Aggregated data)
        </Typography>
        <ContractProfileDetails
          isReportView={isReportView}
          dashboardType="buyer"
          metrics={[
            {
              metric: 'Race Relations',
              buyer: buyerProfile.buyerMetrics.raceRelationsPc,
              supplier: buyerProfile.supplierMetrics.raceRelationsPc,
              combined: buyerProfile.combinedMetrics.raceRelationsPc,
            },
            {
              metric: 'Equality & Equity',
              buyer: buyerProfile.buyerMetrics.equalityEquityPc,
              supplier: buyerProfile.supplierMetrics.equalityEquityPc,
              combined: buyerProfile.combinedMetrics.equalityEquityPc,
            },
            {
              metric: 'Institutional Integrity',
              buyer: buyerProfile.buyerMetrics.institutionalIntegrityPc,
              supplier: buyerProfile.supplierMetrics.institutionalIntegrityPc,
              combined: buyerProfile.combinedMetrics.institutionalIntegrityPc,
            },
            {
              metric: 'Unity',
              buyer: buyerProfile.buyerMetrics.unityPc,
              supplier: buyerProfile.supplierMetrics.unityPc,
              combined: buyerProfile.combinedMetrics.unityPc,
            },
            {
              metric: 'Historical Acceptance',
              buyer: buyerProfile.buyerMetrics.historicalAcceptancePc,
              supplier: buyerProfile.supplierMetrics.historicalAcceptancePc,
              combined: buyerProfile.combinedMetrics.historicalAcceptancePc,
            },
          ]}
        />
      </Box>
    </>
  )
}

export function DashboardBodyRight(props: BuyerProfileProps) {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box
          padding={4}
          width={'100%'}
          height={'460px'}
          style={{ ...baseGridStyle, borderRadius: '0px 12px 0px 0px' }}
        >
          <DimensionalProfile {...props} />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          padding={4}
          width={'100%'}
          height={'257px'}
          style={{ ...baseGridStyle, borderRadius: '0px 0px 12px 0px' }}
        >
          <RAPCorePillarsProgress
            corePillars={props.buyerProfile.corePillars}
            reportingPeriod={props.reportingPeriod.slice(0, 10)}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export function PanelBody(props: PropsWithChildren) {
  return (
    <Box padding={4} width={'100%'} style={baseGridStyle}>
      {props.children}
    </Box>
  )
}

export type RAPCorePillarsProgressProps = {
  corePillars: GetCombinedProfileCorePillars
  reportingPeriod: string
}

export function RAPCorePillarsProgress({
  corePillars,
  reportingPeriod,
}: RAPCorePillarsProgressProps) {
  return (
    <RAPMetrics
      metrics={[
        { label: 'Relationship', value: corePillars.relationshipPc },
        { label: 'Respect', value: corePillars.respectPc },
        {
          label: 'Opportunity',
          value: corePillars.opportunityPc,
        },
        { label: 'Governance', value: corePillars.governancePc },
      ]}
      reportingPeriod={reportingPeriod}
    />
  )
}

interface RAPMetricsProps {
  metrics: {
    label: string
    value: number
  }[]
  reportingPeriod: string
}

const RAPMetrics: React.FC<RAPMetricsProps> = ({ metrics, reportingPeriod }) => {
  return (
    <>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Typography variant="h3" color={'#736A65'}>
          RAP Core Pillars Progress
        </Typography>
        <Typography variant="subtitle1" color="#A89B94" fontSize={'16px'}>
          {reportingPeriod}
        </Typography>
      </Stack>
      <Box display="flex" justifyContent="space-between" mt={6}>
        {metrics.map((m, i) => (
          <Box key={m.label} sx={{ boxSizing: 'borderBox' }}>
            <Box display="flex" justifyContent="center">
              <CircularIndicator
                value={m.value}
                label={`${m.value}%`}
                color={i > 1 ? 'primary.dark' : 'primary.main'}
                scale={1.2}
              />
            </Box>
            <Typography textAlign={'center'} my={1}>
              {m.label}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  )
}

type MetricsIconsProps = {
  metrics: {
    metric:
      | 'Race Relations'
      | 'Equality & Equity'
      | 'Institutional Integrity'
      | 'Unity'
      | 'Historical Acceptance'
      | 'Relationship'
      | 'Respect'
      | 'Opportunity'
      | 'Governance'
    percentage: number
  }[]
  color: 'primary' | 'secondary'
}

export function MetricsIcon({ metrics, color }: MetricsIconsProps) {
  const shouldBeFilled = (percentage: number) => percentage >= 50
  return (
    <Box display="flex" justifyContent="space-between" ml={'-9px'}>
      {metrics.map((m, idx) => (
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          key={m.metric}
          width={'58px'}
        >
          <Box textAlign="center" my={1}>
            <MetricIcon
              metric={m.metric}
              filled={shouldBeFilled(m.percentage)}
              width={40}
              height={40}
              color={theme.palette[color][m.percentage >= 70 ? 'main' : 'light']}
            />
          </Box>
          <Typography textAlign="center" fontSize={'15px'}>{`${m.percentage}%`}</Typography>
          <Box marginTop={1} height={3}>
            <Typography textAlign="center" fontSize={'12px'} variant="subtitle2" color="GrayText">
              {m.metric}
            </Typography>
          </Box>
        </Box>
      ))}
    </Box>
  )
}

export function DashboardBanner({ buyerProfile, stage }: BuyerProfileProps) {
  return (
    <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} marginTop={4}>
      <Box>
        <Box display={'flex'} alignItems={'center'} gap={'19px'}>
          <TextAvatar text={getAvatarShortenName(buyerProfile.buyerName)} type={'buyer'} />
          <Box display={'flex'} flexDirection={'column'} alignItems={'flex-start'}>
            <Typography
              alignSelf={'stretch'}
              fontSize={'25px'}
              fontStyle={'normal'}
              fontWeight={900}
              lineHeight={'24px'}
              letterSpacing={'0.1px'}
              color={'#736A65'}
            >
              <b>{buyerProfile.buyerName}</b>
            </Typography>
            <Typography
              width={'325px'}
              fontSize={'15px'}
              fontStyle={'normal'}
              fontWeight={400}
              lineHeight={'20px'}
              letterSpacing={'0.1px'}
              color={'#A89B94'}
            >
              {stage}
            </Typography>
          </Box>
        </Box>
        <Typography
          mt={6}
          fontSize={'18px'}
          fontWeight={700}
          lineHeight={'24px'}
          letterSpacing={'0.16px'}
          color={'#CE6632'}
        >
          Reconciliation Dimensional Profiles
        </Typography>
      </Box>
      <Box display={'flex'} justifyContent={'space-between'} width={'600px'}>
        <Box display={'flex'} gap={2} alignItems={'center'} flexDirection={'column'}>
          <CircularIndicator
            color="#FAA338"
            value={buyerProfile.rapImpactPc}
            label={buyerProfile.rapImpactPc + '%'}
            scale={1.2}
            labelStyle={{ color: '#736A65', fontSize: '22px', fontWeight: '600' }}
          />
          <Box>
            <Typography
              alignSelf={'stretch'}
              fontSize={'18px'}
              fontStyle={'normal'}
              lineHeight={'24px'}
              letterSpacing={'0.1px'}
              color={'#736A65'}
            >
              RAP Impact
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} gap={2} alignItems={'center'} flexDirection={'column'}>
          <CircularIndicator
            color="#a42a2e"
            value={buyerProfile.reconciliationPc}
            label={buyerProfile.reconciliationPc + '%'}
            scale={1.2}
            labelStyle={{ color: '#736A65', fontSize: '22px', fontWeight: '600' }}
          />
          <Box>
            <Typography
              alignSelf={'stretch'}
              fontSize={'18px'}
              fontStyle={'normal'}
              lineHeight={'24px'}
              letterSpacing={'0.1px'}
              color={'#736A65'}
            >
              Reconciliation
            </Typography>
          </Box>
        </Box>

        <Box display={'flex'} gap={2} alignItems={'center'} flexDirection={'column'}>
          <CircularIndicator
            color="#0074bc"
            value={buyerProfile.trustPc}
            label={buyerProfile.trustPc + '%'}
            scale={1.2}
            labelStyle={{ color: '#736A65', fontSize: '22px', fontWeight: '600' }}
          />
          <Box>
            <Typography
              alignSelf={'stretch'}
              fontSize={'18px'}
              fontStyle={'normal'}
              lineHeight={'24px'}
              letterSpacing={'0.1px'}
              color={'#736A65'}
            >
              Trust
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export type ReportGenerationStatus = 'None' | 'Requested' | 'Generating'

export function BuyerDashboardNavigation({ buyerId }: { buyerId: string }) {
  const [reportStatus, setReportStatus] = useState<ReportGenerationStatus>('None')
  const [progress, updateProgress] = useState<number>(0)
  useEffect(() => {
    if (reportStatus === 'Requested') {
      setReportStatus('Generating')
      setTimeout(async () => {
        await generatePdf('landscape', (progress: number) => updateProgress(progress))
        setReportStatus('None')
        setTimeout(async () => updateProgress(0), 100)
      }, 0)
    }
  }, [reportStatus])
  return (
    <Box display={'flex'} justifyContent={'space-between'}>
      <Backdrop
        open={reportStatus !== 'None'}
        sx={{
          color: `${theme.palette.primary.main}`,
          zIndex: 9999,
        }}
      >
        <Box
          width={340}
          maxWidth={'80%'}
          bgcolor={theme.palette.common.white}
          px={4}
          py={4}
          borderRadius={1}
        >
          <Typography color={theme.palette.common.black} marginBottom={3}>
            Report generation may take up to 1 minute
          </Typography>
          <LinearProgressWithLabel value={progress} color="primary" />
        </Box>
      </Backdrop>
      <Box marginLeft={-2}>
        <Button
          variant="text"
          startIcon={<ChevronLeft />}
          color="primary"
          component={RouterLink}
          to={`/buyerdetails/${buyerId}`}
        >
          Back to Console
        </Button>
      </Box>
      <Box display={'flex'} gap={2} marginRight={-1}>
        <Tooltip title="Export this dashboard" arrow>
          <Button
            variant="text"
            endIcon={<PdfIcon />}
            color="primary"
            onClick={() => setReportStatus('Requested')}
          >
            Export
          </Button>
        </Tooltip>
      </Box>
    </Box>
  )
}

type RapProgressContent = {
  overview: string
  relationship: string
  respect: string
  opportunity: string
  governance: string
}

function EditRapProgress({
  rapProgressContent,
  buyerOrgId,
}: {
  rapProgressContent: GetCombinedProfileRapProgressReportContent
  buyerOrgId: number
}) {
  const [content, setContent] = useState<RapProgressContent>({
    overview: rapProgressContent.overview,
    relationship: rapProgressContent.relationship,
    respect: rapProgressContent.respect,
    opportunity: rapProgressContent.opportunity,
    governance: rapProgressContent.governance,
  })

  const { callApi, state } = useApi(
    dataProcessingClient.editRapProgressReportContent.bind(dataProcessingClient)
  )

  return (
    <FormControl>
      <Typography fontWeight={500} fontSize={25} mb={5}>
        Rap Progress Edit
      </Typography>
      <Box width={'500px'} display={'flex'} flexDirection={'column'} gap={2}>
        <TextField
          label="Overview"
          multiline
          rows={5}
          fullWidth
          value={content.overview}
          onChange={(e: any) => setContent({ ...content, overview: e.target.value })}
        />
        <TextField
          label="Relationship"
          multiline
          rows={5}
          fullWidth
          value={content.relationship}
          onChange={(e: any) => setContent({ ...content, relationship: e.target.value })}
        />
        <TextField
          label="Respect"
          multiline
          rows={5}
          fullWidth
          value={content.respect}
          onChange={(e: any) => setContent({ ...content, respect: e.target.value })}
        />
        <TextField
          label="Opportunity"
          multiline
          rows={5}
          fullWidth
          value={content.opportunity}
          onChange={(e: any) => setContent({ ...content, opportunity: e.target.value })}
        />
        <TextField
          label="Governance"
          multiline
          rows={5}
          fullWidth
          value={content.governance}
          onChange={(e: any) => setContent({ ...content, governance: e.target.value })}
        />
        <Button
          type="submit"
          onClick={() => {
            callApi({
              buyerOrganizationId: buyerOrgId,
              overview: content.overview,
              opportunity: content.opportunity,
              relationship: content.relationship,
              governance: content.governance,
              respect: content.respect,
            })
          }}
        >
          Submit
        </Button>
        {state === 'fulfilled' && <CheckCircleIcon style={{ color: 'green' }} />}
      </Box>
    </FormControl>
  )
}

export type PreviewReportProps = {
  previewReport?: boolean
} & PropsWithChildren

export function HiddenBuyerDashboardReport(props: CombinedProfileProps & PreviewReportProps) {
  return (
    <ReportContainer previewReport={props.previewReport}>
      <ReportPage bgcolor="#60130E">
        <Introduction
          orgName={props.combinedProfile.buyerProfile.buyerName}
          rapStage={props.combinedProfile.rapStage}
          period={props.combinedProfile.reportingPeriod.slice(0, 10)}
        />
      </ReportPage>
      <ReportPage>
        <Acknowledgement />
      </ReportPage>
      <ReportPage>
        <AboutThisReport />
      </ReportPage>
      <ReportPage>
        <ObservationsAndAnalysis />
      </ReportPage>
      <EditRapProgress
        rapProgressContent={props.combinedProfile.buyerProfile.rapProgressReportContent}
        buyerOrgId={props.combinedProfile.buyerOrgId}
      />
      <ReportPage>
        <RAPProgress
          rapProgressContent={props.combinedProfile.buyerProfile.rapProgressReportContent}
        />
      </ReportPage>
      <ReportPage>
        <Observations />
      </ReportPage>
      <ReportPage>
        <SwotAnalysis />
      </ReportPage>
      <ReportPage>
        <RAPProgressMetrics
          period={props.combinedProfile.reportingPeriod.slice(0, 10)}
          profile={props.combinedProfile.buyerProfile.corePillars}
        />
      </ReportPage>
      <ReportPage>
        <RAPRelationship {...props.combinedProfile.buyerProfile.relationshipDetailedMetrics} />
      </ReportPage>
      <ReportPage>
        <RAPRespect {...props.combinedProfile.buyerProfile.respectDetailedMetrics} />
      </ReportPage>
      <ReportPage>
        <RAPOpportunity {...props.combinedProfile.buyerProfile.opportunityDetailedMetrics} />
      </ReportPage>
      <ReportPage>
        <RAPGovernance {...props.combinedProfile.buyerProfile.governanceDetailedMetrics} />
      </ReportPage>
      <ReportPage>
        <OrganisationalProgressAndTrends />
      </ReportPage>
      <ReportPage>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            padding: '20px',
            position: 'relative',
          }}
        >
          <Box mx={6} marginTop={6} marginBottom={10}>
            <LogoDark scale={2} />
          </Box>

          <BuyerDashboard
            buyerId={props.combinedProfile.buyerOrgId.toString()}
            isReportView={true}
            buyerProfile={props.combinedProfile.buyerProfile}
            stage={props.combinedProfile.rapStage}
            reportingPeriod={props.combinedProfile.reportingPeriod.slice(0, 10)}
          />
          <Box mx={6}>
            <Copyright pageNumber={14} />
          </Box>
        </Box>
      </ReportPage>
      <ReportPage>
        <ReconciliationDimensionIntensityLevels />
      </ReportPage>
      <ReportPage>
        <ReconciliationDimensionProfiles />
      </ReportPage>
      <ReportPage>
        <RapImpactAndReconciliationIndex
          reconIndex={props.combinedProfile.buyerProfile.reconciliationPc}
          rapImpactPc={props.combinedProfile.buyerProfile.rapImpactPc}
        />
      </ReportPage>
      <ReportPage>
        <Trust trustPc={props.combinedProfile.buyerProfile.trustPc} />
      </ReportPage>
      <ReportPage>
        <OrganisationalProgressAndTrendsDefinitions />
      </ReportPage>
      <ReportPage>
        <IndigenousSupplierRelationships />
      </ReportPage>
      {props.combinedProfile.buyerSupplierProfiles.map((x, idx) => (
        <ReportPage>
          <Box
            sx={{
              width: '100%',
              height: '100%',
              padding: '20px',
              position: 'relative',
            }}
          >
            <Box mx={6} marginTop={6} marginBottom={18}>
              <LogoDark scale={2} />
            </Box>
            <BuyerSupplierDashboard
              key={x.supplierName}
              isReportView={true}
              buyerSupplierProfile={x}
              reportingPeriod={props.combinedProfile.reportingPeriod.slice(0, 10)}
            />
            <Box mx={6}>
              <Copyright pageNumber={21 + idx} />
            </Box>
          </Box>
        </ReportPage>
      ))}
      <ReportPage>
        <OurServices pageNumber={props.combinedProfile.buyerSupplierProfiles.length + 21} />
      </ReportPage>
    </ReportContainer>
  )
}
