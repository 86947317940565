import { PropsWithChildren } from 'react'
import { accountApi } from '../data/myApis'
import useApi from '../hooks/useApi'
import UserContext from './UserContext'

const UserContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { callApi, data, state } = useApi(accountApi.getUser.bind(accountApi))

  return (
    <UserContext.Provider value={{ user: data, state, refreshUser: callApi }}>
      {children}
    </UserContext.Provider>
  )
}

export default UserContextProvider
