import React, { useEffect, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import {
  Container,
  Paper,
  Typography,
  Button,
  CircularProgress,
  Card,
  CardContent,
  CardActions,
} from '@mui/material'
import { accountApi, dataProcessingClient } from '../../../data/myApis'
import useApi from '../../../hooks/useApi'
import Box from '@mui/material/Box'

const BuyersList: React.FC = () => {
  const navigate = useNavigate()
  const { callApi: callLogOut } = useApi(accountApi.logout.bind(accountApi), () =>
    navigate('/login')
  )

  const { callApi, data, state } = useApi(
    dataProcessingClient.getAllBuyerOrganisations.bind(dataProcessingClient)
  )

  const { callApi: callSetArchiveBuyerOrg } = useApi(
    dataProcessingClient.setArchiveBuyerOrganization.bind(dataProcessingClient)
  )

  const [showArchived, setShowArchived] = useState(false)

  useEffect(() => {
    callApi()
    // eslint-disable-next-line
  }, [])

  if (state === 'loading') {
    return <CircularProgress />
  }

  if (state === 'errored') {
    return <Typography variant="body1">Error loading data.</Typography>
  }

  const archiveBuyerOrganization = async (buyerOrganizationId: number, archived: boolean) => {
    try {
      callSetArchiveBuyerOrg({ buyerOrganizationId, archived })
      callApi()
    } catch (error) {
      console.error('Error setting archive status for buyer organization:', error)
    }
  }

  var renderBuyerOrganizations = () => {
    if (!data?.buyerOrganizations) {
      return null
    }

    var sortedBuyerOrganizations = [...data.buyerOrganizations].sort((a, b) => {
      // THIS IS UGLY ASF!
      if (a.submissionDate === null) return 1
      if (b.submissionDate === null) return -1

      // Compare dates
      return new Date(a.submissionDate).getTime() - new Date(b.submissionDate).getTime()
    })

    return sortedBuyerOrganizations
      .filter((buyer) => showArchived || !buyer.archived)
      .map((buyer, index) => (
        <Card key={buyer.buyerOrganizationId} style={{ marginBottom: 20 }}>
          <CardContent>
            <Typography variant="h6">{buyer.name || 'N/A'}</Typography>
            <Typography color="textSecondary">Email: {buyer.email || 'Not available'}</Typography>
            <Typography color="textSecondary">
              Rap Stage: {buyer.rapStage || 'Not available'}
            </Typography>
            <Typography color="textSecondary">
              Submission Date:{' '}
              {buyer.submissionDate
                ? new Date(buyer.submissionDate).toLocaleDateString()
                : 'Not available'}
            </Typography>
          </CardContent>
          <CardActions>
            <Box mr={2}>
              {' '}
              {/* Add this Box component */}
              <Button
                size="small"
                component={RouterLink}
                to={`/buyerdetails/${buyer.buyerOrganizationId}`}
              >
                View Details
              </Button>
            </Box>{' '}
            {/* Close the Box component */}
            {!buyer.archived && (
              <Button
                size="small"
                color="primary"
                onClick={() => archiveBuyerOrganization(buyer.buyerOrganizationId, true)}
              >
                Archive
              </Button>
            )}
            {buyer.archived && (
              <Button
                size="small"
                color="secondary"
                onClick={() => archiveBuyerOrganization(buyer.buyerOrganizationId, false)}
              >
                Unarchive
              </Button>
            )}
          </CardActions>
        </Card>
      ))
  }

  return (
    <Container maxWidth="md">
      <Button variant="contained" color="primary" onClick={() => callLogOut()}>
        Logout
      </Button>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h4">Buyer Organization List</Typography>
        <Typography variant="body1">Generic Text</Typography>
      </Paper>

      <Button variant="contained" color="secondary" component={RouterLink} to={`/dropzone/buyer`}>
        Create New Buyer Organization
      </Button>
      <Paper style={{ padding: 20, marginBottom: 20 }}>
        <Typography variant="h6">Buyer Orgs</Typography>

        {state === 'fulfilled' ? (
          <>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => setShowArchived(!showArchived)}
            >
              {showArchived ? 'Hide Archived' : 'Show Archived'}
            </Button>
            {renderBuyerOrganizations()}
          </>
        ) : (
          <Typography variant="body1">No buyer organizations found.</Typography>
        )}
      </Paper>
    </Container>
  )
}

export default BuyersList
