import { createTheme, responsiveFontSizes } from '@mui/material'
import colours from './palette'

const theme = createTheme({
  palette: colours,
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: '#C0E1D7',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '13px',
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        color: 'primary',
      },
    },
  },
  typography: {
    fontFamily: 'Montserrat, Lato, Roboto, sans-serif',
    button: {
      textTransform: 'none',
    },
    h1: { fontSize: 25, fontWeight: 700 },
    h2: { fontSize: 21, fontWeight: 400 },
    h3: { fontSize: 16, fontWeight: 700 },
    subtitle1: { fontSize: 13 },
    subtitle2: { fontSize: 10 },
    body1: { fontSize: 15 },
  },
  spacing: 6,
})

export default responsiveFontSizes(theme)
