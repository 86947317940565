import { CircularProgress, Box, Typography, Button } from '@mui/material'
import { PropsWithChildren } from 'react'
import RefreshIcon from '@mui/icons-material/Refresh'

interface LoadingContainerProps {
  isLoading: boolean
  isErrored?: boolean
  errorMessage?: string
  showRefreshButton?: boolean
}

const LoadingContainer: React.FC<PropsWithChildren<LoadingContainerProps>> = ({
  isLoading,
  isErrored,
  children,
  showRefreshButton = true,
  errorMessage = 'Oh no! An unexpected error occurred. Please try again later.',
}) => {
  if (isLoading) {
    return (
      <Box width={1} height={1} justifyContent="center" alignItems="center" display="flex">
        <CircularProgress color="primary" />
      </Box>
    )
  }

  if (isErrored) {
    return (
      <Box
        width={1}
        height={1}
        justifyContent="center"
        alignItems="center"
        display="flex"
        flexDirection="column"
      >
        <Typography variant="h5" color="text.primary" paddingBottom={3} align="center">
          {errorMessage}
        </Typography>
        {showRefreshButton && (
          <>
            <Typography variant="h5" color="text.primary" paddingBottom={3} align="center">
              Please click below to refresh the page.
            </Typography>
            <Button
              color="error"
              variant="outlined"
              size="large"
              aria-label="Refresh Page"
              endIcon={<RefreshIcon />}
              onClick={() => window.location.reload()}
            >
              Refresh Page
            </Button>
          </>
        )}
      </Box>
    )
  }

  // Default case: Render children if no loading or error state (Time to display).
  return <>{children}</>
}

export default LoadingContainer
