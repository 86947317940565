import { ReactComponent as Relations } from '../../../assets/relations.svg'
import { ReactComponent as RelationsLine } from '../../../assets/relations-line.svg'
import { ReactComponent as Capability } from '../../../assets/Capability.svg'
import { ReactComponent as CapabilityLine } from '../../../assets/Capability-line.svg'
import { ReactComponent as Equality } from '../../../assets/Equality.svg'
import { ReactComponent as EqualityLine } from '../../../assets/Equality-line.svg'
import { ReactComponent as Harmony } from '../../../assets/Harmony.svg'
import { ReactComponent as HarmonyLine } from '../../../assets/Harmony-line.svg'
import { ReactComponent as Acceptance } from '../../../assets/acceptance.svg'
import { ReactComponent as AcceptanceLine } from '../../../assets/acceptance-line.svg'

export type MetricType =
  | 'Race Relations'
  | 'Equality & Equity'
  | 'Institutional Integrity'
  | 'Unity'
  | 'Historical Acceptance'
  | 'Relationship'
  | 'Respect'
  | 'Opportunity'
  | 'Governance'

interface MetricIconProps {
  metric: MetricType
  fill?: string
  stroke?: string
  filled: boolean
  width?: number
  height?: number
  color: string
}

const MetricIcon = ({ filled, metric, ...rest }: MetricIconProps) => {
  switch (metric) {
    case 'Race Relations':
      return filled ? <Relations {...rest} /> : <RelationsLine {...rest} />
    case 'Institutional Integrity':
      return filled ? <Capability {...rest} /> : <CapabilityLine {...rest} />
    case 'Equality & Equity':
      return filled ? <Equality {...rest} /> : <EqualityLine {...rest} />
    case 'Unity':
      return filled ? <Harmony {...rest} /> : <HarmonyLine {...rest} />
    case 'Historical Acceptance':
      return filled ? <Acceptance {...rest} /> : <AcceptanceLine {...rest} />
    default:
      return <></>
  }
}

export default MetricIcon
