import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'

export default function Observations() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box>
        <LogoDark scale={2} />
      </Box>
      <Box display={'flex'} mt={10}>
        <Box width={'45%'}>
          <Typography mt={20} fontSize={46} fontWeight={600}>
            Organisational and industry trends
          </Typography>
          <Header title="Overview">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Header>
          <Observation title="Observation #1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Observation>
          <Observation title="Observation #2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Observation>
        </Box>
        <Box width={'45%'} marginLeft={12}>
          <Typography mt={20} fontSize={46} fontWeight={600}>
            Indigenous supplier relationships
          </Typography>
          <Header title="Overview">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Header>
          <Observation title="Observation #1">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Observation>
          <Observation title="Observation #2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
            incididunt ut labore et dolore magna aliqua. Dictum non consectetur a erat nam. Ipsum a
            arcu cursus vitae congue mauris rhoncus aenean. Tincidunt praesent semper feugiat nibh.
            Nunc non blandit massa enim nec dui nunc. Aliquam sem et tortor consequat id porta nibh
            venenatis. Fringilla urna porttitor rhoncus dolor purus non enim praesent elementum.
            Morbi tristique senectus et netus et malesuada fames ac.
          </Observation>
        </Box>
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={6} />
    </Box>
  )
}

type TextBlockProps = PropsWithChildren & {
  title: string
}

function Header({ title, children }: TextBlockProps) {
  return (
    <Box mt={5}>
      <Typography fontSize={26} color={'#CE6632'} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={14} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}

function Observation({ title, children }: TextBlockProps) {
  return (
    <Box mt={5}>
      <Typography fontSize={14} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={14} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}
