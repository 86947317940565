// RapDetails.tsx
import React from 'react'
import { Paper, Typography, Button } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { GetBuyerOrganizationRapDTO } from '../../../api/api.generated'

interface RapDetailsProps {
  rap: GetBuyerOrganizationRapDTO | null | undefined
  buyerId: string
}

export const RapDetails: React.FC<RapDetailsProps> = ({ rap, buyerId }) => {
  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h6">Rap Details</Typography>
      {rap ? (
        <div>
          <Typography variant="subtitle1">
            <strong>Start Date:</strong> {rap.start?.toString() || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Completion Date:</strong> {rap.completion?.toString() || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Modified Date:</strong> {rap.modified?.toString() || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Period:</strong> {rap.rapPeriod?.toString() || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Mutual Relationships Aboriginal:</strong>{' '}
            {rap.mutualRelationshipsAboriginal || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Celebrate NRW:</strong> {rap.celebrateNRW || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Promote Reconciliation Influence:</strong>{' '}
            {rap.promoteReconciliationInfluence || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Anti-Discrimination Strategies:</strong>{' '}
            {rap.antiDiscriminationStrategies || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Cultural Understanding Recognition:</strong>{' '}
            {rap.culturalUnderstandingRecognition || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Observe Cultural Protocols:</strong> {rap.observeCulturalProtocols || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Celebrate NAIDOC Week:</strong> {rap.celebrateNAIDOCWeek || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Improve Employment Outcomes:</strong> {rap.improveEmploymentOutcomes || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Increase Supplier Diversity:</strong> {rap.increaseSupplierDiversity || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Effective RAP Working Group:</strong> {rap.effectiveRAPWorkingGroup || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Support RAP Implementation:</strong> {rap.supportRAPImplementation || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>RAP Accountability Transparency:</strong>{' '}
            {rap.rapAccountabilityTransparency || 'N/A'}
          </Typography>
          <Typography variant="subtitle1">
            <strong>Continue Reconciliation Journey:</strong>{' '}
            {rap.continueReconciliationJourney || 'N/A'}
          </Typography>
        </div>
      ) : (
        <>
          <Button
            variant="contained"
            color="secondary"
            component={RouterLink}
            to={`/dropzone/rap/${buyerId}`}
          >
            Create New Rap Relationship
          </Button>
          <Typography variant="body1">No Rap details available</Typography>
        </>
      )}
    </Paper>
  )
}
