import React, { PropsWithChildren, useEffect } from 'react'
import { Navigate, useLocation, useSearchParams } from 'react-router-dom'
import UserContext from '../contexts/UserContext'
import { getPathWithRedirectQuery } from '../utils/routeUtils'

const AuthenticatedRoute: React.FC<PropsWithChildren> = (props) => {
  const userContext = React.useContext(UserContext)
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const plan = searchParams.get('plan')
  let redirectTo = location.pathname === '/' ? '' : location.pathname
  if (plan) {
    // TODO: if more query param needs to be supported, create a utils to handle this generically
    redirectTo += `?plan=${plan}`
  }

  useEffect(() => {
    // Only need to get user if token exists, will fail otherwise so no point
    if (!userContext.user && userContext.state === 'idle') {
      userContext.refreshUser()
    }
  }, [userContext])

  if (!userContext.user && userContext.state !== 'errored' && userContext.state !== 'fulfilled') {
    return <></>
  } else if (userContext.user) {
    return <>{props.children}</>
  } else {
    return <Navigate to={getPathWithRedirectQuery('/login', redirectTo)} />
  }
}

export default AuthenticatedRoute
