import { Box, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RainbowBar from '../common/logos/RainbowBar'

export default function ReconciliationDimensionIntensityLevels() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          Organisational Progress and Trends
        </Typography>
      </Box>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      />
      <Box width={'49%'} mb={8}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          Reconciliation Dimension Intensity Levels
        </Typography>
        <Typography mt={12} fontSize={26}>
          There are four levels of relational contract norm intensities.
        </Typography>
      </Box>
      <RainbowBar thickness={40} />
      <Box mt={10} />
      <Grid container spacing={15}>
        {levelConfig.map((x) => (
          <Grid item xs={3} key={x.heading}>
            <Level {...x} />
          </Grid>
        ))}
      </Grid>
      <Copyright pageNumber={15} />
    </Box>
  )
}

export type LevelProps = {
  heading: string
  description: string
}

const levelConfig: LevelProps[] = [
  {
    heading: 'Low (0-50%)',
    description:
      'Inferior dimensional intensity range. May have a negative impact on other dimensions. Improve or risk significant relationship compromise.',
  },
  {
    heading: 'Average (51-75%)',
    description:
      'Generally normalised and tolerated by both organisations. Seek to improve sooner rather than later or risk further degradation, with impact to relationship.',
  },
  {
    heading: 'High (76-90%)',
    description:
      'Healthy dimensional intensity and organisation is making significant strides to reconciliation. Look for opportunities to improve towards Elite.',
  },
  {
    heading: 'Elite (91%+ )',
    description:
      'Dimensional intensity at its best. Has positive effect on supporting other dimensions as well as overall relationship. Monitor and maintain.',
  },
]

const Level = ({ heading, description }: LevelProps) => {
  return (
    <>
      <Typography fontSize={25} fontWeight={600} color={'#DB5C11'}>
        {heading}
      </Typography>
      <Typography mt={'10px'} fontSize={16}>
        {description}
      </Typography>
    </>
  )
}
