import { Box, Theme, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren, ReactNode } from 'react'
import AppHeader from '../header/AppHeader'
import React from 'react'

interface FullscreenContainerProps {
  showHeader?: boolean
  bgImage?: string
  bgColor?: string
  appBarBgColor?: string
  bgGraphics?: ReactNode
}

const FullscreenContainer: React.FunctionComponent<PropsWithChildren<FullscreenContainerProps>> = ({
  showHeader = false,
  children,
  bgImage,
  bgColor,
  appBarBgColor,
  bgGraphics,
}) => {
  const theme = useTheme()

  React.useEffect(() => {
    const originalValue = (document.querySelector('meta[name=viewport]') as any).getAttribute(
      'content'
    )
    // Zoom reset hack, some browser on mobile will zoom in on form, leaving subsequent page zoom
    ;(document.querySelector('meta[name=viewport]') as any).setAttribute(
      'content',
      'width=device-width, initial-scale=1.0, maximum-scale=1.0, minimum-scale=1.0, user-scalable=no, target-densityDpi=device-dpi'
    )
    return () => {
      ;(document.querySelector('meta[name=viewport]') as any).setAttribute('content', originalValue)
    }
  }, [])

  return (
    <>
      {showHeader && <AppHeader bgColor={appBarBgColor} />}
      <Box
        position="fixed"
        top={0}
        bottom={0}
        left={0}
        right={0}
        width={'100%'}
        height={'100%'}
        style={{ overflowY: 'auto', background: bgColor ?? theme.palette.background.gradient }}
        display="flex"
        flexDirection="column"
      >
        <Box
          flexGrow={1}
          pt={2}
          mt={showHeader ? 9 : 0}
          sx={{
            backgroundImage: bgImage ? `url(${bgImage})` : undefined,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right',
            backgroundSize: 'auto 100%',
          }}
          zIndex={2}
        >
          {children}
        </Box>
        <BackgroundGraphicsContainer bgGraphics={bgGraphics} />
      </Box>
    </>
  )
}

const BackgroundGraphicsContainer = (props: { bgGraphics?: ReactNode }) => {
  const isMobile = useMediaQuery((t: Theme) => t.breakpoints.down('md'))

  return (
    <Box
      position={'fixed'}
      bottom={isMobile ? '-100px' : '0'}
      right={isMobile ? '' : '50%'}
      display={isMobile ? 'flex' : ''}
      width={isMobile ? '100%' : ''}
      justifyContent={isMobile ? 'center' : ''}
      sx={{
        transform: isMobile ? 'scale(0.7)' : 'scale(1.2)',
        filter: 'grayscale(1)',
      }}
      zIndex={1}
    >
      {props.bgGraphics}
    </Box>
  )
}

export default FullscreenContainer
