import {
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Typography,
} from '@mui/material'
import { GetBuyerOrganizationReportReadinessDTO } from '../../../api/api.generated'
import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import CancelIcon from '@mui/icons-material/Cancel'

type ReportDetailsProps = {
  reportReadiness?: GetBuyerOrganizationReportReadinessDTO
  buyerId?: string
}

export default function ReportDetails(props: ReportDetailsProps) {
  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h6">Report/Dashboard Readiness</Typography>
      <List>
        <CheckListItem
          isCompleted={props.reportReadiness?.hasUploadedBuyerOrgDetails ?? false}
          text="Has Uploaded Buyer Org Details"
        />
        <CheckListItem
          isCompleted={props.reportReadiness?.hasUploadedRapDetails ?? false}
          text="Has Uploaded Rap Details"
        />
        <CheckListItem
          isCompleted={props.reportReadiness?.hasCompletedSurveysForAllBuyersPersonnel ?? false}
          text="Has Completed Surveys For All Buyers Personnel"
        />
        <CheckListItem
          isCompleted={props.reportReadiness?.hasUploadedAtLeastOneSupplier ?? false}
          text="Has Uploaded At Least One Supplier"
        />
        <CheckListItem
          isCompleted={
            props.reportReadiness?.hasCompletedSurveysForAllPersonnelsForAtLeastOneSupplier ?? false
          }
          text="Has Completed Surveys For All Personnel For At Least One Supplier"
        />
      </List>
      <Button
        variant="contained"
        color="secondary"
        component={RouterLink}
        to={`/dashboard/${props.buyerId}`}
      >
        Preview Dashboard and Print Report
      </Button>
    </Paper>
  )
}

function CheckListItem({ isCompleted, text }: { isCompleted: boolean; text: string }) {
  return (
    <ListItem>
      <ListItemIcon>
        {isCompleted ? (
          <CheckCircleIcon style={{ color: 'green' }} />
        ) : (
          <CancelIcon style={{ color: 'red' }} />
        )}
      </ListItemIcon>
      <ListItemText primary={text} />
    </ListItem>
  )
}
