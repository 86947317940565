import React from 'react'
import { Paper, Typography, Button, List, ListItem, ListItemText, Divider } from '@mui/material'
import { Link as RouterLink } from 'react-router-dom'
import { GetBuyerOrganizationSupplierDTO } from '../../../api/api.generated'

interface BuyerSuppliersDetailsProps {
  suppliers: GetBuyerOrganizationSupplierDTO[]
  buyerId: string
}

export const BuyerSuppliersDetails: React.FC<BuyerSuppliersDetailsProps> = ({
  suppliers,
  buyerId,
}) => {
  return (
    <Paper style={{ padding: 20, marginBottom: 20 }}>
      <Typography variant="h6">Suppliers</Typography>
      <Button
        variant="contained"
        color="secondary"
        component={RouterLink}
        to={`/dropzone/supplier/${buyerId}`}
      >
        Create New Supplier Relationship
      </Button>
      <List>
        {suppliers.map((supplier) => (
          <React.Fragment key={supplier.supplierId}>
            <ListItem component={RouterLink} to={`/supplierdetails/${supplier.supplierId}`}>
              <ListItemText primary={supplier.name} secondary={`ID: ${supplier.supplierId}`} />
            </ListItem>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Paper>
  )
}
