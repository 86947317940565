import { PropsWithChildren } from 'react'
import theme from '../../theme'
import { Box } from '@mui/material'
import { PreviewReportProps } from './BuyerDashboard/BuyerDashboard'

export function ReportContainer(props: PreviewReportProps) {
  return (
    <Box display={props.previewReport ? 'block' : 'none'} width="100%" height="100%" id="print">
      {/* <Box display={'block'} width="100%" height="100%" id="print"> */}
      {props.children}
    </Box>
  )
}

export type ReportPageProps = PropsWithChildren & {
  bgcolor?: string
}

export function ReportPage(props: ReportPageProps) {
  const pageStyles = {
    width: 1635,
    height: 1150,
    bgcolor: props.bgcolor ?? theme.palette.common.white,
  }

  return (
    <Box sx={pageStyles} className="page">
      {props.children}
    </Box>
  )
}
