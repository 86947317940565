import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RAPDetail from './RAPDetail'
import { GetCombinedProfileRelationshipDetailedMetrics } from '../../api/api.generated'

export default function RAPRelationship(props: GetCombinedProfileRelationshipDetailedMetrics) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#DB5C1110',
      }}
    >
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'200px'}
        bgcolor={'#ffffff'}
      />
      <Box display={'flex'} justifyContent={'space-between'} zIndex={1} position={'relative'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          RAP Progress
        </Typography>
      </Box>
      <RAPDetail
        rapType="Relationship"
        metrics={[
          {
            percentage: props.establishAndMaintain,
            description:
              'Establish and maintain mutually beneficial relationships with Aboriginal and Torres Strait Islander stakeholders and organisations',
          },
          {
            percentage: props.buildRelationships,
            description:
              'Build relationships through celebrating National Reconciliation Week (NRW)',
          },
          {
            percentage: props.promoteReconciliation,
            description: 'Promote reconciliation through our sphere of influence',
          },
          {
            percentage: props.promoteRaceRelations,
            description: 'Promote positive race relations through anti-discrimination strategies',
          },
        ]}
        rapDescription="Building and strengthening genuine, positive, and meaningful relationships between the organization and Aboriginal and Torres Strait Islander peoples. Recognising the importance of partnerships, collaboration, and engagement to advance reconciliation. Establishing connections based on trust, equality, and mutual benefit."
      />
      <Copyright pageNumber={9} />
    </Box>
  )
}
