import { Box } from '@mui/material'

interface RainbowBarProps {
  isVertical?: boolean
  thickness?: number
  borderRadius?: number
}

const RainbowBar: React.FC<RainbowBarProps> = ({
  isVertical = false,
  thickness = 8,
  borderRadius = 20,
}) => (
  <Box
    width={isVertical ? thickness : undefined}
    height={isVertical ? undefined : thickness}
    borderRadius={borderRadius}
    sx={{
      background: `linear-gradient(${
        isVertical ? '0deg' : '90deg'
      }, rgba(255, 0, 0, 1) 0%, rgba(255, 154, 0, 1) 16%, rgba(208, 222, 33, 1) 32%, rgba(79, 220, 74, 1) 48%, rgba(63, 218, 216, 1) 64%, rgba(47, 201, 226, 1) 80%, rgba(28, 127, 238, 1) 100%)`,
    }}
  />
)

export default RainbowBar
