import { Box, Typography } from '@mui/material'
import LogoWhite from './LogoWhite'
import PoweredByCHLogo from './PoweredByCHLogo'
import IndgaugeFrontPageSquare from './IndgaugeFrontPageSquare'

const Introduction = ({
  orgName,
  rapStage,
  period,
}: {
  orgName: string
  rapStage: string
  period: string
}) => {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
      }}
      color={'#ffffff'}
    >
      <Box>
        <LogoWhite scale={2} />
      </Box>

      <Typography mt={28} display={'inline-block'} fontSize={70} lineHeight={1.2} fontWeight={600}>
        Indigenous
        <br />
        Supplier
        <br />
        Engagement
      </Typography>
      <Typography mt={5} fontSize={30} fontWeight={600}>
        {orgName}
      </Typography>
      <Typography mt={3} fontSize={25} fontWeight={400}>
        RAP stage: {rapStage}
      </Typography>
      <Typography mt={3} fontSize={25} fontWeight={400}>
        {period}
      </Typography>
      <Box mt={33}>
        <PoweredByCHLogo scale={1.8} />
      </Box>
      <Box position={'absolute'} top={'180px'} left={'650px'}>
        <IndgaugeFrontPageSquare scale={1.9} />
      </Box>
    </Box>
  )
}

export default Introduction
