import { Box, AppBar, Toolbar, IconButton, Menu, MenuItem } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import LogoWithTitle from '../logos/LogoWithTitle'
import AccountCircle from '@mui/icons-material/AccountCircle'
import UserContext from '../../../contexts/UserContext'
import React from 'react'
import { accountApi } from '../../../data/myApis'
import { useSnackbar } from 'notistack'
import useApi from '../../../hooks/useApi'

type AppHeaderProps = {
  bgColor?: string
}

const AppHeader = ({ bgColor }: AppHeaderProps) => {
  const { user } = React.useContext(UserContext)
  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const onFailure = () => {
    enqueueSnackbar('Unable to logout', { variant: 'error' })
  }

  const onSuccess = () => {
    navigate('/login')
  }

  const { callApi } = useApi(accountApi.logout.bind(accountApi), onSuccess, onFailure)
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogOut = () => {
    callApi()
  }

  return (
    <Box position={'relative'}>
      <AppBar
        position="sticky"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: bgColor,
        }}
      >
        <Toolbar>
          <Link to="/">
            <LogoWithTitle width={150} height="auto" />
          </Link>
          {!!user && (
            <Box flexGrow={1} display="flex" justifyContent="flex-end">
              <IconButton size="large" onClick={handleClick}>
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem onClick={() => navigate('/account/edit')}>Edit Account</MenuItem>
                <MenuItem onClick={() => navigate('/account/changepassword')}>
                  Change Password
                </MenuItem>
                <MenuItem onClick={handleLogOut}>Logout</MenuItem>
              </Menu>
            </Box>
          )}
        </Toolbar>
      </AppBar>
    </Box>
  )
}

export default AppHeader
