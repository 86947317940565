import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RAPDetail from './RAPDetail'
import { GetCombinedProfileGovernanceDetailedMetrics } from '../../api/api.generated'

export default function RAPGovernance(props: GetCombinedProfileGovernanceDetailedMetrics) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#DB5C1110',
      }}
    >
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'200px'}
        bgcolor={'#ffffff'}
      />
      <Box display={'flex'} justifyContent={'space-between'} zIndex={1} position={'relative'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          RAP Progress
        </Typography>
      </Box>
      <RAPDetail
        rapType="Governance"
        metrics={[
          {
            percentage: props.establishAndMaintain,
            description:
              'Establish and maintain an effective RAP Working group (RWG) to drive governance of the RAP',
          },
          {
            percentage: props.provideSupport,
            description:
              'Provide appropriate support for effective implementation of RAP commitments',
          },
          {
            percentage: props.buildAccountability,
            description:
              'Build accountability and transparency through reporting RAP achievements, challenges, and learnings both internally and externally',
          },
          {
            percentage: props.continueJourney,
            description: 'Continue our reconciliation journey by developing our next RAP',
          },
        ]}
        rapDescription="The structure, procedures, and mechanisms established to steer and supervise the execution of initiatives designed to foster reconciliation between Aboriginal and Torres Strait Islander peoples and the broader community."
      />
      <Copyright pageNumber={12} />
    </Box>
  )
}
