export type LogoWhiteProps = {
  scale?: number
}

export default function LogoWhite({ scale }: LogoWhiteProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={scale ? scale * 86 : '86'}
      height={scale ? scale * 15 : '15'}
      viewBox="0 0 86 15"
      fill="none"
    >
      <g clip-path="url(#clip0_37_1015)">
        <path
          d="M2.1521 10.8809C2.1521 11.3343 1.7894 11.697 1.33603 11.697C0.882656 11.697 0.519958 11.3343 0.519958 10.8809C0.519958 10.4275 0.882656 10.0648 1.33603 10.0648C1.7894 10.0648 2.1521 10.4275 2.1521 10.8809Z"
          fill="#FADC92"
        />
        <path
          d="M4.51971 10.8809C4.51971 11.3343 4.15702 11.697 3.70364 11.697C3.25027 11.697 2.88757 11.3343 2.88757 10.8809C2.88757 10.4275 3.25027 10.0648 3.70364 10.0648C4.15702 10.0648 4.51971 10.4275 4.51971 10.8809Z"
          fill="#FAA338"
        />
        <path
          d="M6.88733 10.8809C6.88733 11.3343 6.52463 11.697 6.07126 11.697C5.61789 11.697 5.25519 11.3343 5.25519 10.8809C5.25519 10.4275 5.61789 10.0648 6.07126 10.0648C6.52463 10.0648 6.88733 10.4275 6.88733 10.8809Z"
          fill="#E38135"
        />
        <path
          d="M9.24481 10.8809C9.24481 11.3343 8.88211 11.697 8.42874 11.697C7.97537 11.697 7.61267 11.3343 7.61267 10.8809C7.61267 10.4275 7.97537 10.0648 8.42874 10.0648C8.88211 10.0648 9.24481 10.4275 9.24481 10.8809Z"
          fill="#CE6632"
        />
        <path
          d="M11.6125 10.8809C11.6125 11.3343 11.2498 11.697 10.7964 11.697C10.343 11.697 9.98035 11.3343 9.98035 10.8809C9.98035 10.4275 10.343 10.0648 10.7964 10.0648C11.2498 10.0648 11.6125 10.4275 11.6125 10.8809Z"
          fill="#A4292E"
        />
        <path
          d="M2.1521 8.47299C2.1521 8.92636 1.7894 9.28906 1.33603 9.28906C0.882656 9.28906 0.519958 8.92636 0.519958 8.47299C0.519958 8.01962 0.882656 7.65692 1.33603 7.65692C1.7894 7.65692 2.1521 8.01962 2.1521 8.47299Z"
          fill="#FAA338"
        />
        <path
          d="M4.51971 8.47299C4.51971 8.92636 4.15702 9.28906 3.70364 9.28906C3.25027 9.28906 2.88757 8.92636 2.88757 8.47299C2.88757 8.01962 3.25027 7.65692 3.70364 7.65692C4.15702 7.65692 4.51971 8.01962 4.51971 8.47299Z"
          fill="#FAA338"
        />
        <path
          d="M6.88733 8.49319C6.88733 8.94657 6.52463 9.30926 6.07126 9.30926C5.61789 9.30926 5.25519 8.94657 5.25519 8.49319C5.25519 8.03982 5.61789 7.67712 6.07126 7.67712C6.52463 7.67712 6.88733 8.03982 6.88733 8.49319Z"
          fill="#E38135"
        />
        <path
          d="M9.24481 8.49319C9.24481 8.94657 8.88211 9.30926 8.42874 9.30926C7.97537 9.30926 7.61267 8.94657 7.61267 8.49319C7.61267 8.03982 7.97537 7.67712 8.42874 7.67712C8.88211 7.67712 9.24481 8.03982 9.24481 8.49319Z"
          fill="#CE6632"
        />
        <path
          d="M11.6125 8.49319C11.6125 8.94657 11.2498 9.30926 10.7964 9.30926C10.343 9.30926 9.98035 8.94657 9.98035 8.49319C9.98035 8.03982 10.343 7.67712 10.7964 7.67712C11.2498 7.67712 11.6125 8.03982 11.6125 8.49319Z"
          fill="#A4292E"
        />
        <path
          d="M2.1521 6.06515C2.1521 6.51853 1.7894 6.88122 1.33603 6.88122C0.882656 6.88122 0.519958 6.51853 0.519958 6.06515C0.519958 5.61178 0.882656 5.24908 1.33603 5.24908C1.7894 5.24908 2.1521 5.61178 2.1521 6.06515Z"
          fill="#E38135"
        />
        <path
          d="M4.51971 6.06515C4.51971 6.51853 4.15702 6.88122 3.70364 6.88122C3.25027 6.88122 2.88757 6.51853 2.88757 6.06515C2.88757 5.61178 3.25027 5.24908 3.70364 5.24908C4.15702 5.24908 4.51971 5.61178 4.51971 6.06515Z"
          fill="#E38135"
        />
        <path
          d="M6.88733 6.06515C6.88733 6.51853 6.52463 6.88122 6.07126 6.88122C5.61789 6.88122 5.25519 6.51853 5.25519 6.06515C5.25519 5.61178 5.61789 5.24908 6.07126 5.24908C6.52463 5.24908 6.88733 5.61178 6.88733 6.06515Z"
          fill="#E38135"
        />
        <path
          d="M9.24481 6.06515C9.24481 6.51853 8.88211 6.88122 8.42874 6.88122C7.97537 6.88122 7.61267 6.51853 7.61267 6.06515C7.61267 5.61178 7.97537 5.24908 8.42874 5.24908C8.88211 5.24908 9.24481 5.61178 9.24481 6.06515Z"
          fill="#CE6632"
        />
        <path
          d="M11.6125 6.06515C11.6125 6.51853 11.2498 6.88122 10.7964 6.88122C10.343 6.88122 9.98035 6.51853 9.98035 6.06515C9.98035 5.61178 10.343 5.24908 10.7964 5.24908C11.2498 5.24908 11.6125 5.61178 11.6125 6.06515Z"
          fill="#A4292E"
        />
        <path
          d="M2.1521 3.6572C2.1521 4.11057 1.7894 4.47327 1.33603 4.47327C0.882656 4.47327 0.519958 4.11057 0.519958 3.6572C0.519958 3.20382 0.882656 2.84113 1.33603 2.84113C1.7894 2.84113 2.1521 3.20382 2.1521 3.6572Z"
          fill="#CE6632"
        />
        <path
          d="M4.51971 3.6572C4.51971 4.11057 4.15702 4.47327 3.70364 4.47327C3.25027 4.47327 2.88757 4.11057 2.88757 3.6572C2.88757 3.20382 3.25027 2.84113 3.70364 2.84113C4.15702 2.84113 4.51971 3.20382 4.51971 3.6572Z"
          fill="#CE6632"
        />
        <path
          d="M6.88733 3.6774C6.88733 4.13077 6.52463 4.49347 6.07126 4.49347C5.61789 4.49347 5.25519 4.13077 5.25519 3.6774C5.25519 3.22403 5.61789 2.86133 6.07126 2.86133C6.52463 2.86133 6.88733 3.22403 6.88733 3.6774Z"
          fill="#CE6632"
        />
        <path
          d="M9.24481 3.6774C9.24481 4.13077 8.88211 4.49347 8.42874 4.49347C7.97537 4.49347 7.61267 4.13077 7.61267 3.6774C7.61267 3.22403 7.97537 2.86133 8.42874 2.86133C8.88211 2.86133 9.24481 3.22403 9.24481 3.6774Z"
          fill="#CE6632"
        />
        <path
          d="M11.6125 3.6774C11.6125 4.13077 11.2498 4.49347 10.7964 4.49347C10.343 4.49347 9.98035 4.13077 9.98035 3.6774C9.98035 3.22403 10.343 2.86133 10.7964 2.86133C11.2498 2.86133 11.6125 3.22403 11.6125 3.6774Z"
          fill="#A4292E"
        />
        <path
          d="M2.1521 1.2493C2.1521 1.70267 1.7894 2.06537 1.33603 2.06537C0.882656 2.06537 0.519958 1.70267 0.519958 1.2493C0.519958 0.795925 0.882656 0.433228 1.33603 0.433228C1.7894 0.433228 2.1521 0.795925 2.1521 1.2493Z"
          fill="#A4292E"
        />
        <path
          d="M4.51971 1.2493C4.51971 1.70267 4.15702 2.06537 3.70364 2.06537C3.25027 2.06537 2.88757 1.70267 2.88757 1.2493C2.88757 0.795925 3.25027 0.433228 3.70364 0.433228C4.15702 0.433228 4.51971 0.795925 4.51971 1.2493Z"
          fill="#A4292E"
        />
        <path
          d="M6.88733 1.2493C6.88733 1.70267 6.52463 2.06537 6.07126 2.06537C5.61789 2.06537 5.25519 1.70267 5.25519 1.2493C5.25519 0.795925 5.61789 0.433228 6.07126 0.433228C6.52463 0.433228 6.88733 0.795925 6.88733 1.2493Z"
          fill="#A4292E"
        />
        <path
          d="M9.24481 1.2493C9.24481 1.70267 8.88211 2.06537 8.42874 2.06537C7.97537 2.06537 7.61267 1.70267 7.61267 1.2493C7.61267 0.795925 7.97537 0.433228 8.42874 0.433228C8.88211 0.433228 9.24481 0.795925 9.24481 1.2493Z"
          fill="#A4292E"
        />
        <path
          d="M11.6125 1.2493C11.6125 1.70267 11.2498 2.06537 10.7964 2.06537C10.343 2.06537 9.98035 1.70267 9.98035 1.2493C9.98035 0.795925 10.343 0.433228 10.7964 0.433228C11.2498 0.433228 11.6125 0.795925 11.6125 1.2493Z"
          fill="#A4292E"
        />
        <path d="M18.01 0.644836H20.0149V11.4351H18.01V0.644836Z" fill="#231F20" />
        <path
          d="M29.4753 6.71999V11.4351H27.551V6.96178C27.551 5.50092 26.8256 4.7856 25.5965 4.7856C24.2263 4.7856 23.2994 5.62182 23.2994 7.26403V11.425H21.3751V3.20383H23.2087V4.27178C23.8434 3.51616 24.8408 3.11316 26.0498 3.11316C28.0245 3.11316 29.4854 4.24155 29.4854 6.71999H29.4753Z"
          fill="#231F20"
        />
        <path
          d="M39.1372 0V11.4351H37.2834V10.3671C36.6386 11.153 35.6815 11.5358 34.5531 11.5358C32.1653 11.5358 30.3922 9.87344 30.3922 7.3144C30.3922 4.75537 32.1653 3.10308 34.5531 3.10308C35.621 3.10308 36.558 3.4557 37.2028 4.21132V0H39.1271H39.1372ZM37.2431 7.32448C37.2431 5.75279 36.1752 4.75537 34.7949 4.75537C33.4146 4.75537 32.3467 5.75279 32.3467 7.32448C32.3467 8.89617 33.4146 9.89359 34.7949 9.89359C36.1752 9.89359 37.2431 8.89617 37.2431 7.32448Z"
          fill="#231F20"
        />
        <path
          d="M48.9905 3.20383V10.1858C48.9905 13.178 47.4288 14.5281 44.5474 14.5281C43.0059 14.5281 41.4846 14.1251 40.5376 13.3594L41.404 11.9086C42.1294 12.5131 43.2981 12.8959 44.4366 12.8959C46.2601 12.8959 47.0561 12.0597 47.0561 10.3973V9.96412C46.381 10.6996 45.4038 11.0623 44.2955 11.0623C41.938 11.0623 40.1346 9.46037 40.1346 7.07261C40.1346 4.68485 41.938 3.11316 44.2955 3.11316C45.4541 3.11316 46.4717 3.48593 47.1467 4.302V3.20383H48.9804H48.9905ZM47.0964 7.07261C47.0964 5.68227 46.0486 4.7453 44.5978 4.7453C43.147 4.7453 42.0891 5.68227 42.0891 7.07261C42.0891 8.46295 43.1369 9.43014 44.5978 9.43014C46.0586 9.43014 47.0964 8.47302 47.0964 7.07261Z"
          fill="#231F20"
        />
        <path
          d="M57.4635 6.60916V11.4351H55.6399V10.4376C55.1764 11.1429 54.2798 11.5459 53.0506 11.5459C51.1666 11.5459 49.9879 10.5182 49.9879 9.07752C49.9879 7.6368 50.9147 6.62931 53.4133 6.62931H55.5391V6.50841C55.5391 5.38002 54.8641 4.72514 53.4939 4.72514C52.567 4.72514 51.6099 5.03747 50.9953 5.54121L50.2397 4.1408C51.1162 3.46578 52.3958 3.12323 53.7257 3.12323C56.1033 3.12323 57.4735 4.25162 57.4735 6.61923L57.4635 6.60916ZM55.5391 8.86594V7.90882H53.5544C52.2446 7.90882 51.892 8.4025 51.892 9.00699C51.892 9.70216 52.4764 10.1455 53.4637 10.1455C54.4511 10.1455 55.2167 9.71224 55.5492 8.86594H55.5391Z"
          fill="#231F20"
        />
        <path
          d="M66.7727 3.2038V11.435H64.9391V10.3872C64.3245 11.1428 63.3472 11.5458 62.2894 11.5458C60.1736 11.5458 58.743 10.3872 58.743 7.90879V3.2038H60.6673V7.64684C60.6673 9.13793 61.3725 9.85325 62.5916 9.85325C63.9316 9.85325 64.8383 9.01703 64.8383 7.37482V3.2038H66.7626H66.7727Z"
          fill="#231F20"
        />
        <path
          d="M76.7066 3.20383V10.1858C76.7066 13.178 75.145 14.5281 72.2636 14.5281C70.7221 14.5281 69.2008 14.1251 68.2538 13.3594L69.1202 11.9086C69.8456 12.5131 71.0143 12.8959 72.1528 12.8959C73.9663 12.8959 74.7722 12.0597 74.7722 10.3973V9.96412C74.0972 10.6996 73.12 11.0623 72.0117 11.0623C69.6542 11.0623 67.8508 9.46037 67.8508 7.07261C67.8508 4.68485 69.6542 3.11316 72.0117 3.11316C73.1703 3.11316 74.1879 3.48593 74.8629 4.302V3.20383H76.6966H76.7066ZM74.8025 7.07261C74.8025 5.68227 73.7547 4.7453 72.3039 4.7453C70.8531 4.7453 69.7952 5.68227 69.7952 7.07261C69.7952 8.46295 70.843 9.43014 72.3039 9.43014C73.7648 9.43014 74.8025 8.47302 74.8025 7.07261Z"
          fill="#231F20"
        />
        <path
          d="M86.167 7.93905H79.709C79.9407 9.13797 80.9381 9.91374 82.3788 9.91374C83.3057 9.91374 84.0311 9.62157 84.6155 9.01707L85.6431 10.2059C84.9076 11.0824 83.749 11.5459 82.3285 11.5459C79.5679 11.5459 77.7847 9.77269 77.7847 7.32448C77.7847 4.87627 79.5881 3.11316 82.0363 3.11316C84.4845 3.11316 86.1972 4.79567 86.1972 7.36478C86.1972 7.51591 86.1771 7.74763 86.167 7.93905ZM79.6888 6.65954H84.3636C84.2125 5.47069 83.2957 4.65462 82.0363 4.65462C80.7769 4.65462 79.8803 5.46062 79.6888 6.65954Z"
          fill="#231F20"
        />
      </g>
      <defs>
        <clipPath id="clip0_37_1015">
          <rect width="85.6772" height="14.5381" fill="white" transform="translate(0.519958)" />
        </clipPath>
      </defs>
    </svg>
  )
}
