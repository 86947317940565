import { Box, Grid, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import { PropsWithChildren } from 'react'
import { BusinessContractGive, BusinessDealCash, BusinessDealHandshake } from './BusinessDealIcons'

export default function OrganisationalProgressAndTrendsDefinitions() {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Box display={'flex'} justifyContent={'space-between'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          Organisational Progress and Trends
        </Typography>
      </Box>
      <Box width={'49%'}>
        <Typography mt={20} fontSize={46} fontWeight={600}>
          Organisational progress and trends definitions
        </Typography>
      </Box>
      <Grid container spacing={10} mt={1}>
        <Grid item xs={4}>
          <Box display={'flex'} alignItems={'center'} height={'100px'} gap={3} color={'#DB5C11'}>
            <BusinessDealHandshake scale={2.4} />
            <Typography fontSize={'26px'} lineHeight={1.2} fontWeight={600}>
              Indigenous Business Engagement
            </Typography>
          </Box>
          <TextBlock title="Businesses engaged">
            The number of Indigenous businesses that your organisation has a commercial relationship
            with.
          </TextBlock>
          <TextBlock title="National median">
            The median number of commercial relationships that an organisation has with indigenous
            businesses, within Australia.
          </TextBlock>
          <TextBlock title="Industry median">
            The median number of commercial relationships that an organisation has with indigenous
            businesses; within the same industry they operate.
          </TextBlock>
        </Grid>
        <Grid item xs={4}>
          <Box display={'flex'} alignItems={'center'} height={'100px'} gap={3} color={'#DB5C11'}>
            <BusinessDealCash scale={1.8} />
            <Typography fontSize={'26px'} lineHeight={1.2} fontWeight={600}>
              Indigenous Spend
            </Typography>
          </Box>
          <TextBlock title="Organisation spend">
            The amount paid to Indigenous businesses that your organisation has a commercial
            relationship with.
          </TextBlock>
          <TextBlock title="National median">
            The median amount that an organisation spends with indigenous businesses within
            Australia.
          </TextBlock>
          <TextBlock title="Industry median">
            The median number of commercial relationships that organisations have with indigenous
            businesses; within the same industry they operate.
          </TextBlock>
        </Grid>
        <Grid item xs={4}>
          <Box display={'flex'} alignItems={'center'} height={'100px'} gap={3} color={'#DB5C11'}>
            <BusinessContractGive scale={2} />
            <Typography fontSize={'26px'} lineHeight={1.2} fontWeight={600}>
              Indigenous Contracts Data
            </Typography>
          </Box>
          <TextBlock title="# of Contracts">
            The total number of contracts that your organisation has with Indigenous businesses.
          </TextBlock>
          <TextBlock title="National median">
            The median number of contracts that an organisation has with indigenous businesses,
            within Australia.
          </TextBlock>
          <TextBlock title="Industry median">
            The median number of contracts that an organisation has with indigenous businesses;
            within the same industry they operate.
          </TextBlock>
          <TextBlock title="Contract value average">
            The average value of a contract that organisations have with indigenous businesses
            within Australia.
          </TextBlock>
        </Grid>
      </Grid>
      <Box
        position={'absolute'}
        top={'200px'}
        left={0}
        width={'100%'}
        height={'100%'}
        bgcolor={'#DB5C1110'}
      ></Box>
      <Copyright pageNumber={19} />
    </Box>
  )
}

type TextBlockProps = PropsWithChildren & {
  title: string
}

function TextBlock({ title, children }: TextBlockProps) {
  return (
    <Box mt={5}>
      <Typography fontSize={16} fontWeight={600}>
        {title}
      </Typography>
      <Typography fontSize={16} mt={2}>
        {children}
      </Typography>
    </Box>
  )
}
