import { Box, Typography } from '@mui/material'
import RAPIcons, { RAPType } from './RAPIcons'
import CircularIndicator from '../common/outputs/CircularIndicator'

type RAPDetailProps = {
  rapType: RAPType
  rapDescription: string
  metrics: MetricValue[]
}

type MetricValue = {
  description: string
  percentage: number
}

export default function RAPDetail({ rapType, rapDescription, metrics }: RAPDetailProps) {
  return (
    <Box display={'flex'} mt={20} gap={15}>
      <Box width={'45%'}>
        <Box display={'flex'} gap={5} alignItems={'center'}>
          <Box>{RAPIcons(rapType, 1)}</Box>
          <Typography fontSize={46} fontWeight={600}>
            {rapType}
          </Typography>
        </Box>
        <Typography mt={8} fontSize={26}>
          {rapDescription}
        </Typography>
      </Box>
      <Box
        position={'relative'}
        width={'45%'}
        display={'flex'}
        flexDirection={'column'}
        zIndex={2}
        border={'3px solid #CE66322F'}
        borderRadius={3}
        padding={10}
        gap={10}
        style={{
          backgroundColor: '#FFFFFF',
        }}
      >
        {metrics.map((x) => (
          <MetricDisplay {...x} />
        ))}
      </Box>
      <Box
        position={'absolute'}
        top={210}
        left={1180}
        style={{
          opacity: '10%',
        }}
        zIndex={1}
      >
        {RAPIcons(rapType, 9)}
      </Box>
    </Box>
  )
}

function MetricDisplay({ percentage, description }: MetricValue) {
  return (
    <Box display={'flex'} gap={5} alignItems={'center'}>
      <CircularIndicator
        value={percentage}
        label={`${percentage}%`}
        color={'#DB5C11'}
        scale={1.6}
        labelStyle={{ fontSize: '28px', fontWeight: '600' }}
      />
      <Typography fontSize={18}>{description}</Typography>
    </Box>
  )
}
