import {
  Box,
  InputAdornment,
  InputLabel,
  InputProps,
  TextField,
  TextFieldProps,
} from '@mui/material'

export interface TextInputProps
  extends Pick<
      TextFieldProps,
      | 'name'
      | 'onBlur'
      | 'onChange'
      | 'value'
      | 'required'
      | 'placeholder'
      | 'type'
      | 'disabled'
      | 'fullWidth'
      | 'defaultValue'
    >,
    Pick<InputProps, 'startAdornment' | 'endAdornment'> {
  errorMessage?: string
  label?: string
  children?: React.ReactNode
}

const TextInput: React.FunctionComponent<TextInputProps> = ({
  errorMessage,
  label,
  children,
  startAdornment,
  endAdornment,
  ...rest
}) => (
  <Box my={2}>
    <Box my={0.5}>
      <InputLabel htmlFor={rest.name}>{label}</InputLabel>
    </Box>
    <TextField
      {...rest}
      id={rest.name}
      size="small"
      error={!!errorMessage}
      helperText={errorMessage}
      InputProps={{
        startAdornment: startAdornment && (
          <InputAdornment position="start">{startAdornment}</InputAdornment>
        ),
        endAdornment: endAdornment && (
          <InputAdornment position="end">{endAdornment}</InputAdornment>
        ),
        sx: { backgroundColor: 'common.white' },
      }}
    />
    {children}
  </Box>
)

export default TextInput
