import { Backdrop, Box, Grid, Theme, Typography, useMediaQuery } from '@mui/material'
import LinearProgressWithLabel from '../../common/utils/LinearProgressWithLabel'
import theme from '../../../theme'
import GroupProfile from '../GroupProfile'
import SectionHeader from '../SectionHeader'
import contractViewConfig from '../ContractDashboardDimensions'
import { useEffect, useState } from 'react'
import { generatePdf } from '../../../utils/PdfUtils'
import { RAPCorePillarsProgress, ReportSupportedProps } from '../BuyerDashboard/BuyerDashboard'
import ContractProfileDetails from '../ContractProfileDetails'
import { GetCombinedProfileBuyerSupplierProfile } from '../../../api/api.generated'
import CircularIndicator from '../../common/outputs/CircularIndicator'

export type BuyerSupplierDashboardProps = ReportSupportedProps & {
  buyerSupplierProfile: GetCombinedProfileBuyerSupplierProfile
  reportingPeriod: string
}

export function BuyerSupplierDashboard(props: BuyerSupplierDashboardProps) {
  return (
    <Box>
      <Dashboard {...props} />
    </Box>
  )
}

export type ReportGenerationStatus = 'None' | 'Requested' | 'Generating'

const Dashboard = ({
  isReportView,
  buyerSupplierProfile,
  reportingPeriod,
}: BuyerSupplierDashboardProps) => {
  const [reportStatus, setReportStatus] = useState<ReportGenerationStatus>('None')
  const [progress, updateProgress] = useState<number>(0)
  useEffect(() => {
    if (reportStatus === 'Requested') {
      setReportStatus('Generating')
      setTimeout(async () => {
        await generatePdf('landscape', (progress: number) => updateProgress(progress))
        setReportStatus('None')
        setTimeout(async () => updateProgress(0), 100)
      }, 0)
    }
  }, [reportStatus])
  const viewConfig = isReportView ? contractViewConfig.report : contractViewConfig.dashboard
  let isMobileView = useMediaQuery((t: Theme) => t.breakpoints.down('lg')) && !isReportView

  return (
    <Box
      sx={viewConfig.dimensions}
      height={viewConfig.dimensions === undefined ? 1 : undefined}
      boxSizing="border-box"
      display="flex"
      flexDirection="column"
    >
      <Backdrop
        open={reportStatus !== 'None'}
        sx={{
          color: `${theme.palette.primary.main}`,
          zIndex: 9999,
        }}
      >
        <Box
          width={340}
          maxWidth={'80%'}
          bgcolor={theme.palette.common.white}
          px={4}
          py={4}
          borderRadius={1}
        >
          <Typography color={theme.palette.common.black} marginBottom={3}>
            Report generation may take up to 1 minute
          </Typography>
          <LinearProgressWithLabel value={progress} color="primary" />
        </Box>
      </Backdrop>
      <Grid
        container
        spacing={isMobileView ? 0.5 : 1}
        flexGrow={isReportView ? 0 : 1}
        p={isMobileView ? 1 : 2}
      >
        <>
          <Grid
            item
            xs={viewConfig.buyerOrgGrid.xs}
            md={viewConfig.buyerOrgGrid.md}
            lg={viewConfig.buyerOrgGrid.lg}
            xl={viewConfig.buyerOrgGrid.xl}
          >
            <SectionHeader groupName={buyerSupplierProfile.buyerName} type={'buyer'} pl={'24px'} />
          </Grid>
          <Grid
            item
            xs={viewConfig.supplierOrgGrid.xs}
            md={viewConfig.supplierOrgGrid.md}
            lg={viewConfig.supplierOrgGrid.lg}
            xl={viewConfig.supplierOrgGrid.xl}
          >
            <SectionHeader
              groupName={buyerSupplierProfile.supplierName}
              type={'supplier'}
              pl={'24px'}
            />
          </Grid>
          <Grid
            item
            xs={viewConfig.contractDetailGrid.xs}
            md={viewConfig.contractDetailGrid.md}
            lg={viewConfig.contractDetailGrid.lg}
            xl={viewConfig.contractDetailGrid.xl}
          >
            <Box display="flex" justifyContent="space-between" pl={4}>
              <Box>
                <SectionHeader
                  groupName={buyerSupplierProfile.contractName}
                  subHeader={buyerSupplierProfile.contractCategory}
                />
              </Box>
              <Box display="flex" alignItems="center">
                <Box display={'flex'} gap={2} alignItems={'center'} flexDirection={'column'}>
                  <CircularIndicator
                    color="#0074bc"
                    value={buyerSupplierProfile.trustPc}
                    label={buyerSupplierProfile.trustPc + '%'}
                    scale={1.2}
                    labelStyle={{ color: '#736A65', fontSize: '22px', fontWeight: '600' }}
                  />
                  <Box>
                    <Typography
                      alignSelf={'stretch'}
                      fontSize={'18px'}
                      fontStyle={'normal'}
                      lineHeight={'24px'}
                      letterSpacing={'0.1px'}
                      color={'#736A65'}
                    >
                      Trust
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </>
        <Grid
          item
          xs={viewConfig.buyerPersonnelGrid.xs}
          md={viewConfig.buyerPersonnelGrid.md}
          lg={viewConfig.buyerPersonnelGrid.lg}
          xl={viewConfig.buyerPersonnelGrid.xl}
        >
          <GroupProfile
            isReportView={isReportView}
            groupProfileType="Buyer"
            personnel={buyerSupplierProfile.buyerPersonnels.map((x) => {
              return { name: x.name, email: x.email, role: x.role }
            })}
            metrics={[
              {
                metric: 'Race Relations',
                percentage: buyerSupplierProfile.buyerMetrics.raceRelationsPc,
              },
              {
                metric: 'Equality & Equity',
                percentage: buyerSupplierProfile.buyerMetrics.equalityEquityPc,
              },
              {
                metric: 'Institutional Integrity',
                percentage: buyerSupplierProfile.buyerMetrics.institutionalIntegrityPc,
              },
              { metric: 'Unity', percentage: buyerSupplierProfile.buyerMetrics.unityPc },
              {
                metric: 'Historical Acceptance',
                percentage: buyerSupplierProfile.buyerMetrics.historicalAcceptancePc,
              },
            ]}
            isMobile={isMobileView}
            groupName={'Lorem Ipsum Buyer Org'}
          />
        </Grid>
        <Grid
          item
          md={viewConfig.supplierPersonnelGrid.md}
          lg={viewConfig.supplierPersonnelGrid.lg}
          xl={viewConfig.supplierPersonnelGrid.xl}
          xs={viewConfig.supplierPersonnelGrid.xs}
        >
          <GroupProfile
            isReportView={isReportView}
            groupProfileType="Supplier"
            personnel={buyerSupplierProfile.supplierPersonnels.map((x) => {
              return { name: x.name, email: x.email, role: x.role }
            })}
            metrics={[
              {
                metric: 'Race Relations',
                percentage: buyerSupplierProfile.supplierMetrics.raceRelationsPc,
              },
              {
                metric: 'Equality & Equity',
                percentage: buyerSupplierProfile.supplierMetrics.equalityEquityPc,
              },
              {
                metric: 'Institutional Integrity',
                percentage: buyerSupplierProfile.supplierMetrics.institutionalIntegrityPc,
              },
              { metric: 'Unity', percentage: buyerSupplierProfile.supplierMetrics.unityPc },
              {
                metric: 'Historical Acceptance',
                percentage: buyerSupplierProfile.supplierMetrics.historicalAcceptancePc,
              },
            ]}
            isMobile={isMobileView}
            groupName={'Lorem Ipsum Supplier Org'}
          />
        </Grid>
        <Grid
          item
          xs={viewConfig.profilesDetailGrid.xs}
          lg={viewConfig.profilesDetailGrid.lg}
          xl={viewConfig.profilesDetailGrid.xl}
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                padding={4}
                width={'100%'}
                height={'460px'}
                bgcolor={isMobileView ? theme.palette.common.white : '#F5F5F5'}
                style={{ borderRadius: '0px 12px 0px 0px' }}
              >
                <DimensionalProfile
                  isReportView={isReportView}
                  buyerSupplierProfile={buyerSupplierProfile}
                  reportingPeriod={reportingPeriod}
                />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                padding={4}
                width={'100%'}
                height={'257px'}
                bgcolor={isMobileView ? theme.palette.common.white : '#F5F5F5'}
                style={{ borderRadius: '0px 0px 12px 0px' }}
              >
                <RAPCorePillarsProgress
                  corePillars={buyerSupplierProfile.corePillars}
                  reportingPeriod={reportingPeriod}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
export function DimensionalProfile({
  isReportView,
  buyerSupplierProfile,
}: BuyerSupplierDashboardProps) {
  return (
    <>
      <Box flexShrink={0} height={'100%'}>
        <Typography fontSize={'16px'} fontWeight={'700'} lineHeight={'24px'} color={'#736A65'}>
          Combined Dimensional Profile
        </Typography>
        <ContractProfileDetails
          isReportView={isReportView}
          dashboardType="buyerSupplier"
          metrics={[
            {
              metric: 'Race Relations',
              buyer: buyerSupplierProfile.buyerMetrics.raceRelationsPc,
              supplier: buyerSupplierProfile.supplierMetrics.raceRelationsPc,
              combined: buyerSupplierProfile.combinedMetrics.raceRelationsPc,
            },
            {
              metric: 'Equality & Equity',
              buyer: buyerSupplierProfile.buyerMetrics.equalityEquityPc,
              supplier: buyerSupplierProfile.supplierMetrics.equalityEquityPc,
              combined: buyerSupplierProfile.combinedMetrics.equalityEquityPc,
            },
            {
              metric: 'Institutional Integrity',
              buyer: buyerSupplierProfile.buyerMetrics.institutionalIntegrityPc,
              supplier: buyerSupplierProfile.supplierMetrics.institutionalIntegrityPc,
              combined: buyerSupplierProfile.combinedMetrics.institutionalIntegrityPc,
            },
            {
              metric: 'Unity',
              buyer: buyerSupplierProfile.buyerMetrics.unityPc,
              supplier: buyerSupplierProfile.supplierMetrics.unityPc,
              combined: buyerSupplierProfile.combinedMetrics.unityPc,
            },
            {
              metric: 'Historical Acceptance',
              buyer: buyerSupplierProfile.buyerMetrics.historicalAcceptancePc,
              supplier: buyerSupplierProfile.supplierMetrics.historicalAcceptancePc,
              combined: buyerSupplierProfile.combinedMetrics.historicalAcceptancePc,
            },
          ]}
        />
      </Box>
    </>
  )
}
