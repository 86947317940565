import { useParams } from 'react-router-dom'
// import AuthenticatedRoute from "../../routes/AuthenticatedRoute";
import FullscreenContainer from '../common/containers/FullscreenContainer'
import LoadingContainer from '../common/containers/LoadingContainer'
import ContractDashboardContainer from './ContractDashboardContainer'
import useApi from '../../hooks/useApi'
import { dataProcessingClient } from '../../data/myApis'
import { useEffect } from 'react'
import AuthenticatedRoute from '../../routes/AuthenticatedRoute'

export default function DashboardRoute() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { buyerId } = useParams()
  const { callApi, data, state } = useApi(
    dataProcessingClient.getCombinedProfile.bind(dataProcessingClient, parseInt(buyerId!))
  )

  useEffect(() => {
    callApi()
    // eslint-disable-next-line
  }, [])

  return (
    <AuthenticatedRoute>
      <FullscreenContainer bgColor="#FFFFFF">
        <LoadingContainer
          isLoading={state === 'loading' || state === 'idle'}
          isErrored={state === 'errored'}
        >
          <ContractDashboardContainer combinedProfile={data!} />
        </LoadingContainer>
      </FullscreenContainer>
    </AuthenticatedRoute>
  )
}
