import { ScalableProps } from '../../utils/typeUtils'

export default function PoweredByCHLogo({ scale }: ScalableProps) {
  return (
    <>
      <svg
        width={scale ? scale * 104 : '104'}
        height={scale ? scale * 50 : '50'}
        viewBox="0 0 104 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.05602 0.82998C7.24002 0.82998 8.56002 1.93398 8.56002 3.75798C8.56002 5.58198 7.24002 6.68598 5.05602 6.68598H2.98002V9.22998H1.78002V0.82998H5.05602ZM5.02002 5.64198C6.55602 5.64198 7.36002 4.95798 7.36002 3.75798C7.36002 2.55798 6.55602 1.87398 5.02002 1.87398H2.98002V5.64198H5.02002Z"
          fill="white"
        />
        <path
          d="M12.736 9.30198C10.828 9.30198 9.42398 7.94598 9.42398 6.04998C9.42398 4.15398 10.828 2.80998 12.736 2.80998C14.644 2.80998 16.036 4.15398 16.036 6.04998C16.036 7.94598 14.644 9.30198 12.736 9.30198ZM12.736 8.29398C13.96 8.29398 14.872 7.40598 14.872 6.04998C14.872 4.69398 13.96 3.81798 12.736 3.81798C11.512 3.81798 10.588 4.69398 10.588 6.04998C10.588 7.40598 11.512 8.29398 12.736 8.29398ZM26.0454 2.86998H27.0774L24.7014 9.22998H23.5974L21.7614 4.40598L19.9014 9.22998H18.7974L16.4334 2.86998H17.5254L19.3734 7.93398L21.2934 2.86998H22.2654L24.1494 7.95798L26.0454 2.86998ZM33.8186 6.08598C33.8186 6.18198 33.8066 6.32598 33.7946 6.43398H28.6346C28.7786 7.54998 29.6786 8.29398 30.9386 8.29398C31.6826 8.29398 32.3066 8.04198 32.7746 7.52598L33.4106 8.26998C32.8346 8.94198 31.9586 9.30198 30.9026 9.30198C28.8506 9.30198 27.4826 7.94598 27.4826 6.04998C27.4826 4.16598 28.8386 2.80998 30.6746 2.80998C32.5106 2.80998 33.8186 4.12998 33.8186 6.08598ZM30.6746 3.78198C29.5706 3.78198 28.7546 4.52598 28.6346 5.60598H32.7146C32.5946 4.53798 31.7906 3.78198 30.6746 3.78198Z"
          fill="white"
        />
        <path
          d="M36.146 3.93798C36.554 3.19398 37.346 2.80998 38.462 2.80998V3.92598C38.366 3.91398 38.282 3.91398 38.198 3.91398C36.962 3.91398 36.194 4.66998 36.194 6.06198V9.22998H35.042V2.86998H36.146V3.93798Z"
          fill="white"
        />
        <path
          d="M45.45 6.08598C45.45 6.18198 45.438 6.32598 45.426 6.43398H40.266C40.41 7.54998 41.31 8.29398 42.57 8.29398C43.314 8.29398 43.938 8.04198 44.406 7.52598L45.042 8.26998C44.466 8.94198 43.59 9.30198 42.534 9.30198C40.482 9.30198 39.114 7.94598 39.114 6.04998C39.114 4.16598 40.47 2.80998 42.306 2.80998C44.142 2.80998 45.45 4.12998 45.45 6.08598ZM42.306 3.78198C41.202 3.78198 40.386 4.52598 40.266 5.60598H44.346C44.226 4.53798 43.422 3.78198 42.306 3.78198ZM51.8976 0.32598H53.0496V9.22998H51.9456V8.22198C51.4296 8.94198 50.6136 9.30198 49.6776 9.30198C47.8176 9.30198 46.4616 7.99398 46.4616 6.04998C46.4616 4.10598 47.8176 2.80998 49.6776 2.80998C50.5776 2.80998 51.3696 3.14598 51.8976 3.82998V0.32598ZM49.7736 8.29398C50.9856 8.29398 51.9096 7.40598 51.9096 6.04998C51.9096 4.69398 50.9856 3.81798 49.7736 3.81798C48.5496 3.81798 47.6256 4.69398 47.6256 6.04998C47.6256 7.40598 48.5496 8.29398 49.7736 8.29398Z"
          fill="white"
        />
        <path
          d="M61.464 2.80998C63.324 2.80998 64.68 4.10598 64.68 6.04998C64.68 7.99398 63.324 9.30198 61.464 9.30198C60.528 9.30198 59.712 8.94198 59.196 8.22198V9.22998H58.092V0.32598H59.244V3.82998C59.772 3.14598 60.564 2.80998 61.464 2.80998ZM61.368 8.29398C62.592 8.29398 63.516 7.40598 63.516 6.04998C63.516 4.69398 62.592 3.81798 61.368 3.81798C60.156 3.81798 59.232 4.69398 59.232 6.04998C59.232 7.40598 60.156 8.29398 61.368 8.29398ZM70.5959 2.86998H71.7239L68.6399 9.86598C68.0759 11.21 67.3439 11.63 66.3719 11.63C65.7599 11.63 65.1359 11.426 64.7399 11.042L65.2319 10.178C65.5439 10.478 65.9399 10.646 66.3719 10.646C66.9239 10.646 67.2719 10.394 67.5839 9.66198L67.7879 9.21798L64.9799 2.86998H66.1799L68.3999 7.95798L70.5959 2.86998Z"
          fill="white"
        />
        <path
          d="M7.60001 29.02C8.51001 29.02 9.25001 29.78 9.25001 30.73C9.25001 31.68 8.51001 32.44 7.60001 32.44C6.69001 32.44 5.95001 31.68 5.95001 30.73C5.95001 29.78 6.69001 29.02 7.60001 29.02ZM13.27 26.7C14.18 26.7 14.92 27.46 14.92 28.41C14.92 29.36 14.18 30.12 13.27 30.12C12.36 30.12 11.62 29.36 11.62 28.41C11.62 27.46 12.36 26.7 13.27 26.7ZM19.01 29.02C19.92 29.02 20.66 29.78 20.66 30.73C20.66 31.68 19.92 32.44 19.01 32.44C18.1 32.44 17.36 31.68 17.36 30.73C17.36 29.78 18.1 29.02 19.01 29.02ZM4.68001 38.3C5.04001 38.3 5.33001 38 5.33001 37.62V35.41C5.33001 34.16 6.31001 33.14 7.52001 33.14H7.61001C8.82001 33.14 9.80001 34.16 9.80001 35.41V44.48C9.80001 44.85 10.09 45.16 10.45 45.16C10.81 45.16 11.1 44.86 11.1 44.48V33.01C11.1 31.76 12.08 30.74 13.29 30.74H13.34C14.55 30.74 15.53 31.76 15.53 33.01V42.91C15.53 43.28 15.82 43.59 16.18 43.59H16.23C16.59 43.59 16.88 43.29 16.88 42.91V35.41C16.88 34.16 17.86 33.14 19.07 33.14H19.12C20.33 33.14 21.31 34.16 21.31 35.41V37.71C21.31 38.08 21.6 38.39 21.96 38.39H25.97C26.04 37.84 26.07 37.28 26.07 36.71C26.07 29.4 20.35 23.47 13.29 23.47C6.23001 23.47 0.51001 29.4 0.51001 36.71C0.51001 37.24 0.54001 37.77 0.60001 38.29H4.67001L4.68001 38.3Z"
          fill="white"
        />
        <path
          d="M21.96 39.9901C20.75 39.9901 19.77 38.97 19.77 37.72V35.42C19.77 35.05 19.48 34.7401 19.12 34.7401H19.07C18.71 34.7401 18.42 35.04 18.42 35.42V42.92C18.42 44.17 17.44 45.1901 16.23 45.1901H16.18C14.97 45.1901 13.99 44.17 13.99 42.92V33.0201C13.99 32.6501 13.7 32.3401 13.34 32.3401H13.29C12.93 32.3401 12.64 32.6401 12.64 33.0201V44.4901C12.64 45.7401 11.66 46.7601 10.45 46.7601C9.24002 46.7601 8.26001 45.7401 8.26001 44.4901V35.42C8.26001 35.05 7.97002 34.7401 7.61002 34.7401H7.52002C7.16002 34.7401 6.87001 35.04 6.87001 35.42V37.6301C6.87001 38.8801 5.89002 39.9001 4.68002 39.9001H0.890015C2.26001 45.6801 7.30001 49.97 13.3 49.97C19.3 49.97 24.28 45.7301 25.69 40.0001H21.97L21.96 39.9901Z"
          fill="white"
        />
        <path
          d="M32.15 30.8301C32.15 27.7601 34.42 25.54 37.47 25.54C39.09 25.54 40.48 26.1401 41.4 27.2601L40.21 28.42C39.49 27.61 38.59 27.2101 37.56 27.2101C35.5 27.2101 34.01 28.7101 34.01 30.8301C34.01 32.9501 35.5 34.4501 37.56 34.4501C38.6 34.4501 39.49 34.05 40.21 33.23L41.4 34.41C40.48 35.53 39.09 36.1301 37.46 36.1301C34.42 36.1301 32.15 33.9101 32.15 30.8401V30.8301Z"
          fill="white"
        />
        <path
          d="M47.74 32.05C47.74 30.55 46.78 29.6 45.48 29.6C44.18 29.6 43.24 30.56 43.24 32.05C43.24 33.54 44.2 34.5001 45.48 34.5001C46.76 34.5001 47.74 33.54 47.74 32.05ZM41.46 32.05C41.46 29.7 43.16 28.04 45.49 28.04C47.82 28.04 49.53 29.7 49.53 32.05C49.53 34.4 47.84 36.0801 45.49 36.0801C43.14 36.0801 41.46 34.4 41.46 32.05Z"
          fill="white"
        />
        <path
          d="M57.76 31.48V35.98H55.99V31.72C55.99 30.32 55.32 29.6501 54.19 29.6501C52.93 29.6501 52.08 30.4401 52.08 32.0201V35.9901H50.31V28.1401H52V29.1501C52.58 28.4301 53.5 28.05 54.61 28.05C56.43 28.05 57.77 29.1201 57.77 31.4901L57.76 31.48Z"
          fill="white"
        />
        <path
          d="M63.68 35.55C63.25 35.9 62.62 36.08 61.98 36.08C60.32 36.08 59.37 35.17 59.37 33.43V29.65H58.12V28.18H59.37V26.39H61.14V28.18H63.17V29.65H61.14V33.38C61.14 34.14 61.51 34.56 62.19 34.56C62.56 34.56 62.91 34.46 63.18 34.24L63.68 35.55Z"
          fill="white"
        />
        <path
          d="M68.79 28.04V29.79C68.63 29.76 68.51 29.7501 68.38 29.7501C67.03 29.7501 66.18 30.5701 66.18 32.1801V35.9901H64.41V28.1401H66.1V29.29C66.61 28.47 67.53 28.0601 68.8 28.0601L68.79 28.04Z"
          fill="white"
        />
        <path
          d="M74.14 33.52V32.61H72.31C71.1 32.61 70.78 33.08 70.78 33.65C70.78 34.31 71.32 34.74 72.23 34.74C73.14 34.74 73.85 34.33 74.15 33.52H74.14ZM75.91 31.38V35.98H74.24V35.02C73.81 35.7 72.99 36.08 71.86 36.08C70.13 36.08 69.04 35.1 69.04 33.73C69.04 32.36 69.89 31.39 72.19 31.39H74.15V31.27C74.15 30.2 73.53 29.56 72.26 29.56C71.41 29.56 70.53 29.85 69.96 30.34L69.26 29C70.07 28.35 71.25 28.03 72.47 28.03C74.66 28.03 75.92 29.1 75.92 31.37L75.91 31.38Z"
          fill="white"
        />
        <path
          d="M76.68 32.05C76.68 29.7 78.38 28.04 80.78 28.04C82.26 28.04 83.43 28.67 84.02 29.86L82.66 30.6801C82.21 29.9301 81.52 29.5901 80.77 29.5901C79.46 29.5901 78.47 30.53 78.47 32.04C78.47 33.55 79.46 34.4901 80.77 34.4901C81.52 34.4901 82.2 34.1501 82.66 33.4001L84.02 34.22C83.44 35.4 82.26 36.0601 80.78 36.0601C78.38 36.0601 76.68 34.3801 76.68 32.0301V32.05Z"
          fill="white"
        />
        <path
          d="M89.94 35.55C89.51 35.9 88.88 36.08 88.24 36.08C86.58 36.08 85.63 35.17 85.63 33.43V29.65H84.38V28.18H85.63V26.39H87.4V28.18H89.43V29.65H87.4V33.38C87.4 34.14 87.77 34.56 88.45 34.56C88.82 34.56 89.17 34.46 89.44 34.24L89.94 35.55Z"
          fill="white"
        />
        <path
          d="M41.69 37.71V47.9999H39.84V43.59H34.7V47.9999H32.86V37.71H34.7V41.96H39.84V37.71H41.69Z"
          fill="white"
        />
        <path
          d="M47.62 45.55V44.6401H45.79C44.58 44.6401 44.26 45.1101 44.26 45.6801C44.26 46.3401 44.8 46.7701 45.71 46.7701C46.62 46.7701 47.33 46.36 47.63 45.55H47.62ZM49.39 43.4001V48.0001H47.72V47.04C47.29 47.72 46.47 48.1 45.34 48.1C43.61 48.1 42.52 47.1201 42.52 45.7501C42.52 44.3801 43.37 43.41 45.67 43.41H47.63V43.29C47.63 42.22 47.01 41.5801 45.74 41.5801C44.89 41.5801 44.01 41.87 43.44 42.36L42.74 41.0201C43.55 40.3701 44.73 40.05 45.95 40.05C48.14 40.05 49.4 41.1201 49.4 43.3901L49.39 43.4001Z"
          fill="white"
        />
        <path
          d="M54.87 40.0601V41.8101C54.71 41.7801 54.59 41.7701 54.46 41.7701C53.11 41.7701 52.26 42.5901 52.26 44.2001V48.0101H50.49V40.16H52.18V41.3101C52.69 40.4901 53.61 40.0801 54.88 40.0801L54.87 40.0601Z"
          fill="white"
        />
        <path
          d="M68.29 43.5001V48.0001H66.52V43.7401C66.52 42.3401 65.9 41.67 64.82 41.67C63.66 41.67 62.85 42.4601 62.85 44.0201V48.0001H61.08V43.7401C61.08 42.3401 60.46 41.67 59.38 41.67C58.2 41.67 57.41 42.4601 57.41 44.0201V48.0001H55.64V40.1501H57.33V41.1501C57.9 40.4401 58.78 40.0601 59.81 40.0601C60.92 40.0601 61.84 40.4901 62.36 41.3701C62.97 40.5601 63.99 40.0601 65.2 40.0601C67.03 40.0601 68.31 41.1301 68.31 43.5001H68.29Z"
          fill="white"
        />
        <path
          d="M75.36 44.0801C75.36 42.5801 74.4 41.6301 73.1 41.6301C71.8 41.6301 70.86 42.5901 70.86 44.0801C70.86 45.5701 71.83 46.5301 73.1 46.5301C74.37 46.5301 75.36 45.5701 75.36 44.0801ZM69.07 44.0801C69.07 41.7301 70.77 40.0701 73.1 40.0701C75.43 40.0701 77.14 41.7301 77.14 44.0801C77.14 46.4301 75.45 48.11 73.1 48.11C70.75 48.11 69.07 46.4301 69.07 44.0801Z"
          fill="white"
        />
        <path
          d="M85.4 43.5001V48.0001H83.63V43.7401C83.63 42.3401 82.96 41.67 81.83 41.67C80.57 41.67 79.72 42.46 79.72 44.04V48.0101H77.95V40.16H79.64V41.17C80.22 40.45 81.14 40.0701 82.25 40.0701C84.07 40.0701 85.41 41.1401 85.41 43.5101L85.4 43.5001Z"
          fill="white"
        />
        <path
          d="M86.48 40.15H88.25V47.9999H86.48V40.15ZM86.24 38.0099C86.24 37.3899 86.72 36.9099 87.38 36.9099C88.04 36.9099 88.52 37.3699 88.52 37.9699C88.52 38.6199 88.05 39.1199 87.38 39.1199C86.71 39.1199 86.24 38.63 86.24 38.02V38.0099Z"
          fill="white"
        />
        <path
          d="M89.12 44.0801C89.12 41.7301 90.82 40.0701 93.22 40.0701C94.7 40.0701 95.87 40.7001 96.46 41.8901L95.1 42.7101C94.65 41.9601 93.96 41.6201 93.21 41.6201C91.9 41.6201 90.91 42.5601 90.91 44.0701C90.91 45.5801 91.9 46.5201 93.21 46.5201C93.96 46.5201 94.64 46.1801 95.1 45.4301L96.46 46.2501C95.88 47.4301 94.7 48.0901 93.22 48.0901C90.82 48.0901 89.12 46.4101 89.12 44.0601V44.0801Z"
          fill="white"
        />
        <path
          d="M96.68 47.22L97.36 45.8201C98.03 46.2801 99.05 46.6 100 46.6C101.12 46.6 101.59 46.28 101.59 45.73C101.59 44.23 96.91 45.6401 96.91 42.5101C96.91 41.0301 98.2 40.0601 100.25 40.0601C101.26 40.0601 102.41 40.3101 103.09 40.7401L102.41 42.1401C101.69 41.7001 100.96 41.55 100.24 41.55C99.16 41.55 98.65 41.9201 98.65 42.4301C98.65 44.0201 103.33 42.6101 103.33 45.6801C103.33 47.1501 102.02 48.11 99.9 48.11C98.64 48.11 97.37 47.73 96.69 47.23L96.68 47.22Z"
          fill="white"
        />
      </svg>
    </>
  )
}
