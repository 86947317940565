import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'
import '@fontsource/roboto/900.css'
import EnvVars from './envVars'
import LoginRoute from './routes/LoginRoute'
import { ThemeProvider } from '@mui/material'
import theme from './theme'
import AxiosInterceptors from './data/AxiosInterceptors'
import { SnackbarProvider } from 'notistack'
import UserContextProvider from './contexts/UserContextProvider'
import { CssBaseline } from '@mui/material'
import DashboardRoute from './components/dashboard'
import ConsoleRoute from './components/console'
import BuyerDetailsRouter from './components/console/BuyerOrganization/BuyerDetailsRouter'
import SupplierDetailsRouter from './components/console/SupplierRelationship/SupplierDetailsRouter'
import DropzoneRouter from './components/console/Dropzone/DropzoneRouter'

declare global {
  interface Window {
    _env_: EnvVars
  }
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <AxiosInterceptors>
            <UserContextProvider>
              <CssBaseline />
              <Routes>
                <Route path="/login" element={<LoginRoute />} />
                {/* <Route path="/signup" element={<SignupRoute />} /> */}
                <Route path="/dashboard/:buyerId" element={<DashboardRoute />} />
                <Route path="/console" element={<ConsoleRoute />} />
                <Route path="/buyerdetails/:buyerId" element={<BuyerDetailsRouter />} />
                <Route path="/supplierdetails/:supplierId" element={<SupplierDetailsRouter />} />
                <Route path="/dropzone/buyer" element={<DropzoneRouter uploadType="buyer" />} />
                <Route
                  path="/dropzone/supplier/:buyerId"
                  element={<DropzoneRouter uploadType="supplier" />}
                />
                <Route
                  path="/dropzone/rap/:buyerId"
                  element={<DropzoneRouter uploadType="rap" />}
                />
                <Route
                  path="/dropzone/personnel"
                  element={<DropzoneRouter uploadType="personnel" />}
                />
                <Route
                  path="/dropzone/buyersurvey/:buyerId"
                  element={<DropzoneRouter uploadType="buyerResponses" />}
                />

                {/*
                // eslint-disable-next-line
                TODO: This is confusing as this is actually the supplierId but i'm running out of time
                */}
                <Route
                  path="/dropzone/suppliersurvey/:buyerId"
                  element={<DropzoneRouter uploadType="supplierResponses" />}
                />
                <Route path="*" element={<Navigate to="/console" replace={true} />} />
              </Routes>
            </UserContextProvider>
          </AxiosInterceptors>
        </SnackbarProvider>
      </ThemeProvider>
    </BrowserRouter>
  </React.StrictMode>
)
