import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RAPDetail from './RAPDetail'
import { GetCombinedProfileRespectDetailedMetrics } from '../../api/api.generated'

export default function RAPRespect(props: GetCombinedProfileRespectDetailedMetrics) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#DB5C1110',
      }}
    >
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'200px'}
        bgcolor={'#ffffff'}
      />
      <Box display={'flex'} justifyContent={'space-between'} zIndex={1} position={'relative'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          RAP Progress
        </Typography>
      </Box>
      <RAPDetail
        rapType="Respect"
        metrics={[
          {
            percentage: props.increaseUnderstanding,
            description:
              'Increase understanding, value and recognition of Aboriginal and Torres Strait Islander cultures, histories, knowledge, and rights through cultural protocols',
          },
          {
            percentage: props.demonstrateRespect,
            description:
              'Demonstrate respect to Aboriginal and Torres Strait Islander peoples by observing cultural protocols',
          },
          {
            percentage: props.buildRespect,
            description:
              'Build respect for Aboriginal and Torres Strait Islander cultures and histories by celebrating NAIDOC Week (or major events)',
          },
        ]}
        rapDescription="Nurturing mutual respect and understanding between Aboriginal and Torres Strait Islander peoples and the broader community, this pillar is dedicated to recognizing and appreciating the rich diversity of cultures, histories, and contributions of Indigenous communities."
      />
      <Copyright pageNumber={10} />
    </Box>
  )
}
