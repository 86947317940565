import * as yup from 'yup'

const loginSchema = yup.object().shape({
  email: yup.string().required().label('Email').email('Invalid email address'),
  password: yup
    .string()
    .required()
    .min(6, 'Password must be at least 6 characters')
    .label('Password'),
})

export default loginSchema
