import React, { useCallback, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import * as XLSX from 'xlsx'
import useApi from '../../../hooks/useApi'
import { dataProcessingClient } from '../../../data/myApis'
import { FileParameter } from '../../../api/api.generated'
import { s2ab, uploadTypeText } from './DropzoneHelpers'
import { Button, Container, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'

type DropzoneComponentProps = {
  uploadType: 'buyer' | 'supplier' | 'personnel' | 'rap' | 'buyerResponses' | 'supplierResponses'
  buyerOrganizationId?: number
}

const DropzoneComponent: React.FC<DropzoneComponentProps> = ({
  uploadType,
  buyerOrganizationId,
}) => {
  const navigate = useNavigate()
  const [isUploadSuccessful, setIsUploadSuccessful] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const onSuccess = () => {
    setIsUploadSuccessful(true)
    setUploadError(false)
    setErrorMessage('')
  }

  const onError = (_error: any) => {
    console.dir(_error)
    setIsUploadSuccessful(false)
    setUploadError(true)
  }

  // Initialize useApi hooks at the top level
  const { callApi: callCreateBuyerOrganization } = useApi(
    dataProcessingClient.createBuyerOrganization.bind(dataProcessingClient),
    onSuccess,
    onError
  )
  const { callApi: callCreateSupplierRelationship } = useApi(
    (file: FileParameter | undefined) =>
      dataProcessingClient.createSupplier(file, buyerOrganizationId!),
    onSuccess,
    onError
  )
  const { callApi: callRapRelationship } = useApi(
    (file: FileParameter | undefined) => dataProcessingClient.createRap(file, buyerOrganizationId!),
    onSuccess,
    onError
  )
  const { callApi: callCreateBuyerResponses } = useApi(
    (file: FileParameter | undefined) =>
      dataProcessingClient.addBuyerOrgSurveyResponses(file, buyerOrganizationId!),
    onSuccess,
    onError
  )

  const { callApi: callCreateSupplierResponses } = useApi(
    (file: FileParameter | undefined) =>
      dataProcessingClient.addSupplierSurveyResponses(file, buyerOrganizationId!),
    onSuccess,
    onError
  )

  const executeApiCall = async (fileData: any) => {
    switch (uploadType) {
      case 'buyer':
        callCreateBuyerOrganization(fileData)
        break
      case 'supplier':
        callCreateSupplierRelationship(fileData)
        break
      case 'rap':
        callRapRelationship(fileData)
        break
      case 'buyerResponses':
        callCreateBuyerResponses(fileData)
        break
      case 'supplierResponses':
        callCreateSupplierResponses(fileData)
        break
      default:
        console.log('Invalid upload type')
    }
  }

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        if (file.name.toLowerCase().includes(uploadType.toLowerCase())) {
          const reader = new FileReader()
          reader.onabort = () => console.log('file reading was aborted')
          reader.onerror = () => console.log('file reading has failed')
          reader.onload = async (e: ProgressEvent<FileReader>) => {
            const bstr = e.target?.result
            if (bstr) {
              const workbook = XLSX.read(bstr, { type: 'binary' })
              const wbout = XLSX.write(workbook, { bookType: 'xlsx', type: 'binary' })
              const blob = new Blob([s2ab(wbout)], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
              })
              const fileData = {
                data: blob,
                fileName: file.name,
              }
              await executeApiCall(fileData)
            }
          }
          reader.readAsBinaryString(file)
        } else {
          const message = `Invalid file name. The file must be related to '${uploadType}'.`
          console.log(message)
          setErrorMessage(message)
          setUploadError(true)
        }
      })
    },
    // eslint-disable-next-line
    [uploadType, buyerOrganizationId, callCreateBuyerOrganization, callCreateSupplierRelationship]
  )

  const onDropRejected = useCallback((rejectedFiles: any) => {
    console.error('Unsupported file type:', rejectedFiles)
    setIsUploadSuccessful(false)
    setUploadError(true)
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
    },
  })

  return (
    <Container maxWidth="md">
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Typography variant="h4" gutterBottom>
          {uploadTypeText[uploadType]}
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
          Back
        </Button>
      </div>
      <div
        {...getRootProps()}
        style={{
          border: '2px dashed #0087F7',
          borderRadius: '5px',
          padding: '20px',
          textAlign: 'center',
          cursor: 'pointer',
          position: 'relative',
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <p>Drop the .xlsx files here ...</p>
        ) : (
          <p>Drag 'n' drop .xlsx files here, or click to select files</p>
        )}
        {isUploadSuccessful && (
          <CheckCircleIcon
            style={{
              color: 'green',
              position: 'absolute',
              top: '50%',
              right: '10px',
              transform: 'translateY(-50%)',
            }}
          />
        )}
        {uploadError && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '20px',
            }}
          >
            <ErrorIcon style={{ color: 'red', marginRight: '10px' }} />
            <Typography color="error" style={{ fontWeight: 'bold', fontSize: '1.2em' }}>
              {errorMessage}
            </Typography>
          </div>
        )}
      </div>
    </Container>
  )
}

export default DropzoneComponent
