import { Box, Typography } from '@mui/material'
import Copyright from './Copyright'
import LogoDark from './LogoDark'
import RAPDetail from './RAPDetail'
import { GetCombinedProfileOpportunityDetailedMetrics } from '../../api/api.generated'

export default function RAPOpportunity(props: GetCombinedProfileOpportunityDetailedMetrics) {
  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        padding: '100px',
        position: 'relative',
        overflow: 'hidden',
        bgcolor: '#DB5C1110',
      }}
    >
      <Box
        position={'absolute'}
        top={0}
        left={0}
        width={'100%'}
        height={'200px'}
        bgcolor={'#ffffff'}
      />
      <Box display={'flex'} justifyContent={'space-between'} zIndex={1} position={'relative'}>
        <LogoDark scale={2} />
        <Typography color={'#DB5C11'} fontSize={'26px'} fontWeight={'600'}>
          RAP Progress
        </Typography>
      </Box>
      <RAPDetail
        rapType="Opportunity"
        metrics={[
          {
            percentage: props.improveEmploymentOutcomes,
            description:
              'Improve employment outcomes by increasing Aboriginal and Torres Strait Islander recruitment, retention, and professional developmen',
          },
          {
            percentage: props.increaseSupplierDiversity,
            description:
              'Increase Aboriginal and Torres Strait Islander supplier diversity to support improved economic and social outcomes',
          },
        ]}
        rapDescription="Fostering and broadening opportunities for Aboriginal and Torres Strait Islander peoples, with a primary goal of tackling issues related to access, equity, and participation across diverse domains."
      />
      <Copyright pageNumber={11} />
    </Box>
  )
}
