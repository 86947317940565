import { ScalableProps } from '../../utils/typeUtils'

export default function IndgaugeFrontPageSquare({ scale }: ScalableProps) {
  return (
    <>
      <svg
        width={scale ? scale * 427 : '427'}
        height={scale ? scale * 427 : '427'}
        viewBox="0 0 427 427"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_37_983)">
          <path
            d="M21.68 4.45998C21.68 6.44998 20.06 8.06998 18.07 8.06998C16.08 8.06998 14.46 6.44998 14.46 4.45998C14.46 2.46998 16.08 0.849976 18.07 0.849976C20.06 0.849976 21.68 2.46998 21.68 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 4.45998C36.1302 6.44998 34.5101 8.06998 32.5201 8.06998C30.5301 8.06998 28.9102 6.44998 28.9102 4.45998C28.9102 2.46998 30.5301 0.849976 32.5201 0.849976C34.5101 0.849976 36.1302 2.46998 36.1302 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 4.45998C50.5701 6.44998 48.9501 8.06998 46.9601 8.06998C44.9701 8.06998 43.3501 6.44998 43.3501 4.45998C43.3501 2.46998 44.9701 0.849976 46.9601 0.849976C48.9501 0.849976 50.5701 2.46998 50.5701 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 4.45998C65.01 6.44998 63.39 8.06998 61.4 8.06998C59.41 8.06998 57.79 6.44998 57.79 4.45998C57.79 2.46998 59.41 0.849976 61.4 0.849976C63.39 0.849976 65.01 2.46998 65.01 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 4.45998C79.4602 6.44998 77.8403 8.06998 75.8503 8.06998C73.8603 8.06998 72.2402 6.44998 72.2402 4.45998C72.2402 2.46998 73.8603 0.849976 75.8503 0.849976C77.8403 0.849976 79.4602 2.46998 79.4602 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 4.45998C93.9002 6.44998 92.2802 8.06998 90.2902 8.06998C88.3002 8.06998 86.6802 6.44998 86.6802 4.45998C86.6802 2.46998 88.3002 0.849976 90.2902 0.849976C92.2802 0.849976 93.9002 2.46998 93.9002 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 4.45998C108.34 6.44998 106.72 8.06998 104.73 8.06998C102.74 8.06998 101.12 6.44998 101.12 4.45998C101.12 2.46998 102.74 0.849976 104.73 0.849976C106.72 0.849976 108.34 2.46998 108.34 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 4.45998C122.79 6.44998 121.17 8.06998 119.18 8.06998C117.19 8.06998 115.57 6.44998 115.57 4.45998C115.57 2.46998 117.19 0.849976 119.18 0.849976C121.17 0.849976 122.79 2.46998 122.79 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 4.45998C137.23 6.44998 135.61 8.06998 133.62 8.06998C131.63 8.06998 130.01 6.44998 130.01 4.45998C130.01 2.46998 131.63 0.849976 133.62 0.849976C135.61 0.849976 137.23 2.46998 137.23 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 4.45998C151.67 6.44998 150.05 8.06998 148.06 8.06998C146.07 8.06998 144.45 6.44998 144.45 4.45998C144.45 2.46998 146.07 0.849976 148.06 0.849976C150.05 0.849976 151.67 2.46998 151.67 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 4.45998C166.12 6.44998 164.5 8.06998 162.51 8.06998C160.52 8.06998 158.9 6.44998 158.9 4.45998C158.9 2.46998 160.52 0.849976 162.51 0.849976C164.5 0.849976 166.12 2.46998 166.12 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 4.45998C180.56 6.44998 178.94 8.06998 176.95 8.06998C174.96 8.06998 173.34 6.44998 173.34 4.45998C173.34 2.46998 174.96 0.849976 176.95 0.849976C178.94 0.849976 180.56 2.46998 180.56 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M195 4.45998C195 6.44998 193.38 8.06998 191.39 8.06998C189.4 8.06998 187.78 6.44998 187.78 4.45998C187.78 2.46998 189.4 0.849976 191.39 0.849976C193.38 0.849976 195 2.46998 195 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 4.45998C209.45 6.44998 207.83 8.06998 205.84 8.06998C203.85 8.06998 202.23 6.44998 202.23 4.45998C202.23 2.46998 203.85 0.849976 205.84 0.849976C207.83 0.849976 209.45 2.46998 209.45 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 4.45998C223.89 6.44998 222.27 8.06998 220.28 8.06998C218.29 8.06998 216.67 6.44998 216.67 4.45998C216.67 2.46998 218.29 0.849976 220.28 0.849976C222.27 0.849976 223.89 2.46998 223.89 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 4.45998C238.33 6.44998 236.71 8.06998 234.72 8.06998C232.73 8.06998 231.11 6.44998 231.11 4.45998C231.11 2.46998 232.73 0.849976 234.72 0.849976C236.71 0.849976 238.33 2.46998 238.33 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 4.45998C252.78 6.44998 251.16 8.06998 249.17 8.06998C247.18 8.06998 245.56 6.44998 245.56 4.45998C245.56 2.46998 247.18 0.849976 249.17 0.849976C251.16 0.849976 252.78 2.46998 252.78 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 4.45998C267.22 6.44998 265.6 8.06998 263.61 8.06998C261.62 8.06998 260 6.44998 260 4.45998C260 2.46998 261.62 0.849976 263.61 0.849976C265.6 0.849976 267.22 2.46998 267.22 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 4.45998C281.66 6.44998 280.04 8.06998 278.05 8.06998C276.06 8.06998 274.44 6.44998 274.44 4.45998C274.44 2.46998 276.06 0.849976 278.05 0.849976C280.04 0.849976 281.66 2.46998 281.66 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 4.45998C296.11 6.44998 294.49 8.06998 292.5 8.06998C290.51 8.06998 288.89 6.44998 288.89 4.45998C288.89 2.46998 290.51 0.849976 292.5 0.849976C294.49 0.849976 296.11 2.46998 296.11 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 4.45998C310.55 6.44998 308.93 8.06998 306.94 8.06998C304.95 8.06998 303.33 6.44998 303.33 4.45998C303.33 2.46998 304.95 0.849976 306.94 0.849976C308.93 0.849976 310.55 2.46998 310.55 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 4.45998C324.99 6.44998 323.37 8.06998 321.38 8.06998C319.39 8.06998 317.77 6.44998 317.77 4.45998C317.77 2.46998 319.39 0.849976 321.38 0.849976C323.37 0.849976 324.99 2.46998 324.99 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 4.45998C339.44 6.44998 337.82 8.06998 335.83 8.06998C333.84 8.06998 332.22 6.44998 332.22 4.45998C332.22 2.46998 333.84 0.849976 335.83 0.849976C337.82 0.849976 339.44 2.46998 339.44 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 4.45998C353.88 6.44998 352.26 8.06998 350.27 8.06998C348.28 8.06998 346.66 6.44998 346.66 4.45998C346.66 2.46998 348.28 0.849976 350.27 0.849976C352.26 0.849976 353.88 2.46998 353.88 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 4.45998C368.32 6.44998 366.7 8.06998 364.71 8.06998C362.72 8.06998 361.1 6.44998 361.1 4.45998C361.1 2.46998 362.72 0.849976 364.71 0.849976C366.7 0.849976 368.32 2.46998 368.32 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 4.45998C382.77 6.44998 381.15 8.06998 379.16 8.06998C377.17 8.06998 375.55 6.44998 375.55 4.45998C375.55 2.46998 377.17 0.849976 379.16 0.849976C381.15 0.849976 382.77 2.46998 382.77 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 4.45998C397.21 6.44998 395.59 8.06998 393.6 8.06998C391.61 8.06998 389.99 6.44998 389.99 4.45998C389.99 2.46998 391.61 0.849976 393.6 0.849976C395.59 0.849976 397.21 2.46998 397.21 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 4.45998C411.65 6.44998 410.03 8.06998 408.04 8.06998C406.05 8.06998 404.43 6.44998 404.43 4.45998C404.43 2.46998 406.05 0.849976 408.04 0.849976C410.03 0.849976 411.65 2.46998 411.65 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 4.45998C426.1 6.44998 424.48 8.06998 422.49 8.06998C420.5 8.06998 418.88 6.44998 418.88 4.45998C418.88 2.46998 420.5 0.849976 422.49 0.849976C424.48 0.849976 426.1 2.46998 426.1 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 18.91C21.68 20.9 20.06 22.52 18.07 22.52C16.08 22.52 14.46 20.9 14.46 18.91C14.46 16.92 16.08 15.3 18.07 15.3C20.06 15.3 21.68 16.92 21.68 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 18.91C36.1302 20.9 34.5101 22.52 32.5201 22.52C30.5301 22.52 28.9102 20.9 28.9102 18.91C28.9102 16.92 30.5301 15.3 32.5201 15.3C34.5101 15.3 36.1302 16.92 36.1302 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 18.91C50.5701 20.9 48.9501 22.52 46.9601 22.52C44.9701 22.52 43.3501 20.9 43.3501 18.91C43.3501 16.92 44.9701 15.3 46.9601 15.3C48.9501 15.3 50.5701 16.92 50.5701 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 18.91C65.01 20.9 63.39 22.52 61.4 22.52C59.41 22.52 57.79 20.9 57.79 18.91C57.79 16.92 59.41 15.3 61.4 15.3C63.39 15.3 65.01 16.92 65.01 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 18.91C79.4602 20.9 77.8403 22.52 75.8503 22.52C73.8603 22.52 72.2402 20.9 72.2402 18.91C72.2402 16.92 73.8603 15.3 75.8503 15.3C77.8403 15.3 79.4602 16.92 79.4602 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 18.91C93.9002 20.9 92.2802 22.52 90.2902 22.52C88.3002 22.52 86.6802 20.9 86.6802 18.91C86.6802 16.92 88.3002 15.3 90.2902 15.3C92.2802 15.3 93.9002 16.92 93.9002 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 18.91C108.34 20.9 106.72 22.52 104.73 22.52C102.74 22.52 101.12 20.9 101.12 18.91C101.12 16.92 102.74 15.3 104.73 15.3C106.72 15.3 108.34 16.92 108.34 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 18.91C122.79 20.9 121.17 22.52 119.18 22.52C117.19 22.52 115.57 20.9 115.57 18.91C115.57 16.92 117.19 15.3 119.18 15.3C121.17 15.3 122.79 16.92 122.79 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 18.91C137.23 20.9 135.61 22.52 133.62 22.52C131.63 22.52 130.01 20.9 130.01 18.91C130.01 16.92 131.63 15.3 133.62 15.3C135.61 15.3 137.23 16.92 137.23 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 18.91C151.67 20.9 150.05 22.52 148.06 22.52C146.07 22.52 144.45 20.9 144.45 18.91C144.45 16.92 146.07 15.3 148.06 15.3C150.05 15.3 151.67 16.92 151.67 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 18.91C166.12 20.9 164.5 22.52 162.51 22.52C160.52 22.52 158.9 20.9 158.9 18.91C158.9 16.92 160.52 15.3 162.51 15.3C164.5 15.3 166.12 16.92 166.12 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 18.91C180.56 20.9 178.94 22.52 176.95 22.52C174.96 22.52 173.34 20.9 173.34 18.91C173.34 16.92 174.96 15.3 176.95 15.3C178.94 15.3 180.56 16.92 180.56 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M195 18.91C195 20.9 193.38 22.52 191.39 22.52C189.4 22.52 187.78 20.9 187.78 18.91C187.78 16.92 189.4 15.3 191.39 15.3C193.38 15.3 195 16.92 195 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 18.91C209.45 20.9 207.83 22.52 205.84 22.52C203.85 22.52 202.23 20.9 202.23 18.91C202.23 16.92 203.85 15.3 205.84 15.3C207.83 15.3 209.45 16.92 209.45 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 18.91C223.89 20.9 222.27 22.52 220.28 22.52C218.29 22.52 216.67 20.9 216.67 18.91C216.67 16.92 218.29 15.3 220.28 15.3C222.27 15.3 223.89 16.92 223.89 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 18.91C238.33 20.9 236.71 22.52 234.72 22.52C232.73 22.52 231.11 20.9 231.11 18.91C231.11 16.92 232.73 15.3 234.72 15.3C236.71 15.3 238.33 16.92 238.33 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 18.91C252.78 20.9 251.16 22.52 249.17 22.52C247.18 22.52 245.56 20.9 245.56 18.91C245.56 16.92 247.18 15.3 249.17 15.3C251.16 15.3 252.78 16.92 252.78 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 18.91C267.22 20.9 265.6 22.52 263.61 22.52C261.62 22.52 260 20.9 260 18.91C260 16.92 261.62 15.3 263.61 15.3C265.6 15.3 267.22 16.92 267.22 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 18.91C281.66 20.9 280.04 22.52 278.05 22.52C276.06 22.52 274.44 20.9 274.44 18.91C274.44 16.92 276.06 15.3 278.05 15.3C280.04 15.3 281.66 16.92 281.66 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 18.91C296.11 20.9 294.49 22.52 292.5 22.52C290.51 22.52 288.89 20.9 288.89 18.91C288.89 16.92 290.51 15.3 292.5 15.3C294.49 15.3 296.11 16.92 296.11 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 18.91C310.55 20.9 308.93 22.52 306.94 22.52C304.95 22.52 303.33 20.9 303.33 18.91C303.33 16.92 304.95 15.3 306.94 15.3C308.93 15.3 310.55 16.92 310.55 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 18.91C324.99 20.9 323.37 22.52 321.38 22.52C319.39 22.52 317.77 20.9 317.77 18.91C317.77 16.92 319.39 15.3 321.38 15.3C323.37 15.3 324.99 16.92 324.99 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 18.91C339.44 20.9 337.82 22.52 335.83 22.52C333.84 22.52 332.22 20.9 332.22 18.91C332.22 16.92 333.84 15.3 335.83 15.3C337.82 15.3 339.44 16.92 339.44 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 18.91C353.88 20.9 352.26 22.52 350.27 22.52C348.28 22.52 346.66 20.9 346.66 18.91C346.66 16.92 348.28 15.3 350.27 15.3C352.26 15.3 353.88 16.92 353.88 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 18.91C368.32 20.9 366.7 22.52 364.71 22.52C362.72 22.52 361.1 20.9 361.1 18.91C361.1 16.92 362.72 15.3 364.71 15.3C366.7 15.3 368.32 16.92 368.32 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 18.91C382.77 20.9 381.15 22.52 379.16 22.52C377.17 22.52 375.55 20.9 375.55 18.91C375.55 16.92 377.17 15.3 379.16 15.3C381.15 15.3 382.77 16.92 382.77 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 18.91C397.21 20.9 395.59 22.52 393.6 22.52C391.61 22.52 389.99 20.9 389.99 18.91C389.99 16.92 391.61 15.3 393.6 15.3C395.59 15.3 397.21 16.92 397.21 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 18.91C411.65 20.9 410.03 22.52 408.04 22.52C406.05 22.52 404.43 20.9 404.43 18.91C404.43 16.92 406.05 15.3 408.04 15.3C410.03 15.3 411.65 16.92 411.65 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 18.91C426.1 20.9 424.48 22.52 422.49 22.52C420.5 22.52 418.88 20.9 418.88 18.91C418.88 16.92 420.5 15.3 422.49 15.3C424.48 15.3 426.1 16.92 426.1 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 33.35C21.68 35.34 20.06 36.96 18.07 36.96C16.08 36.96 14.46 35.34 14.46 33.35C14.46 31.36 16.08 29.74 18.07 29.74C20.06 29.74 21.68 31.36 21.68 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 33.35C36.1302 35.34 34.5101 36.96 32.5201 36.96C30.5301 36.96 28.9102 35.34 28.9102 33.35C28.9102 31.36 30.5301 29.74 32.5201 29.74C34.5101 29.74 36.1302 31.36 36.1302 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 33.35C50.5701 35.34 48.9501 36.96 46.9601 36.96C44.9701 36.96 43.3501 35.34 43.3501 33.35C43.3501 31.36 44.9701 29.74 46.9601 29.74C48.9501 29.74 50.5701 31.36 50.5701 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 33.35C65.01 35.34 63.39 36.96 61.4 36.96C59.41 36.96 57.79 35.34 57.79 33.35C57.79 31.36 59.41 29.74 61.4 29.74C63.39 29.74 65.01 31.36 65.01 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 33.35C79.4602 35.34 77.8403 36.96 75.8503 36.96C73.8603 36.96 72.2402 35.34 72.2402 33.35C72.2402 31.36 73.8603 29.74 75.8503 29.74C77.8403 29.74 79.4602 31.36 79.4602 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 33.35C93.9002 35.34 92.2802 36.96 90.2902 36.96C88.3002 36.96 86.6802 35.34 86.6802 33.35C86.6802 31.36 88.3002 29.74 90.2902 29.74C92.2802 29.74 93.9002 31.36 93.9002 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 33.35C108.34 35.34 106.72 36.96 104.73 36.96C102.74 36.96 101.12 35.34 101.12 33.35C101.12 31.36 102.74 29.74 104.73 29.74C106.72 29.74 108.34 31.36 108.34 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 33.35C122.79 35.34 121.17 36.96 119.18 36.96C117.19 36.96 115.57 35.34 115.57 33.35C115.57 31.36 117.19 29.74 119.18 29.74C121.17 29.74 122.79 31.36 122.79 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 33.35C137.23 35.34 135.61 36.96 133.62 36.96C131.63 36.96 130.01 35.34 130.01 33.35C130.01 31.36 131.63 29.74 133.62 29.74C135.61 29.74 137.23 31.36 137.23 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 33.35C151.67 35.34 150.05 36.96 148.06 36.96C146.07 36.96 144.45 35.34 144.45 33.35C144.45 31.36 146.07 29.74 148.06 29.74C150.05 29.74 151.67 31.36 151.67 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 33.35C166.12 35.34 164.5 36.96 162.51 36.96C160.52 36.96 158.9 35.34 158.9 33.35C158.9 31.36 160.52 29.74 162.51 29.74C164.5 29.74 166.12 31.36 166.12 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 33.35C180.56 35.34 178.94 36.96 176.95 36.96C174.96 36.96 173.34 35.34 173.34 33.35C173.34 31.36 174.96 29.74 176.95 29.74C178.94 29.74 180.56 31.36 180.56 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M195 33.35C195 35.34 193.38 36.96 191.39 36.96C189.4 36.96 187.78 35.34 187.78 33.35C187.78 31.36 189.4 29.74 191.39 29.74C193.38 29.74 195 31.36 195 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 33.35C209.45 35.34 207.83 36.96 205.84 36.96C203.85 36.96 202.23 35.34 202.23 33.35C202.23 31.36 203.85 29.74 205.84 29.74C207.83 29.74 209.45 31.36 209.45 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 33.35C223.89 35.34 222.27 36.96 220.28 36.96C218.29 36.96 216.67 35.34 216.67 33.35C216.67 31.36 218.29 29.74 220.28 29.74C222.27 29.74 223.89 31.36 223.89 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 33.35C238.33 35.34 236.71 36.96 234.72 36.96C232.73 36.96 231.11 35.34 231.11 33.35C231.11 31.36 232.73 29.74 234.72 29.74C236.71 29.74 238.33 31.36 238.33 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 33.35C252.78 35.34 251.16 36.96 249.17 36.96C247.18 36.96 245.56 35.34 245.56 33.35C245.56 31.36 247.18 29.74 249.17 29.74C251.16 29.74 252.78 31.36 252.78 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 33.35C267.22 35.34 265.6 36.96 263.61 36.96C261.62 36.96 260 35.34 260 33.35C260 31.36 261.62 29.74 263.61 29.74C265.6 29.74 267.22 31.36 267.22 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 33.35C281.66 35.34 280.04 36.96 278.05 36.96C276.06 36.96 274.44 35.34 274.44 33.35C274.44 31.36 276.06 29.74 278.05 29.74C280.04 29.74 281.66 31.36 281.66 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 33.35C296.11 35.34 294.49 36.96 292.5 36.96C290.51 36.96 288.89 35.34 288.89 33.35C288.89 31.36 290.51 29.74 292.5 29.74C294.49 29.74 296.11 31.36 296.11 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 33.35C310.55 35.34 308.93 36.96 306.94 36.96C304.95 36.96 303.33 35.34 303.33 33.35C303.33 31.36 304.95 29.74 306.94 29.74C308.93 29.74 310.55 31.36 310.55 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 33.35C324.99 35.34 323.37 36.96 321.38 36.96C319.39 36.96 317.77 35.34 317.77 33.35C317.77 31.36 319.39 29.74 321.38 29.74C323.37 29.74 324.99 31.36 324.99 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 33.35C339.44 35.34 337.82 36.96 335.83 36.96C333.84 36.96 332.22 35.34 332.22 33.35C332.22 31.36 333.84 29.74 335.83 29.74C337.82 29.74 339.44 31.36 339.44 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 33.35C353.88 35.34 352.26 36.96 350.27 36.96C348.28 36.96 346.66 35.34 346.66 33.35C346.66 31.36 348.28 29.74 350.27 29.74C352.26 29.74 353.88 31.36 353.88 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 33.35C368.32 35.34 366.7 36.96 364.71 36.96C362.72 36.96 361.1 35.34 361.1 33.35C361.1 31.36 362.72 29.74 364.71 29.74C366.7 29.74 368.32 31.36 368.32 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 33.35C382.77 35.34 381.15 36.96 379.16 36.96C377.17 36.96 375.55 35.34 375.55 33.35C375.55 31.36 377.17 29.74 379.16 29.74C381.15 29.74 382.77 31.36 382.77 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 33.35C397.21 35.34 395.59 36.96 393.6 36.96C391.61 36.96 389.99 35.34 389.99 33.35C389.99 31.36 391.61 29.74 393.6 29.74C395.59 29.74 397.21 31.36 397.21 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 33.35C411.65 35.34 410.03 36.96 408.04 36.96C406.05 36.96 404.43 35.34 404.43 33.35C404.43 31.36 406.05 29.74 408.04 29.74C410.03 29.74 411.65 31.36 411.65 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 33.35C426.1 35.34 424.48 36.96 422.49 36.96C420.5 36.96 418.88 35.34 418.88 33.35C418.88 31.36 420.5 29.74 422.49 29.74C424.48 29.74 426.1 31.36 426.1 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 47.7899C21.68 49.7799 20.06 51.3999 18.07 51.3999C16.08 51.3999 14.46 49.7799 14.46 47.7899C14.46 45.7999 16.08 44.1799 18.07 44.1799C20.06 44.1799 21.68 45.7999 21.68 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 47.7899C36.1302 49.7799 34.5101 51.3999 32.5201 51.3999C30.5301 51.3999 28.9102 49.7799 28.9102 47.7899C28.9102 45.7999 30.5301 44.1799 32.5201 44.1799C34.5101 44.1799 36.1302 45.7999 36.1302 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 47.7899C50.5701 49.7799 48.9501 51.3999 46.9601 51.3999C44.9701 51.3999 43.3501 49.7799 43.3501 47.7899C43.3501 45.7999 44.9701 44.1799 46.9601 44.1799C48.9501 44.1799 50.5701 45.7999 50.5701 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 47.7899C65.01 49.7799 63.39 51.3999 61.4 51.3999C59.41 51.3999 57.79 49.7799 57.79 47.7899C57.79 45.7999 59.41 44.1799 61.4 44.1799C63.39 44.1799 65.01 45.7999 65.01 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 47.7899C79.4602 49.7799 77.8403 51.3999 75.8503 51.3999C73.8603 51.3999 72.2402 49.7799 72.2402 47.7899C72.2402 45.7999 73.8603 44.1799 75.8503 44.1799C77.8403 44.1799 79.4602 45.7999 79.4602 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 47.7899C93.9002 49.7799 92.2802 51.3999 90.2902 51.3999C88.3002 51.3999 86.6802 49.7799 86.6802 47.7899C86.6802 45.7999 88.3002 44.1799 90.2902 44.1799C92.2802 44.1799 93.9002 45.7999 93.9002 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 47.7899C108.34 49.7799 106.72 51.3999 104.73 51.3999C102.74 51.3999 101.12 49.7799 101.12 47.7899C101.12 45.7999 102.74 44.1799 104.73 44.1799C106.72 44.1799 108.34 45.7999 108.34 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 47.7899C122.79 49.7799 121.17 51.3999 119.18 51.3999C117.19 51.3999 115.57 49.7799 115.57 47.7899C115.57 45.7999 117.19 44.1799 119.18 44.1799C121.17 44.1799 122.79 45.7999 122.79 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 47.7899C137.23 49.7799 135.61 51.3999 133.62 51.3999C131.63 51.3999 130.01 49.7799 130.01 47.7899C130.01 45.7999 131.63 44.1799 133.62 44.1799C135.61 44.1799 137.23 45.7999 137.23 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 47.7899C151.67 49.7799 150.05 51.3999 148.06 51.3999C146.07 51.3999 144.45 49.7799 144.45 47.7899C144.45 45.7999 146.07 44.1799 148.06 44.1799C150.05 44.1799 151.67 45.7999 151.67 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 47.7899C166.12 49.7799 164.5 51.3999 162.51 51.3999C160.52 51.3999 158.9 49.7799 158.9 47.7899C158.9 45.7999 160.52 44.1799 162.51 44.1799C164.5 44.1799 166.12 45.7999 166.12 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 47.7899C180.56 49.7799 178.94 51.3999 176.95 51.3999C174.96 51.3999 173.34 49.7799 173.34 47.7899C173.34 45.7999 174.96 44.1799 176.95 44.1799C178.94 44.1799 180.56 45.7999 180.56 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M195 47.7899C195 49.7799 193.38 51.3999 191.39 51.3999C189.4 51.3999 187.78 49.7799 187.78 47.7899C187.78 45.7999 189.4 44.1799 191.39 44.1799C193.38 44.1799 195 45.7999 195 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 47.7899C209.45 49.7799 207.83 51.3999 205.84 51.3999C203.85 51.3999 202.23 49.7799 202.23 47.7899C202.23 45.7999 203.85 44.1799 205.84 44.1799C207.83 44.1799 209.45 45.7999 209.45 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 47.7899C223.89 49.7799 222.27 51.3999 220.28 51.3999C218.29 51.3999 216.67 49.7799 216.67 47.7899C216.67 45.7999 218.29 44.1799 220.28 44.1799C222.27 44.1799 223.89 45.7999 223.89 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 47.7899C238.33 49.7799 236.71 51.3999 234.72 51.3999C232.73 51.3999 231.11 49.7799 231.11 47.7899C231.11 45.7999 232.73 44.1799 234.72 44.1799C236.71 44.1799 238.33 45.7999 238.33 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 47.7899C252.78 49.7799 251.16 51.3999 249.17 51.3999C247.18 51.3999 245.56 49.7799 245.56 47.7899C245.56 45.7999 247.18 44.1799 249.17 44.1799C251.16 44.1799 252.78 45.7999 252.78 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 47.7899C267.22 49.7799 265.6 51.3999 263.61 51.3999C261.62 51.3999 260 49.7799 260 47.7899C260 45.7999 261.62 44.1799 263.61 44.1799C265.6 44.1799 267.22 45.7999 267.22 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 47.7899C281.66 49.7799 280.04 51.3999 278.05 51.3999C276.06 51.3999 274.44 49.7799 274.44 47.7899C274.44 45.7999 276.06 44.1799 278.05 44.1799C280.04 44.1799 281.66 45.7999 281.66 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 47.7899C296.11 49.7799 294.49 51.3999 292.5 51.3999C290.51 51.3999 288.89 49.7799 288.89 47.7899C288.89 45.7999 290.51 44.1799 292.5 44.1799C294.49 44.1799 296.11 45.7999 296.11 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 47.7899C310.55 49.7799 308.93 51.3999 306.94 51.3999C304.95 51.3999 303.33 49.7799 303.33 47.7899C303.33 45.7999 304.95 44.1799 306.94 44.1799C308.93 44.1799 310.55 45.7999 310.55 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 47.7899C324.99 49.7799 323.37 51.3999 321.38 51.3999C319.39 51.3999 317.77 49.7799 317.77 47.7899C317.77 45.7999 319.39 44.1799 321.38 44.1799C323.37 44.1799 324.99 45.7999 324.99 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 47.7899C339.44 49.7799 337.82 51.3999 335.83 51.3999C333.84 51.3999 332.22 49.7799 332.22 47.7899C332.22 45.7999 333.84 44.1799 335.83 44.1799C337.82 44.1799 339.44 45.7999 339.44 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 47.7899C353.88 49.7799 352.26 51.3999 350.27 51.3999C348.28 51.3999 346.66 49.7799 346.66 47.7899C346.66 45.7999 348.28 44.1799 350.27 44.1799C352.26 44.1799 353.88 45.7999 353.88 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 47.7899C368.32 49.7799 366.7 51.3999 364.71 51.3999C362.72 51.3999 361.1 49.7799 361.1 47.7899C361.1 45.7999 362.72 44.1799 364.71 44.1799C366.7 44.1799 368.32 45.7999 368.32 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 47.7899C382.77 49.7799 381.15 51.3999 379.16 51.3999C377.17 51.3999 375.55 49.7799 375.55 47.7899C375.55 45.7999 377.17 44.1799 379.16 44.1799C381.15 44.1799 382.77 45.7999 382.77 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 47.7899C397.21 49.7799 395.59 51.3999 393.6 51.3999C391.61 51.3999 389.99 49.7799 389.99 47.7899C389.99 45.7999 391.61 44.1799 393.6 44.1799C395.59 44.1799 397.21 45.7999 397.21 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 47.7899C411.65 49.7799 410.03 51.3999 408.04 51.3999C406.05 51.3999 404.43 49.7799 404.43 47.7899C404.43 45.7999 406.05 44.1799 408.04 44.1799C410.03 44.1799 411.65 45.7999 411.65 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 47.7899C426.1 49.7799 424.48 51.3999 422.49 51.3999C420.5 51.3999 418.88 49.7799 418.88 47.7899C418.88 45.7999 420.5 44.1799 422.49 44.1799C424.48 44.1799 426.1 45.7999 426.1 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 62.24C21.68 64.23 20.06 65.85 18.07 65.85C16.08 65.85 14.46 64.23 14.46 62.24C14.46 60.25 16.08 58.63 18.07 58.63C20.06 58.63 21.68 60.25 21.68 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 62.24C36.1302 64.23 34.5101 65.85 32.5201 65.85C30.5301 65.85 28.9102 64.23 28.9102 62.24C28.9102 60.25 30.5301 58.63 32.5201 58.63C34.5101 58.63 36.1302 60.25 36.1302 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 62.24C50.5701 64.23 48.9501 65.85 46.9601 65.85C44.9701 65.85 43.3501 64.23 43.3501 62.24C43.3501 60.25 44.9701 58.63 46.9601 58.63C48.9501 58.63 50.5701 60.25 50.5701 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 62.24C65.01 64.23 63.39 65.85 61.4 65.85C59.41 65.85 57.79 64.23 57.79 62.24C57.79 60.25 59.41 58.63 61.4 58.63C63.39 58.63 65.01 60.25 65.01 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 62.24C79.4602 64.23 77.8403 65.85 75.8503 65.85C73.8603 65.85 72.2402 64.23 72.2402 62.24C72.2402 60.25 73.8603 58.63 75.8503 58.63C77.8403 58.63 79.4602 60.25 79.4602 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 62.24C93.9002 64.23 92.2802 65.85 90.2902 65.85C88.3002 65.85 86.6802 64.23 86.6802 62.24C86.6802 60.25 88.3002 58.63 90.2902 58.63C92.2802 58.63 93.9002 60.25 93.9002 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 62.24C108.34 64.23 106.72 65.85 104.73 65.85C102.74 65.85 101.12 64.23 101.12 62.24C101.12 60.25 102.74 58.63 104.73 58.63C106.72 58.63 108.34 60.25 108.34 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 62.24C122.79 64.23 121.17 65.85 119.18 65.85C117.19 65.85 115.57 64.23 115.57 62.24C115.57 60.25 117.19 58.63 119.18 58.63C121.17 58.63 122.79 60.25 122.79 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 62.24C137.23 64.23 135.61 65.85 133.62 65.85C131.63 65.85 130.01 64.23 130.01 62.24C130.01 60.25 131.63 58.63 133.62 58.63C135.61 58.63 137.23 60.25 137.23 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 62.24C151.67 64.23 150.05 65.85 148.06 65.85C146.07 65.85 144.45 64.23 144.45 62.24C144.45 60.25 146.07 58.63 148.06 58.63C150.05 58.63 151.67 60.25 151.67 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 62.24C166.12 64.23 164.5 65.85 162.51 65.85C160.52 65.85 158.9 64.23 158.9 62.24C158.9 60.25 160.52 58.63 162.51 58.63C164.5 58.63 166.12 60.25 166.12 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 62.24C180.56 64.23 178.94 65.85 176.95 65.85C174.96 65.85 173.34 64.23 173.34 62.24C173.34 60.25 174.96 58.63 176.95 58.63C178.94 58.63 180.56 60.25 180.56 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M195 62.24C195 64.23 193.38 65.85 191.39 65.85C189.4 65.85 187.78 64.23 187.78 62.24C187.78 60.25 189.4 58.63 191.39 58.63C193.38 58.63 195 60.25 195 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 62.24C209.45 64.23 207.83 65.85 205.84 65.85C203.85 65.85 202.23 64.23 202.23 62.24C202.23 60.25 203.85 58.63 205.84 58.63C207.83 58.63 209.45 60.25 209.45 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 62.24C223.89 64.23 222.27 65.85 220.28 65.85C218.29 65.85 216.67 64.23 216.67 62.24C216.67 60.25 218.29 58.63 220.28 58.63C222.27 58.63 223.89 60.25 223.89 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 62.24C238.33 64.23 236.71 65.85 234.72 65.85C232.73 65.85 231.11 64.23 231.11 62.24C231.11 60.25 232.73 58.63 234.72 58.63C236.71 58.63 238.33 60.25 238.33 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 62.24C252.78 64.23 251.16 65.85 249.17 65.85C247.18 65.85 245.56 64.23 245.56 62.24C245.56 60.25 247.18 58.63 249.17 58.63C251.16 58.63 252.78 60.25 252.78 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 62.24C267.22 64.23 265.6 65.85 263.61 65.85C261.62 65.85 260 64.23 260 62.24C260 60.25 261.62 58.63 263.61 58.63C265.6 58.63 267.22 60.25 267.22 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 62.24C281.66 64.23 280.04 65.85 278.05 65.85C276.06 65.85 274.44 64.23 274.44 62.24C274.44 60.25 276.06 58.63 278.05 58.63C280.04 58.63 281.66 60.25 281.66 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 62.24C296.11 64.23 294.49 65.85 292.5 65.85C290.51 65.85 288.89 64.23 288.89 62.24C288.89 60.25 290.51 58.63 292.5 58.63C294.49 58.63 296.11 60.25 296.11 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 62.24C310.55 64.23 308.93 65.85 306.94 65.85C304.95 65.85 303.33 64.23 303.33 62.24C303.33 60.25 304.95 58.63 306.94 58.63C308.93 58.63 310.55 60.25 310.55 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 62.24C324.99 64.23 323.37 65.85 321.38 65.85C319.39 65.85 317.77 64.23 317.77 62.24C317.77 60.25 319.39 58.63 321.38 58.63C323.37 58.63 324.99 60.25 324.99 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 62.24C339.44 64.23 337.82 65.85 335.83 65.85C333.84 65.85 332.22 64.23 332.22 62.24C332.22 60.25 333.84 58.63 335.83 58.63C337.82 58.63 339.44 60.25 339.44 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 62.24C353.88 64.23 352.26 65.85 350.27 65.85C348.28 65.85 346.66 64.23 346.66 62.24C346.66 60.25 348.28 58.63 350.27 58.63C352.26 58.63 353.88 60.25 353.88 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 62.24C368.32 64.23 366.7 65.85 364.71 65.85C362.72 65.85 361.1 64.23 361.1 62.24C361.1 60.25 362.72 58.63 364.71 58.63C366.7 58.63 368.32 60.25 368.32 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 62.24C382.77 64.23 381.15 65.85 379.16 65.85C377.17 65.85 375.55 64.23 375.55 62.24C375.55 60.25 377.17 58.63 379.16 58.63C381.15 58.63 382.77 60.25 382.77 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 62.24C397.21 64.23 395.59 65.85 393.6 65.85C391.61 65.85 389.99 64.23 389.99 62.24C389.99 60.25 391.61 58.63 393.6 58.63C395.59 58.63 397.21 60.25 397.21 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 62.24C411.65 64.23 410.03 65.85 408.04 65.85C406.05 65.85 404.43 64.23 404.43 62.24C404.43 60.25 406.05 58.63 408.04 58.63C410.03 58.63 411.65 60.25 411.65 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 62.24C426.1 64.23 424.48 65.85 422.49 65.85C420.5 65.85 418.88 64.23 418.88 62.24C418.88 60.25 420.5 58.63 422.49 58.63C424.48 58.63 426.1 60.25 426.1 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 76.6801C21.68 78.6701 20.06 80.2901 18.07 80.2901C16.08 80.2901 14.46 78.6701 14.46 76.6801C14.46 74.6901 16.08 73.0701 18.07 73.0701C20.06 73.0701 21.68 74.6901 21.68 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M36.1302 76.6801C36.1302 78.6701 34.5101 80.2901 32.5201 80.2901C30.5301 80.2901 28.9102 78.6701 28.9102 76.6801C28.9102 74.6901 30.5301 73.0701 32.5201 73.0701C34.5101 73.0701 36.1302 74.6901 36.1302 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M50.5701 76.6801C50.5701 78.6701 48.9501 80.2901 46.9601 80.2901C44.9701 80.2901 43.3501 78.6701 43.3501 76.6801C43.3501 74.6901 44.9701 73.0701 46.9601 73.0701C48.9501 73.0701 50.5701 74.6901 50.5701 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M65.01 76.6801C65.01 78.6701 63.39 80.2901 61.4 80.2901C59.41 80.2901 57.79 78.6701 57.79 76.6801C57.79 74.6901 59.41 73.0701 61.4 73.0701C63.39 73.0701 65.01 74.6901 65.01 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M79.4602 76.6801C79.4602 78.6701 77.8403 80.2901 75.8503 80.2901C73.8603 80.2901 72.2402 78.6701 72.2402 76.6801C72.2402 74.6901 73.8603 73.0701 75.8503 73.0701C77.8403 73.0701 79.4602 74.6901 79.4602 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M93.9002 76.6801C93.9002 78.6701 92.2802 80.2901 90.2902 80.2901C88.3002 80.2901 86.6802 78.6701 86.6802 76.6801C86.6802 74.6901 88.3002 73.0701 90.2902 73.0701C92.2802 73.0701 93.9002 74.6901 93.9002 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M108.34 76.6801C108.34 78.6701 106.72 80.2901 104.73 80.2901C102.74 80.2901 101.12 78.6701 101.12 76.6801C101.12 74.6901 102.74 73.0701 104.73 73.0701C106.72 73.0701 108.34 74.6901 108.34 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M122.79 76.6801C122.79 78.6701 121.17 80.2901 119.18 80.2901C117.19 80.2901 115.57 78.6701 115.57 76.6801C115.57 74.6901 117.19 73.0701 119.18 73.0701C121.17 73.0701 122.79 74.6901 122.79 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M137.23 76.6801C137.23 78.6701 135.61 80.2901 133.62 80.2901C131.63 80.2901 130.01 78.6701 130.01 76.6801C130.01 74.6901 131.63 73.0701 133.62 73.0701C135.61 73.0701 137.23 74.6901 137.23 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M151.67 76.6801C151.67 78.6701 150.05 80.2901 148.06 80.2901C146.07 80.2901 144.45 78.6701 144.45 76.6801C144.45 74.6901 146.07 73.0701 148.06 73.0701C150.05 73.0701 151.67 74.6901 151.67 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M166.12 76.6801C166.12 78.6701 164.5 80.2901 162.51 80.2901C160.52 80.2901 158.9 78.6701 158.9 76.6801C158.9 74.6901 160.52 73.0701 162.51 73.0701C164.5 73.0701 166.12 74.6901 166.12 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M180.56 76.6801C180.56 78.6701 178.94 80.2901 176.95 80.2901C174.96 80.2901 173.34 78.6701 173.34 76.6801C173.34 74.6901 174.96 73.0701 176.95 73.0701C178.94 73.0701 180.56 74.6901 180.56 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M195 76.6801C195 78.6701 193.38 80.2901 191.39 80.2901C189.4 80.2901 187.78 78.6701 187.78 76.6801C187.78 74.6901 189.4 73.0701 191.39 73.0701C193.38 73.0701 195 74.6901 195 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M209.45 76.6801C209.45 78.6701 207.83 80.2901 205.84 80.2901C203.85 80.2901 202.23 78.6701 202.23 76.6801C202.23 74.6901 203.85 73.0701 205.84 73.0701C207.83 73.0701 209.45 74.6901 209.45 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M223.89 76.6801C223.89 78.6701 222.27 80.2901 220.28 80.2901C218.29 80.2901 216.67 78.6701 216.67 76.6801C216.67 74.6901 218.29 73.0701 220.28 73.0701C222.27 73.0701 223.89 74.6901 223.89 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M238.33 76.6801C238.33 78.6701 236.71 80.2901 234.72 80.2901C232.73 80.2901 231.11 78.6701 231.11 76.6801C231.11 74.6901 232.73 73.0701 234.72 73.0701C236.71 73.0701 238.33 74.6901 238.33 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M252.78 76.6801C252.78 78.6701 251.16 80.2901 249.17 80.2901C247.18 80.2901 245.56 78.6701 245.56 76.6801C245.56 74.6901 247.18 73.0701 249.17 73.0701C251.16 73.0701 252.78 74.6901 252.78 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M267.22 76.6801C267.22 78.6701 265.6 80.2901 263.61 80.2901C261.62 80.2901 260 78.6701 260 76.6801C260 74.6901 261.62 73.0701 263.61 73.0701C265.6 73.0701 267.22 74.6901 267.22 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M281.66 76.6801C281.66 78.6701 280.04 80.2901 278.05 80.2901C276.06 80.2901 274.44 78.6701 274.44 76.6801C274.44 74.6901 276.06 73.0701 278.05 73.0701C280.04 73.0701 281.66 74.6901 281.66 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M296.11 76.6801C296.11 78.6701 294.49 80.2901 292.5 80.2901C290.51 80.2901 288.89 78.6701 288.89 76.6801C288.89 74.6901 290.51 73.0701 292.5 73.0701C294.49 73.0701 296.11 74.6901 296.11 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M310.55 76.6801C310.55 78.6701 308.93 80.2901 306.94 80.2901C304.95 80.2901 303.33 78.6701 303.33 76.6801C303.33 74.6901 304.95 73.0701 306.94 73.0701C308.93 73.0701 310.55 74.6901 310.55 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M324.99 76.6801C324.99 78.6701 323.37 80.2901 321.38 80.2901C319.39 80.2901 317.77 78.6701 317.77 76.6801C317.77 74.6901 319.39 73.0701 321.38 73.0701C323.37 73.0701 324.99 74.6901 324.99 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M339.44 76.6801C339.44 78.6701 337.82 80.2901 335.83 80.2901C333.84 80.2901 332.22 78.6701 332.22 76.6801C332.22 74.6901 333.84 73.0701 335.83 73.0701C337.82 73.0701 339.44 74.6901 339.44 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M353.88 76.6801C353.88 78.6701 352.26 80.2901 350.27 80.2901C348.28 80.2901 346.66 78.6701 346.66 76.6801C346.66 74.6901 348.28 73.0701 350.27 73.0701C352.26 73.0701 353.88 74.6901 353.88 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 76.6801C368.32 78.6701 366.7 80.2901 364.71 80.2901C362.72 80.2901 361.1 78.6701 361.1 76.6801C361.1 74.6901 362.72 73.0701 364.71 73.0701C366.7 73.0701 368.32 74.6901 368.32 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 76.6801C382.77 78.6701 381.15 80.2901 379.16 80.2901C377.17 80.2901 375.55 78.6701 375.55 76.6801C375.55 74.6901 377.17 73.0701 379.16 73.0701C381.15 73.0701 382.77 74.6901 382.77 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 76.6801C397.21 78.6701 395.59 80.2901 393.6 80.2901C391.61 80.2901 389.99 78.6701 389.99 76.6801C389.99 74.6901 391.61 73.0701 393.6 73.0701C395.59 73.0701 397.21 74.6901 397.21 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 76.6801C411.65 78.6701 410.03 80.2901 408.04 80.2901C406.05 80.2901 404.43 78.6701 404.43 76.6801C404.43 74.6901 406.05 73.0701 408.04 73.0701C410.03 73.0701 411.65 74.6901 411.65 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 76.6801C426.1 78.6701 424.48 80.2901 422.49 80.2901C420.5 80.2901 418.88 78.6701 418.88 76.6801C418.88 74.6901 420.5 73.0701 422.49 73.0701C424.48 73.0701 426.1 74.6901 426.1 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 91.12C21.68 93.11 20.06 94.73 18.07 94.73C16.08 94.73 14.46 93.11 14.46 91.12C14.46 89.13 16.08 87.51 18.07 87.51C20.06 87.51 21.68 89.13 21.68 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M36.1302 91.12C36.1302 93.11 34.5101 94.73 32.5201 94.73C30.5301 94.73 28.9102 93.11 28.9102 91.12C28.9102 89.13 30.5301 87.51 32.5201 87.51C34.5101 87.51 36.1302 89.13 36.1302 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M50.5701 91.12C50.5701 93.11 48.9501 94.73 46.9601 94.73C44.9701 94.73 43.3501 93.11 43.3501 91.12C43.3501 89.13 44.9701 87.51 46.9601 87.51C48.9501 87.51 50.5701 89.13 50.5701 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M65.01 91.12C65.01 93.11 63.39 94.73 61.4 94.73C59.41 94.73 57.79 93.11 57.79 91.12C57.79 89.13 59.41 87.51 61.4 87.51C63.39 87.51 65.01 89.13 65.01 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M79.4602 91.12C79.4602 93.11 77.8403 94.73 75.8503 94.73C73.8603 94.73 72.2402 93.11 72.2402 91.12C72.2402 89.13 73.8603 87.51 75.8503 87.51C77.8403 87.51 79.4602 89.13 79.4602 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M93.9002 91.12C93.9002 93.11 92.2802 94.73 90.2902 94.73C88.3002 94.73 86.6802 93.11 86.6802 91.12C86.6802 89.13 88.3002 87.51 90.2902 87.51C92.2802 87.51 93.9002 89.13 93.9002 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M108.34 91.12C108.34 93.11 106.72 94.73 104.73 94.73C102.74 94.73 101.12 93.11 101.12 91.12C101.12 89.13 102.74 87.51 104.73 87.51C106.72 87.51 108.34 89.13 108.34 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M122.79 91.12C122.79 93.11 121.17 94.73 119.18 94.73C117.19 94.73 115.57 93.11 115.57 91.12C115.57 89.13 117.19 87.51 119.18 87.51C121.17 87.51 122.79 89.13 122.79 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M137.23 91.12C137.23 93.11 135.61 94.73 133.62 94.73C131.63 94.73 130.01 93.11 130.01 91.12C130.01 89.13 131.63 87.51 133.62 87.51C135.61 87.51 137.23 89.13 137.23 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M151.67 91.12C151.67 93.11 150.05 94.73 148.06 94.73C146.07 94.73 144.45 93.11 144.45 91.12C144.45 89.13 146.07 87.51 148.06 87.51C150.05 87.51 151.67 89.13 151.67 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M166.12 91.12C166.12 93.11 164.5 94.73 162.51 94.73C160.52 94.73 158.9 93.11 158.9 91.12C158.9 89.13 160.52 87.51 162.51 87.51C164.5 87.51 166.12 89.13 166.12 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M180.56 91.12C180.56 93.11 178.94 94.73 176.95 94.73C174.96 94.73 173.34 93.11 173.34 91.12C173.34 89.13 174.96 87.51 176.95 87.51C178.94 87.51 180.56 89.13 180.56 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M195 91.12C195 93.11 193.38 94.73 191.39 94.73C189.4 94.73 187.78 93.11 187.78 91.12C187.78 89.13 189.4 87.51 191.39 87.51C193.38 87.51 195 89.13 195 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M209.45 91.12C209.45 93.11 207.83 94.73 205.84 94.73C203.85 94.73 202.23 93.11 202.23 91.12C202.23 89.13 203.85 87.51 205.84 87.51C207.83 87.51 209.45 89.13 209.45 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M223.89 91.12C223.89 93.11 222.27 94.73 220.28 94.73C218.29 94.73 216.67 93.11 216.67 91.12C216.67 89.13 218.29 87.51 220.28 87.51C222.27 87.51 223.89 89.13 223.89 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M238.33 91.12C238.33 93.11 236.71 94.73 234.72 94.73C232.73 94.73 231.11 93.11 231.11 91.12C231.11 89.13 232.73 87.51 234.72 87.51C236.71 87.51 238.33 89.13 238.33 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M252.78 91.12C252.78 93.11 251.16 94.73 249.17 94.73C247.18 94.73 245.56 93.11 245.56 91.12C245.56 89.13 247.18 87.51 249.17 87.51C251.16 87.51 252.78 89.13 252.78 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M267.22 91.12C267.22 93.11 265.6 94.73 263.61 94.73C261.62 94.73 260 93.11 260 91.12C260 89.13 261.62 87.51 263.61 87.51C265.6 87.51 267.22 89.13 267.22 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M281.66 91.12C281.66 93.11 280.04 94.73 278.05 94.73C276.06 94.73 274.44 93.11 274.44 91.12C274.44 89.13 276.06 87.51 278.05 87.51C280.04 87.51 281.66 89.13 281.66 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M296.11 91.12C296.11 93.11 294.49 94.73 292.5 94.73C290.51 94.73 288.89 93.11 288.89 91.12C288.89 89.13 290.51 87.51 292.5 87.51C294.49 87.51 296.11 89.13 296.11 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 91.12C310.55 93.11 308.93 94.73 306.94 94.73C304.95 94.73 303.33 93.11 303.33 91.12C303.33 89.13 304.95 87.51 306.94 87.51C308.93 87.51 310.55 89.13 310.55 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 91.12C324.99 93.11 323.37 94.73 321.38 94.73C319.39 94.73 317.77 93.11 317.77 91.12C317.77 89.13 319.39 87.51 321.38 87.51C323.37 87.51 324.99 89.13 324.99 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 91.12C339.44 93.11 337.82 94.73 335.83 94.73C333.84 94.73 332.22 93.11 332.22 91.12C332.22 89.13 333.84 87.51 335.83 87.51C337.82 87.51 339.44 89.13 339.44 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 91.12C353.88 93.11 352.26 94.73 350.27 94.73C348.28 94.73 346.66 93.11 346.66 91.12C346.66 89.13 348.28 87.51 350.27 87.51C352.26 87.51 353.88 89.13 353.88 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 91.12C368.32 93.11 366.7 94.73 364.71 94.73C362.72 94.73 361.1 93.11 361.1 91.12C361.1 89.13 362.72 87.51 364.71 87.51C366.7 87.51 368.32 89.13 368.32 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 91.12C382.77 93.11 381.15 94.73 379.16 94.73C377.17 94.73 375.55 93.11 375.55 91.12C375.55 89.13 377.17 87.51 379.16 87.51C381.15 87.51 382.77 89.13 382.77 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 91.12C397.21 93.11 395.59 94.73 393.6 94.73C391.61 94.73 389.99 93.11 389.99 91.12C389.99 89.13 391.61 87.51 393.6 87.51C395.59 87.51 397.21 89.13 397.21 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 91.12C411.65 93.11 410.03 94.73 408.04 94.73C406.05 94.73 404.43 93.11 404.43 91.12C404.43 89.13 406.05 87.51 408.04 87.51C410.03 87.51 411.65 89.13 411.65 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 91.12C426.1 93.11 424.48 94.73 422.49 94.73C420.5 94.73 418.88 93.11 418.88 91.12C418.88 89.13 420.5 87.51 422.49 87.51C424.48 87.51 426.1 89.13 426.1 91.12Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 105.57C21.68 107.56 20.06 109.18 18.07 109.18C16.08 109.18 14.46 107.56 14.46 105.57C14.46 103.58 16.08 101.96 18.07 101.96C20.06 101.96 21.68 103.58 21.68 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M36.1302 105.57C36.1302 107.56 34.5101 109.18 32.5201 109.18C30.5301 109.18 28.9102 107.56 28.9102 105.57C28.9102 103.58 30.5301 101.96 32.5201 101.96C34.5101 101.96 36.1302 103.58 36.1302 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M50.5701 105.57C50.5701 107.56 48.9501 109.18 46.9601 109.18C44.9701 109.18 43.3501 107.56 43.3501 105.57C43.3501 103.58 44.9701 101.96 46.9601 101.96C48.9501 101.96 50.5701 103.58 50.5701 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M65.01 105.57C65.01 107.56 63.39 109.18 61.4 109.18C59.41 109.18 57.79 107.56 57.79 105.57C57.79 103.58 59.41 101.96 61.4 101.96C63.39 101.96 65.01 103.58 65.01 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M79.4602 105.57C79.4602 107.56 77.8403 109.18 75.8503 109.18C73.8603 109.18 72.2402 107.56 72.2402 105.57C72.2402 103.58 73.8603 101.96 75.8503 101.96C77.8403 101.96 79.4602 103.58 79.4602 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M93.9002 105.57C93.9002 107.56 92.2802 109.18 90.2902 109.18C88.3002 109.18 86.6802 107.56 86.6802 105.57C86.6802 103.58 88.3002 101.96 90.2902 101.96C92.2802 101.96 93.9002 103.58 93.9002 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M108.34 105.57C108.34 107.56 106.72 109.18 104.73 109.18C102.74 109.18 101.12 107.56 101.12 105.57C101.12 103.58 102.74 101.96 104.73 101.96C106.72 101.96 108.34 103.58 108.34 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M122.79 105.57C122.79 107.56 121.17 109.18 119.18 109.18C117.19 109.18 115.57 107.56 115.57 105.57C115.57 103.58 117.19 101.96 119.18 101.96C121.17 101.96 122.79 103.58 122.79 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M137.23 105.57C137.23 107.56 135.61 109.18 133.62 109.18C131.63 109.18 130.01 107.56 130.01 105.57C130.01 103.58 131.63 101.96 133.62 101.96C135.61 101.96 137.23 103.58 137.23 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M151.67 105.57C151.67 107.56 150.05 109.18 148.06 109.18C146.07 109.18 144.45 107.56 144.45 105.57C144.45 103.58 146.07 101.96 148.06 101.96C150.05 101.96 151.67 103.58 151.67 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M166.12 105.57C166.12 107.56 164.5 109.18 162.51 109.18C160.52 109.18 158.9 107.56 158.9 105.57C158.9 103.58 160.52 101.96 162.51 101.96C164.5 101.96 166.12 103.58 166.12 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M180.56 105.57C180.56 107.56 178.94 109.18 176.95 109.18C174.96 109.18 173.34 107.56 173.34 105.57C173.34 103.58 174.96 101.96 176.95 101.96C178.94 101.96 180.56 103.58 180.56 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M195 105.57C195 107.56 193.38 109.18 191.39 109.18C189.4 109.18 187.78 107.56 187.78 105.57C187.78 103.58 189.4 101.96 191.39 101.96C193.38 101.96 195 103.58 195 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M209.45 105.57C209.45 107.56 207.83 109.18 205.84 109.18C203.85 109.18 202.23 107.56 202.23 105.57C202.23 103.58 203.85 101.96 205.84 101.96C207.83 101.96 209.45 103.58 209.45 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M223.89 105.57C223.89 107.56 222.27 109.18 220.28 109.18C218.29 109.18 216.67 107.56 216.67 105.57C216.67 103.58 218.29 101.96 220.28 101.96C222.27 101.96 223.89 103.58 223.89 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M238.33 105.57C238.33 107.56 236.71 109.18 234.72 109.18C232.73 109.18 231.11 107.56 231.11 105.57C231.11 103.58 232.73 101.96 234.72 101.96C236.71 101.96 238.33 103.58 238.33 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M252.78 105.57C252.78 107.56 251.16 109.18 249.17 109.18C247.18 109.18 245.56 107.56 245.56 105.57C245.56 103.58 247.18 101.96 249.17 101.96C251.16 101.96 252.78 103.58 252.78 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M267.22 105.57C267.22 107.56 265.6 109.18 263.61 109.18C261.62 109.18 260 107.56 260 105.57C260 103.58 261.62 101.96 263.61 101.96C265.6 101.96 267.22 103.58 267.22 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M281.66 105.57C281.66 107.56 280.04 109.18 278.05 109.18C276.06 109.18 274.44 107.56 274.44 105.57C274.44 103.58 276.06 101.96 278.05 101.96C280.04 101.96 281.66 103.58 281.66 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M296.11 105.57C296.11 107.56 294.49 109.18 292.5 109.18C290.51 109.18 288.89 107.56 288.89 105.57C288.89 103.58 290.51 101.96 292.5 101.96C294.49 101.96 296.11 103.58 296.11 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 105.57C310.55 107.56 308.93 109.18 306.94 109.18C304.95 109.18 303.33 107.56 303.33 105.57C303.33 103.58 304.95 101.96 306.94 101.96C308.93 101.96 310.55 103.58 310.55 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 105.57C324.99 107.56 323.37 109.18 321.38 109.18C319.39 109.18 317.77 107.56 317.77 105.57C317.77 103.58 319.39 101.96 321.38 101.96C323.37 101.96 324.99 103.58 324.99 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 105.57C339.44 107.56 337.82 109.18 335.83 109.18C333.84 109.18 332.22 107.56 332.22 105.57C332.22 103.58 333.84 101.96 335.83 101.96C337.82 101.96 339.44 103.58 339.44 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 105.57C353.88 107.56 352.26 109.18 350.27 109.18C348.28 109.18 346.66 107.56 346.66 105.57C346.66 103.58 348.28 101.96 350.27 101.96C352.26 101.96 353.88 103.58 353.88 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 105.57C368.32 107.56 366.7 109.18 364.71 109.18C362.72 109.18 361.1 107.56 361.1 105.57C361.1 103.58 362.72 101.96 364.71 101.96C366.7 101.96 368.32 103.58 368.32 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 105.57C382.77 107.56 381.15 109.18 379.16 109.18C377.17 109.18 375.55 107.56 375.55 105.57C375.55 103.58 377.17 101.96 379.16 101.96C381.15 101.96 382.77 103.58 382.77 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 105.57C397.21 107.56 395.59 109.18 393.6 109.18C391.61 109.18 389.99 107.56 389.99 105.57C389.99 103.58 391.61 101.96 393.6 101.96C395.59 101.96 397.21 103.58 397.21 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 105.57C411.65 107.56 410.03 109.18 408.04 109.18C406.05 109.18 404.43 107.56 404.43 105.57C404.43 103.58 406.05 101.96 408.04 101.96C410.03 101.96 411.65 103.58 411.65 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 105.57C426.1 107.56 424.48 109.18 422.49 109.18C420.5 109.18 418.88 107.56 418.88 105.57C418.88 103.58 420.5 101.96 422.49 101.96C424.48 101.96 426.1 103.58 426.1 105.57Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 120.01C21.68 122 20.06 123.62 18.07 123.62C16.08 123.62 14.46 122 14.46 120.01C14.46 118.02 16.08 116.4 18.07 116.4C20.06 116.4 21.68 118.02 21.68 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M36.1302 120.01C36.1302 122 34.5101 123.62 32.5201 123.62C30.5301 123.62 28.9102 122 28.9102 120.01C28.9102 118.02 30.5301 116.4 32.5201 116.4C34.5101 116.4 36.1302 118.02 36.1302 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M50.5701 120.01C50.5701 122 48.9501 123.62 46.9601 123.62C44.9701 123.62 43.3501 122 43.3501 120.01C43.3501 118.02 44.9701 116.4 46.9601 116.4C48.9501 116.4 50.5701 118.02 50.5701 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M65.01 120.01C65.01 122 63.39 123.62 61.4 123.62C59.41 123.62 57.79 122 57.79 120.01C57.79 118.02 59.41 116.4 61.4 116.4C63.39 116.4 65.01 118.02 65.01 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M79.4602 120.01C79.4602 122 77.8403 123.62 75.8503 123.62C73.8603 123.62 72.2402 122 72.2402 120.01C72.2402 118.02 73.8603 116.4 75.8503 116.4C77.8403 116.4 79.4602 118.02 79.4602 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M93.9002 120.01C93.9002 122 92.2802 123.62 90.2902 123.62C88.3002 123.62 86.6802 122 86.6802 120.01C86.6802 118.02 88.3002 116.4 90.2902 116.4C92.2802 116.4 93.9002 118.02 93.9002 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M108.34 120.01C108.34 122 106.72 123.62 104.73 123.62C102.74 123.62 101.12 122 101.12 120.01C101.12 118.02 102.74 116.4 104.73 116.4C106.72 116.4 108.34 118.02 108.34 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M122.79 120.01C122.79 122 121.17 123.62 119.18 123.62C117.19 123.62 115.57 122 115.57 120.01C115.57 118.02 117.19 116.4 119.18 116.4C121.17 116.4 122.79 118.02 122.79 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M137.23 120.01C137.23 122 135.61 123.62 133.62 123.62C131.63 123.62 130.01 122 130.01 120.01C130.01 118.02 131.63 116.4 133.62 116.4C135.61 116.4 137.23 118.02 137.23 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M151.67 120.01C151.67 122 150.05 123.62 148.06 123.62C146.07 123.62 144.45 122 144.45 120.01C144.45 118.02 146.07 116.4 148.06 116.4C150.05 116.4 151.67 118.02 151.67 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M166.12 120.01C166.12 122 164.5 123.62 162.51 123.62C160.52 123.62 158.9 122 158.9 120.01C158.9 118.02 160.52 116.4 162.51 116.4C164.5 116.4 166.12 118.02 166.12 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M180.56 120.01C180.56 122 178.94 123.62 176.95 123.62C174.96 123.62 173.34 122 173.34 120.01C173.34 118.02 174.96 116.4 176.95 116.4C178.94 116.4 180.56 118.02 180.56 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M195 120.01C195 122 193.38 123.62 191.39 123.62C189.4 123.62 187.78 122 187.78 120.01C187.78 118.02 189.4 116.4 191.39 116.4C193.38 116.4 195 118.02 195 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M209.45 120.01C209.45 122 207.83 123.62 205.84 123.62C203.85 123.62 202.23 122 202.23 120.01C202.23 118.02 203.85 116.4 205.84 116.4C207.83 116.4 209.45 118.02 209.45 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M223.89 120.01C223.89 122 222.27 123.62 220.28 123.62C218.29 123.62 216.67 122 216.67 120.01C216.67 118.02 218.29 116.4 220.28 116.4C222.27 116.4 223.89 118.02 223.89 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M238.33 120.01C238.33 122 236.71 123.62 234.72 123.62C232.73 123.62 231.11 122 231.11 120.01C231.11 118.02 232.73 116.4 234.72 116.4C236.71 116.4 238.33 118.02 238.33 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M252.78 120.01C252.78 122 251.16 123.62 249.17 123.62C247.18 123.62 245.56 122 245.56 120.01C245.56 118.02 247.18 116.4 249.17 116.4C251.16 116.4 252.78 118.02 252.78 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M267.22 120.01C267.22 122 265.6 123.62 263.61 123.62C261.62 123.62 260 122 260 120.01C260 118.02 261.62 116.4 263.61 116.4C265.6 116.4 267.22 118.02 267.22 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M281.66 120.01C281.66 122 280.04 123.62 278.05 123.62C276.06 123.62 274.44 122 274.44 120.01C274.44 118.02 276.06 116.4 278.05 116.4C280.04 116.4 281.66 118.02 281.66 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M296.11 120.01C296.11 122 294.49 123.62 292.5 123.62C290.51 123.62 288.89 122 288.89 120.01C288.89 118.02 290.51 116.4 292.5 116.4C294.49 116.4 296.11 118.02 296.11 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 120.01C310.55 122 308.93 123.62 306.94 123.62C304.95 123.62 303.33 122 303.33 120.01C303.33 118.02 304.95 116.4 306.94 116.4C308.93 116.4 310.55 118.02 310.55 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 120.01C324.99 122 323.37 123.62 321.38 123.62C319.39 123.62 317.77 122 317.77 120.01C317.77 118.02 319.39 116.4 321.38 116.4C323.37 116.4 324.99 118.02 324.99 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 120.01C339.44 122 337.82 123.62 335.83 123.62C333.84 123.62 332.22 122 332.22 120.01C332.22 118.02 333.84 116.4 335.83 116.4C337.82 116.4 339.44 118.02 339.44 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 120.01C353.88 122 352.26 123.62 350.27 123.62C348.28 123.62 346.66 122 346.66 120.01C346.66 118.02 348.28 116.4 350.27 116.4C352.26 116.4 353.88 118.02 353.88 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 120.01C368.32 122 366.7 123.62 364.71 123.62C362.72 123.62 361.1 122 361.1 120.01C361.1 118.02 362.72 116.4 364.71 116.4C366.7 116.4 368.32 118.02 368.32 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 120.01C382.77 122 381.15 123.62 379.16 123.62C377.17 123.62 375.55 122 375.55 120.01C375.55 118.02 377.17 116.4 379.16 116.4C381.15 116.4 382.77 118.02 382.77 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 120.01C397.21 122 395.59 123.62 393.6 123.62C391.61 123.62 389.99 122 389.99 120.01C389.99 118.02 391.61 116.4 393.6 116.4C395.59 116.4 397.21 118.02 397.21 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 120.01C411.65 122 410.03 123.62 408.04 123.62C406.05 123.62 404.43 122 404.43 120.01C404.43 118.02 406.05 116.4 408.04 116.4C410.03 116.4 411.65 118.02 411.65 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 120.01C426.1 122 424.48 123.62 422.49 123.62C420.5 123.62 418.88 122 418.88 120.01C418.88 118.02 420.5 116.4 422.49 116.4C424.48 116.4 426.1 118.02 426.1 120.01Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 134.45C21.68 136.44 20.06 138.06 18.07 138.06C16.08 138.06 14.46 136.44 14.46 134.45C14.46 132.46 16.08 130.84 18.07 130.84C20.06 130.84 21.68 132.46 21.68 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M36.1302 134.45C36.1302 136.44 34.5101 138.06 32.5201 138.06C30.5301 138.06 28.9102 136.44 28.9102 134.45C28.9102 132.46 30.5301 130.84 32.5201 130.84C34.5101 130.84 36.1302 132.46 36.1302 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M50.5701 134.45C50.5701 136.44 48.9501 138.06 46.9601 138.06C44.9701 138.06 43.3501 136.44 43.3501 134.45C43.3501 132.46 44.9701 130.84 46.9601 130.84C48.9501 130.84 50.5701 132.46 50.5701 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M65.01 134.45C65.01 136.44 63.39 138.06 61.4 138.06C59.41 138.06 57.79 136.44 57.79 134.45C57.79 132.46 59.41 130.84 61.4 130.84C63.39 130.84 65.01 132.46 65.01 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M79.4602 134.45C79.4602 136.44 77.8403 138.06 75.8503 138.06C73.8603 138.06 72.2402 136.44 72.2402 134.45C72.2402 132.46 73.8603 130.84 75.8503 130.84C77.8403 130.84 79.4602 132.46 79.4602 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M93.9002 134.45C93.9002 136.44 92.2802 138.06 90.2902 138.06C88.3002 138.06 86.6802 136.44 86.6802 134.45C86.6802 132.46 88.3002 130.84 90.2902 130.84C92.2802 130.84 93.9002 132.46 93.9002 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M108.34 134.45C108.34 136.44 106.72 138.06 104.73 138.06C102.74 138.06 101.12 136.44 101.12 134.45C101.12 132.46 102.74 130.84 104.73 130.84C106.72 130.84 108.34 132.46 108.34 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M122.79 134.45C122.79 136.44 121.17 138.06 119.18 138.06C117.19 138.06 115.57 136.44 115.57 134.45C115.57 132.46 117.19 130.84 119.18 130.84C121.17 130.84 122.79 132.46 122.79 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M137.23 134.45C137.23 136.44 135.61 138.06 133.62 138.06C131.63 138.06 130.01 136.44 130.01 134.45C130.01 132.46 131.63 130.84 133.62 130.84C135.61 130.84 137.23 132.46 137.23 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M151.67 134.45C151.67 136.44 150.05 138.06 148.06 138.06C146.07 138.06 144.45 136.44 144.45 134.45C144.45 132.46 146.07 130.84 148.06 130.84C150.05 130.84 151.67 132.46 151.67 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M166.12 134.45C166.12 136.44 164.5 138.06 162.51 138.06C160.52 138.06 158.9 136.44 158.9 134.45C158.9 132.46 160.52 130.84 162.51 130.84C164.5 130.84 166.12 132.46 166.12 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M180.56 134.45C180.56 136.44 178.94 138.06 176.95 138.06C174.96 138.06 173.34 136.44 173.34 134.45C173.34 132.46 174.96 130.84 176.95 130.84C178.94 130.84 180.56 132.46 180.56 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M195 134.45C195 136.44 193.38 138.06 191.39 138.06C189.4 138.06 187.78 136.44 187.78 134.45C187.78 132.46 189.4 130.84 191.39 130.84C193.38 130.84 195 132.46 195 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M209.45 134.45C209.45 136.44 207.83 138.06 205.84 138.06C203.85 138.06 202.23 136.44 202.23 134.45C202.23 132.46 203.85 130.84 205.84 130.84C207.83 130.84 209.45 132.46 209.45 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M223.89 134.45C223.89 136.44 222.27 138.06 220.28 138.06C218.29 138.06 216.67 136.44 216.67 134.45C216.67 132.46 218.29 130.84 220.28 130.84C222.27 130.84 223.89 132.46 223.89 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M238.33 134.45C238.33 136.44 236.71 138.06 234.72 138.06C232.73 138.06 231.11 136.44 231.11 134.45C231.11 132.46 232.73 130.84 234.72 130.84C236.71 130.84 238.33 132.46 238.33 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M252.78 134.45C252.78 136.44 251.16 138.06 249.17 138.06C247.18 138.06 245.56 136.44 245.56 134.45C245.56 132.46 247.18 130.84 249.17 130.84C251.16 130.84 252.78 132.46 252.78 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M267.22 134.45C267.22 136.44 265.6 138.06 263.61 138.06C261.62 138.06 260 136.44 260 134.45C260 132.46 261.62 130.84 263.61 130.84C265.6 130.84 267.22 132.46 267.22 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M281.66 134.45C281.66 136.44 280.04 138.06 278.05 138.06C276.06 138.06 274.44 136.44 274.44 134.45C274.44 132.46 276.06 130.84 278.05 130.84C280.04 130.84 281.66 132.46 281.66 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M296.11 134.45C296.11 136.44 294.49 138.06 292.5 138.06C290.51 138.06 288.89 136.44 288.89 134.45C288.89 132.46 290.51 130.84 292.5 130.84C294.49 130.84 296.11 132.46 296.11 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 134.45C310.55 136.44 308.93 138.06 306.94 138.06C304.95 138.06 303.33 136.44 303.33 134.45C303.33 132.46 304.95 130.84 306.94 130.84C308.93 130.84 310.55 132.46 310.55 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 134.45C324.99 136.44 323.37 138.06 321.38 138.06C319.39 138.06 317.77 136.44 317.77 134.45C317.77 132.46 319.39 130.84 321.38 130.84C323.37 130.84 324.99 132.46 324.99 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 134.45C339.44 136.44 337.82 138.06 335.83 138.06C333.84 138.06 332.22 136.44 332.22 134.45C332.22 132.46 333.84 130.84 335.83 130.84C337.82 130.84 339.44 132.46 339.44 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 134.45C353.88 136.44 352.26 138.06 350.27 138.06C348.28 138.06 346.66 136.44 346.66 134.45C346.66 132.46 348.28 130.84 350.27 130.84C352.26 130.84 353.88 132.46 353.88 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 134.45C368.32 136.44 366.7 138.06 364.71 138.06C362.72 138.06 361.1 136.44 361.1 134.45C361.1 132.46 362.72 130.84 364.71 130.84C366.7 130.84 368.32 132.46 368.32 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 134.45C382.77 136.44 381.15 138.06 379.16 138.06C377.17 138.06 375.55 136.44 375.55 134.45C375.55 132.46 377.17 130.84 379.16 130.84C381.15 130.84 382.77 132.46 382.77 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 134.45C397.21 136.44 395.59 138.06 393.6 138.06C391.61 138.06 389.99 136.44 389.99 134.45C389.99 132.46 391.61 130.84 393.6 130.84C395.59 130.84 397.21 132.46 397.21 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 134.45C411.65 136.44 410.03 138.06 408.04 138.06C406.05 138.06 404.43 136.44 404.43 134.45C404.43 132.46 406.05 130.84 408.04 130.84C410.03 130.84 411.65 132.46 411.65 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 134.45C426.1 136.44 424.48 138.06 422.49 138.06C420.5 138.06 418.88 136.44 418.88 134.45C418.88 132.46 420.5 130.84 422.49 130.84C424.48 130.84 426.1 132.46 426.1 134.45Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 148.9C21.68 150.89 20.06 152.51 18.07 152.51C16.08 152.51 14.46 150.89 14.46 148.9C14.46 146.91 16.08 145.29 18.07 145.29C20.06 145.29 21.68 146.91 21.68 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M36.1302 148.9C36.1302 150.89 34.5101 152.51 32.5201 152.51C30.5301 152.51 28.9102 150.89 28.9102 148.9C28.9102 146.91 30.5301 145.29 32.5201 145.29C34.5101 145.29 36.1302 146.91 36.1302 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M50.5701 148.9C50.5701 150.89 48.9501 152.51 46.9601 152.51C44.9701 152.51 43.3501 150.89 43.3501 148.9C43.3501 146.91 44.9701 145.29 46.9601 145.29C48.9501 145.29 50.5701 146.91 50.5701 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M65.01 148.9C65.01 150.89 63.39 152.51 61.4 152.51C59.41 152.51 57.79 150.89 57.79 148.9C57.79 146.91 59.41 145.29 61.4 145.29C63.39 145.29 65.01 146.91 65.01 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M79.4602 148.9C79.4602 150.89 77.8403 152.51 75.8503 152.51C73.8603 152.51 72.2402 150.89 72.2402 148.9C72.2402 146.91 73.8603 145.29 75.8503 145.29C77.8403 145.29 79.4602 146.91 79.4602 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M93.9002 148.9C93.9002 150.89 92.2802 152.51 90.2902 152.51C88.3002 152.51 86.6802 150.89 86.6802 148.9C86.6802 146.91 88.3002 145.29 90.2902 145.29C92.2802 145.29 93.9002 146.91 93.9002 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M108.34 148.9C108.34 150.89 106.72 152.51 104.73 152.51C102.74 152.51 101.12 150.89 101.12 148.9C101.12 146.91 102.74 145.29 104.73 145.29C106.72 145.29 108.34 146.91 108.34 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M122.79 148.9C122.79 150.89 121.17 152.51 119.18 152.51C117.19 152.51 115.57 150.89 115.57 148.9C115.57 146.91 117.19 145.29 119.18 145.29C121.17 145.29 122.79 146.91 122.79 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M137.23 148.9C137.23 150.89 135.61 152.51 133.62 152.51C131.63 152.51 130.01 150.89 130.01 148.9C130.01 146.91 131.63 145.29 133.62 145.29C135.61 145.29 137.23 146.91 137.23 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M151.67 148.9C151.67 150.89 150.05 152.51 148.06 152.51C146.07 152.51 144.45 150.89 144.45 148.9C144.45 146.91 146.07 145.29 148.06 145.29C150.05 145.29 151.67 146.91 151.67 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M166.12 148.9C166.12 150.89 164.5 152.51 162.51 152.51C160.52 152.51 158.9 150.89 158.9 148.9C158.9 146.91 160.52 145.29 162.51 145.29C164.5 145.29 166.12 146.91 166.12 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M180.56 148.9C180.56 150.89 178.94 152.51 176.95 152.51C174.96 152.51 173.34 150.89 173.34 148.9C173.34 146.91 174.96 145.29 176.95 145.29C178.94 145.29 180.56 146.91 180.56 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M195 148.9C195 150.89 193.38 152.51 191.39 152.51C189.4 152.51 187.78 150.89 187.78 148.9C187.78 146.91 189.4 145.29 191.39 145.29C193.38 145.29 195 146.91 195 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M209.45 148.9C209.45 150.89 207.83 152.51 205.84 152.51C203.85 152.51 202.23 150.89 202.23 148.9C202.23 146.91 203.85 145.29 205.84 145.29C207.83 145.29 209.45 146.91 209.45 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M223.89 148.9C223.89 150.89 222.27 152.51 220.28 152.51C218.29 152.51 216.67 150.89 216.67 148.9C216.67 146.91 218.29 145.29 220.28 145.29C222.27 145.29 223.89 146.91 223.89 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M238.33 148.9C238.33 150.89 236.71 152.51 234.72 152.51C232.73 152.51 231.11 150.89 231.11 148.9C231.11 146.91 232.73 145.29 234.72 145.29C236.71 145.29 238.33 146.91 238.33 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M252.78 148.9C252.78 150.89 251.16 152.51 249.17 152.51C247.18 152.51 245.56 150.89 245.56 148.9C245.56 146.91 247.18 145.29 249.17 145.29C251.16 145.29 252.78 146.91 252.78 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M267.22 148.9C267.22 150.89 265.6 152.51 263.61 152.51C261.62 152.51 260 150.89 260 148.9C260 146.91 261.62 145.29 263.61 145.29C265.6 145.29 267.22 146.91 267.22 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 148.9C281.66 150.89 280.04 152.51 278.05 152.51C276.06 152.51 274.44 150.89 274.44 148.9C274.44 146.91 276.06 145.29 278.05 145.29C280.04 145.29 281.66 146.91 281.66 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 148.9C296.11 150.89 294.49 152.51 292.5 152.51C290.51 152.51 288.89 150.89 288.89 148.9C288.89 146.91 290.51 145.29 292.5 145.29C294.49 145.29 296.11 146.91 296.11 148.9Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 148.9C310.55 150.89 308.93 152.51 306.94 152.51C304.95 152.51 303.33 150.89 303.33 148.9C303.33 146.91 304.95 145.29 306.94 145.29C308.93 145.29 310.55 146.91 310.55 148.9Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 148.9C324.99 150.89 323.37 152.51 321.38 152.51C319.39 152.51 317.77 150.89 317.77 148.9C317.77 146.91 319.39 145.29 321.38 145.29C323.37 145.29 324.99 146.91 324.99 148.9Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 148.9C339.44 150.89 337.82 152.51 335.83 152.51C333.84 152.51 332.22 150.89 332.22 148.9C332.22 146.91 333.84 145.29 335.83 145.29C337.82 145.29 339.44 146.91 339.44 148.9Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 148.9C353.88 150.89 352.26 152.51 350.27 152.51C348.28 152.51 346.66 150.89 346.66 148.9C346.66 146.91 348.28 145.29 350.27 145.29C352.26 145.29 353.88 146.91 353.88 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 148.9C368.32 150.89 366.7 152.51 364.71 152.51C362.72 152.51 361.1 150.89 361.1 148.9C361.1 146.91 362.72 145.29 364.71 145.29C366.7 145.29 368.32 146.91 368.32 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 148.9C382.77 150.89 381.15 152.51 379.16 152.51C377.17 152.51 375.55 150.89 375.55 148.9C375.55 146.91 377.17 145.29 379.16 145.29C381.15 145.29 382.77 146.91 382.77 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 148.9C397.21 150.89 395.59 152.51 393.6 152.51C391.61 152.51 389.99 150.89 389.99 148.9C389.99 146.91 391.61 145.29 393.6 145.29C395.59 145.29 397.21 146.91 397.21 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 148.9C411.65 150.89 410.03 152.51 408.04 152.51C406.05 152.51 404.43 150.89 404.43 148.9C404.43 146.91 406.05 145.29 408.04 145.29C410.03 145.29 411.65 146.91 411.65 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 148.9C426.1 150.89 424.48 152.51 422.49 152.51C420.5 152.51 418.88 150.89 418.88 148.9C418.88 146.91 420.5 145.29 422.49 145.29C424.48 145.29 426.1 146.91 426.1 148.9Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 163.34C21.68 165.33 20.06 166.95 18.07 166.95C16.08 166.95 14.46 165.33 14.46 163.34C14.46 161.35 16.08 159.73 18.07 159.73C20.06 159.73 21.68 161.35 21.68 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M36.1302 163.34C36.1302 165.33 34.5101 166.95 32.5201 166.95C30.5301 166.95 28.9102 165.33 28.9102 163.34C28.9102 161.35 30.5301 159.73 32.5201 159.73C34.5101 159.73 36.1302 161.35 36.1302 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M50.5701 163.34C50.5701 165.33 48.9501 166.95 46.9601 166.95C44.9701 166.95 43.3501 165.33 43.3501 163.34C43.3501 161.35 44.9701 159.73 46.9601 159.73C48.9501 159.73 50.5701 161.35 50.5701 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M65.01 163.34C65.01 165.33 63.39 166.95 61.4 166.95C59.41 166.95 57.79 165.33 57.79 163.34C57.79 161.35 59.41 159.73 61.4 159.73C63.39 159.73 65.01 161.35 65.01 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M79.4602 163.34C79.4602 165.33 77.8403 166.95 75.8503 166.95C73.8603 166.95 72.2402 165.33 72.2402 163.34C72.2402 161.35 73.8603 159.73 75.8503 159.73C77.8403 159.73 79.4602 161.35 79.4602 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M93.9002 163.34C93.9002 165.33 92.2802 166.95 90.2902 166.95C88.3002 166.95 86.6802 165.33 86.6802 163.34C86.6802 161.35 88.3002 159.73 90.2902 159.73C92.2802 159.73 93.9002 161.35 93.9002 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M108.34 163.34C108.34 165.33 106.72 166.95 104.73 166.95C102.74 166.95 101.12 165.33 101.12 163.34C101.12 161.35 102.74 159.73 104.73 159.73C106.72 159.73 108.34 161.35 108.34 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M122.79 163.34C122.79 165.33 121.17 166.95 119.18 166.95C117.19 166.95 115.57 165.33 115.57 163.34C115.57 161.35 117.19 159.73 119.18 159.73C121.17 159.73 122.79 161.35 122.79 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M137.23 163.34C137.23 165.33 135.61 166.95 133.62 166.95C131.63 166.95 130.01 165.33 130.01 163.34C130.01 161.35 131.63 159.73 133.62 159.73C135.61 159.73 137.23 161.35 137.23 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M151.67 163.34C151.67 165.33 150.05 166.95 148.06 166.95C146.07 166.95 144.45 165.33 144.45 163.34C144.45 161.35 146.07 159.73 148.06 159.73C150.05 159.73 151.67 161.35 151.67 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M166.12 163.34C166.12 165.33 164.5 166.95 162.51 166.95C160.52 166.95 158.9 165.33 158.9 163.34C158.9 161.35 160.52 159.73 162.51 159.73C164.5 159.73 166.12 161.35 166.12 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M180.56 163.34C180.56 165.33 178.94 166.95 176.95 166.95C174.96 166.95 173.34 165.33 173.34 163.34C173.34 161.35 174.96 159.73 176.95 159.73C178.94 159.73 180.56 161.35 180.56 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M195 163.34C195 165.33 193.38 166.95 191.39 166.95C189.4 166.95 187.78 165.33 187.78 163.34C187.78 161.35 189.4 159.73 191.39 159.73C193.38 159.73 195 161.35 195 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M209.45 163.34C209.45 165.33 207.83 166.95 205.84 166.95C203.85 166.95 202.23 165.33 202.23 163.34C202.23 161.35 203.85 159.73 205.84 159.73C207.83 159.73 209.45 161.35 209.45 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M223.89 163.34C223.89 165.33 222.27 166.95 220.28 166.95C218.29 166.95 216.67 165.33 216.67 163.34C216.67 161.35 218.29 159.73 220.28 159.73C222.27 159.73 223.89 161.35 223.89 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M238.33 163.34C238.33 165.33 236.71 166.95 234.72 166.95C232.73 166.95 231.11 165.33 231.11 163.34C231.11 161.35 232.73 159.73 234.72 159.73C236.71 159.73 238.33 161.35 238.33 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M252.78 163.34C252.78 165.33 251.16 166.95 249.17 166.95C247.18 166.95 245.56 165.33 245.56 163.34C245.56 161.35 247.18 159.73 249.17 159.73C251.16 159.73 252.78 161.35 252.78 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M267.22 163.34C267.22 165.33 265.6 166.95 263.61 166.95C261.62 166.95 260 165.33 260 163.34C260 161.35 261.62 159.73 263.61 159.73C265.6 159.73 267.22 161.35 267.22 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 163.34C281.66 165.33 280.04 166.95 278.05 166.95C276.06 166.95 274.44 165.33 274.44 163.34C274.44 161.35 276.06 159.73 278.05 159.73C280.04 159.73 281.66 161.35 281.66 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 163.34C296.11 165.33 294.49 166.95 292.5 166.95C290.51 166.95 288.89 165.33 288.89 163.34C288.89 161.35 290.51 159.73 292.5 159.73C294.49 159.73 296.11 161.35 296.11 163.34Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 163.34C310.55 165.33 308.93 166.95 306.94 166.95C304.95 166.95 303.33 165.33 303.33 163.34C303.33 161.35 304.95 159.73 306.94 159.73C308.93 159.73 310.55 161.35 310.55 163.34Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 163.34C324.99 165.33 323.37 166.95 321.38 166.95C319.39 166.95 317.77 165.33 317.77 163.34C317.77 161.35 319.39 159.73 321.38 159.73C323.37 159.73 324.99 161.35 324.99 163.34Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 163.34C339.44 165.33 337.82 166.95 335.83 166.95C333.84 166.95 332.22 165.33 332.22 163.34C332.22 161.35 333.84 159.73 335.83 159.73C337.82 159.73 339.44 161.35 339.44 163.34Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 163.34C353.88 165.33 352.26 166.95 350.27 166.95C348.28 166.95 346.66 165.33 346.66 163.34C346.66 161.35 348.28 159.73 350.27 159.73C352.26 159.73 353.88 161.35 353.88 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 163.34C368.32 165.33 366.7 166.95 364.71 166.95C362.72 166.95 361.1 165.33 361.1 163.34C361.1 161.35 362.72 159.73 364.71 159.73C366.7 159.73 368.32 161.35 368.32 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 163.34C382.77 165.33 381.15 166.95 379.16 166.95C377.17 166.95 375.55 165.33 375.55 163.34C375.55 161.35 377.17 159.73 379.16 159.73C381.15 159.73 382.77 161.35 382.77 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 163.34C397.21 165.33 395.59 166.95 393.6 166.95C391.61 166.95 389.99 165.33 389.99 163.34C389.99 161.35 391.61 159.73 393.6 159.73C395.59 159.73 397.21 161.35 397.21 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 163.34C411.65 165.33 410.03 166.95 408.04 166.95C406.05 166.95 404.43 165.33 404.43 163.34C404.43 161.35 406.05 159.73 408.04 159.73C410.03 159.73 411.65 161.35 411.65 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 163.34C426.1 165.33 424.48 166.95 422.49 166.95C420.5 166.95 418.88 165.33 418.88 163.34C418.88 161.35 420.5 159.73 422.49 159.73C424.48 159.73 426.1 161.35 426.1 163.34Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 177.78C21.68 179.77 20.06 181.39 18.07 181.39C16.08 181.39 14.46 179.77 14.46 177.78C14.46 175.79 16.08 174.17 18.07 174.17C20.06 174.17 21.68 175.79 21.68 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M36.1302 177.78C36.1302 179.77 34.5101 181.39 32.5201 181.39C30.5301 181.39 28.9102 179.77 28.9102 177.78C28.9102 175.79 30.5301 174.17 32.5201 174.17C34.5101 174.17 36.1302 175.79 36.1302 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M50.5701 177.78C50.5701 179.77 48.9501 181.39 46.9601 181.39C44.9701 181.39 43.3501 179.77 43.3501 177.78C43.3501 175.79 44.9701 174.17 46.9601 174.17C48.9501 174.17 50.5701 175.79 50.5701 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M65.01 177.78C65.01 179.77 63.39 181.39 61.4 181.39C59.41 181.39 57.79 179.77 57.79 177.78C57.79 175.79 59.41 174.17 61.4 174.17C63.39 174.17 65.01 175.79 65.01 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M79.4602 177.78C79.4602 179.77 77.8403 181.39 75.8503 181.39C73.8603 181.39 72.2402 179.77 72.2402 177.78C72.2402 175.79 73.8603 174.17 75.8503 174.17C77.8403 174.17 79.4602 175.79 79.4602 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M93.9002 177.78C93.9002 179.77 92.2802 181.39 90.2902 181.39C88.3002 181.39 86.6802 179.77 86.6802 177.78C86.6802 175.79 88.3002 174.17 90.2902 174.17C92.2802 174.17 93.9002 175.79 93.9002 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M108.34 177.78C108.34 179.77 106.72 181.39 104.73 181.39C102.74 181.39 101.12 179.77 101.12 177.78C101.12 175.79 102.74 174.17 104.73 174.17C106.72 174.17 108.34 175.79 108.34 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M122.79 177.78C122.79 179.77 121.17 181.39 119.18 181.39C117.19 181.39 115.57 179.77 115.57 177.78C115.57 175.79 117.19 174.17 119.18 174.17C121.17 174.17 122.79 175.79 122.79 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M137.23 177.78C137.23 179.77 135.61 181.39 133.62 181.39C131.63 181.39 130.01 179.77 130.01 177.78C130.01 175.79 131.63 174.17 133.62 174.17C135.61 174.17 137.23 175.79 137.23 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M151.67 177.78C151.67 179.77 150.05 181.39 148.06 181.39C146.07 181.39 144.45 179.77 144.45 177.78C144.45 175.79 146.07 174.17 148.06 174.17C150.05 174.17 151.67 175.79 151.67 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M166.12 177.78C166.12 179.77 164.5 181.39 162.51 181.39C160.52 181.39 158.9 179.77 158.9 177.78C158.9 175.79 160.52 174.17 162.51 174.17C164.5 174.17 166.12 175.79 166.12 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M180.56 177.78C180.56 179.77 178.94 181.39 176.95 181.39C174.96 181.39 173.34 179.77 173.34 177.78C173.34 175.79 174.96 174.17 176.95 174.17C178.94 174.17 180.56 175.79 180.56 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M195 177.78C195 179.77 193.38 181.39 191.39 181.39C189.4 181.39 187.78 179.77 187.78 177.78C187.78 175.79 189.4 174.17 191.39 174.17C193.38 174.17 195 175.79 195 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M209.45 177.78C209.45 179.77 207.83 181.39 205.84 181.39C203.85 181.39 202.23 179.77 202.23 177.78C202.23 175.79 203.85 174.17 205.84 174.17C207.83 174.17 209.45 175.79 209.45 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M223.89 177.78C223.89 179.77 222.27 181.39 220.28 181.39C218.29 181.39 216.67 179.77 216.67 177.78C216.67 175.79 218.29 174.17 220.28 174.17C222.27 174.17 223.89 175.79 223.89 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M238.33 177.78C238.33 179.77 236.71 181.39 234.72 181.39C232.73 181.39 231.11 179.77 231.11 177.78C231.11 175.79 232.73 174.17 234.72 174.17C236.71 174.17 238.33 175.79 238.33 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 177.78C252.78 179.77 251.16 181.39 249.17 181.39C247.18 181.39 245.56 179.77 245.56 177.78C245.56 175.79 247.18 174.17 249.17 174.17C251.16 174.17 252.78 175.79 252.78 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 177.78C267.22 179.77 265.6 181.39 263.61 181.39C261.62 181.39 260 179.77 260 177.78C260 175.79 261.62 174.17 263.61 174.17C265.6 174.17 267.22 175.79 267.22 177.78Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 177.78C281.66 179.77 280.04 181.39 278.05 181.39C276.06 181.39 274.44 179.77 274.44 177.78C274.44 175.79 276.06 174.17 278.05 174.17C280.04 174.17 281.66 175.79 281.66 177.78Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 177.78C296.11 179.77 294.49 181.39 292.5 181.39C290.51 181.39 288.89 179.77 288.89 177.78C288.89 175.79 290.51 174.17 292.5 174.17C294.49 174.17 296.11 175.79 296.11 177.78Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 177.78C310.55 179.77 308.93 181.39 306.94 181.39C304.95 181.39 303.33 179.77 303.33 177.78C303.33 175.79 304.95 174.17 306.94 174.17C308.93 174.17 310.55 175.79 310.55 177.78Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 177.78C324.99 179.77 323.37 181.39 321.38 181.39C319.39 181.39 317.77 179.77 317.77 177.78C317.77 175.79 319.39 174.17 321.38 174.17C323.37 174.17 324.99 175.79 324.99 177.78Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 177.78C339.44 179.77 337.82 181.39 335.83 181.39C333.84 181.39 332.22 179.77 332.22 177.78C332.22 175.79 333.84 174.17 335.83 174.17C337.82 174.17 339.44 175.79 339.44 177.78Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 177.78C353.88 179.77 352.26 181.39 350.27 181.39C348.28 181.39 346.66 179.77 346.66 177.78C346.66 175.79 348.28 174.17 350.27 174.17C352.26 174.17 353.88 175.79 353.88 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 177.78C368.32 179.77 366.7 181.39 364.71 181.39C362.72 181.39 361.1 179.77 361.1 177.78C361.1 175.79 362.72 174.17 364.71 174.17C366.7 174.17 368.32 175.79 368.32 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 177.78C382.77 179.77 381.15 181.39 379.16 181.39C377.17 181.39 375.55 179.77 375.55 177.78C375.55 175.79 377.17 174.17 379.16 174.17C381.15 174.17 382.77 175.79 382.77 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 177.78C397.21 179.77 395.59 181.39 393.6 181.39C391.61 181.39 389.99 179.77 389.99 177.78C389.99 175.79 391.61 174.17 393.6 174.17C395.59 174.17 397.21 175.79 397.21 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 177.78C411.65 179.77 410.03 181.39 408.04 181.39C406.05 181.39 404.43 179.77 404.43 177.78C404.43 175.79 406.05 174.17 408.04 174.17C410.03 174.17 411.65 175.79 411.65 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 177.78C426.1 179.77 424.48 181.39 422.49 181.39C420.5 181.39 418.88 179.77 418.88 177.78C418.88 175.79 420.5 174.17 422.49 174.17C424.48 174.17 426.1 175.79 426.1 177.78Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 192.23C21.68 194.22 20.06 195.84 18.07 195.84C16.08 195.84 14.46 194.22 14.46 192.23C14.46 190.24 16.08 188.62 18.07 188.62C20.06 188.62 21.68 190.24 21.68 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M36.1302 192.23C36.1302 194.22 34.5101 195.84 32.5201 195.84C30.5301 195.84 28.9102 194.22 28.9102 192.23C28.9102 190.24 30.5301 188.62 32.5201 188.62C34.5101 188.62 36.1302 190.24 36.1302 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M50.5701 192.23C50.5701 194.22 48.9501 195.84 46.9601 195.84C44.9701 195.84 43.3501 194.22 43.3501 192.23C43.3501 190.24 44.9701 188.62 46.9601 188.62C48.9501 188.62 50.5701 190.24 50.5701 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M65.01 192.23C65.01 194.22 63.39 195.84 61.4 195.84C59.41 195.84 57.79 194.22 57.79 192.23C57.79 190.24 59.41 188.62 61.4 188.62C63.39 188.62 65.01 190.24 65.01 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M79.4602 192.23C79.4602 194.22 77.8403 195.84 75.8503 195.84C73.8603 195.84 72.2402 194.22 72.2402 192.23C72.2402 190.24 73.8603 188.62 75.8503 188.62C77.8403 188.62 79.4602 190.24 79.4602 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M93.9002 192.23C93.9002 194.22 92.2802 195.84 90.2902 195.84C88.3002 195.84 86.6802 194.22 86.6802 192.23C86.6802 190.24 88.3002 188.62 90.2902 188.62C92.2802 188.62 93.9002 190.24 93.9002 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M108.34 192.23C108.34 194.22 106.72 195.84 104.73 195.84C102.74 195.84 101.12 194.22 101.12 192.23C101.12 190.24 102.74 188.62 104.73 188.62C106.72 188.62 108.34 190.24 108.34 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M122.79 192.23C122.79 194.22 121.17 195.84 119.18 195.84C117.19 195.84 115.57 194.22 115.57 192.23C115.57 190.24 117.19 188.62 119.18 188.62C121.17 188.62 122.79 190.24 122.79 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M137.23 192.23C137.23 194.22 135.61 195.84 133.62 195.84C131.63 195.84 130.01 194.22 130.01 192.23C130.01 190.24 131.63 188.62 133.62 188.62C135.61 188.62 137.23 190.24 137.23 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M151.67 192.23C151.67 194.22 150.05 195.84 148.06 195.84C146.07 195.84 144.45 194.22 144.45 192.23C144.45 190.24 146.07 188.62 148.06 188.62C150.05 188.62 151.67 190.24 151.67 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M166.12 192.23C166.12 194.22 164.5 195.84 162.51 195.84C160.52 195.84 158.9 194.22 158.9 192.23C158.9 190.24 160.52 188.62 162.51 188.62C164.5 188.62 166.12 190.24 166.12 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M180.56 192.23C180.56 194.22 178.94 195.84 176.95 195.84C174.96 195.84 173.34 194.22 173.34 192.23C173.34 190.24 174.96 188.62 176.95 188.62C178.94 188.62 180.56 190.24 180.56 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M195 192.23C195 194.22 193.38 195.84 191.39 195.84C189.4 195.84 187.78 194.22 187.78 192.23C187.78 190.24 189.4 188.62 191.39 188.62C193.38 188.62 195 190.24 195 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M209.45 192.23C209.45 194.22 207.83 195.84 205.84 195.84C203.85 195.84 202.23 194.22 202.23 192.23C202.23 190.24 203.85 188.62 205.84 188.62C207.83 188.62 209.45 190.24 209.45 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M223.89 192.23C223.89 194.22 222.27 195.84 220.28 195.84C218.29 195.84 216.67 194.22 216.67 192.23C216.67 190.24 218.29 188.62 220.28 188.62C222.27 188.62 223.89 190.24 223.89 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M238.33 192.23C238.33 194.22 236.71 195.84 234.72 195.84C232.73 195.84 231.11 194.22 231.11 192.23C231.11 190.24 232.73 188.62 234.72 188.62C236.71 188.62 238.33 190.24 238.33 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 192.23C252.78 194.22 251.16 195.84 249.17 195.84C247.18 195.84 245.56 194.22 245.56 192.23C245.56 190.24 247.18 188.62 249.17 188.62C251.16 188.62 252.78 190.24 252.78 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 192.23C267.22 194.22 265.6 195.84 263.61 195.84C261.62 195.84 260 194.22 260 192.23C260 190.24 261.62 188.62 263.61 188.62C265.6 188.62 267.22 190.24 267.22 192.23Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 192.23C281.66 194.22 280.04 195.84 278.05 195.84C276.06 195.84 274.44 194.22 274.44 192.23C274.44 190.24 276.06 188.62 278.05 188.62C280.04 188.62 281.66 190.24 281.66 192.23Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 192.23C296.11 194.22 294.49 195.84 292.5 195.84C290.51 195.84 288.89 194.22 288.89 192.23C288.89 190.24 290.51 188.62 292.5 188.62C294.49 188.62 296.11 190.24 296.11 192.23Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 192.23C310.55 194.22 308.93 195.84 306.94 195.84C304.95 195.84 303.33 194.22 303.33 192.23C303.33 190.24 304.95 188.62 306.94 188.62C308.93 188.62 310.55 190.24 310.55 192.23Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 192.23C324.99 194.22 323.37 195.84 321.38 195.84C319.39 195.84 317.77 194.22 317.77 192.23C317.77 190.24 319.39 188.62 321.38 188.62C323.37 188.62 324.99 190.24 324.99 192.23Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 192.23C339.44 194.22 337.82 195.84 335.83 195.84C333.84 195.84 332.22 194.22 332.22 192.23C332.22 190.24 333.84 188.62 335.83 188.62C337.82 188.62 339.44 190.24 339.44 192.23Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 192.23C353.88 194.22 352.26 195.84 350.27 195.84C348.28 195.84 346.66 194.22 346.66 192.23C346.66 190.24 348.28 188.62 350.27 188.62C352.26 188.62 353.88 190.24 353.88 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 192.23C368.32 194.22 366.7 195.84 364.71 195.84C362.72 195.84 361.1 194.22 361.1 192.23C361.1 190.24 362.72 188.62 364.71 188.62C366.7 188.62 368.32 190.24 368.32 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 192.23C382.77 194.22 381.15 195.84 379.16 195.84C377.17 195.84 375.55 194.22 375.55 192.23C375.55 190.24 377.17 188.62 379.16 188.62C381.15 188.62 382.77 190.24 382.77 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 192.23C397.21 194.22 395.59 195.84 393.6 195.84C391.61 195.84 389.99 194.22 389.99 192.23C389.99 190.24 391.61 188.62 393.6 188.62C395.59 188.62 397.21 190.24 397.21 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 192.23C411.65 194.22 410.03 195.84 408.04 195.84C406.05 195.84 404.43 194.22 404.43 192.23C404.43 190.24 406.05 188.62 408.04 188.62C410.03 188.62 411.65 190.24 411.65 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 192.23C426.1 194.22 424.48 195.84 422.49 195.84C420.5 195.84 418.88 194.22 418.88 192.23C418.88 190.24 420.5 188.62 422.49 188.62C424.48 188.62 426.1 190.24 426.1 192.23Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 206.67C21.68 208.66 20.06 210.28 18.07 210.28C16.08 210.28 14.46 208.66 14.46 206.67C14.46 204.68 16.08 203.06 18.07 203.06C20.06 203.06 21.68 204.68 21.68 206.67Z"
            fill="#E38135"
          />
          <path
            d="M36.1302 206.67C36.1302 208.66 34.5101 210.28 32.5201 210.28C30.5301 210.28 28.9102 208.66 28.9102 206.67C28.9102 204.68 30.5301 203.06 32.5201 203.06C34.5101 203.06 36.1302 204.68 36.1302 206.67Z"
            fill="#E38135"
          />
          <path
            d="M50.5701 206.67C50.5701 208.66 48.9501 210.28 46.9601 210.28C44.9701 210.28 43.3501 208.66 43.3501 206.67C43.3501 204.68 44.9701 203.06 46.9601 203.06C48.9501 203.06 50.5701 204.68 50.5701 206.67Z"
            fill="#E38135"
          />
          <path
            d="M65.01 206.67C65.01 208.66 63.39 210.28 61.4 210.28C59.41 210.28 57.79 208.66 57.79 206.67C57.79 204.68 59.41 203.06 61.4 203.06C63.39 203.06 65.01 204.68 65.01 206.67Z"
            fill="#E38135"
          />
          <path
            d="M79.4602 206.67C79.4602 208.66 77.8403 210.28 75.8503 210.28C73.8603 210.28 72.2402 208.66 72.2402 206.67C72.2402 204.68 73.8603 203.06 75.8503 203.06C77.8403 203.06 79.4602 204.68 79.4602 206.67Z"
            fill="#E38135"
          />
          <path
            d="M93.9002 206.67C93.9002 208.66 92.2802 210.28 90.2902 210.28C88.3002 210.28 86.6802 208.66 86.6802 206.67C86.6802 204.68 88.3002 203.06 90.2902 203.06C92.2802 203.06 93.9002 204.68 93.9002 206.67Z"
            fill="#E38135"
          />
          <path
            d="M108.34 206.67C108.34 208.66 106.72 210.28 104.73 210.28C102.74 210.28 101.12 208.66 101.12 206.67C101.12 204.68 102.74 203.06 104.73 203.06C106.72 203.06 108.34 204.68 108.34 206.67Z"
            fill="#E38135"
          />
          <path
            d="M122.79 206.67C122.79 208.66 121.17 210.28 119.18 210.28C117.19 210.28 115.57 208.66 115.57 206.67C115.57 204.68 117.19 203.06 119.18 203.06C121.17 203.06 122.79 204.68 122.79 206.67Z"
            fill="#E38135"
          />
          <path
            d="M137.23 206.67C137.23 208.66 135.61 210.28 133.62 210.28C131.63 210.28 130.01 208.66 130.01 206.67C130.01 204.68 131.63 203.06 133.62 203.06C135.61 203.06 137.23 204.68 137.23 206.67Z"
            fill="#E38135"
          />
          <path
            d="M151.67 206.67C151.67 208.66 150.05 210.28 148.06 210.28C146.07 210.28 144.45 208.66 144.45 206.67C144.45 204.68 146.07 203.06 148.06 203.06C150.05 203.06 151.67 204.68 151.67 206.67Z"
            fill="#E38135"
          />
          <path
            d="M166.12 206.67C166.12 208.66 164.5 210.28 162.51 210.28C160.52 210.28 158.9 208.66 158.9 206.67C158.9 204.68 160.52 203.06 162.51 203.06C164.5 203.06 166.12 204.68 166.12 206.67Z"
            fill="#E38135"
          />
          <path
            d="M180.56 206.67C180.56 208.66 178.94 210.28 176.95 210.28C174.96 210.28 173.34 208.66 173.34 206.67C173.34 204.68 174.96 203.06 176.95 203.06C178.94 203.06 180.56 204.68 180.56 206.67Z"
            fill="#E38135"
          />
          <path
            d="M195 206.67C195 208.66 193.38 210.28 191.39 210.28C189.4 210.28 187.78 208.66 187.78 206.67C187.78 204.68 189.4 203.06 191.39 203.06C193.38 203.06 195 204.68 195 206.67Z"
            fill="#E38135"
          />
          <path
            d="M209.45 206.67C209.45 208.66 207.83 210.28 205.84 210.28C203.85 210.28 202.23 208.66 202.23 206.67C202.23 204.68 203.85 203.06 205.84 203.06C207.83 203.06 209.45 204.68 209.45 206.67Z"
            fill="#E38135"
          />
          <path
            d="M223.89 206.67C223.89 208.66 222.27 210.28 220.28 210.28C218.29 210.28 216.67 208.66 216.67 206.67C216.67 204.68 218.29 203.06 220.28 203.06C222.27 203.06 223.89 204.68 223.89 206.67Z"
            fill="#E38135"
          />
          <path
            d="M238.33 206.67C238.33 208.66 236.71 210.28 234.72 210.28C232.73 210.28 231.11 208.66 231.11 206.67C231.11 204.68 232.73 203.06 234.72 203.06C236.71 203.06 238.33 204.68 238.33 206.67Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 206.67C252.78 208.66 251.16 210.28 249.17 210.28C247.18 210.28 245.56 208.66 245.56 206.67C245.56 204.68 247.18 203.06 249.17 203.06C251.16 203.06 252.78 204.68 252.78 206.67Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 206.67C267.22 208.66 265.6 210.28 263.61 210.28C261.62 210.28 260 208.66 260 206.67C260 204.68 261.62 203.06 263.61 203.06C265.6 203.06 267.22 204.68 267.22 206.67Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 206.67C281.66 208.66 280.04 210.28 278.05 210.28C276.06 210.28 274.44 208.66 274.44 206.67C274.44 204.68 276.06 203.06 278.05 203.06C280.04 203.06 281.66 204.68 281.66 206.67Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 206.67C296.11 208.66 294.49 210.28 292.5 210.28C290.51 210.28 288.89 208.66 288.89 206.67C288.89 204.68 290.51 203.06 292.5 203.06C294.49 203.06 296.11 204.68 296.11 206.67Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 206.67C310.55 208.66 308.93 210.28 306.94 210.28C304.95 210.28 303.33 208.66 303.33 206.67C303.33 204.68 304.95 203.06 306.94 203.06C308.93 203.06 310.55 204.68 310.55 206.67Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 206.67C324.99 208.66 323.37 210.28 321.38 210.28C319.39 210.28 317.77 208.66 317.77 206.67C317.77 204.68 319.39 203.06 321.38 203.06C323.37 203.06 324.99 204.68 324.99 206.67Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 206.67C339.44 208.66 337.82 210.28 335.83 210.28C333.84 210.28 332.22 208.66 332.22 206.67C332.22 204.68 333.84 203.06 335.83 203.06C337.82 203.06 339.44 204.68 339.44 206.67Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 206.67C353.88 208.66 352.26 210.28 350.27 210.28C348.28 210.28 346.66 208.66 346.66 206.67C346.66 204.68 348.28 203.06 350.27 203.06C352.26 203.06 353.88 204.68 353.88 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 206.67C368.32 208.66 366.7 210.28 364.71 210.28C362.72 210.28 361.1 208.66 361.1 206.67C361.1 204.68 362.72 203.06 364.71 203.06C366.7 203.06 368.32 204.68 368.32 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 206.67C382.77 208.66 381.15 210.28 379.16 210.28C377.17 210.28 375.55 208.66 375.55 206.67C375.55 204.68 377.17 203.06 379.16 203.06C381.15 203.06 382.77 204.68 382.77 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 206.67C397.21 208.66 395.59 210.28 393.6 210.28C391.61 210.28 389.99 208.66 389.99 206.67C389.99 204.68 391.61 203.06 393.6 203.06C395.59 203.06 397.21 204.68 397.21 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 206.67C411.65 208.66 410.03 210.28 408.04 210.28C406.05 210.28 404.43 208.66 404.43 206.67C404.43 204.68 406.05 203.06 408.04 203.06C410.03 203.06 411.65 204.68 411.65 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 206.67C426.1 208.66 424.48 210.28 422.49 210.28C420.5 210.28 418.88 208.66 418.88 206.67C418.88 204.68 420.5 203.06 422.49 203.06C424.48 203.06 426.1 204.68 426.1 206.67Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 221.11C21.68 223.1 20.06 224.72 18.07 224.72C16.08 224.72 14.46 223.1 14.46 221.11C14.46 219.12 16.08 217.5 18.07 217.5C20.06 217.5 21.68 219.12 21.68 221.11Z"
            fill="#E38135"
          />
          <path
            d="M36.1302 221.11C36.1302 223.1 34.5101 224.72 32.5201 224.72C30.5301 224.72 28.9102 223.1 28.9102 221.11C28.9102 219.12 30.5301 217.5 32.5201 217.5C34.5101 217.5 36.1302 219.12 36.1302 221.11Z"
            fill="#E38135"
          />
          <path
            d="M50.5701 221.11C50.5701 223.1 48.9501 224.72 46.9601 224.72C44.9701 224.72 43.3501 223.1 43.3501 221.11C43.3501 219.12 44.9701 217.5 46.9601 217.5C48.9501 217.5 50.5701 219.12 50.5701 221.11Z"
            fill="#E38135"
          />
          <path
            d="M65.01 221.11C65.01 223.1 63.39 224.72 61.4 224.72C59.41 224.72 57.79 223.1 57.79 221.11C57.79 219.12 59.41 217.5 61.4 217.5C63.39 217.5 65.01 219.12 65.01 221.11Z"
            fill="#E38135"
          />
          <path
            d="M79.4602 221.11C79.4602 223.1 77.8403 224.72 75.8503 224.72C73.8603 224.72 72.2402 223.1 72.2402 221.11C72.2402 219.12 73.8603 217.5 75.8503 217.5C77.8403 217.5 79.4602 219.12 79.4602 221.11Z"
            fill="#E38135"
          />
          <path
            d="M93.9002 221.11C93.9002 223.1 92.2802 224.72 90.2902 224.72C88.3002 224.72 86.6802 223.1 86.6802 221.11C86.6802 219.12 88.3002 217.5 90.2902 217.5C92.2802 217.5 93.9002 219.12 93.9002 221.11Z"
            fill="#E38135"
          />
          <path
            d="M108.34 221.11C108.34 223.1 106.72 224.72 104.73 224.72C102.74 224.72 101.12 223.1 101.12 221.11C101.12 219.12 102.74 217.5 104.73 217.5C106.72 217.5 108.34 219.12 108.34 221.11Z"
            fill="#E38135"
          />
          <path
            d="M122.79 221.11C122.79 223.1 121.17 224.72 119.18 224.72C117.19 224.72 115.57 223.1 115.57 221.11C115.57 219.12 117.19 217.5 119.18 217.5C121.17 217.5 122.79 219.12 122.79 221.11Z"
            fill="#E38135"
          />
          <path
            d="M137.23 221.11C137.23 223.1 135.61 224.72 133.62 224.72C131.63 224.72 130.01 223.1 130.01 221.11C130.01 219.12 131.63 217.5 133.62 217.5C135.61 217.5 137.23 219.12 137.23 221.11Z"
            fill="#E38135"
          />
          <path
            d="M151.67 221.11C151.67 223.1 150.05 224.72 148.06 224.72C146.07 224.72 144.45 223.1 144.45 221.11C144.45 219.12 146.07 217.5 148.06 217.5C150.05 217.5 151.67 219.12 151.67 221.11Z"
            fill="#E38135"
          />
          <path
            d="M166.12 221.11C166.12 223.1 164.5 224.72 162.51 224.72C160.52 224.72 158.9 223.1 158.9 221.11C158.9 219.12 160.52 217.5 162.51 217.5C164.5 217.5 166.12 219.12 166.12 221.11Z"
            fill="#E38135"
          />
          <path
            d="M180.56 221.11C180.56 223.1 178.94 224.72 176.95 224.72C174.96 224.72 173.34 223.1 173.34 221.11C173.34 219.12 174.96 217.5 176.95 217.5C178.94 217.5 180.56 219.12 180.56 221.11Z"
            fill="#E38135"
          />
          <path
            d="M195 221.11C195 223.1 193.38 224.72 191.39 224.72C189.4 224.72 187.78 223.1 187.78 221.11C187.78 219.12 189.4 217.5 191.39 217.5C193.38 217.5 195 219.12 195 221.11Z"
            fill="#E38135"
          />
          <path
            d="M209.45 221.11C209.45 223.1 207.83 224.72 205.84 224.72C203.85 224.72 202.23 223.1 202.23 221.11C202.23 219.12 203.85 217.5 205.84 217.5C207.83 217.5 209.45 219.12 209.45 221.11Z"
            fill="#E38135"
          />
          <path
            d="M223.89 221.11C223.89 223.1 222.27 224.72 220.28 224.72C218.29 224.72 216.67 223.1 216.67 221.11C216.67 219.12 218.29 217.5 220.28 217.5C222.27 217.5 223.89 219.12 223.89 221.11Z"
            fill="#E38135"
          />
          <path
            d="M238.33 221.11C238.33 223.1 236.71 224.72 234.72 224.72C232.73 224.72 231.11 223.1 231.11 221.11C231.11 219.12 232.73 217.5 234.72 217.5C236.71 217.5 238.33 219.12 238.33 221.11Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 221.11C252.78 223.1 251.16 224.72 249.17 224.72C247.18 224.72 245.56 223.1 245.56 221.11C245.56 219.12 247.18 217.5 249.17 217.5C251.16 217.5 252.78 219.12 252.78 221.11Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 221.11C267.22 223.1 265.6 224.72 263.61 224.72C261.62 224.72 260 223.1 260 221.11C260 219.12 261.62 217.5 263.61 217.5C265.6 217.5 267.22 219.12 267.22 221.11Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 221.11C281.66 223.1 280.04 224.72 278.05 224.72C276.06 224.72 274.44 223.1 274.44 221.11C274.44 219.12 276.06 217.5 278.05 217.5C280.04 217.5 281.66 219.12 281.66 221.11Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 221.11C296.11 223.1 294.49 224.72 292.5 224.72C290.51 224.72 288.89 223.1 288.89 221.11C288.89 219.12 290.51 217.5 292.5 217.5C294.49 217.5 296.11 219.12 296.11 221.11Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 221.11C310.55 223.1 308.93 224.72 306.94 224.72C304.95 224.72 303.33 223.1 303.33 221.11C303.33 219.12 304.95 217.5 306.94 217.5C308.93 217.5 310.55 219.12 310.55 221.11Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 221.11C324.99 223.1 323.37 224.72 321.38 224.72C319.39 224.72 317.77 223.1 317.77 221.11C317.77 219.12 319.39 217.5 321.38 217.5C323.37 217.5 324.99 219.12 324.99 221.11Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 221.11C339.44 223.1 337.82 224.72 335.83 224.72C333.84 224.72 332.22 223.1 332.22 221.11C332.22 219.12 333.84 217.5 335.83 217.5C337.82 217.5 339.44 219.12 339.44 221.11Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 221.11C353.88 223.1 352.26 224.72 350.27 224.72C348.28 224.72 346.66 223.1 346.66 221.11C346.66 219.12 348.28 217.5 350.27 217.5C352.26 217.5 353.88 219.12 353.88 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 221.11C368.32 223.1 366.7 224.72 364.71 224.72C362.72 224.72 361.1 223.1 361.1 221.11C361.1 219.12 362.72 217.5 364.71 217.5C366.7 217.5 368.32 219.12 368.32 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 221.11C382.77 223.1 381.15 224.72 379.16 224.72C377.17 224.72 375.55 223.1 375.55 221.11C375.55 219.12 377.17 217.5 379.16 217.5C381.15 217.5 382.77 219.12 382.77 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 221.11C397.21 223.1 395.59 224.72 393.6 224.72C391.61 224.72 389.99 223.1 389.99 221.11C389.99 219.12 391.61 217.5 393.6 217.5C395.59 217.5 397.21 219.12 397.21 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 221.11C411.65 223.1 410.03 224.72 408.04 224.72C406.05 224.72 404.43 223.1 404.43 221.11C404.43 219.12 406.05 217.5 408.04 217.5C410.03 217.5 411.65 219.12 411.65 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 221.11C426.1 223.1 424.48 224.72 422.49 224.72C420.5 224.72 418.88 223.1 418.88 221.11C418.88 219.12 420.5 217.5 422.49 217.5C424.48 217.5 426.1 219.12 426.1 221.11Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 235.56C21.68 237.55 20.06 239.17 18.07 239.17C16.08 239.17 14.46 237.55 14.46 235.56C14.46 233.57 16.08 231.95 18.07 231.95C20.06 231.95 21.68 233.57 21.68 235.56Z"
            fill="#E38135"
          />
          <path
            d="M36.1302 235.56C36.1302 237.55 34.5101 239.17 32.5201 239.17C30.5301 239.17 28.9102 237.55 28.9102 235.56C28.9102 233.57 30.5301 231.95 32.5201 231.95C34.5101 231.95 36.1302 233.57 36.1302 235.56Z"
            fill="#E38135"
          />
          <path
            d="M50.5701 235.56C50.5701 237.55 48.9501 239.17 46.9601 239.17C44.9701 239.17 43.3501 237.55 43.3501 235.56C43.3501 233.57 44.9701 231.95 46.9601 231.95C48.9501 231.95 50.5701 233.57 50.5701 235.56Z"
            fill="#E38135"
          />
          <path
            d="M65.01 235.56C65.01 237.55 63.39 239.17 61.4 239.17C59.41 239.17 57.79 237.55 57.79 235.56C57.79 233.57 59.41 231.95 61.4 231.95C63.39 231.95 65.01 233.57 65.01 235.56Z"
            fill="#E38135"
          />
          <path
            d="M79.4602 235.56C79.4602 237.55 77.8403 239.17 75.8503 239.17C73.8603 239.17 72.2402 237.55 72.2402 235.56C72.2402 233.57 73.8603 231.95 75.8503 231.95C77.8403 231.95 79.4602 233.57 79.4602 235.56Z"
            fill="#E38135"
          />
          <path
            d="M93.9002 235.56C93.9002 237.55 92.2802 239.17 90.2902 239.17C88.3002 239.17 86.6802 237.55 86.6802 235.56C86.6802 233.57 88.3002 231.95 90.2902 231.95C92.2802 231.95 93.9002 233.57 93.9002 235.56Z"
            fill="#E38135"
          />
          <path
            d="M108.34 235.56C108.34 237.55 106.72 239.17 104.73 239.17C102.74 239.17 101.12 237.55 101.12 235.56C101.12 233.57 102.74 231.95 104.73 231.95C106.72 231.95 108.34 233.57 108.34 235.56Z"
            fill="#E38135"
          />
          <path
            d="M122.79 235.56C122.79 237.55 121.17 239.17 119.18 239.17C117.19 239.17 115.57 237.55 115.57 235.56C115.57 233.57 117.19 231.95 119.18 231.95C121.17 231.95 122.79 233.57 122.79 235.56Z"
            fill="#E38135"
          />
          <path
            d="M137.23 235.56C137.23 237.55 135.61 239.17 133.62 239.17C131.63 239.17 130.01 237.55 130.01 235.56C130.01 233.57 131.63 231.95 133.62 231.95C135.61 231.95 137.23 233.57 137.23 235.56Z"
            fill="#E38135"
          />
          <path
            d="M151.67 235.56C151.67 237.55 150.05 239.17 148.06 239.17C146.07 239.17 144.45 237.55 144.45 235.56C144.45 233.57 146.07 231.95 148.06 231.95C150.05 231.95 151.67 233.57 151.67 235.56Z"
            fill="#E38135"
          />
          <path
            d="M166.12 235.56C166.12 237.55 164.5 239.17 162.51 239.17C160.52 239.17 158.9 237.55 158.9 235.56C158.9 233.57 160.52 231.95 162.51 231.95C164.5 231.95 166.12 233.57 166.12 235.56Z"
            fill="#E38135"
          />
          <path
            d="M180.56 235.56C180.56 237.55 178.94 239.17 176.95 239.17C174.96 239.17 173.34 237.55 173.34 235.56C173.34 233.57 174.96 231.95 176.95 231.95C178.94 231.95 180.56 233.57 180.56 235.56Z"
            fill="#E38135"
          />
          <path
            d="M195 235.56C195 237.55 193.38 239.17 191.39 239.17C189.4 239.17 187.78 237.55 187.78 235.56C187.78 233.57 189.4 231.95 191.39 231.95C193.38 231.95 195 233.57 195 235.56Z"
            fill="#E38135"
          />
          <path
            d="M209.45 235.56C209.45 237.55 207.83 239.17 205.84 239.17C203.85 239.17 202.23 237.55 202.23 235.56C202.23 233.57 203.85 231.95 205.84 231.95C207.83 231.95 209.45 233.57 209.45 235.56Z"
            fill="#E38135"
          />
          <path
            d="M223.89 235.56C223.89 237.55 222.27 239.17 220.28 239.17C218.29 239.17 216.67 237.55 216.67 235.56C216.67 233.57 218.29 231.95 220.28 231.95C222.27 231.95 223.89 233.57 223.89 235.56Z"
            fill="#E38135"
          />
          <path
            d="M238.33 235.56C238.33 237.55 236.71 239.17 234.72 239.17C232.73 239.17 231.11 237.55 231.11 235.56C231.11 233.57 232.73 231.95 234.72 231.95C236.71 231.95 238.33 233.57 238.33 235.56Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 235.56C252.78 237.55 251.16 239.17 249.17 239.17C247.18 239.17 245.56 237.55 245.56 235.56C245.56 233.57 247.18 231.95 249.17 231.95C251.16 231.95 252.78 233.57 252.78 235.56Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 235.56C267.22 237.55 265.6 239.17 263.61 239.17C261.62 239.17 260 237.55 260 235.56C260 233.57 261.62 231.95 263.61 231.95C265.6 231.95 267.22 233.57 267.22 235.56Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 235.56C281.66 237.55 280.04 239.17 278.05 239.17C276.06 239.17 274.44 237.55 274.44 235.56C274.44 233.57 276.06 231.95 278.05 231.95C280.04 231.95 281.66 233.57 281.66 235.56Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 235.56C296.11 237.55 294.49 239.17 292.5 239.17C290.51 239.17 288.89 237.55 288.89 235.56C288.89 233.57 290.51 231.95 292.5 231.95C294.49 231.95 296.11 233.57 296.11 235.56Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 235.56C310.55 237.55 308.93 239.17 306.94 239.17C304.95 239.17 303.33 237.55 303.33 235.56C303.33 233.57 304.95 231.95 306.94 231.95C308.93 231.95 310.55 233.57 310.55 235.56Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 235.56C324.99 237.55 323.37 239.17 321.38 239.17C319.39 239.17 317.77 237.55 317.77 235.56C317.77 233.57 319.39 231.95 321.38 231.95C323.37 231.95 324.99 233.57 324.99 235.56Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 235.56C339.44 237.55 337.82 239.17 335.83 239.17C333.84 239.17 332.22 237.55 332.22 235.56C332.22 233.57 333.84 231.95 335.83 231.95C337.82 231.95 339.44 233.57 339.44 235.56Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 235.56C353.88 237.55 352.26 239.17 350.27 239.17C348.28 239.17 346.66 237.55 346.66 235.56C346.66 233.57 348.28 231.95 350.27 231.95C352.26 231.95 353.88 233.57 353.88 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 235.56C368.32 237.55 366.7 239.17 364.71 239.17C362.72 239.17 361.1 237.55 361.1 235.56C361.1 233.57 362.72 231.95 364.71 231.95C366.7 231.95 368.32 233.57 368.32 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 235.56C382.77 237.55 381.15 239.17 379.16 239.17C377.17 239.17 375.55 237.55 375.55 235.56C375.55 233.57 377.17 231.95 379.16 231.95C381.15 231.95 382.77 233.57 382.77 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 235.56C397.21 237.55 395.59 239.17 393.6 239.17C391.61 239.17 389.99 237.55 389.99 235.56C389.99 233.57 391.61 231.95 393.6 231.95C395.59 231.95 397.21 233.57 397.21 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 235.56C411.65 237.55 410.03 239.17 408.04 239.17C406.05 239.17 404.43 237.55 404.43 235.56C404.43 233.57 406.05 231.95 408.04 231.95C410.03 231.95 411.65 233.57 411.65 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 235.56C426.1 237.55 424.48 239.17 422.49 239.17C420.5 239.17 418.88 237.55 418.88 235.56C418.88 233.57 420.5 231.95 422.49 231.95C424.48 231.95 426.1 233.57 426.1 235.56Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 250C21.68 251.99 20.06 253.61 18.07 253.61C16.08 253.61 14.46 251.99 14.46 250C14.46 248.01 16.08 246.39 18.07 246.39C20.06 246.39 21.68 248.01 21.68 250Z"
            fill="#E38135"
          />
          <path
            d="M36.1302 250C36.1302 251.99 34.5101 253.61 32.5201 253.61C30.5301 253.61 28.9102 251.99 28.9102 250C28.9102 248.01 30.5301 246.39 32.5201 246.39C34.5101 246.39 36.1302 248.01 36.1302 250Z"
            fill="#E38135"
          />
          <path
            d="M50.5701 250C50.5701 251.99 48.9501 253.61 46.9601 253.61C44.9701 253.61 43.3501 251.99 43.3501 250C43.3501 248.01 44.9701 246.39 46.9601 246.39C48.9501 246.39 50.5701 248.01 50.5701 250Z"
            fill="#E38135"
          />
          <path
            d="M65.01 250C65.01 251.99 63.39 253.61 61.4 253.61C59.41 253.61 57.79 251.99 57.79 250C57.79 248.01 59.41 246.39 61.4 246.39C63.39 246.39 65.01 248.01 65.01 250Z"
            fill="#E38135"
          />
          <path
            d="M79.4602 250C79.4602 251.99 77.8403 253.61 75.8503 253.61C73.8603 253.61 72.2402 251.99 72.2402 250C72.2402 248.01 73.8603 246.39 75.8503 246.39C77.8403 246.39 79.4602 248.01 79.4602 250Z"
            fill="#E38135"
          />
          <path
            d="M93.9002 250C93.9002 251.99 92.2802 253.61 90.2902 253.61C88.3002 253.61 86.6802 251.99 86.6802 250C86.6802 248.01 88.3002 246.39 90.2902 246.39C92.2802 246.39 93.9002 248.01 93.9002 250Z"
            fill="#E38135"
          />
          <path
            d="M108.34 250C108.34 251.99 106.72 253.61 104.73 253.61C102.74 253.61 101.12 251.99 101.12 250C101.12 248.01 102.74 246.39 104.73 246.39C106.72 246.39 108.34 248.01 108.34 250Z"
            fill="#E38135"
          />
          <path
            d="M122.79 250C122.79 251.99 121.17 253.61 119.18 253.61C117.19 253.61 115.57 251.99 115.57 250C115.57 248.01 117.19 246.39 119.18 246.39C121.17 246.39 122.79 248.01 122.79 250Z"
            fill="#E38135"
          />
          <path
            d="M137.23 250C137.23 251.99 135.61 253.61 133.62 253.61C131.63 253.61 130.01 251.99 130.01 250C130.01 248.01 131.63 246.39 133.62 246.39C135.61 246.39 137.23 248.01 137.23 250Z"
            fill="#E38135"
          />
          <path
            d="M151.67 250C151.67 251.99 150.05 253.61 148.06 253.61C146.07 253.61 144.45 251.99 144.45 250C144.45 248.01 146.07 246.39 148.06 246.39C150.05 246.39 151.67 248.01 151.67 250Z"
            fill="#E38135"
          />
          <path
            d="M166.12 250C166.12 251.99 164.5 253.61 162.51 253.61C160.52 253.61 158.9 251.99 158.9 250C158.9 248.01 160.52 246.39 162.51 246.39C164.5 246.39 166.12 248.01 166.12 250Z"
            fill="#E38135"
          />
          <path
            d="M180.56 250C180.56 251.99 178.94 253.61 176.95 253.61C174.96 253.61 173.34 251.99 173.34 250C173.34 248.01 174.96 246.39 176.95 246.39C178.94 246.39 180.56 248.01 180.56 250Z"
            fill="#E38135"
          />
          <path
            d="M195 250C195 251.99 193.38 253.61 191.39 253.61C189.4 253.61 187.78 251.99 187.78 250C187.78 248.01 189.4 246.39 191.39 246.39C193.38 246.39 195 248.01 195 250Z"
            fill="#E38135"
          />
          <path
            d="M209.45 250C209.45 251.99 207.83 253.61 205.84 253.61C203.85 253.61 202.23 251.99 202.23 250C202.23 248.01 203.85 246.39 205.84 246.39C207.83 246.39 209.45 248.01 209.45 250Z"
            fill="#E38135"
          />
          <path
            d="M223.89 250C223.89 251.99 222.27 253.61 220.28 253.61C218.29 253.61 216.67 251.99 216.67 250C216.67 248.01 218.29 246.39 220.28 246.39C222.27 246.39 223.89 248.01 223.89 250Z"
            fill="#E38135"
          />
          <path
            d="M238.33 250C238.33 251.99 236.71 253.61 234.72 253.61C232.73 253.61 231.11 251.99 231.11 250C231.11 248.01 232.73 246.39 234.72 246.39C236.71 246.39 238.33 248.01 238.33 250Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 250C252.78 251.99 251.16 253.61 249.17 253.61C247.18 253.61 245.56 251.99 245.56 250C245.56 248.01 247.18 246.39 249.17 246.39C251.16 246.39 252.78 248.01 252.78 250Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 250C267.22 251.99 265.6 253.61 263.61 253.61C261.62 253.61 260 251.99 260 250C260 248.01 261.62 246.39 263.61 246.39C265.6 246.39 267.22 248.01 267.22 250Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 250C281.66 251.99 280.04 253.61 278.05 253.61C276.06 253.61 274.44 251.99 274.44 250C274.44 248.01 276.06 246.39 278.05 246.39C280.04 246.39 281.66 248.01 281.66 250Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 250C296.11 251.99 294.49 253.61 292.5 253.61C290.51 253.61 288.89 251.99 288.89 250C288.89 248.01 290.51 246.39 292.5 246.39C294.49 246.39 296.11 248.01 296.11 250Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 250C310.55 251.99 308.93 253.61 306.94 253.61C304.95 253.61 303.33 251.99 303.33 250C303.33 248.01 304.95 246.39 306.94 246.39C308.93 246.39 310.55 248.01 310.55 250Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 250C324.99 251.99 323.37 253.61 321.38 253.61C319.39 253.61 317.77 251.99 317.77 250C317.77 248.01 319.39 246.39 321.38 246.39C323.37 246.39 324.99 248.01 324.99 250Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 250C339.44 251.99 337.82 253.61 335.83 253.61C333.84 253.61 332.22 251.99 332.22 250C332.22 248.01 333.84 246.39 335.83 246.39C337.82 246.39 339.44 248.01 339.44 250Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 250C353.88 251.99 352.26 253.61 350.27 253.61C348.28 253.61 346.66 251.99 346.66 250C346.66 248.01 348.28 246.39 350.27 246.39C352.26 246.39 353.88 248.01 353.88 250Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 250C368.32 251.99 366.7 253.61 364.71 253.61C362.72 253.61 361.1 251.99 361.1 250C361.1 248.01 362.72 246.39 364.71 246.39C366.7 246.39 368.32 248.01 368.32 250Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 250C382.77 251.99 381.15 253.61 379.16 253.61C377.17 253.61 375.55 251.99 375.55 250C375.55 248.01 377.17 246.39 379.16 246.39C381.15 246.39 382.77 248.01 382.77 250Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 250C397.21 251.99 395.59 253.61 393.6 253.61C391.61 253.61 389.99 251.99 389.99 250C389.99 248.01 391.61 246.39 393.6 246.39C395.59 246.39 397.21 248.01 397.21 250Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 250C411.65 251.99 410.03 253.61 408.04 253.61C406.05 253.61 404.43 251.99 404.43 250C404.43 248.01 406.05 246.39 408.04 246.39C410.03 246.39 411.65 248.01 411.65 250Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 250C426.1 251.99 424.48 253.61 422.49 253.61C420.5 253.61 418.88 251.99 418.88 250C418.88 248.01 420.5 246.39 422.49 246.39C424.48 246.39 426.1 248.01 426.1 250Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 264.44C21.68 266.43 20.06 268.05 18.07 268.05C16.08 268.05 14.46 266.43 14.46 264.44C14.46 262.45 16.08 260.83 18.07 260.83C20.06 260.83 21.68 262.45 21.68 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 264.44C36.1302 266.43 34.5101 268.05 32.5201 268.05C30.5301 268.05 28.9102 266.43 28.9102 264.44C28.9102 262.45 30.5301 260.83 32.5201 260.83C34.5101 260.83 36.1302 262.45 36.1302 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 264.44C50.5701 266.43 48.9501 268.05 46.9601 268.05C44.9701 268.05 43.3501 266.43 43.3501 264.44C43.3501 262.45 44.9701 260.83 46.9601 260.83C48.9501 260.83 50.5701 262.45 50.5701 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 264.44C65.01 266.43 63.39 268.05 61.4 268.05C59.41 268.05 57.79 266.43 57.79 264.44C57.79 262.45 59.41 260.83 61.4 260.83C63.39 260.83 65.01 262.45 65.01 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 264.44C79.4602 266.43 77.8403 268.05 75.8503 268.05C73.8603 268.05 72.2402 266.43 72.2402 264.44C72.2402 262.45 73.8603 260.83 75.8503 260.83C77.8403 260.83 79.4602 262.45 79.4602 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 264.44C93.9002 266.43 92.2802 268.05 90.2902 268.05C88.3002 268.05 86.6802 266.43 86.6802 264.44C86.6802 262.45 88.3002 260.83 90.2902 260.83C92.2802 260.83 93.9002 262.45 93.9002 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 264.44C108.34 266.43 106.72 268.05 104.73 268.05C102.74 268.05 101.12 266.43 101.12 264.44C101.12 262.45 102.74 260.83 104.73 260.83C106.72 260.83 108.34 262.45 108.34 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 264.44C122.79 266.43 121.17 268.05 119.18 268.05C117.19 268.05 115.57 266.43 115.57 264.44C115.57 262.45 117.19 260.83 119.18 260.83C121.17 260.83 122.79 262.45 122.79 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 264.44C137.23 266.43 135.61 268.05 133.62 268.05C131.63 268.05 130.01 266.43 130.01 264.44C130.01 262.45 131.63 260.83 133.62 260.83C135.61 260.83 137.23 262.45 137.23 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 264.44C151.67 266.43 150.05 268.05 148.06 268.05C146.07 268.05 144.45 266.43 144.45 264.44C144.45 262.45 146.07 260.83 148.06 260.83C150.05 260.83 151.67 262.45 151.67 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 264.44C166.12 266.43 164.5 268.05 162.51 268.05C160.52 268.05 158.9 266.43 158.9 264.44C158.9 262.45 160.52 260.83 162.51 260.83C164.5 260.83 166.12 262.45 166.12 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 264.44C180.56 266.43 178.94 268.05 176.95 268.05C174.96 268.05 173.34 266.43 173.34 264.44C173.34 262.45 174.96 260.83 176.95 260.83C178.94 260.83 180.56 262.45 180.56 264.44Z"
            fill="#E38135"
          />
          <path
            d="M195 264.44C195 266.43 193.38 268.05 191.39 268.05C189.4 268.05 187.78 266.43 187.78 264.44C187.78 262.45 189.4 260.83 191.39 260.83C193.38 260.83 195 262.45 195 264.44Z"
            fill="#E38135"
          />
          <path
            d="M209.45 264.44C209.45 266.43 207.83 268.05 205.84 268.05C203.85 268.05 202.23 266.43 202.23 264.44C202.23 262.45 203.85 260.83 205.84 260.83C207.83 260.83 209.45 262.45 209.45 264.44Z"
            fill="#E38135"
          />
          <path
            d="M223.89 264.44C223.89 266.43 222.27 268.05 220.28 268.05C218.29 268.05 216.67 266.43 216.67 264.44C216.67 262.45 218.29 260.83 220.28 260.83C222.27 260.83 223.89 262.45 223.89 264.44Z"
            fill="#E38135"
          />
          <path
            d="M238.33 264.44C238.33 266.43 236.71 268.05 234.72 268.05C232.73 268.05 231.11 266.43 231.11 264.44C231.11 262.45 232.73 260.83 234.72 260.83C236.71 260.83 238.33 262.45 238.33 264.44Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 264.44C252.78 266.43 251.16 268.05 249.17 268.05C247.18 268.05 245.56 266.43 245.56 264.44C245.56 262.45 247.18 260.83 249.17 260.83C251.16 260.83 252.78 262.45 252.78 264.44Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 264.44C267.22 266.43 265.6 268.05 263.61 268.05C261.62 268.05 260 266.43 260 264.44C260 262.45 261.62 260.83 263.61 260.83C265.6 260.83 267.22 262.45 267.22 264.44Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 264.44C281.66 266.43 280.04 268.05 278.05 268.05C276.06 268.05 274.44 266.43 274.44 264.44C274.44 262.45 276.06 260.83 278.05 260.83C280.04 260.83 281.66 262.45 281.66 264.44Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 264.44C296.11 266.43 294.49 268.05 292.5 268.05C290.51 268.05 288.89 266.43 288.89 264.44C288.89 262.45 290.51 260.83 292.5 260.83C294.49 260.83 296.11 262.45 296.11 264.44Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 264.44C310.55 266.43 308.93 268.05 306.94 268.05C304.95 268.05 303.33 266.43 303.33 264.44C303.33 262.45 304.95 260.83 306.94 260.83C308.93 260.83 310.55 262.45 310.55 264.44Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 264.44C324.99 266.43 323.37 268.05 321.38 268.05C319.39 268.05 317.77 266.43 317.77 264.44C317.77 262.45 319.39 260.83 321.38 260.83C323.37 260.83 324.99 262.45 324.99 264.44Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 264.44C339.44 266.43 337.82 268.05 335.83 268.05C333.84 268.05 332.22 266.43 332.22 264.44C332.22 262.45 333.84 260.83 335.83 260.83C337.82 260.83 339.44 262.45 339.44 264.44Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 264.44C353.88 266.43 352.26 268.05 350.27 268.05C348.28 268.05 346.66 266.43 346.66 264.44C346.66 262.45 348.28 260.83 350.27 260.83C352.26 260.83 353.88 262.45 353.88 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 264.44C368.32 266.43 366.7 268.05 364.71 268.05C362.72 268.05 361.1 266.43 361.1 264.44C361.1 262.45 362.72 260.83 364.71 260.83C366.7 260.83 368.32 262.45 368.32 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 264.44C382.77 266.43 381.15 268.05 379.16 268.05C377.17 268.05 375.55 266.43 375.55 264.44C375.55 262.45 377.17 260.83 379.16 260.83C381.15 260.83 382.77 262.45 382.77 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 264.44C397.21 266.43 395.59 268.05 393.6 268.05C391.61 268.05 389.99 266.43 389.99 264.44C389.99 262.45 391.61 260.83 393.6 260.83C395.59 260.83 397.21 262.45 397.21 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 264.44C411.65 266.43 410.03 268.05 408.04 268.05C406.05 268.05 404.43 266.43 404.43 264.44C404.43 262.45 406.05 260.83 408.04 260.83C410.03 260.83 411.65 262.45 411.65 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 264.44C426.1 266.43 424.48 268.05 422.49 268.05C420.5 268.05 418.88 266.43 418.88 264.44C418.88 262.45 420.5 260.83 422.49 260.83C424.48 260.83 426.1 262.45 426.1 264.44Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 278.89C21.68 280.88 20.06 282.5 18.07 282.5C16.08 282.5 14.46 280.88 14.46 278.89C14.46 276.9 16.08 275.28 18.07 275.28C20.06 275.28 21.68 276.9 21.68 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 278.89C36.1302 280.88 34.5101 282.5 32.5201 282.5C30.5301 282.5 28.9102 280.88 28.9102 278.89C28.9102 276.9 30.5301 275.28 32.5201 275.28C34.5101 275.28 36.1302 276.9 36.1302 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 278.89C50.5701 280.88 48.9501 282.5 46.9601 282.5C44.9701 282.5 43.3501 280.88 43.3501 278.89C43.3501 276.9 44.9701 275.28 46.9601 275.28C48.9501 275.28 50.5701 276.9 50.5701 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 278.89C65.01 280.88 63.39 282.5 61.4 282.5C59.41 282.5 57.79 280.88 57.79 278.89C57.79 276.9 59.41 275.28 61.4 275.28C63.39 275.28 65.01 276.9 65.01 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 278.89C79.4602 280.88 77.8403 282.5 75.8503 282.5C73.8603 282.5 72.2402 280.88 72.2402 278.89C72.2402 276.9 73.8603 275.28 75.8503 275.28C77.8403 275.28 79.4602 276.9 79.4602 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 278.89C93.9002 280.88 92.2802 282.5 90.2902 282.5C88.3002 282.5 86.6802 280.88 86.6802 278.89C86.6802 276.9 88.3002 275.28 90.2902 275.28C92.2802 275.28 93.9002 276.9 93.9002 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 278.89C108.34 280.88 106.72 282.5 104.73 282.5C102.74 282.5 101.12 280.88 101.12 278.89C101.12 276.9 102.74 275.28 104.73 275.28C106.72 275.28 108.34 276.9 108.34 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 278.89C122.79 280.88 121.17 282.5 119.18 282.5C117.19 282.5 115.57 280.88 115.57 278.89C115.57 276.9 117.19 275.28 119.18 275.28C121.17 275.28 122.79 276.9 122.79 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 278.89C137.23 280.88 135.61 282.5 133.62 282.5C131.63 282.5 130.01 280.88 130.01 278.89C130.01 276.9 131.63 275.28 133.62 275.28C135.61 275.28 137.23 276.9 137.23 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 278.89C151.67 280.88 150.05 282.5 148.06 282.5C146.07 282.5 144.45 280.88 144.45 278.89C144.45 276.9 146.07 275.28 148.06 275.28C150.05 275.28 151.67 276.9 151.67 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 278.89C166.12 280.88 164.5 282.5 162.51 282.5C160.52 282.5 158.9 280.88 158.9 278.89C158.9 276.9 160.52 275.28 162.51 275.28C164.5 275.28 166.12 276.9 166.12 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 278.89C180.56 280.88 178.94 282.5 176.95 282.5C174.96 282.5 173.34 280.88 173.34 278.89C173.34 276.9 174.96 275.28 176.95 275.28C178.94 275.28 180.56 276.9 180.56 278.89Z"
            fill="#E38135"
          />
          <path
            d="M195 278.89C195 280.88 193.38 282.5 191.39 282.5C189.4 282.5 187.78 280.88 187.78 278.89C187.78 276.9 189.4 275.28 191.39 275.28C193.38 275.28 195 276.9 195 278.89Z"
            fill="#E38135"
          />
          <path
            d="M209.45 278.89C209.45 280.88 207.83 282.5 205.84 282.5C203.85 282.5 202.23 280.88 202.23 278.89C202.23 276.9 203.85 275.28 205.84 275.28C207.83 275.28 209.45 276.9 209.45 278.89Z"
            fill="#E38135"
          />
          <path
            d="M223.89 278.89C223.89 280.88 222.27 282.5 220.28 282.5C218.29 282.5 216.67 280.88 216.67 278.89C216.67 276.9 218.29 275.28 220.28 275.28C222.27 275.28 223.89 276.9 223.89 278.89Z"
            fill="#E38135"
          />
          <path
            d="M238.33 278.89C238.33 280.88 236.71 282.5 234.72 282.5C232.73 282.5 231.11 280.88 231.11 278.89C231.11 276.9 232.73 275.28 234.72 275.28C236.71 275.28 238.33 276.9 238.33 278.89Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 278.89C252.78 280.88 251.16 282.5 249.17 282.5C247.18 282.5 245.56 280.88 245.56 278.89C245.56 276.9 247.18 275.28 249.17 275.28C251.16 275.28 252.78 276.9 252.78 278.89Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 278.89C267.22 280.88 265.6 282.5 263.61 282.5C261.62 282.5 260 280.88 260 278.89C260 276.9 261.62 275.28 263.61 275.28C265.6 275.28 267.22 276.9 267.22 278.89Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 278.89C281.66 280.88 280.04 282.5 278.05 282.5C276.06 282.5 274.44 280.88 274.44 278.89C274.44 276.9 276.06 275.28 278.05 275.28C280.04 275.28 281.66 276.9 281.66 278.89Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 278.89C296.11 280.88 294.49 282.5 292.5 282.5C290.51 282.5 288.89 280.88 288.89 278.89C288.89 276.9 290.51 275.28 292.5 275.28C294.49 275.28 296.11 276.9 296.11 278.89Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 278.89C310.55 280.88 308.93 282.5 306.94 282.5C304.95 282.5 303.33 280.88 303.33 278.89C303.33 276.9 304.95 275.28 306.94 275.28C308.93 275.28 310.55 276.9 310.55 278.89Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 278.89C324.99 280.88 323.37 282.5 321.38 282.5C319.39 282.5 317.77 280.88 317.77 278.89C317.77 276.9 319.39 275.28 321.38 275.28C323.37 275.28 324.99 276.9 324.99 278.89Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 278.89C339.44 280.88 337.82 282.5 335.83 282.5C333.84 282.5 332.22 280.88 332.22 278.89C332.22 276.9 333.84 275.28 335.83 275.28C337.82 275.28 339.44 276.9 339.44 278.89Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 278.89C353.88 280.88 352.26 282.5 350.27 282.5C348.28 282.5 346.66 280.88 346.66 278.89C346.66 276.9 348.28 275.28 350.27 275.28C352.26 275.28 353.88 276.9 353.88 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 278.89C368.32 280.88 366.7 282.5 364.71 282.5C362.72 282.5 361.1 280.88 361.1 278.89C361.1 276.9 362.72 275.28 364.71 275.28C366.7 275.28 368.32 276.9 368.32 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 278.89C382.77 280.88 381.15 282.5 379.16 282.5C377.17 282.5 375.55 280.88 375.55 278.89C375.55 276.9 377.17 275.28 379.16 275.28C381.15 275.28 382.77 276.9 382.77 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 278.89C397.21 280.88 395.59 282.5 393.6 282.5C391.61 282.5 389.99 280.88 389.99 278.89C389.99 276.9 391.61 275.28 393.6 275.28C395.59 275.28 397.21 276.9 397.21 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 278.89C411.65 280.88 410.03 282.5 408.04 282.5C406.05 282.5 404.43 280.88 404.43 278.89C404.43 276.9 406.05 275.28 408.04 275.28C410.03 275.28 411.65 276.9 411.65 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 278.89C426.1 280.88 424.48 282.5 422.49 282.5C420.5 282.5 418.88 280.88 418.88 278.89C418.88 276.9 420.5 275.28 422.49 275.28C424.48 275.28 426.1 276.9 426.1 278.89Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 293.33C21.68 295.32 20.06 296.94 18.07 296.94C16.08 296.94 14.46 295.32 14.46 293.33C14.46 291.34 16.08 289.72 18.07 289.72C20.06 289.72 21.68 291.34 21.68 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 293.33C36.1302 295.32 34.5101 296.94 32.5201 296.94C30.5301 296.94 28.9102 295.32 28.9102 293.33C28.9102 291.34 30.5301 289.72 32.5201 289.72C34.5101 289.72 36.1302 291.34 36.1302 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 293.33C50.5701 295.32 48.9501 296.94 46.9601 296.94C44.9701 296.94 43.3501 295.32 43.3501 293.33C43.3501 291.34 44.9701 289.72 46.9601 289.72C48.9501 289.72 50.5701 291.34 50.5701 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 293.33C65.01 295.32 63.39 296.94 61.4 296.94C59.41 296.94 57.79 295.32 57.79 293.33C57.79 291.34 59.41 289.72 61.4 289.72C63.39 289.72 65.01 291.34 65.01 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 293.33C79.4602 295.32 77.8403 296.94 75.8503 296.94C73.8603 296.94 72.2402 295.32 72.2402 293.33C72.2402 291.34 73.8603 289.72 75.8503 289.72C77.8403 289.72 79.4602 291.34 79.4602 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 293.33C93.9002 295.32 92.2802 296.94 90.2902 296.94C88.3002 296.94 86.6802 295.32 86.6802 293.33C86.6802 291.34 88.3002 289.72 90.2902 289.72C92.2802 289.72 93.9002 291.34 93.9002 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 293.33C108.34 295.32 106.72 296.94 104.73 296.94C102.74 296.94 101.12 295.32 101.12 293.33C101.12 291.34 102.74 289.72 104.73 289.72C106.72 289.72 108.34 291.34 108.34 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 293.33C122.79 295.32 121.17 296.94 119.18 296.94C117.19 296.94 115.57 295.32 115.57 293.33C115.57 291.34 117.19 289.72 119.18 289.72C121.17 289.72 122.79 291.34 122.79 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 293.33C137.23 295.32 135.61 296.94 133.62 296.94C131.63 296.94 130.01 295.32 130.01 293.33C130.01 291.34 131.63 289.72 133.62 289.72C135.61 289.72 137.23 291.34 137.23 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 293.33C151.67 295.32 150.05 296.94 148.06 296.94C146.07 296.94 144.45 295.32 144.45 293.33C144.45 291.34 146.07 289.72 148.06 289.72C150.05 289.72 151.67 291.34 151.67 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 293.33C166.12 295.32 164.5 296.94 162.51 296.94C160.52 296.94 158.9 295.32 158.9 293.33C158.9 291.34 160.52 289.72 162.51 289.72C164.5 289.72 166.12 291.34 166.12 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 293.33C180.56 295.32 178.94 296.94 176.95 296.94C174.96 296.94 173.34 295.32 173.34 293.33C173.34 291.34 174.96 289.72 176.95 289.72C178.94 289.72 180.56 291.34 180.56 293.33Z"
            fill="#E38135"
          />
          <path
            d="M195 293.33C195 295.32 193.38 296.94 191.39 296.94C189.4 296.94 187.78 295.32 187.78 293.33C187.78 291.34 189.4 289.72 191.39 289.72C193.38 289.72 195 291.34 195 293.33Z"
            fill="#E38135"
          />
          <path
            d="M209.45 293.33C209.45 295.32 207.83 296.94 205.84 296.94C203.85 296.94 202.23 295.32 202.23 293.33C202.23 291.34 203.85 289.72 205.84 289.72C207.83 289.72 209.45 291.34 209.45 293.33Z"
            fill="#E38135"
          />
          <path
            d="M223.89 293.33C223.89 295.32 222.27 296.94 220.28 296.94C218.29 296.94 216.67 295.32 216.67 293.33C216.67 291.34 218.29 289.72 220.28 289.72C222.27 289.72 223.89 291.34 223.89 293.33Z"
            fill="#E38135"
          />
          <path
            d="M238.33 293.33C238.33 295.32 236.71 296.94 234.72 296.94C232.73 296.94 231.11 295.32 231.11 293.33C231.11 291.34 232.73 289.72 234.72 289.72C236.71 289.72 238.33 291.34 238.33 293.33Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 293.33C252.78 295.32 251.16 296.94 249.17 296.94C247.18 296.94 245.56 295.32 245.56 293.33C245.56 291.34 247.18 289.72 249.17 289.72C251.16 289.72 252.78 291.34 252.78 293.33Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 293.33C267.22 295.32 265.6 296.94 263.61 296.94C261.62 296.94 260 295.32 260 293.33C260 291.34 261.62 289.72 263.61 289.72C265.6 289.72 267.22 291.34 267.22 293.33Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 293.33C281.66 295.32 280.04 296.94 278.05 296.94C276.06 296.94 274.44 295.32 274.44 293.33C274.44 291.34 276.06 289.72 278.05 289.72C280.04 289.72 281.66 291.34 281.66 293.33Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 293.33C296.11 295.32 294.49 296.94 292.5 296.94C290.51 296.94 288.89 295.32 288.89 293.33C288.89 291.34 290.51 289.72 292.5 289.72C294.49 289.72 296.11 291.34 296.11 293.33Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 293.33C310.55 295.32 308.93 296.94 306.94 296.94C304.95 296.94 303.33 295.32 303.33 293.33C303.33 291.34 304.95 289.72 306.94 289.72C308.93 289.72 310.55 291.34 310.55 293.33Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 293.33C324.99 295.32 323.37 296.94 321.38 296.94C319.39 296.94 317.77 295.32 317.77 293.33C317.77 291.34 319.39 289.72 321.38 289.72C323.37 289.72 324.99 291.34 324.99 293.33Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 293.33C339.44 295.32 337.82 296.94 335.83 296.94C333.84 296.94 332.22 295.32 332.22 293.33C332.22 291.34 333.84 289.72 335.83 289.72C337.82 289.72 339.44 291.34 339.44 293.33Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 293.33C353.88 295.32 352.26 296.94 350.27 296.94C348.28 296.94 346.66 295.32 346.66 293.33C346.66 291.34 348.28 289.72 350.27 289.72C352.26 289.72 353.88 291.34 353.88 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 293.33C368.32 295.32 366.7 296.94 364.71 296.94C362.72 296.94 361.1 295.32 361.1 293.33C361.1 291.34 362.72 289.72 364.71 289.72C366.7 289.72 368.32 291.34 368.32 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 293.33C382.77 295.32 381.15 296.94 379.16 296.94C377.17 296.94 375.55 295.32 375.55 293.33C375.55 291.34 377.17 289.72 379.16 289.72C381.15 289.72 382.77 291.34 382.77 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 293.33C397.21 295.32 395.59 296.94 393.6 296.94C391.61 296.94 389.99 295.32 389.99 293.33C389.99 291.34 391.61 289.72 393.6 289.72C395.59 289.72 397.21 291.34 397.21 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 293.33C411.65 295.32 410.03 296.94 408.04 296.94C406.05 296.94 404.43 295.32 404.43 293.33C404.43 291.34 406.05 289.72 408.04 289.72C410.03 289.72 411.65 291.34 411.65 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 293.33C426.1 295.32 424.48 296.94 422.49 296.94C420.5 296.94 418.88 295.32 418.88 293.33C418.88 291.34 420.5 289.72 422.49 289.72C424.48 289.72 426.1 291.34 426.1 293.33Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 307.77C21.68 309.76 20.06 311.38 18.07 311.38C16.08 311.38 14.46 309.76 14.46 307.77C14.46 305.78 16.08 304.16 18.07 304.16C20.06 304.16 21.68 305.78 21.68 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 307.77C36.1302 309.76 34.5101 311.38 32.5201 311.38C30.5301 311.38 28.9102 309.76 28.9102 307.77C28.9102 305.78 30.5301 304.16 32.5201 304.16C34.5101 304.16 36.1302 305.78 36.1302 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 307.77C50.5701 309.76 48.9501 311.38 46.9601 311.38C44.9701 311.38 43.3501 309.76 43.3501 307.77C43.3501 305.78 44.9701 304.16 46.9601 304.16C48.9501 304.16 50.5701 305.78 50.5701 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 307.77C65.01 309.76 63.39 311.38 61.4 311.38C59.41 311.38 57.79 309.76 57.79 307.77C57.79 305.78 59.41 304.16 61.4 304.16C63.39 304.16 65.01 305.78 65.01 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 307.77C79.4602 309.76 77.8403 311.38 75.8503 311.38C73.8603 311.38 72.2402 309.76 72.2402 307.77C72.2402 305.78 73.8603 304.16 75.8503 304.16C77.8403 304.16 79.4602 305.78 79.4602 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 307.77C93.9002 309.76 92.2802 311.38 90.2902 311.38C88.3002 311.38 86.6802 309.76 86.6802 307.77C86.6802 305.78 88.3002 304.16 90.2902 304.16C92.2802 304.16 93.9002 305.78 93.9002 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 307.77C108.34 309.76 106.72 311.38 104.73 311.38C102.74 311.38 101.12 309.76 101.12 307.77C101.12 305.78 102.74 304.16 104.73 304.16C106.72 304.16 108.34 305.78 108.34 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 307.77C122.79 309.76 121.17 311.38 119.18 311.38C117.19 311.38 115.57 309.76 115.57 307.77C115.57 305.78 117.19 304.16 119.18 304.16C121.17 304.16 122.79 305.78 122.79 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 307.77C137.23 309.76 135.61 311.38 133.62 311.38C131.63 311.38 130.01 309.76 130.01 307.77C130.01 305.78 131.63 304.16 133.62 304.16C135.61 304.16 137.23 305.78 137.23 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 307.77C151.67 309.76 150.05 311.38 148.06 311.38C146.07 311.38 144.45 309.76 144.45 307.77C144.45 305.78 146.07 304.16 148.06 304.16C150.05 304.16 151.67 305.78 151.67 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 307.77C166.12 309.76 164.5 311.38 162.51 311.38C160.52 311.38 158.9 309.76 158.9 307.77C158.9 305.78 160.52 304.16 162.51 304.16C164.5 304.16 166.12 305.78 166.12 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 307.77C180.56 309.76 178.94 311.38 176.95 311.38C174.96 311.38 173.34 309.76 173.34 307.77C173.34 305.78 174.96 304.16 176.95 304.16C178.94 304.16 180.56 305.78 180.56 307.77Z"
            fill="#E38135"
          />
          <path
            d="M195 307.77C195 309.76 193.38 311.38 191.39 311.38C189.4 311.38 187.78 309.76 187.78 307.77C187.78 305.78 189.4 304.16 191.39 304.16C193.38 304.16 195 305.78 195 307.77Z"
            fill="#E38135"
          />
          <path
            d="M209.45 307.77C209.45 309.76 207.83 311.38 205.84 311.38C203.85 311.38 202.23 309.76 202.23 307.77C202.23 305.78 203.85 304.16 205.84 304.16C207.83 304.16 209.45 305.78 209.45 307.77Z"
            fill="#E38135"
          />
          <path
            d="M223.89 307.77C223.89 309.76 222.27 311.38 220.28 311.38C218.29 311.38 216.67 309.76 216.67 307.77C216.67 305.78 218.29 304.16 220.28 304.16C222.27 304.16 223.89 305.78 223.89 307.77Z"
            fill="#E38135"
          />
          <path
            d="M238.33 307.77C238.33 309.76 236.71 311.38 234.72 311.38C232.73 311.38 231.11 309.76 231.11 307.77C231.11 305.78 232.73 304.16 234.72 304.16C236.71 304.16 238.33 305.78 238.33 307.77Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 307.77C252.78 309.76 251.16 311.38 249.17 311.38C247.18 311.38 245.56 309.76 245.56 307.77C245.56 305.78 247.18 304.16 249.17 304.16C251.16 304.16 252.78 305.78 252.78 307.77Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 307.77C267.22 309.76 265.6 311.38 263.61 311.38C261.62 311.38 260 309.76 260 307.77C260 305.78 261.62 304.16 263.61 304.16C265.6 304.16 267.22 305.78 267.22 307.77Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 307.77C281.66 309.76 280.04 311.38 278.05 311.38C276.06 311.38 274.44 309.76 274.44 307.77C274.44 305.78 276.06 304.16 278.05 304.16C280.04 304.16 281.66 305.78 281.66 307.77Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 307.77C296.11 309.76 294.49 311.38 292.5 311.38C290.51 311.38 288.89 309.76 288.89 307.77C288.89 305.78 290.51 304.16 292.5 304.16C294.49 304.16 296.11 305.78 296.11 307.77Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 307.77C310.55 309.76 308.93 311.38 306.94 311.38C304.95 311.38 303.33 309.76 303.33 307.77C303.33 305.78 304.95 304.16 306.94 304.16C308.93 304.16 310.55 305.78 310.55 307.77Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 307.77C324.99 309.76 323.37 311.38 321.38 311.38C319.39 311.38 317.77 309.76 317.77 307.77C317.77 305.78 319.39 304.16 321.38 304.16C323.37 304.16 324.99 305.78 324.99 307.77Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 307.77C339.44 309.76 337.82 311.38 335.83 311.38C333.84 311.38 332.22 309.76 332.22 307.77C332.22 305.78 333.84 304.16 335.83 304.16C337.82 304.16 339.44 305.78 339.44 307.77Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 307.77C353.88 309.76 352.26 311.38 350.27 311.38C348.28 311.38 346.66 309.76 346.66 307.77C346.66 305.78 348.28 304.16 350.27 304.16C352.26 304.16 353.88 305.78 353.88 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 307.77C368.32 309.76 366.7 311.38 364.71 311.38C362.72 311.38 361.1 309.76 361.1 307.77C361.1 305.78 362.72 304.16 364.71 304.16C366.7 304.16 368.32 305.78 368.32 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 307.77C382.77 309.76 381.15 311.38 379.16 311.38C377.17 311.38 375.55 309.76 375.55 307.77C375.55 305.78 377.17 304.16 379.16 304.16C381.15 304.16 382.77 305.78 382.77 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 307.77C397.21 309.76 395.59 311.38 393.6 311.38C391.61 311.38 389.99 309.76 389.99 307.77C389.99 305.78 391.61 304.16 393.6 304.16C395.59 304.16 397.21 305.78 397.21 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 307.77C411.65 309.76 410.03 311.38 408.04 311.38C406.05 311.38 404.43 309.76 404.43 307.77C404.43 305.78 406.05 304.16 408.04 304.16C410.03 304.16 411.65 305.78 411.65 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 307.77C426.1 309.76 424.48 311.38 422.49 311.38C420.5 311.38 418.88 309.76 418.88 307.77C418.88 305.78 420.5 304.16 422.49 304.16C424.48 304.16 426.1 305.78 426.1 307.77Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 322.22C21.68 324.21 20.06 325.83 18.07 325.83C16.08 325.83 14.46 324.21 14.46 322.22C14.46 320.23 16.08 318.61 18.07 318.61C20.06 318.61 21.68 320.23 21.68 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 322.22C36.1302 324.21 34.5101 325.83 32.5201 325.83C30.5301 325.83 28.9102 324.21 28.9102 322.22C28.9102 320.23 30.5301 318.61 32.5201 318.61C34.5101 318.61 36.1302 320.23 36.1302 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 322.22C50.5701 324.21 48.9501 325.83 46.9601 325.83C44.9701 325.83 43.3501 324.21 43.3501 322.22C43.3501 320.23 44.9701 318.61 46.9601 318.61C48.9501 318.61 50.5701 320.23 50.5701 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 322.22C65.01 324.21 63.39 325.83 61.4 325.83C59.41 325.83 57.79 324.21 57.79 322.22C57.79 320.23 59.41 318.61 61.4 318.61C63.39 318.61 65.01 320.23 65.01 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 322.22C79.4602 324.21 77.8403 325.83 75.8503 325.83C73.8603 325.83 72.2402 324.21 72.2402 322.22C72.2402 320.23 73.8603 318.61 75.8503 318.61C77.8403 318.61 79.4602 320.23 79.4602 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 322.22C93.9002 324.21 92.2802 325.83 90.2902 325.83C88.3002 325.83 86.6802 324.21 86.6802 322.22C86.6802 320.23 88.3002 318.61 90.2902 318.61C92.2802 318.61 93.9002 320.23 93.9002 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 322.22C108.34 324.21 106.72 325.83 104.73 325.83C102.74 325.83 101.12 324.21 101.12 322.22C101.12 320.23 102.74 318.61 104.73 318.61C106.72 318.61 108.34 320.23 108.34 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 322.22C122.79 324.21 121.17 325.83 119.18 325.83C117.19 325.83 115.57 324.21 115.57 322.22C115.57 320.23 117.19 318.61 119.18 318.61C121.17 318.61 122.79 320.23 122.79 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 322.22C137.23 324.21 135.61 325.83 133.62 325.83C131.63 325.83 130.01 324.21 130.01 322.22C130.01 320.23 131.63 318.61 133.62 318.61C135.61 318.61 137.23 320.23 137.23 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 322.22C151.67 324.21 150.05 325.83 148.06 325.83C146.07 325.83 144.45 324.21 144.45 322.22C144.45 320.23 146.07 318.61 148.06 318.61C150.05 318.61 151.67 320.23 151.67 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 322.22C166.12 324.21 164.5 325.83 162.51 325.83C160.52 325.83 158.9 324.21 158.9 322.22C158.9 320.23 160.52 318.61 162.51 318.61C164.5 318.61 166.12 320.23 166.12 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 322.22C180.56 324.21 178.94 325.83 176.95 325.83C174.96 325.83 173.34 324.21 173.34 322.22C173.34 320.23 174.96 318.61 176.95 318.61C178.94 318.61 180.56 320.23 180.56 322.22Z"
            fill="#E38135"
          />
          <path
            d="M195 322.22C195 324.21 193.38 325.83 191.39 325.83C189.4 325.83 187.78 324.21 187.78 322.22C187.78 320.23 189.4 318.61 191.39 318.61C193.38 318.61 195 320.23 195 322.22Z"
            fill="#E38135"
          />
          <path
            d="M209.45 322.22C209.45 324.21 207.83 325.83 205.84 325.83C203.85 325.83 202.23 324.21 202.23 322.22C202.23 320.23 203.85 318.61 205.84 318.61C207.83 318.61 209.45 320.23 209.45 322.22Z"
            fill="#E38135"
          />
          <path
            d="M223.89 322.22C223.89 324.21 222.27 325.83 220.28 325.83C218.29 325.83 216.67 324.21 216.67 322.22C216.67 320.23 218.29 318.61 220.28 318.61C222.27 318.61 223.89 320.23 223.89 322.22Z"
            fill="#E38135"
          />
          <path
            d="M238.33 322.22C238.33 324.21 236.71 325.83 234.72 325.83C232.73 325.83 231.11 324.21 231.11 322.22C231.11 320.23 232.73 318.61 234.72 318.61C236.71 318.61 238.33 320.23 238.33 322.22Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 322.22C252.78 324.21 251.16 325.83 249.17 325.83C247.18 325.83 245.56 324.21 245.56 322.22C245.56 320.23 247.18 318.61 249.17 318.61C251.16 318.61 252.78 320.23 252.78 322.22Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 322.22C267.22 324.21 265.6 325.83 263.61 325.83C261.62 325.83 260 324.21 260 322.22C260 320.23 261.62 318.61 263.61 318.61C265.6 318.61 267.22 320.23 267.22 322.22Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 322.22C281.66 324.21 280.04 325.83 278.05 325.83C276.06 325.83 274.44 324.21 274.44 322.22C274.44 320.23 276.06 318.61 278.05 318.61C280.04 318.61 281.66 320.23 281.66 322.22Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 322.22C296.11 324.21 294.49 325.83 292.5 325.83C290.51 325.83 288.89 324.21 288.89 322.22C288.89 320.23 290.51 318.61 292.5 318.61C294.49 318.61 296.11 320.23 296.11 322.22Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 322.22C310.55 324.21 308.93 325.83 306.94 325.83C304.95 325.83 303.33 324.21 303.33 322.22C303.33 320.23 304.95 318.61 306.94 318.61C308.93 318.61 310.55 320.23 310.55 322.22Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 322.22C324.99 324.21 323.37 325.83 321.38 325.83C319.39 325.83 317.77 324.21 317.77 322.22C317.77 320.23 319.39 318.61 321.38 318.61C323.37 318.61 324.99 320.23 324.99 322.22Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 322.22C339.44 324.21 337.82 325.83 335.83 325.83C333.84 325.83 332.22 324.21 332.22 322.22C332.22 320.23 333.84 318.61 335.83 318.61C337.82 318.61 339.44 320.23 339.44 322.22Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 322.22C353.88 324.21 352.26 325.83 350.27 325.83C348.28 325.83 346.66 324.21 346.66 322.22C346.66 320.23 348.28 318.61 350.27 318.61C352.26 318.61 353.88 320.23 353.88 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 322.22C368.32 324.21 366.7 325.83 364.71 325.83C362.72 325.83 361.1 324.21 361.1 322.22C361.1 320.23 362.72 318.61 364.71 318.61C366.7 318.61 368.32 320.23 368.32 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 322.22C382.77 324.21 381.15 325.83 379.16 325.83C377.17 325.83 375.55 324.21 375.55 322.22C375.55 320.23 377.17 318.61 379.16 318.61C381.15 318.61 382.77 320.23 382.77 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 322.22C397.21 324.21 395.59 325.83 393.6 325.83C391.61 325.83 389.99 324.21 389.99 322.22C389.99 320.23 391.61 318.61 393.6 318.61C395.59 318.61 397.21 320.23 397.21 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 322.22C411.65 324.21 410.03 325.83 408.04 325.83C406.05 325.83 404.43 324.21 404.43 322.22C404.43 320.23 406.05 318.61 408.04 318.61C410.03 318.61 411.65 320.23 411.65 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 322.22C426.1 324.21 424.48 325.83 422.49 325.83C420.5 325.83 418.88 324.21 418.88 322.22C418.88 320.23 420.5 318.61 422.49 318.61C424.48 318.61 426.1 320.23 426.1 322.22Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 336.66C21.68 338.65 20.06 340.27 18.07 340.27C16.08 340.27 14.46 338.65 14.46 336.66C14.46 334.67 16.08 333.05 18.07 333.05C20.06 333.05 21.68 334.67 21.68 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M36.1302 336.66C36.1302 338.65 34.5101 340.27 32.5201 340.27C30.5301 340.27 28.9102 338.65 28.9102 336.66C28.9102 334.67 30.5301 333.05 32.5201 333.05C34.5101 333.05 36.1302 334.67 36.1302 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M50.5701 336.66C50.5701 338.65 48.9501 340.27 46.9601 340.27C44.9701 340.27 43.3501 338.65 43.3501 336.66C43.3501 334.67 44.9701 333.05 46.9601 333.05C48.9501 333.05 50.5701 334.67 50.5701 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M65.01 336.66C65.01 338.65 63.39 340.27 61.4 340.27C59.41 340.27 57.79 338.65 57.79 336.66C57.79 334.67 59.41 333.05 61.4 333.05C63.39 333.05 65.01 334.67 65.01 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M79.4602 336.66C79.4602 338.65 77.8403 340.27 75.8503 340.27C73.8603 340.27 72.2402 338.65 72.2402 336.66C72.2402 334.67 73.8603 333.05 75.8503 333.05C77.8403 333.05 79.4602 334.67 79.4602 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M93.9002 336.66C93.9002 338.65 92.2802 340.27 90.2902 340.27C88.3002 340.27 86.6802 338.65 86.6802 336.66C86.6802 334.67 88.3002 333.05 90.2902 333.05C92.2802 333.05 93.9002 334.67 93.9002 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 336.66C108.34 338.65 106.72 340.27 104.73 340.27C102.74 340.27 101.12 338.65 101.12 336.66C101.12 334.67 102.74 333.05 104.73 333.05C106.72 333.05 108.34 334.67 108.34 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 336.66C122.79 338.65 121.17 340.27 119.18 340.27C117.19 340.27 115.57 338.65 115.57 336.66C115.57 334.67 117.19 333.05 119.18 333.05C121.17 333.05 122.79 334.67 122.79 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 336.66C137.23 338.65 135.61 340.27 133.62 340.27C131.63 340.27 130.01 338.65 130.01 336.66C130.01 334.67 131.63 333.05 133.62 333.05C135.61 333.05 137.23 334.67 137.23 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 336.66C151.67 338.65 150.05 340.27 148.06 340.27C146.07 340.27 144.45 338.65 144.45 336.66C144.45 334.67 146.07 333.05 148.06 333.05C150.05 333.05 151.67 334.67 151.67 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 336.66C166.12 338.65 164.5 340.27 162.51 340.27C160.52 340.27 158.9 338.65 158.9 336.66C158.9 334.67 160.52 333.05 162.51 333.05C164.5 333.05 166.12 334.67 166.12 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 336.66C180.56 338.65 178.94 340.27 176.95 340.27C174.96 340.27 173.34 338.65 173.34 336.66C173.34 334.67 174.96 333.05 176.95 333.05C178.94 333.05 180.56 334.67 180.56 336.66Z"
            fill="#E38135"
          />
          <path
            d="M195 336.66C195 338.65 193.38 340.27 191.39 340.27C189.4 340.27 187.78 338.65 187.78 336.66C187.78 334.67 189.4 333.05 191.39 333.05C193.38 333.05 195 334.67 195 336.66Z"
            fill="#E38135"
          />
          <path
            d="M209.45 336.66C209.45 338.65 207.83 340.27 205.84 340.27C203.85 340.27 202.23 338.65 202.23 336.66C202.23 334.67 203.85 333.05 205.84 333.05C207.83 333.05 209.45 334.67 209.45 336.66Z"
            fill="#E38135"
          />
          <path
            d="M223.89 336.66C223.89 338.65 222.27 340.27 220.28 340.27C218.29 340.27 216.67 338.65 216.67 336.66C216.67 334.67 218.29 333.05 220.28 333.05C222.27 333.05 223.89 334.67 223.89 336.66Z"
            fill="#E38135"
          />
          <path
            d="M238.33 336.66C238.33 338.65 236.71 340.27 234.72 340.27C232.73 340.27 231.11 338.65 231.11 336.66C231.11 334.67 232.73 333.05 234.72 333.05C236.71 333.05 238.33 334.67 238.33 336.66Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 336.66C252.78 338.65 251.16 340.27 249.17 340.27C247.18 340.27 245.56 338.65 245.56 336.66C245.56 334.67 247.18 333.05 249.17 333.05C251.16 333.05 252.78 334.67 252.78 336.66Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 336.66C267.22 338.65 265.6 340.27 263.61 340.27C261.62 340.27 260 338.65 260 336.66C260 334.67 261.62 333.05 263.61 333.05C265.6 333.05 267.22 334.67 267.22 336.66Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 336.66C281.66 338.65 280.04 340.27 278.05 340.27C276.06 340.27 274.44 338.65 274.44 336.66C274.44 334.67 276.06 333.05 278.05 333.05C280.04 333.05 281.66 334.67 281.66 336.66Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 336.66C296.11 338.65 294.49 340.27 292.5 340.27C290.51 340.27 288.89 338.65 288.89 336.66C288.89 334.67 290.51 333.05 292.5 333.05C294.49 333.05 296.11 334.67 296.11 336.66Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 336.66C310.55 338.65 308.93 340.27 306.94 340.27C304.95 340.27 303.33 338.65 303.33 336.66C303.33 334.67 304.95 333.05 306.94 333.05C308.93 333.05 310.55 334.67 310.55 336.66Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 336.66C324.99 338.65 323.37 340.27 321.38 340.27C319.39 340.27 317.77 338.65 317.77 336.66C317.77 334.67 319.39 333.05 321.38 333.05C323.37 333.05 324.99 334.67 324.99 336.66Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 336.66C339.44 338.65 337.82 340.27 335.83 340.27C333.84 340.27 332.22 338.65 332.22 336.66C332.22 334.67 333.84 333.05 335.83 333.05C337.82 333.05 339.44 334.67 339.44 336.66Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 336.66C353.88 338.65 352.26 340.27 350.27 340.27C348.28 340.27 346.66 338.65 346.66 336.66C346.66 334.67 348.28 333.05 350.27 333.05C352.26 333.05 353.88 334.67 353.88 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 336.66C368.32 338.65 366.7 340.27 364.71 340.27C362.72 340.27 361.1 338.65 361.1 336.66C361.1 334.67 362.72 333.05 364.71 333.05C366.7 333.05 368.32 334.67 368.32 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 336.66C382.77 338.65 381.15 340.27 379.16 340.27C377.17 340.27 375.55 338.65 375.55 336.66C375.55 334.67 377.17 333.05 379.16 333.05C381.15 333.05 382.77 334.67 382.77 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 336.66C397.21 338.65 395.59 340.27 393.6 340.27C391.61 340.27 389.99 338.65 389.99 336.66C389.99 334.67 391.61 333.05 393.6 333.05C395.59 333.05 397.21 334.67 397.21 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 336.66C411.65 338.65 410.03 340.27 408.04 340.27C406.05 340.27 404.43 338.65 404.43 336.66C404.43 334.67 406.05 333.05 408.04 333.05C410.03 333.05 411.65 334.67 411.65 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 336.66C426.1 338.65 424.48 340.27 422.49 340.27C420.5 340.27 418.88 338.65 418.88 336.66C418.88 334.67 420.5 333.05 422.49 333.05C424.48 333.05 426.1 334.67 426.1 336.66Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 351.1C21.68 353.09 20.06 354.71 18.07 354.71C16.08 354.71 14.46 353.09 14.46 351.1C14.46 349.11 16.08 347.49 18.07 347.49C20.06 347.49 21.68 349.11 21.68 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 351.1C36.1302 353.09 34.5101 354.71 32.5201 354.71C30.5301 354.71 28.9102 353.09 28.9102 351.1C28.9102 349.11 30.5301 347.49 32.5201 347.49C34.5101 347.49 36.1302 349.11 36.1302 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 351.1C50.5701 353.09 48.9501 354.71 46.9601 354.71C44.9701 354.71 43.3501 353.09 43.3501 351.1C43.3501 349.11 44.9701 347.49 46.9601 347.49C48.9501 347.49 50.5701 349.11 50.5701 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 351.1C65.01 353.09 63.39 354.71 61.4 354.71C59.41 354.71 57.79 353.09 57.79 351.1C57.79 349.11 59.41 347.49 61.4 347.49C63.39 347.49 65.01 349.11 65.01 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 351.1C79.4602 353.09 77.8403 354.71 75.8503 354.71C73.8603 354.71 72.2402 353.09 72.2402 351.1C72.2402 349.11 73.8603 347.49 75.8503 347.49C77.8403 347.49 79.4602 349.11 79.4602 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 351.1C93.9002 353.09 92.2802 354.71 90.2902 354.71C88.3002 354.71 86.6802 353.09 86.6802 351.1C86.6802 349.11 88.3002 347.49 90.2902 347.49C92.2802 347.49 93.9002 349.11 93.9002 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 351.1C108.34 353.09 106.72 354.71 104.73 354.71C102.74 354.71 101.12 353.09 101.12 351.1C101.12 349.11 102.74 347.49 104.73 347.49C106.72 347.49 108.34 349.11 108.34 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 351.1C122.79 353.09 121.17 354.71 119.18 354.71C117.19 354.71 115.57 353.09 115.57 351.1C115.57 349.11 117.19 347.49 119.18 347.49C121.17 347.49 122.79 349.11 122.79 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 351.1C137.23 353.09 135.61 354.71 133.62 354.71C131.63 354.71 130.01 353.09 130.01 351.1C130.01 349.11 131.63 347.49 133.62 347.49C135.61 347.49 137.23 349.11 137.23 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 351.1C151.67 353.09 150.05 354.71 148.06 354.71C146.07 354.71 144.45 353.09 144.45 351.1C144.45 349.11 146.07 347.49 148.06 347.49C150.05 347.49 151.67 349.11 151.67 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 351.1C166.12 353.09 164.5 354.71 162.51 354.71C160.52 354.71 158.9 353.09 158.9 351.1C158.9 349.11 160.52 347.49 162.51 347.49C164.5 347.49 166.12 349.11 166.12 351.1Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 351.1C180.56 353.09 178.94 354.71 176.95 354.71C174.96 354.71 173.34 353.09 173.34 351.1C173.34 349.11 174.96 347.49 176.95 347.49C178.94 347.49 180.56 349.11 180.56 351.1Z"
            fill="#E38135"
          />
          <path
            d="M195 351.1C195 353.09 193.38 354.71 191.39 354.71C189.4 354.71 187.78 353.09 187.78 351.1C187.78 349.11 189.4 347.49 191.39 347.49C193.38 347.49 195 349.11 195 351.1Z"
            fill="#E38135"
          />
          <path
            d="M209.45 351.1C209.45 353.09 207.83 354.71 205.84 354.71C203.85 354.71 202.23 353.09 202.23 351.1C202.23 349.11 203.85 347.49 205.84 347.49C207.83 347.49 209.45 349.11 209.45 351.1Z"
            fill="#E38135"
          />
          <path
            d="M223.89 351.1C223.89 353.09 222.27 354.71 220.28 354.71C218.29 354.71 216.67 353.09 216.67 351.1C216.67 349.11 218.29 347.49 220.28 347.49C222.27 347.49 223.89 349.11 223.89 351.1Z"
            fill="#E38135"
          />
          <path
            d="M238.33 351.1C238.33 353.09 236.71 354.71 234.72 354.71C232.73 354.71 231.11 353.09 231.11 351.1C231.11 349.11 232.73 347.49 234.72 347.49C236.71 347.49 238.33 349.11 238.33 351.1Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 351.1C252.78 353.09 251.16 354.71 249.17 354.71C247.18 354.71 245.56 353.09 245.56 351.1C245.56 349.11 247.18 347.49 249.17 347.49C251.16 347.49 252.78 349.11 252.78 351.1Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 351.1C267.22 353.09 265.6 354.71 263.61 354.71C261.62 354.71 260 353.09 260 351.1C260 349.11 261.62 347.49 263.61 347.49C265.6 347.49 267.22 349.11 267.22 351.1Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 351.1C281.66 353.09 280.04 354.71 278.05 354.71C276.06 354.71 274.44 353.09 274.44 351.1C274.44 349.11 276.06 347.49 278.05 347.49C280.04 347.49 281.66 349.11 281.66 351.1Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 351.1C296.11 353.09 294.49 354.71 292.5 354.71C290.51 354.71 288.89 353.09 288.89 351.1C288.89 349.11 290.51 347.49 292.5 347.49C294.49 347.49 296.11 349.11 296.11 351.1Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 351.1C310.55 353.09 308.93 354.71 306.94 354.71C304.95 354.71 303.33 353.09 303.33 351.1C303.33 349.11 304.95 347.49 306.94 347.49C308.93 347.49 310.55 349.11 310.55 351.1Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 351.1C324.99 353.09 323.37 354.71 321.38 354.71C319.39 354.71 317.77 353.09 317.77 351.1C317.77 349.11 319.39 347.49 321.38 347.49C323.37 347.49 324.99 349.11 324.99 351.1Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 351.1C339.44 353.09 337.82 354.71 335.83 354.71C333.84 354.71 332.22 353.09 332.22 351.1C332.22 349.11 333.84 347.49 335.83 347.49C337.82 347.49 339.44 349.11 339.44 351.1Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 351.1C353.88 353.09 352.26 354.71 350.27 354.71C348.28 354.71 346.66 353.09 346.66 351.1C346.66 349.11 348.28 347.49 350.27 347.49C352.26 347.49 353.88 349.11 353.88 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 351.1C368.32 353.09 366.7 354.71 364.71 354.71C362.72 354.71 361.1 353.09 361.1 351.1C361.1 349.11 362.72 347.49 364.71 347.49C366.7 347.49 368.32 349.11 368.32 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 351.1C382.77 353.09 381.15 354.71 379.16 354.71C377.17 354.71 375.55 353.09 375.55 351.1C375.55 349.11 377.17 347.49 379.16 347.49C381.15 347.49 382.77 349.11 382.77 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 351.1C397.21 353.09 395.59 354.71 393.6 354.71C391.61 354.71 389.99 353.09 389.99 351.1C389.99 349.11 391.61 347.49 393.6 347.49C395.59 347.49 397.21 349.11 397.21 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 351.1C411.65 353.09 410.03 354.71 408.04 354.71C406.05 354.71 404.43 353.09 404.43 351.1C404.43 349.11 406.05 347.49 408.04 347.49C410.03 347.49 411.65 349.11 411.65 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 351.1C426.1 353.09 424.48 354.71 422.49 354.71C420.5 354.71 418.88 353.09 418.88 351.1C418.88 349.11 420.5 347.49 422.49 347.49C424.48 347.49 426.1 349.11 426.1 351.1Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 365.55C21.68 367.54 20.06 369.16 18.07 369.16C16.08 369.16 14.46 367.54 14.46 365.55C14.46 363.56 16.08 361.94 18.07 361.94C20.06 361.94 21.68 363.56 21.68 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 365.55C36.1302 367.54 34.5101 369.16 32.5201 369.16C30.5301 369.16 28.9102 367.54 28.9102 365.55C28.9102 363.56 30.5301 361.94 32.5201 361.94C34.5101 361.94 36.1302 363.56 36.1302 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 365.55C50.5701 367.54 48.9501 369.16 46.9601 369.16C44.9701 369.16 43.3501 367.54 43.3501 365.55C43.3501 363.56 44.9701 361.94 46.9601 361.94C48.9501 361.94 50.5701 363.56 50.5701 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 365.55C65.01 367.54 63.39 369.16 61.4 369.16C59.41 369.16 57.79 367.54 57.79 365.55C57.79 363.56 59.41 361.94 61.4 361.94C63.39 361.94 65.01 363.56 65.01 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 365.55C79.4602 367.54 77.8403 369.16 75.8503 369.16C73.8603 369.16 72.2402 367.54 72.2402 365.55C72.2402 363.56 73.8603 361.94 75.8503 361.94C77.8403 361.94 79.4602 363.56 79.4602 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 365.55C93.9002 367.54 92.2802 369.16 90.2902 369.16C88.3002 369.16 86.6802 367.54 86.6802 365.55C86.6802 363.56 88.3002 361.94 90.2902 361.94C92.2802 361.94 93.9002 363.56 93.9002 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 365.55C108.34 367.54 106.72 369.16 104.73 369.16C102.74 369.16 101.12 367.54 101.12 365.55C101.12 363.56 102.74 361.94 104.73 361.94C106.72 361.94 108.34 363.56 108.34 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 365.55C122.79 367.54 121.17 369.16 119.18 369.16C117.19 369.16 115.57 367.54 115.57 365.55C115.57 363.56 117.19 361.94 119.18 361.94C121.17 361.94 122.79 363.56 122.79 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 365.55C137.23 367.54 135.61 369.16 133.62 369.16C131.63 369.16 130.01 367.54 130.01 365.55C130.01 363.56 131.63 361.94 133.62 361.94C135.61 361.94 137.23 363.56 137.23 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 365.55C151.67 367.54 150.05 369.16 148.06 369.16C146.07 369.16 144.45 367.54 144.45 365.55C144.45 363.56 146.07 361.94 148.06 361.94C150.05 361.94 151.67 363.56 151.67 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 365.55C166.12 367.54 164.5 369.16 162.51 369.16C160.52 369.16 158.9 367.54 158.9 365.55C158.9 363.56 160.52 361.94 162.51 361.94C164.5 361.94 166.12 363.56 166.12 365.55Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 365.55C180.56 367.54 178.94 369.16 176.95 369.16C174.96 369.16 173.34 367.54 173.34 365.55C173.34 363.56 174.96 361.94 176.95 361.94C178.94 361.94 180.56 363.56 180.56 365.55Z"
            fill="#E38135"
          />
          <path
            d="M195 365.55C195 367.54 193.38 369.16 191.39 369.16C189.4 369.16 187.78 367.54 187.78 365.55C187.78 363.56 189.4 361.94 191.39 361.94C193.38 361.94 195 363.56 195 365.55Z"
            fill="#E38135"
          />
          <path
            d="M209.45 365.55C209.45 367.54 207.83 369.16 205.84 369.16C203.85 369.16 202.23 367.54 202.23 365.55C202.23 363.56 203.85 361.94 205.84 361.94C207.83 361.94 209.45 363.56 209.45 365.55Z"
            fill="#E38135"
          />
          <path
            d="M223.89 365.55C223.89 367.54 222.27 369.16 220.28 369.16C218.29 369.16 216.67 367.54 216.67 365.55C216.67 363.56 218.29 361.94 220.28 361.94C222.27 361.94 223.89 363.56 223.89 365.55Z"
            fill="#E38135"
          />
          <path
            d="M238.33 365.55C238.33 367.54 236.71 369.16 234.72 369.16C232.73 369.16 231.11 367.54 231.11 365.55C231.11 363.56 232.73 361.94 234.72 361.94C236.71 361.94 238.33 363.56 238.33 365.55Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 365.55C252.78 367.54 251.16 369.16 249.17 369.16C247.18 369.16 245.56 367.54 245.56 365.55C245.56 363.56 247.18 361.94 249.17 361.94C251.16 361.94 252.78 363.56 252.78 365.55Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 365.55C267.22 367.54 265.6 369.16 263.61 369.16C261.62 369.16 260 367.54 260 365.55C260 363.56 261.62 361.94 263.61 361.94C265.6 361.94 267.22 363.56 267.22 365.55Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 365.55C281.66 367.54 280.04 369.16 278.05 369.16C276.06 369.16 274.44 367.54 274.44 365.55C274.44 363.56 276.06 361.94 278.05 361.94C280.04 361.94 281.66 363.56 281.66 365.55Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 365.55C296.11 367.54 294.49 369.16 292.5 369.16C290.51 369.16 288.89 367.54 288.89 365.55C288.89 363.56 290.51 361.94 292.5 361.94C294.49 361.94 296.11 363.56 296.11 365.55Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 365.55C310.55 367.54 308.93 369.16 306.94 369.16C304.95 369.16 303.33 367.54 303.33 365.55C303.33 363.56 304.95 361.94 306.94 361.94C308.93 361.94 310.55 363.56 310.55 365.55Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 365.55C324.99 367.54 323.37 369.16 321.38 369.16C319.39 369.16 317.77 367.54 317.77 365.55C317.77 363.56 319.39 361.94 321.38 361.94C323.37 361.94 324.99 363.56 324.99 365.55Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 365.55C339.44 367.54 337.82 369.16 335.83 369.16C333.84 369.16 332.22 367.54 332.22 365.55C332.22 363.56 333.84 361.94 335.83 361.94C337.82 361.94 339.44 363.56 339.44 365.55Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 365.55C353.88 367.54 352.26 369.16 350.27 369.16C348.28 369.16 346.66 367.54 346.66 365.55C346.66 363.56 348.28 361.94 350.27 361.94C352.26 361.94 353.88 363.56 353.88 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 365.55C368.32 367.54 366.7 369.16 364.71 369.16C362.72 369.16 361.1 367.54 361.1 365.55C361.1 363.56 362.72 361.94 364.71 361.94C366.7 361.94 368.32 363.56 368.32 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 365.55C382.77 367.54 381.15 369.16 379.16 369.16C377.17 369.16 375.55 367.54 375.55 365.55C375.55 363.56 377.17 361.94 379.16 361.94C381.15 361.94 382.77 363.56 382.77 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 365.55C397.21 367.54 395.59 369.16 393.6 369.16C391.61 369.16 389.99 367.54 389.99 365.55C389.99 363.56 391.61 361.94 393.6 361.94C395.59 361.94 397.21 363.56 397.21 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 365.55C411.65 367.54 410.03 369.16 408.04 369.16C406.05 369.16 404.43 367.54 404.43 365.55C404.43 363.56 406.05 361.94 408.04 361.94C410.03 361.94 411.65 363.56 411.65 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 365.55C426.1 367.54 424.48 369.16 422.49 369.16C420.5 369.16 418.88 367.54 418.88 365.55C418.88 363.56 420.5 361.94 422.49 361.94C424.48 361.94 426.1 363.56 426.1 365.55Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 379.99C21.68 381.98 20.06 383.6 18.07 383.6C16.08 383.6 14.46 381.98 14.46 379.99C14.46 378 16.08 376.38 18.07 376.38C20.06 376.38 21.68 378 21.68 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 379.99C36.1302 381.98 34.5101 383.6 32.5201 383.6C30.5301 383.6 28.9102 381.98 28.9102 379.99C28.9102 378 30.5301 376.38 32.5201 376.38C34.5101 376.38 36.1302 378 36.1302 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 379.99C50.5701 381.98 48.9501 383.6 46.9601 383.6C44.9701 383.6 43.3501 381.98 43.3501 379.99C43.3501 378 44.9701 376.38 46.9601 376.38C48.9501 376.38 50.5701 378 50.5701 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 379.99C65.01 381.98 63.39 383.6 61.4 383.6C59.41 383.6 57.79 381.98 57.79 379.99C57.79 378 59.41 376.38 61.4 376.38C63.39 376.38 65.01 378 65.01 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 379.99C79.4602 381.98 77.8403 383.6 75.8503 383.6C73.8603 383.6 72.2402 381.98 72.2402 379.99C72.2402 378 73.8603 376.38 75.8503 376.38C77.8403 376.38 79.4602 378 79.4602 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 379.99C93.9002 381.98 92.2802 383.6 90.2902 383.6C88.3002 383.6 86.6802 381.98 86.6802 379.99C86.6802 378 88.3002 376.38 90.2902 376.38C92.2802 376.38 93.9002 378 93.9002 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 379.99C108.34 381.98 106.72 383.6 104.73 383.6C102.74 383.6 101.12 381.98 101.12 379.99C101.12 378 102.74 376.38 104.73 376.38C106.72 376.38 108.34 378 108.34 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 379.99C122.79 381.98 121.17 383.6 119.18 383.6C117.19 383.6 115.57 381.98 115.57 379.99C115.57 378 117.19 376.38 119.18 376.38C121.17 376.38 122.79 378 122.79 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 379.99C137.23 381.98 135.61 383.6 133.62 383.6C131.63 383.6 130.01 381.98 130.01 379.99C130.01 378 131.63 376.38 133.62 376.38C135.61 376.38 137.23 378 137.23 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 379.99C151.67 381.98 150.05 383.6 148.06 383.6C146.07 383.6 144.45 381.98 144.45 379.99C144.45 378 146.07 376.38 148.06 376.38C150.05 376.38 151.67 378 151.67 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 379.99C166.12 381.98 164.5 383.6 162.51 383.6C160.52 383.6 158.9 381.98 158.9 379.99C158.9 378 160.52 376.38 162.51 376.38C164.5 376.38 166.12 378 166.12 379.99Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 379.99C180.56 381.98 178.94 383.6 176.95 383.6C174.96 383.6 173.34 381.98 173.34 379.99C173.34 378 174.96 376.38 176.95 376.38C178.94 376.38 180.56 378 180.56 379.99Z"
            fill="#E38135"
          />
          <path
            d="M195 379.99C195 381.98 193.38 383.6 191.39 383.6C189.4 383.6 187.78 381.98 187.78 379.99C187.78 378 189.4 376.38 191.39 376.38C193.38 376.38 195 378 195 379.99Z"
            fill="#E38135"
          />
          <path
            d="M209.45 379.99C209.45 381.98 207.83 383.6 205.84 383.6C203.85 383.6 202.23 381.98 202.23 379.99C202.23 378 203.85 376.38 205.84 376.38C207.83 376.38 209.45 378 209.45 379.99Z"
            fill="#E38135"
          />
          <path
            d="M223.89 379.99C223.89 381.98 222.27 383.6 220.28 383.6C218.29 383.6 216.67 381.98 216.67 379.99C216.67 378 218.29 376.38 220.28 376.38C222.27 376.38 223.89 378 223.89 379.99Z"
            fill="#E38135"
          />
          <path
            d="M238.33 379.99C238.33 381.98 236.71 383.6 234.72 383.6C232.73 383.6 231.11 381.98 231.11 379.99C231.11 378 232.73 376.38 234.72 376.38C236.71 376.38 238.33 378 238.33 379.99Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 379.99C252.78 381.98 251.16 383.6 249.17 383.6C247.18 383.6 245.56 381.98 245.56 379.99C245.56 378 247.18 376.38 249.17 376.38C251.16 376.38 252.78 378 252.78 379.99Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 379.99C267.22 381.98 265.6 383.6 263.61 383.6C261.62 383.6 260 381.98 260 379.99C260 378 261.62 376.38 263.61 376.38C265.6 376.38 267.22 378 267.22 379.99Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 379.99C281.66 381.98 280.04 383.6 278.05 383.6C276.06 383.6 274.44 381.98 274.44 379.99C274.44 378 276.06 376.38 278.05 376.38C280.04 376.38 281.66 378 281.66 379.99Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 379.99C296.11 381.98 294.49 383.6 292.5 383.6C290.51 383.6 288.89 381.98 288.89 379.99C288.89 378 290.51 376.38 292.5 376.38C294.49 376.38 296.11 378 296.11 379.99Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 379.99C310.55 381.98 308.93 383.6 306.94 383.6C304.95 383.6 303.33 381.98 303.33 379.99C303.33 378 304.95 376.38 306.94 376.38C308.93 376.38 310.55 378 310.55 379.99Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 379.99C324.99 381.98 323.37 383.6 321.38 383.6C319.39 383.6 317.77 381.98 317.77 379.99C317.77 378 319.39 376.38 321.38 376.38C323.37 376.38 324.99 378 324.99 379.99Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 379.99C339.44 381.98 337.82 383.6 335.83 383.6C333.84 383.6 332.22 381.98 332.22 379.99C332.22 378 333.84 376.38 335.83 376.38C337.82 376.38 339.44 378 339.44 379.99Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 379.99C353.88 381.98 352.26 383.6 350.27 383.6C348.28 383.6 346.66 381.98 346.66 379.99C346.66 378 348.28 376.38 350.27 376.38C352.26 376.38 353.88 378 353.88 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 379.99C368.32 381.98 366.7 383.6 364.71 383.6C362.72 383.6 361.1 381.98 361.1 379.99C361.1 378 362.72 376.38 364.71 376.38C366.7 376.38 368.32 378 368.32 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 379.99C382.77 381.98 381.15 383.6 379.16 383.6C377.17 383.6 375.55 381.98 375.55 379.99C375.55 378 377.17 376.38 379.16 376.38C381.15 376.38 382.77 378 382.77 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 379.99C397.21 381.98 395.59 383.6 393.6 383.6C391.61 383.6 389.99 381.98 389.99 379.99C389.99 378 391.61 376.38 393.6 376.38C395.59 376.38 397.21 378 397.21 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 379.99C411.65 381.98 410.03 383.6 408.04 383.6C406.05 383.6 404.43 381.98 404.43 379.99C404.43 378 406.05 376.38 408.04 376.38C410.03 376.38 411.65 378 411.65 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 379.99C426.1 381.98 424.48 383.6 422.49 383.6C420.5 383.6 418.88 381.98 418.88 379.99C418.88 378 420.5 376.38 422.49 376.38C424.48 376.38 426.1 378 426.1 379.99Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 394.43C21.68 396.42 20.06 398.04 18.07 398.04C16.08 398.04 14.46 396.42 14.46 394.43C14.46 392.44 16.08 390.82 18.07 390.82C20.06 390.82 21.68 392.44 21.68 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 394.43C36.1302 396.42 34.5101 398.04 32.5201 398.04C30.5301 398.04 28.9102 396.42 28.9102 394.43C28.9102 392.44 30.5301 390.82 32.5201 390.82C34.5101 390.82 36.1302 392.44 36.1302 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 394.43C50.5701 396.42 48.9501 398.04 46.9601 398.04C44.9701 398.04 43.3501 396.42 43.3501 394.43C43.3501 392.44 44.9701 390.82 46.9601 390.82C48.9501 390.82 50.5701 392.44 50.5701 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 394.43C65.01 396.42 63.39 398.04 61.4 398.04C59.41 398.04 57.79 396.42 57.79 394.43C57.79 392.44 59.41 390.82 61.4 390.82C63.39 390.82 65.01 392.44 65.01 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 394.43C79.4602 396.42 77.8403 398.04 75.8503 398.04C73.8603 398.04 72.2402 396.42 72.2402 394.43C72.2402 392.44 73.8603 390.82 75.8503 390.82C77.8403 390.82 79.4602 392.44 79.4602 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 394.43C93.9002 396.42 92.2802 398.04 90.2902 398.04C88.3002 398.04 86.6802 396.42 86.6802 394.43C86.6802 392.44 88.3002 390.82 90.2902 390.82C92.2802 390.82 93.9002 392.44 93.9002 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 394.43C108.34 396.42 106.72 398.04 104.73 398.04C102.74 398.04 101.12 396.42 101.12 394.43C101.12 392.44 102.74 390.82 104.73 390.82C106.72 390.82 108.34 392.44 108.34 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 394.43C122.79 396.42 121.17 398.04 119.18 398.04C117.19 398.04 115.57 396.42 115.57 394.43C115.57 392.44 117.19 390.82 119.18 390.82C121.17 390.82 122.79 392.44 122.79 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 394.43C137.23 396.42 135.61 398.04 133.62 398.04C131.63 398.04 130.01 396.42 130.01 394.43C130.01 392.44 131.63 390.82 133.62 390.82C135.61 390.82 137.23 392.44 137.23 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 394.43C151.67 396.42 150.05 398.04 148.06 398.04C146.07 398.04 144.45 396.42 144.45 394.43C144.45 392.44 146.07 390.82 148.06 390.82C150.05 390.82 151.67 392.44 151.67 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 394.43C166.12 396.42 164.5 398.04 162.51 398.04C160.52 398.04 158.9 396.42 158.9 394.43C158.9 392.44 160.52 390.82 162.51 390.82C164.5 390.82 166.12 392.44 166.12 394.43Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 394.43C180.56 396.42 178.94 398.04 176.95 398.04C174.96 398.04 173.34 396.42 173.34 394.43C173.34 392.44 174.96 390.82 176.95 390.82C178.94 390.82 180.56 392.44 180.56 394.43Z"
            fill="#E38135"
          />
          <path
            d="M195 394.43C195 396.42 193.38 398.04 191.39 398.04C189.4 398.04 187.78 396.42 187.78 394.43C187.78 392.44 189.4 390.82 191.39 390.82C193.38 390.82 195 392.44 195 394.43Z"
            fill="#E38135"
          />
          <path
            d="M209.45 394.43C209.45 396.42 207.83 398.04 205.84 398.04C203.85 398.04 202.23 396.42 202.23 394.43C202.23 392.44 203.85 390.82 205.84 390.82C207.83 390.82 209.45 392.44 209.45 394.43Z"
            fill="#E38135"
          />
          <path
            d="M223.89 394.43C223.89 396.42 222.27 398.04 220.28 398.04C218.29 398.04 216.67 396.42 216.67 394.43C216.67 392.44 218.29 390.82 220.28 390.82C222.27 390.82 223.89 392.44 223.89 394.43Z"
            fill="#E38135"
          />
          <path
            d="M238.33 394.43C238.33 396.42 236.71 398.04 234.72 398.04C232.73 398.04 231.11 396.42 231.11 394.43C231.11 392.44 232.73 390.82 234.72 390.82C236.71 390.82 238.33 392.44 238.33 394.43Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 394.43C252.78 396.42 251.16 398.04 249.17 398.04C247.18 398.04 245.56 396.42 245.56 394.43C245.56 392.44 247.18 390.82 249.17 390.82C251.16 390.82 252.78 392.44 252.78 394.43Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 394.43C267.22 396.42 265.6 398.04 263.61 398.04C261.62 398.04 260 396.42 260 394.43C260 392.44 261.62 390.82 263.61 390.82C265.6 390.82 267.22 392.44 267.22 394.43Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 394.43C281.66 396.42 280.04 398.04 278.05 398.04C276.06 398.04 274.44 396.42 274.44 394.43C274.44 392.44 276.06 390.82 278.05 390.82C280.04 390.82 281.66 392.44 281.66 394.43Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 394.43C296.11 396.42 294.49 398.04 292.5 398.04C290.51 398.04 288.89 396.42 288.89 394.43C288.89 392.44 290.51 390.82 292.5 390.82C294.49 390.82 296.11 392.44 296.11 394.43Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 394.43C310.55 396.42 308.93 398.04 306.94 398.04C304.95 398.04 303.33 396.42 303.33 394.43C303.33 392.44 304.95 390.82 306.94 390.82C308.93 390.82 310.55 392.44 310.55 394.43Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 394.43C324.99 396.42 323.37 398.04 321.38 398.04C319.39 398.04 317.77 396.42 317.77 394.43C317.77 392.44 319.39 390.82 321.38 390.82C323.37 390.82 324.99 392.44 324.99 394.43Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 394.43C339.44 396.42 337.82 398.04 335.83 398.04C333.84 398.04 332.22 396.42 332.22 394.43C332.22 392.44 333.84 390.82 335.83 390.82C337.82 390.82 339.44 392.44 339.44 394.43Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 394.43C353.88 396.42 352.26 398.04 350.27 398.04C348.28 398.04 346.66 396.42 346.66 394.43C346.66 392.44 348.28 390.82 350.27 390.82C352.26 390.82 353.88 392.44 353.88 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 394.43C368.32 396.42 366.7 398.04 364.71 398.04C362.72 398.04 361.1 396.42 361.1 394.43C361.1 392.44 362.72 390.82 364.71 390.82C366.7 390.82 368.32 392.44 368.32 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 394.43C382.77 396.42 381.15 398.04 379.16 398.04C377.17 398.04 375.55 396.42 375.55 394.43C375.55 392.44 377.17 390.82 379.16 390.82C381.15 390.82 382.77 392.44 382.77 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 394.43C397.21 396.42 395.59 398.04 393.6 398.04C391.61 398.04 389.99 396.42 389.99 394.43C389.99 392.44 391.61 390.82 393.6 390.82C395.59 390.82 397.21 392.44 397.21 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 394.43C411.65 396.42 410.03 398.04 408.04 398.04C406.05 398.04 404.43 396.42 404.43 394.43C404.43 392.44 406.05 390.82 408.04 390.82C410.03 390.82 411.65 392.44 411.65 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 394.43C426.1 396.42 424.48 398.04 422.49 398.04C420.5 398.04 418.88 396.42 418.88 394.43C418.88 392.44 420.5 390.82 422.49 390.82C424.48 390.82 426.1 392.44 426.1 394.43Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 408.88C21.68 410.87 20.06 412.49 18.07 412.49C16.08 412.49 14.46 410.87 14.46 408.88C14.46 406.89 16.08 405.27 18.07 405.27C20.06 405.27 21.68 406.89 21.68 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 408.88C36.1302 410.87 34.5101 412.49 32.5201 412.49C30.5301 412.49 28.9102 410.87 28.9102 408.88C28.9102 406.89 30.5301 405.27 32.5201 405.27C34.5101 405.27 36.1302 406.89 36.1302 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 408.88C50.5701 410.87 48.9501 412.49 46.9601 412.49C44.9701 412.49 43.3501 410.87 43.3501 408.88C43.3501 406.89 44.9701 405.27 46.9601 405.27C48.9501 405.27 50.5701 406.89 50.5701 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 408.88C65.01 410.87 63.39 412.49 61.4 412.49C59.41 412.49 57.79 410.87 57.79 408.88C57.79 406.89 59.41 405.27 61.4 405.27C63.39 405.27 65.01 406.89 65.01 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 408.88C79.4602 410.87 77.8403 412.49 75.8503 412.49C73.8603 412.49 72.2402 410.87 72.2402 408.88C72.2402 406.89 73.8603 405.27 75.8503 405.27C77.8403 405.27 79.4602 406.89 79.4602 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 408.88C93.9002 410.87 92.2802 412.49 90.2902 412.49C88.3002 412.49 86.6802 410.87 86.6802 408.88C86.6802 406.89 88.3002 405.27 90.2902 405.27C92.2802 405.27 93.9002 406.89 93.9002 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 408.88C108.34 410.87 106.72 412.49 104.73 412.49C102.74 412.49 101.12 410.87 101.12 408.88C101.12 406.89 102.74 405.27 104.73 405.27C106.72 405.27 108.34 406.89 108.34 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 408.88C122.79 410.87 121.17 412.49 119.18 412.49C117.19 412.49 115.57 410.87 115.57 408.88C115.57 406.89 117.19 405.27 119.18 405.27C121.17 405.27 122.79 406.89 122.79 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 408.88C137.23 410.87 135.61 412.49 133.62 412.49C131.63 412.49 130.01 410.87 130.01 408.88C130.01 406.89 131.63 405.27 133.62 405.27C135.61 405.27 137.23 406.89 137.23 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 408.88C151.67 410.87 150.05 412.49 148.06 412.49C146.07 412.49 144.45 410.87 144.45 408.88C144.45 406.89 146.07 405.27 148.06 405.27C150.05 405.27 151.67 406.89 151.67 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 408.88C166.12 410.87 164.5 412.49 162.51 412.49C160.52 412.49 158.9 410.87 158.9 408.88C158.9 406.89 160.52 405.27 162.51 405.27C164.5 405.27 166.12 406.89 166.12 408.88Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 408.88C180.56 410.87 178.94 412.49 176.95 412.49C174.96 412.49 173.34 410.87 173.34 408.88C173.34 406.89 174.96 405.27 176.95 405.27C178.94 405.27 180.56 406.89 180.56 408.88Z"
            fill="#E38135"
          />
          <path
            d="M195 408.88C195 410.87 193.38 412.49 191.39 412.49C189.4 412.49 187.78 410.87 187.78 408.88C187.78 406.89 189.4 405.27 191.39 405.27C193.38 405.27 195 406.89 195 408.88Z"
            fill="#E38135"
          />
          <path
            d="M209.45 408.88C209.45 410.87 207.83 412.49 205.84 412.49C203.85 412.49 202.23 410.87 202.23 408.88C202.23 406.89 203.85 405.27 205.84 405.27C207.83 405.27 209.45 406.89 209.45 408.88Z"
            fill="#E38135"
          />
          <path
            d="M223.89 408.88C223.89 410.87 222.27 412.49 220.28 412.49C218.29 412.49 216.67 410.87 216.67 408.88C216.67 406.89 218.29 405.27 220.28 405.27C222.27 405.27 223.89 406.89 223.89 408.88Z"
            fill="#E38135"
          />
          <path
            d="M238.33 408.88C238.33 410.87 236.71 412.49 234.72 412.49C232.73 412.49 231.11 410.87 231.11 408.88C231.11 406.89 232.73 405.27 234.72 405.27C236.71 405.27 238.33 406.89 238.33 408.88Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 408.88C252.78 410.87 251.16 412.49 249.17 412.49C247.18 412.49 245.56 410.87 245.56 408.88C245.56 406.89 247.18 405.27 249.17 405.27C251.16 405.27 252.78 406.89 252.78 408.88Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 408.88C267.22 410.87 265.6 412.49 263.61 412.49C261.62 412.49 260 410.87 260 408.88C260 406.89 261.62 405.27 263.61 405.27C265.6 405.27 267.22 406.89 267.22 408.88Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 408.88C281.66 410.87 280.04 412.49 278.05 412.49C276.06 412.49 274.44 410.87 274.44 408.88C274.44 406.89 276.06 405.27 278.05 405.27C280.04 405.27 281.66 406.89 281.66 408.88Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 408.88C296.11 410.87 294.49 412.49 292.5 412.49C290.51 412.49 288.89 410.87 288.89 408.88C288.89 406.89 290.51 405.27 292.5 405.27C294.49 405.27 296.11 406.89 296.11 408.88Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 408.88C310.55 410.87 308.93 412.49 306.94 412.49C304.95 412.49 303.33 410.87 303.33 408.88C303.33 406.89 304.95 405.27 306.94 405.27C308.93 405.27 310.55 406.89 310.55 408.88Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 408.88C324.99 410.87 323.37 412.49 321.38 412.49C319.39 412.49 317.77 410.87 317.77 408.88C317.77 406.89 319.39 405.27 321.38 405.27C323.37 405.27 324.99 406.89 324.99 408.88Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 408.88C339.44 410.87 337.82 412.49 335.83 412.49C333.84 412.49 332.22 410.87 332.22 408.88C332.22 406.89 333.84 405.27 335.83 405.27C337.82 405.27 339.44 406.89 339.44 408.88Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 408.88C353.88 410.87 352.26 412.49 350.27 412.49C348.28 412.49 346.66 410.87 346.66 408.88C346.66 406.89 348.28 405.27 350.27 405.27C352.26 405.27 353.88 406.89 353.88 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 408.88C368.32 410.87 366.7 412.49 364.71 412.49C362.72 412.49 361.1 410.87 361.1 408.88C361.1 406.89 362.72 405.27 364.71 405.27C366.7 405.27 368.32 406.89 368.32 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 408.88C382.77 410.87 381.15 412.49 379.16 412.49C377.17 412.49 375.55 410.87 375.55 408.88C375.55 406.89 377.17 405.27 379.16 405.27C381.15 405.27 382.77 406.89 382.77 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 408.88C397.21 410.87 395.59 412.49 393.6 412.49C391.61 412.49 389.99 410.87 389.99 408.88C389.99 406.89 391.61 405.27 393.6 405.27C395.59 405.27 397.21 406.89 397.21 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 408.88C411.65 410.87 410.03 412.49 408.04 412.49C406.05 412.49 404.43 410.87 404.43 408.88C404.43 406.89 406.05 405.27 408.04 405.27C410.03 405.27 411.65 406.89 411.65 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 408.88C426.1 410.87 424.48 412.49 422.49 412.49C420.5 412.49 418.88 410.87 418.88 408.88C418.88 406.89 420.5 405.27 422.49 405.27C424.48 405.27 426.1 406.89 426.1 408.88Z"
            fill="#A4292E"
          />
          <path
            d="M21.68 423.32C21.68 425.31 20.06 426.93 18.07 426.93C16.08 426.93 14.46 425.31 14.46 423.32C14.46 421.33 16.08 419.71 18.07 419.71C20.06 419.71 21.68 421.33 21.68 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 4.45998C7.67996 6.44998 6.05998 8.06998 4.06998 8.06998C2.07998 8.06998 0.459961 6.44998 0.459961 4.45998C0.459961 2.46998 2.07998 0.849976 4.06998 0.849976C6.05998 0.849976 7.67996 2.46998 7.67996 4.45998Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 18.91C7.67996 20.9 6.05998 22.52 4.06998 22.52C2.07998 22.52 0.459961 20.9 0.459961 18.91C0.459961 16.92 2.07998 15.3 4.06998 15.3C6.05998 15.3 7.67996 16.92 7.67996 18.91Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 33.35C7.67996 35.34 6.05998 36.96 4.06998 36.96C2.07998 36.96 0.459961 35.34 0.459961 33.35C0.459961 31.36 2.07998 29.74 4.06998 29.74C6.05998 29.74 7.67996 31.36 7.67996 33.35Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 47.7899C7.67996 49.7799 6.05998 51.3999 4.06998 51.3999C2.07998 51.3999 0.459961 49.7799 0.459961 47.7899C0.459961 45.7999 2.07998 44.1799 4.06998 44.1799C6.05998 44.1799 7.67996 45.7999 7.67996 47.7899Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 62.24C7.67996 64.23 6.05998 65.85 4.06998 65.85C2.07998 65.85 0.459961 64.23 0.459961 62.24C0.459961 60.25 2.07998 58.63 4.06998 58.63C6.05998 58.63 7.67996 60.25 7.67996 62.24Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 76.6801C7.67996 78.6701 6.05998 80.2901 4.06998 80.2901C2.07998 80.2901 0.459961 78.6701 0.459961 76.6801C0.459961 74.6901 2.07998 73.0701 4.06998 73.0701C6.05998 73.0701 7.67996 74.6901 7.67996 76.6801Z"
            fill="#A4292E"
          />
          <path
            d="M7.67996 91.12C7.67996 93.11 6.05998 94.73 4.06998 94.73C2.07998 94.73 0.459961 93.11 0.459961 91.12C0.459961 89.13 2.07998 87.51 4.06998 87.51C6.05998 87.51 7.67996 89.13 7.67996 91.12Z"
            fill="#CE6632"
          />
          <path
            d="M7.67996 105.57C7.67996 107.56 6.05998 109.18 4.06998 109.18C2.07998 109.18 0.459961 107.56 0.459961 105.57C0.459961 103.58 2.07998 101.96 4.06998 101.96C6.05998 101.96 7.67996 103.58 7.67996 105.57Z"
            fill="#CE6632"
          />
          <path
            d="M7.67996 120.01C7.67996 122 6.05998 123.62 4.06998 123.62C2.07998 123.62 0.459961 122 0.459961 120.01C0.459961 118.02 2.07998 116.4 4.06998 116.4C6.05998 116.4 7.67996 118.02 7.67996 120.01Z"
            fill="#CE6632"
          />
          <path
            d="M7.67996 134.45C7.67996 136.44 6.05998 138.06 4.06998 138.06C2.07998 138.06 0.459961 136.44 0.459961 134.45C0.459961 132.46 2.07998 130.84 4.06998 130.84C6.05998 130.84 7.67996 132.46 7.67996 134.45Z"
            fill="#CE6632"
          />
          <path
            d="M7.67996 148.9C7.67996 150.89 6.05998 152.51 4.06998 152.51C2.07998 152.51 0.459961 150.89 0.459961 148.9C0.459961 146.91 2.07998 145.29 4.06998 145.29C6.05998 145.29 7.67996 146.91 7.67996 148.9Z"
            fill="#A5DDEE"
          />
          <path
            d="M7.67996 163.34C7.67996 165.33 6.05998 166.95 4.06998 166.95C2.07998 166.95 0.459961 165.33 0.459961 163.34C0.459961 161.35 2.07998 159.73 4.06998 159.73C6.05998 159.73 7.67996 161.35 7.67996 163.34Z"
            fill="#A5DDEE"
          />
          <path
            d="M7.67996 177.78C7.67996 179.77 6.05998 181.39 4.06998 181.39C2.07998 181.39 0.459961 179.77 0.459961 177.78C0.459961 175.79 2.07998 174.17 4.06998 174.17C6.05998 174.17 7.67996 175.79 7.67996 177.78Z"
            fill="#809DBB"
          />
          <path
            d="M7.67996 192.23C7.67996 194.22 6.05998 195.84 4.06998 195.84C2.07998 195.84 0.459961 194.22 0.459961 192.23C0.459961 190.24 2.07998 188.62 4.06998 188.62C6.05998 188.62 7.67996 190.24 7.67996 192.23Z"
            fill="#809DBB"
          />
          <path
            d="M7.67996 206.67C7.67996 208.66 6.05998 210.28 4.06998 210.28C2.07998 210.28 0.459961 208.66 0.459961 206.67C0.459961 204.68 2.07998 203.06 4.06998 203.06C6.05998 203.06 7.67996 204.68 7.67996 206.67Z"
            fill="#E38135"
          />
          <path
            d="M7.67996 221.11C7.67996 223.1 6.05998 224.72 4.06998 224.72C2.07998 224.72 0.459961 223.1 0.459961 221.11C0.459961 219.12 2.07998 217.5 4.06998 217.5C6.05998 217.5 7.67996 219.12 7.67996 221.11Z"
            fill="#E38135"
          />
          <path
            d="M7.67996 235.56C7.67996 237.55 6.05998 239.17 4.06998 239.17C2.07998 239.17 0.459961 237.55 0.459961 235.56C0.459961 233.57 2.07998 231.95 4.06998 231.95C6.05998 231.95 7.67996 233.57 7.67996 235.56Z"
            fill="#E38135"
          />
          <path
            d="M7.67996 250C7.67996 251.99 6.05998 253.61 4.06998 253.61C2.07998 253.61 0.459961 251.99 0.459961 250C0.459961 248.01 2.07998 246.39 4.06998 246.39C6.05998 246.39 7.67996 248.01 7.67996 250Z"
            fill="#E38135"
          />
          <path
            d="M7.67996 264.44C7.67996 266.43 6.05998 268.05 4.06998 268.05C2.07998 268.05 0.459961 266.43 0.459961 264.44C0.459961 262.45 2.07998 260.83 4.06998 260.83C6.05998 260.83 7.67996 262.45 7.67996 264.44Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 278.89C7.67996 280.88 6.05998 282.5 4.06998 282.5C2.07998 282.5 0.459961 280.88 0.459961 278.89C0.459961 276.9 2.07998 275.28 4.06998 275.28C6.05998 275.28 7.67996 276.9 7.67996 278.89Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 293.33C7.67996 295.32 6.05998 296.94 4.06998 296.94C2.07998 296.94 0.459961 295.32 0.459961 293.33C0.459961 291.34 2.07998 289.72 4.06998 289.72C6.05998 289.72 7.67996 291.34 7.67996 293.33Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 307.77C7.67996 309.76 6.05998 311.38 4.06998 311.38C2.07998 311.38 0.459961 309.76 0.459961 307.77C0.459961 305.78 2.07998 304.16 4.06998 304.16C6.05998 304.16 7.67996 305.78 7.67996 307.77Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 322.22C7.67996 324.21 6.05998 325.83 4.06998 325.83C2.07998 325.83 0.459961 324.21 0.459961 322.22C0.459961 320.23 2.07998 318.61 4.06998 318.61C6.05998 318.61 7.67996 320.23 7.67996 322.22Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 336.66C7.67996 338.65 6.05998 340.27 4.06998 340.27C2.07998 340.27 0.459961 338.65 0.459961 336.66C0.459961 334.67 2.07998 333.05 4.06998 333.05C6.05998 333.05 7.67996 334.67 7.67996 336.66Z"
            fill="#FAA338"
          />
          <path
            d="M7.67996 351.1C7.67996 353.09 6.05998 354.71 4.06998 354.71C2.07998 354.71 0.459961 353.09 0.459961 351.1C0.459961 349.11 2.07998 347.49 4.06998 347.49C6.05998 347.49 7.67996 349.11 7.67996 351.1Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 365.55C7.67996 367.54 6.05998 369.16 4.06998 369.16C2.07998 369.16 0.459961 367.54 0.459961 365.55C0.459961 363.56 2.07998 361.94 4.06998 361.94C6.05998 361.94 7.67996 363.56 7.67996 365.55Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 379.99C7.67996 381.98 6.05998 383.6 4.06998 383.6C2.07998 383.6 0.459961 381.98 0.459961 379.99C0.459961 378 2.07998 376.38 4.06998 376.38C6.05998 376.38 7.67996 378 7.67996 379.99Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 394.43C7.67996 396.42 6.05998 398.04 4.06998 398.04C2.07998 398.04 0.459961 396.42 0.459961 394.43C0.459961 392.44 2.07998 390.82 4.06998 390.82C6.05998 390.82 7.67996 392.44 7.67996 394.43Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 408.88C7.67996 410.87 6.05998 412.49 4.06998 412.49C2.07998 412.49 0.459961 410.87 0.459961 408.88C0.459961 406.89 2.07998 405.27 4.06998 405.27C6.05998 405.27 7.67996 406.89 7.67996 408.88Z"
            fill="#FADC92"
          />
          <path
            d="M7.67996 423.32C7.67996 425.31 6.05998 426.93 4.06998 426.93C2.07998 426.93 0.459961 425.31 0.459961 423.32C0.459961 421.33 2.07998 419.71 4.06998 419.71C6.05998 419.71 7.67996 421.33 7.67996 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M36.1302 423.32C36.1302 425.31 34.5101 426.93 32.5201 426.93C30.5301 426.93 28.9102 425.31 28.9102 423.32C28.9102 421.33 30.5301 419.71 32.5201 419.71C34.5101 419.71 36.1302 421.33 36.1302 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M50.5701 423.32C50.5701 425.31 48.9501 426.93 46.9601 426.93C44.9701 426.93 43.3501 425.31 43.3501 423.32C43.3501 421.33 44.9701 419.71 46.9601 419.71C48.9501 419.71 50.5701 421.33 50.5701 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M65.01 423.32C65.01 425.31 63.39 426.93 61.4 426.93C59.41 426.93 57.79 425.31 57.79 423.32C57.79 421.33 59.41 419.71 61.4 419.71C63.39 419.71 65.01 421.33 65.01 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M79.4602 423.32C79.4602 425.31 77.8403 426.93 75.8503 426.93C73.8603 426.93 72.2402 425.31 72.2402 423.32C72.2402 421.33 73.8603 419.71 75.8503 419.71C77.8403 419.71 79.4602 421.33 79.4602 423.32Z"
            fill="#FADC92"
          />
          <path
            d="M93.9002 423.32C93.9002 425.31 92.2802 426.93 90.2902 426.93C88.3002 426.93 86.6802 425.31 86.6802 423.32C86.6802 421.33 88.3002 419.71 90.2902 419.71C92.2802 419.71 93.9002 421.33 93.9002 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M108.34 423.32C108.34 425.31 106.72 426.93 104.73 426.93C102.74 426.93 101.12 425.31 101.12 423.32C101.12 421.33 102.74 419.71 104.73 419.71C106.72 419.71 108.34 421.33 108.34 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M122.79 423.32C122.79 425.31 121.17 426.93 119.18 426.93C117.19 426.93 115.57 425.31 115.57 423.32C115.57 421.33 117.19 419.71 119.18 419.71C121.17 419.71 122.79 421.33 122.79 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M137.23 423.32C137.23 425.31 135.61 426.93 133.62 426.93C131.63 426.93 130.01 425.31 130.01 423.32C130.01 421.33 131.63 419.71 133.62 419.71C135.61 419.71 137.23 421.33 137.23 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M151.67 423.32C151.67 425.31 150.05 426.93 148.06 426.93C146.07 426.93 144.45 425.31 144.45 423.32C144.45 421.33 146.07 419.71 148.06 419.71C150.05 419.71 151.67 421.33 151.67 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M166.12 423.32C166.12 425.31 164.5 426.93 162.51 426.93C160.52 426.93 158.9 425.31 158.9 423.32C158.9 421.33 160.52 419.71 162.51 419.71C164.5 419.71 166.12 421.33 166.12 423.32Z"
            fill="#FAA338"
          />
          <path
            d="M180.56 423.32C180.56 425.31 178.94 426.93 176.95 426.93C174.96 426.93 173.34 425.31 173.34 423.32C173.34 421.33 174.96 419.71 176.95 419.71C178.94 419.71 180.56 421.33 180.56 423.32Z"
            fill="#E38135"
          />
          <path
            d="M195 423.32C195 425.31 193.38 426.93 191.39 426.93C189.4 426.93 187.78 425.31 187.78 423.32C187.78 421.33 189.4 419.71 191.39 419.71C193.38 419.71 195 421.33 195 423.32Z"
            fill="#E38135"
          />
          <path
            d="M209.45 423.32C209.45 425.31 207.83 426.93 205.84 426.93C203.85 426.93 202.23 425.31 202.23 423.32C202.23 421.33 203.85 419.71 205.84 419.71C207.83 419.71 209.45 421.33 209.45 423.32Z"
            fill="#E38135"
          />
          <path
            d="M223.89 423.32C223.89 425.31 222.27 426.93 220.28 426.93C218.29 426.93 216.67 425.31 216.67 423.32C216.67 421.33 218.29 419.71 220.28 419.71C222.27 419.71 223.89 421.33 223.89 423.32Z"
            fill="#E38135"
          />
          <path
            d="M238.33 423.32C238.33 425.31 236.71 426.93 234.72 426.93C232.73 426.93 231.11 425.31 231.11 423.32C231.11 421.33 232.73 419.71 234.72 419.71C236.71 419.71 238.33 421.33 238.33 423.32Z"
            fill="#809DBB"
          />
          <path
            d="M252.78 423.32C252.78 425.31 251.16 426.93 249.17 426.93C247.18 426.93 245.56 425.31 245.56 423.32C245.56 421.33 247.18 419.71 249.17 419.71C251.16 419.71 252.78 421.33 252.78 423.32Z"
            fill="#809DBB"
          />
          <path
            d="M267.22 423.32C267.22 425.31 265.6 426.93 263.61 426.93C261.62 426.93 260 425.31 260 423.32C260 421.33 261.62 419.71 263.61 419.71C265.6 419.71 267.22 421.33 267.22 423.32Z"
            fill="#A5DDEE"
          />
          <path
            d="M281.66 423.32C281.66 425.31 280.04 426.93 278.05 426.93C276.06 426.93 274.44 425.31 274.44 423.32C274.44 421.33 276.06 419.71 278.05 419.71C280.04 419.71 281.66 421.33 281.66 423.32Z"
            fill="#A5DDEE"
          />
          <path
            d="M296.11 423.32C296.11 425.31 294.49 426.93 292.5 426.93C290.51 426.93 288.89 425.31 288.89 423.32C288.89 421.33 290.51 419.71 292.5 419.71C294.49 419.71 296.11 421.33 296.11 423.32Z"
            fill="#CE6632"
          />
          <path
            d="M310.55 423.32C310.55 425.31 308.93 426.93 306.94 426.93C304.95 426.93 303.33 425.31 303.33 423.32C303.33 421.33 304.95 419.71 306.94 419.71C308.93 419.71 310.55 421.33 310.55 423.32Z"
            fill="#CE6632"
          />
          <path
            d="M324.99 423.32C324.99 425.31 323.37 426.93 321.38 426.93C319.39 426.93 317.77 425.31 317.77 423.32C317.77 421.33 319.39 419.71 321.38 419.71C323.37 419.71 324.99 421.33 324.99 423.32Z"
            fill="#CE6632"
          />
          <path
            d="M339.44 423.32C339.44 425.31 337.82 426.93 335.83 426.93C333.84 426.93 332.22 425.31 332.22 423.32C332.22 421.33 333.84 419.71 335.83 419.71C337.82 419.71 339.44 421.33 339.44 423.32Z"
            fill="#CE6632"
          />
          <path
            d="M353.88 423.32C353.88 425.31 352.26 426.93 350.27 426.93C348.28 426.93 346.66 425.31 346.66 423.32C346.66 421.33 348.28 419.71 350.27 419.71C352.26 419.71 353.88 421.33 353.88 423.32Z"
            fill="#A4292E"
          />
          <path
            d="M368.32 423.32C368.32 425.31 366.7 426.93 364.71 426.93C362.72 426.93 361.1 425.31 361.1 423.32C361.1 421.33 362.72 419.71 364.71 419.71C366.7 419.71 368.32 421.33 368.32 423.32Z"
            fill="#A4292E"
          />
          <path
            d="M382.77 423.32C382.77 425.31 381.15 426.93 379.16 426.93C377.17 426.93 375.55 425.31 375.55 423.32C375.55 421.33 377.17 419.71 379.16 419.71C381.15 419.71 382.77 421.33 382.77 423.32Z"
            fill="#A4292E"
          />
          <path
            d="M397.21 423.32C397.21 425.31 395.59 426.93 393.6 426.93C391.61 426.93 389.99 425.31 389.99 423.32C389.99 421.33 391.61 419.71 393.6 419.71C395.59 419.71 397.21 421.33 397.21 423.32Z"
            fill="#A4292E"
          />
          <path
            d="M411.65 423.32C411.65 425.31 410.03 426.93 408.04 426.93C406.05 426.93 404.43 425.31 404.43 423.32C404.43 421.33 406.05 419.71 408.04 419.71C410.03 419.71 411.65 421.33 411.65 423.32Z"
            fill="#A4292E"
          />
          <path
            d="M426.1 423.32C426.1 425.31 424.48 426.93 422.49 426.93C420.5 426.93 418.88 425.31 418.88 423.32C418.88 421.33 420.5 419.71 422.49 419.71C424.48 419.71 426.1 421.33 426.1 423.32Z"
            fill="#A4292E"
          />
        </g>
        <defs>
          <clipPath id="clip0_37_983">
            <rect
              width="425.64"
              height="426.08"
              fill="white"
              transform="translate(0.459961 0.849976)"
            />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}
