export const isAuthenticated = (): boolean => {
  const token = window.localStorage.getItem('jwt')
  if (!token) return false

  const jwtToken = JSON.parse(atob(token.split('.')[1]))

  return new Date(jwtToken.exp * 1000) > new Date()
}

export const setToken = (token: string) => window.localStorage.setItem('jwt', token)

export const removeToken = () => window.localStorage.removeItem('jwt')

export const getToken = () => window.localStorage.getItem('jwt')

export const hasToken = () => !!window.localStorage.getItem('jwt')
